import React, { useEffect, useState} from "react";
import NoDataFound from "../assets/Nodatafound.png";
import styles from "./ManageEmployee.module.css"
import { SearchBox, FontIcon, mergeStyles, mergeStyleSets, MessageBarType, Dropdown, Callout, DirectionalHint, MessageBar } from '@fluentui/react';
import { Shimmer } from '@fluentui/react';
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosPrivateCall } from "../constants";
import Nomatchimg from "../assets/no.png"
import { Spinner, SpinnerSize } from "@fluentui/react";
import InterviewReschedule from "./InterviewReschedule";
import { DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import { EventCancelPopup } from "../components/EventCancelPopup";
import { loginRequest } from "../utils/authConfig";
import { useMsal } from "@azure/msal-react";
import {  ISOdateToCustomDate } from "../utils/helpers";

import  mailContent  from '../utils/emailContent';
import { graphConfig } from "../utils/authConfig";


const searchIcon = { iconName: 'Search' };
const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px',
    color: '#999DA0',
    cursor: 'pointer',
    userSelect: 'none',
});

const iconClass1 = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    margin: '0 ',
    color: '#999DA0',
    cursor: 'pointer'
});

const searchFieldStyles = mergeStyleSets({
    root: { width: '185px', },
});

const dropdownStyles = {
    dropdown: { width: 200 },
};

const CalloutNameStyles = {
    calloutMain: {
        background: '#EDF2F6',
        padding: '2',

    },
}


const messageBarStyles = {
    content: {
        maxWidth: 620,
        minWidth: 450,
    }
}

const DropdownControlledMultiExampleOptions = [
    { key: 'allHeader', text: 'All', itemType: DropdownMenuItemType.Header },
    { key: 'myInterviewHeader', text: 'My Interview', itemType: DropdownMenuItemType.Header },
    { key: 'accManagerHeader', text: 'Account Managers', itemType: DropdownMenuItemType.Header },
    { key: 'Dharshana', text: 'Dharshana' },
];
let items = Array(4).fill(null);
function InterviewListing(props) {

    const [showPopup1, setShowPopup1] = useState(false)
    const [showMessageBar, setShowMessageBar] = useState(false);
    const [cancelPop, setCancelPop] = useState(false);
    const [rescheduleValue, setReScheduleValue] = useState({});
    const location = useLocation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [interviewList, setInterviewList] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [updateCallout, setUpdateCallout] = useState(false);
    const [isUserSearching, setIsUserSearching] = useState(false)
    const [fetchOptions, setFetchOptions] = useState({
        skip: 0,
        limit: 15,
        sort_field: 'updatedAt',
        sort_type: -1,
        search_field: ''
    })
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const [meetObjValue, setMeetObjValue] = useState()
    const [selectedKeys, setSelectedKeys] = useState()
    const [hoverCallout, setHoverCallout] = useState('');

    const { instance, accounts } = useMsal();
	const request = {
        scopes: ["openid", "profile", "User.Read", "User.ReadWrite.All", "Mail.Send", "Calendars.ReadWrite","OnlineMeetingArtifact.Read.All","OnlineMeetings.ReadWrite","OnlineMeetings.Read","OnlineMeetingRecording.Read.All"],
		account: accounts[0],
	};    
    
    const navigateTo = useNavigate();
    const userData = location.state;

    const addEllipsisToName = (name) => {
        if (name?.length > 13) {
            let new_name = name.substring(0, 13).padEnd(16, '.')
            return new_name
        }
        else return name
    }

    const columns = [
        {
            columnKey: 'Meeting ID',
            label: 'Meeting ID',
        }, {
            columnKey: 'Demand ID',
            label: 'Demand ID',

        }, {
            columnKey: 'Candidate ID',
            label: 'Candidate ID',
        }, {
            columnKey: 'Candidate Name',
            label: 'Candidate Name',
        },
        {
            columnKey: 'Mobile',
            label: 'Mobile'
        }, {
            columnKey: 'Candidate Email',
            label: 'Candidate Email'
        }, {
            columnKey: 'Interview Email',
            label: 'Interview Email',
        }, {
            columnKey: 'Scheduled By',
            label: 'Scheduled By',
        },

        {
            columnKey: 'Client',
            label: 'Client',
        },
        {
            columnKey: 'Interview Date',
            label: 'Interview Date',
        },
        {
            columnKey: 'Start time',
            label: 'Start time',
        },
        {
            columnKey: 'Duration',
            label: 'Duration',
        }, {
            columnKey: 'Status',
            label: 'Status',
        },

        {
            columnKey: 'More Options',
            label: ' '
        }];

    const token = localStorage.getItem("token");
    let base64Url = token.split(".")[1];
    let decodedValue = JSON.parse(window.atob(base64Url));
    const user_id = decodedValue.user_id
    useEffect(() => {
        axiosPrivateCall.patch('api/v1/interview/trackstat', { user_id: decodedValue.user_id })
            .then(() => {
                console.log("Cron work!!")
            }).catch((err) => {
                console.log(`Cron work: ${err}`);
            })
    }, [])
    

    useEffect(() => {
        if (showMessageBar) {
            setTimeout(() => {
                setShowMessageBar(!showMessageBar)
            }, 3500)
        }

    }, [showMessageBar])

    useEffect(() => {
        getInterviewData()
        setHasMore(true)
        setCancelPop(false)
        setFetchOptions({ ...fetchOptions, skip: 0, limit: 20 })
    }, [isModalOpen, fetchOptions.sort_field, fetchOptions.sort_type])
    
    const getInterviewData = async () => {
        setIsDataLoaded(false);
        try {
            const loginResponse = await instance.loginPopup(loginRequest);
    
            const tokenResponse = await instance.acquireTokenSilent({
                ...request,
                account: loginResponse.account,
            });
    
            const accessToken = tokenResponse.accessToken;
            localStorage.setItem('accessToken', accessToken);
    
        } catch (error) {
            console.error("Error during login:", error);
        }
        const res= await axiosPrivateCall.get(`api/v1/interview/getHierarchyListing?skip=${fetchOptions.skip}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
                const data = res.data
                setInterviewList(res.data);
                setIsDataLoaded(true); 
                        
                //Filter incomplete OnlineMeetings Data
                const filteredEvents = data.filter(event => event.status !== 'Completed' && event.status !== 'Cancelled' && event.status !== 'On Progress');
    
                if(filteredEvents.length > 0){
                    const accessToken = localStorage.getItem('accessToken')
                    const headers = new Headers();  
                    const bearer = `Bearer ${accessToken}`;
                    headers.append('Authorization', bearer);
                    headers.append('Content-Type', 'application/json');         
                
                const attendanceReports = await Promise.all(filteredEvents.map(async (obj) => {
                    try {
                    const event_id = obj.event_id;
                    const attendanceReportURL = `https://graph.microsoft.com/v1.0/me/onlineMeetings/${event_id}/attendanceReports`;                          
                   
                        const attendanceReportResponse = await fetch(attendanceReportURL, {
                            method: 'GET',
                            headers: headers
                        });
                        const reportData = await attendanceReportResponse.json();  //attendance reports response
                        if(reportData.value.length > 0){                                                                              
                        const attendanceRecordsURL = `https://graph.microsoft.com/v1.0/me/onlineMeetings/${event_id}/attendanceReports/${reportData.value[0].id}/attendanceRecords`; 
                        
                        const attendanceRecordsResponse = await fetch(attendanceRecordsURL, {
                                method: 'GET',
                                headers: headers
                            });
                            const recordsData =await attendanceRecordsResponse.json();  // attendance records response

                            let attendance_records=[];
                            for(let i=0;i<recordsData.value.length; i++){

                                let email = recordsData.value[i].emailAddress !== null
                                ? recordsData.value[i].emailAddress.replace(/_/g, '@').replace(/#.*$/, '')
                                : recordsData.value[i].emailAddress;

                                const records={                                    
                                    name: recordsData.value[i].identity.displayName,
                                    emailAddress: email,
                                    joinDateTime: recordsData.value[i].attendanceIntervals[0].joinDateTime,
                                    leaveDateTime: recordsData.value[i].attendanceIntervals[0].leaveDateTime,
                                    duration: recordsData.value[i].totalAttendanceInSeconds
                                }                               
                                attendance_records.push(records);                                  
                            }

                            const reportsObject ={
                            attendance_reports_id: reportData.value[0].id,
                            meetingStartDateTime: reportData.value[0].meetingStartDateTime,
                            meetingEndDateTime: reportData.value[0].meetingEndDateTime,
                            totalParticipantCount: reportData.value[0].totalParticipantCount,
                            attendance_records: attendance_records
                            }  

                            //Update the status Completed and store the attendance records response to the Mango DB
                             await axiosPrivateCall.patch(`/api/v1/interview/updateStatus/${obj._id}`, { status: 'Completed', online_meeting_completed_status: reportsObject }).then((res)=>{
                                console.log(res,'Interview Status Updated Successfully!')
                            }).catch((err)=>{
                                console.log("error: ",err)
                            })    
                        }                             
                        
                    } catch (error) {                        
                        return {error: error.message }; 
                    }
                
               
                  }));
            }
                
        }
            

    const [searchTerm, setSearchTerm] = useState('');
    const [SearchData, setSearchData] = useState('')
    const [SplitedData, setSplitedData] = useState('')


    const clearSearchBox = () => {
        setIsUserSearching(false)
        setFetchOptions(prevData => {
            return {
                ...prevData,
                search_field: ''
            }
        })
        setSearchTerm('');
        getInterviewData();
        setHasMore(true)
    }
    
    const fetchMoreData = () => {
        if (isUserSearching) {
            const moreInterviews = SearchData.slice(SplitedData, SplitedData + 20)
            setSplitedData(SplitedData + 20)
            setInterviewList([...interviewList, ...moreInterviews])
            if (SplitedData >= SearchData.length) {
                setHasMore(false)
            }
        }
        else {
            axiosPrivateCall.get(`api/v1/interview/getHierarchyListing?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
                .then(res => {
                    const moreInterviews = res.data;
                    setInterviewList([...interviewList, ...moreInterviews])
                    if (moreInterviews.length < 20 || moreInterviews.length === 0) {
                        setHasMore(false)
                    }
                    setFetchOptions((prevState) => {

                        return {
                            ...prevState,
                            skip: fetchOptions.skip + fetchOptions.limit,
                        }
                    })
                }).catch(e => {
                    console.log(e)
                })
        }
    }

  
    const ISOdateToCustomTime = (value) => {
        const date = new Date(value);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
        return timeString;
    }

    const scheduleMeet = async (id) => {
        setMeetObjValue(id)
        setUpdateCallout(!updateCallout)
        setShowPopup1(!showPopup1);
    }

    const handleSearchInputChange = (e) => {
        const { value } = e?.target || '';
        setSearchTerm(value ? value : "");
    }

    const onChange = (event, item) => {
        if (item) {
            setSelectedKeys(
                item.selected ? [...selectedKeys, item.key] : selectedKeys.filter(key => key !== item.key),
            );
        }
    };
    const cancelStatu = async(cancelComments) => {
        const accessToken = localStorage.getItem('accessToken')
        if(rescheduleValue.status === 'Cancelled'){
            console.log("Meeting has been Cancelled Already!")
        } else{
            const meetId = rescheduleValue._id;
            const data = localStorage.getItem("employee_list_data")
            const parsedData = JSON.parse(data)
            const newDetail = {
                title: rescheduleValue.title,
                level: rescheduleValue.level,
                level_title: rescheduleValue.level_title,
                startDateTime: rescheduleValue.startDateTime,
                duration: rescheduleValue.duration,
                interviewer_email: rescheduleValue.interviewer_email,
                status: 'Cancelled',
                cancel_comments: cancelComments, 
                updated_by: parsedData.first_name + " " + parsedData.last_name,
                created_by: parsedData._id 
            };

                const date = new Date(rescheduleValue.startDateTime);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const formattedDate = `${day}-${month}-${year}`;

                const number = [rescheduleValue.mobile_no];
                const text = {
                    name: rescheduleValue.candidate_name,
                    job: rescheduleValue.title,
                    date_w: formattedDate,
                    time_w: rescheduleValue.timings,                  
                    
                }
                await axiosPrivateCall.post(`/api/v1/interview/sendmessage?template=sight_interview_cancel_temp `,{number:number,text:text}).then((res)=>{
                    console.log("Whatsapp cancelled message send successfully!")
                }).catch((err)=>{
                    console.log("error: ",err)
                })            
            const updatedDetails = [...rescheduleValue.interview_schedule_details, newDetail];
            await axiosPrivateCall.patch(`/api/v1/interview/updateStatus/${meetId}`, { status: "Cancelled", interview_schedule_details: updatedDetails }).then((res) => {
                setCancelPop(!cancelPop)
                setReScheduleValue({})
            }).catch((err) => {
                throw err;
            })
        }
    }
    const cancelEvent = async (cancelComments) => {
        try {
            const eventId = rescheduleValue.event_id;
            const accessToken = localStorage.getItem('accessToken')
            
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;
            headers.append('Authorization', bearer);
            headers.append('Content-Type', 'application/json');
            const deleteEndpoint = `https://graph.microsoft.com/v1.0/me/onlineMeetings/${eventId}`;
            const deleteResponse = await fetch(deleteEndpoint, {
                method: 'DELETE',
                headers: headers,
            }).then(() => cancelStatu(cancelComments)) 
            return deleteResponse;
        } catch (error) {
            console.error('Error:', error);
            throw error; 
        }
    };
    const handleUpdate = (showPopup, cancelComments) => {
        if (!showPopup) {
            cancelEvent(cancelComments); 
        }
    }
    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <InterviewReschedule
                    showPopup1={showPopup1}
                    setShowPopup1={setShowPopup1}
                    setCancelPop={setCancelPop}
                    cancelPop={cancelPop}
                    setReScheduleValue={setReScheduleValue}
                    meetObj={meetObjValue}
                    updateCallout={updateCallout}
                    setUpdateCallout={setUpdateCallout}
                    setShowMessageBar={setShowMessageBar}
                    showMessageBar={showMessageBar}
                />

                <div className={styles.nav_container}>
                    <div className={styles.title}>Interview Listing</div>

                    {showMessageBar && <div >

                        <MessageBar onDismiss={() => setShowMessageBar(!showMessageBar)} styles={messageBarStyles} dismissButtonAriaLabel="Close" messageBarType={MessageBarType.success}>
                            Scheduled Successfully
                        </MessageBar>
                    </div>}

                    <div className={styles.nav_items}>
                        <Dropdown
                            placeholder='Select Filter'
                            selectedKeys={selectedKeys}
                            onChange={onChange}
                            options={DropdownControlledMultiExampleOptions}
                            multiSelect
                            styles={dropdownStyles} />

                        <SearchBox
                            onChange={handleSearchInputChange}
                            value={searchTerm}
                            onClear={clearSearchBox} placeholder=" " iconProps={searchIcon} className={styles.search} styles={searchFieldStyles}
                            showIcon />
                        {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) :
                            completed ? (<FontIcon iconName="CheckMark" className={iconClass} />) :
                                (<FontIcon iconName="Download"
                                    className={iconClass} />)}
                    </div>
                </div>
                <EventCancelPopup
                    showPopup={cancelPop}
                    setShowPopup={setCancelPop}
                    onSaveButtonClick={(cancelComments) => handleUpdate(false, cancelComments)}
                    titleValue={rescheduleValue.title}
                />
                <div id="scrollableDiv" className={styles.table_container}>
                    <InfiniteScroll style={{ overflow: 'visible', height: '100%' }} dataLength={interviewList.length} loader={isDataLoaded && interviewList.length >= 20 && <h4>Loading...</h4>}
                        hasMore={hasMore} next={fetchMoreData} scrollableTarget="scrollableDiv">
                        <table>
                            <thead className={styles.table_header}>
                                <tr className={styles.table_row}>
                                    {columns.map((column) =>
                                        <th
                                            className={styles.table_headerContents} key={column.columnKey}>
                                            <div
                                                className={styles.table_heading}>
                                                <div>{column.label}</div>
                                                {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                                            </div>
                                        </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {isDataLoaded && interviewList.length === 0 ? (
                                    <tr>
                                        <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                                            <img src={NoDataFound} alt="NoDataimage" width={"190px"} height={"200px"} />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {isDataLoaded && interviewList?.length === 0 ? (
                                            <tr>
                                                <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                                                    <img src={Nomatchimg} alt="Noimage" width={"190px"} height={"200px"} />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {isDataLoaded && interviewList?.filter(inter => {
                                                    for (const key in inter) {
                                                        if (Array.isArray(inter[key])) {
                                                            for (const item of inter[key]) {
                                                                if (typeof item === 'string' && item.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                                    return true;}
                                                            }
                                                        } else {
                                                           if (inter[key] && typeof inter[key] === 'string' && inter[key].toLowerCase().includes(searchTerm.toLowerCase())) {
                                                                return true; }
                                                        }
                                                    }
                                                    return false; })?.map((data) => (                                                    
                                                    <tr className={styles.table_row} key={data._id}>
                                                        <td onClick={() => scheduleMeet(data)} className={styles.table_dataContents}><span className={styles.custom_link}>{data.MeetID || '-'}</span></td>  
                                                        <td onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${data?.demandObjID}`, { state: { demandData: data?.demandData, demandListing: 'demandListing' } })} className={styles.table_dataContents}>
                                                          <span className={styles.custom_link}>{data?.demand_id}</span>
                                                        </td>
                                                        <td onClick={() => navigateTo(`/candidatelibrary/editcandidate?candidate_id=${data?.candidateObjID}`)} className={styles.table_dataContents}><span className={styles.custom_link}>{data.candidate_id || '-'}</span></td>
                                                        <td className={styles.table_dataContents}>{data.candidate_name || '-'}</td>
                                                        <td className={styles.table_dataContents}>{data.mobile_no || '-'}</td>
                                                        <td className={styles.table_dataContents}>{data.candidate_email || '-'}</td>
                                                        <td onMouseEnter={() => setHoverCallout(data._id)} onMouseLeave={() => setHoverCallout('')}
                                                            id={`ME${data._id}`} className={styles.table_dataContents}>
                                                            {addEllipsisToName(data.interviewer_email.map((e) => (e.name)).join(", ") || '-')}
                                                            {(data.interviewer_email.length > 0 && data.interviewer_email.map((e) => (e.name)).join(", ").length >= 13 && hoverCallout === data._id) &&
                                                                <Callout alignTargetEdge={true}
                                                                    isBeakVisible={false} styles={CalloutNameStyles}
                                                                    directionalHint={DirectionalHint.bottomLeftEdge}
                                                                    target={`#ME${data._id}`}>
                                                                    {data.interviewer_email.map((e) => (e.name))}
                                                                </Callout>
                                                            }
                                                        </td>
                                                        <td className={styles.table_dataContents}>{data.scheduled_by || '-'}</td>
                                                        <td onMouseEnter={() => setHoverCallout(data._id)} onMouseLeave={() => setHoverCallout('')}
                                                            id={`ME${data._id}`} className={styles.table_dataContents}>
                                                            {addEllipsisToName(data.company_name || '-')}
                                                            {(data.company_name.length > 0 && data.company_name.length >= 15 && hoverCallout === data._id) &&
                                                                <Callout alignTargetEdge={true}
                                                                    bounds={e => { console.log('log', e) }}
                                                                    isBeakVisible={false} styles={CalloutNameStyles}
                                                                    directionalHint={DirectionalHint.bottomLeftEdge}
                                                                    target={`#ME${data._id}`}>
                                                                    {data.company_name}
                                                                </Callout>
                                                            }
                                                        </td>
                                                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(data.startDateTime) || '-'}</td>
                                                        <td className={styles.table_dataContents}>{ISOdateToCustomTime(data.startDateTime) || '-'}</td>
                                                        <td className={styles.table_dataContents}>{data.duration || '-'}</td>
                                                        <td className={data.status === "Scheduled" ? styles.scheduled : data.status === "Completed" ?
                                                            styles.completed : data.status === "On Progress" ? styles.onProgress : data.status === "Cancelled" ?
                                                                styles.Cancelled : data.status === "Rescheduled" ? styles.Rescheduled : styles.statusStyle}>{data.status || '-'} </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                        {!isDataLoaded && items.map(data => (
                                            <tr className={styles.table_row} >
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}>
                                                    <div className={styles.moreOptions} >
                                                        <FontIcon iconName='MoreVertical' className={iconClass1} />
                                                    </div>
                                                </td>
                                            </tr>))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );

};

export default InterviewListing;








import { ThemeProvider } from '@fluentui/react';
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import Login from "./Login";
import { UserProvider } from './contexts/UserProvider';
import BdeDashboard from "./pages/BdeDashboard";
import CandidateListing from "./pages/CandidateListing";
import Dashboard from "./pages/Dashboard";
import DemandListing from "./pages/DemandListing";
import EditCandidate from './pages/EditCandidate';
import EditClient from './pages/EditClient';
import EditEmployee from './pages/EditEmployee';
import EditLeadModel from "./pages/EditLeadModal";
import EditSkill from './pages/EditSkill';
import EditVendor from './pages/EditVendor';
import LogReport from "./pages/LogReport";
import ClientListing from "./pages/ManageClient";
import EmployeeListing from "./pages/ManageEmployee";
import SkillListing from "./pages/ManageSkill";
import VendorListing from "./pages/ManageVendor";
import MyCandidate from './pages/MyCandidate';
import MyDemands from './pages/MyDemands';
import MySubmissions from './pages/MySubmissions';
import SubmissionApproval from "./pages/SubmissionApproval";
import SubmissionListing from "./pages/SubmissionListing";
import TrackSubmission from "./pages/TrackSubmission";
import TrackInterview from './pages/TrackInterview';
import UC from "./pages/UC";
// hooks 
import { useLocationPath } from '../src/hooks/useLocationPath';
import Reset from "./ResetPassword";
import LeadsAllListing from "./pages/LeadsAllListing";
import ManageOpportunity from "./pages/ManageOpportunity";
import MyTarget from "./pages/MyTarget";
import BdeTarget from './pages/bdeTarget'
import TargetListing from "./pages/TargetListing";
import ViewSubmission from "./pages/ViewSubmission";
import LeadsActiveListing from "./pages/LeadsActiveListing";
import LeadsPassiveListing from "./pages/LeadsPassiveListing";
import ViewLeadModal from "./pages/ViewLeadModal";
import { SearchResultsProvider } from './components/SeachComponent/SearchResultsContext';
import CandidateComments from "./pages/CandidateComments";
import CandidateHistory from "./pages/CandidateHistory";
import InterviewListing from "./pages/InterviewListing";
import MyInterview from "./pages/MyInterview";
import ViewAndEditDemand from './pages/ViewAndEditDemand';
import ViewCandidate from './pages/ViewCandidate';
import ViewDealsModal from './pages/ViewDeals';
import ViewEmployee from './pages/ViewEmployee';
import ViewandEditOpportunityModel from './pages/ViewandEditOpportunityModel';
import BdTargetListing from './pages/BdTargetListing';
import SubmissionReport from './pages/SubmissionReport';
import { useDispatch } from 'react-redux';
import { axiosPrivateCall } from './constants';
import { resetDashboardData } from './redux_library/slice/dashboarddata.slice';
import { resetBDEDashboardData } from './redux_library/slice/bdeDashboardData.slice'
import TrackOpportunity from './pages/TrackOpportunity';


function App() {
  const location = useLocation();
  const navigateTo = useNavigate();
  const { mainPath, subPath } = useLocationPath();
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();



  useEffect(()=>{
     
    if(window.location.pathname === "/demand/viewandeditdemand"){
     const queryString = window.location.search;
     const params = new URLSearchParams(queryString);
     const demandId = params.get('demand_id');
     localStorage.setItem("Demand_id_mail",demandId)  
    }else  if(window.location.pathname === "/submission/submissionapproval"){
     localStorage.setItem("submissionPath_mail",window.location.pathname)  
    }
  
  },[])

    window.addEventListener("unload", function (e) {
    axiosPrivateCall
      .post("/api/v1/employee/logoutEmployee", {})
      .then((res) => {
        localStorage.removeItem("token");
        dispatch(resetDashboardData());
        dispatch(resetBDEDashboardData()); 
      })
      .catch((e) => console.log(e));
  });


  // Modified decoding code
  const [decodedValue, setDecodedValue] = useState(null);
  useEffect(() => {
    if (token) {
      try {
        let base64Url = token.split('.')[1];
        const decoded = JSON.parse(window.atob(base64Url));
        setDecodedValue(decoded);
      } catch (error) {
        console.error('Error decoding JWT token:', error);
      }
    }
  }, [token]);

  useEffect(() => {
    const allowedRoutes = [
      '/login',
      '/resetpassword',
    ];

    if (!allowedRoutes.includes(mainPath) && !token) {
      navigateTo('/login');
    }

    // Your other authentication logic here...

  }, [mainPath, subPath, navigateTo, decodedValue, token]);

  return (
    <div>
      <ThemeProvider >
        <UserProvider>
          <SearchResultsProvider> {/* Wrap the relevant components */}
            <Routes>
              <Route path='/' element={<Navigate to='/login' />} />
              <Route path='/login' element={<Login />} />

              <Route path='/resetpassword' element={<Reset />} />

              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='bdedashboard' element={<Layout />} >
                  <Route index element={<BdeDashboard />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='dashboard' element={<Layout />} >
                  <Route index element={<Dashboard />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='target' element={<Layout />} >
                  <Route path="managetarget" element={<TargetListing />} />
                  <Route path="mytarget" element={<MyTarget />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='target' element={<Layout />} >
                  <Route path="managetarget" element={<TargetListing />} />
                  <Route path="bdetarget" element={<BdeTarget />} />
                </Route>
              )}
                {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='target' element={<Layout />} >
                  <Route path="bdmanagetarget" element={<BdTargetListing />} />
                  <Route path="mytarget" element={<MyTarget />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='leads' element={<Layout />} >
                  <Route path="leadsall" element={<LeadsAllListing />} />
                  <Route path="leadsactive" element={<LeadsActiveListing />} />
                  <Route path="leadspassive" element={<LeadsPassiveListing />} />
                  <Route path="editleadmodal" element={<EditLeadModel />} />
                  <Route path="viewleadmodal" element={<ViewLeadModal />} />
                  <Route path="AddLeadModal" element={<UC />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='demand' element={<Layout />}>
                  <Route path='adddemand' element={<UC />} />
                  <Route path='managedemands' element={<DemandListing />} />
                  <Route path='mydemands' element={<MyDemands />} />
                  <Route path='demandstatus' element={<UC />} />
                  <Route path='viewandeditdemand' element={<ViewAndEditDemand />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='submission' element={<Layout />}>
                  <Route index path='managesubmissions' element={<SubmissionListing />} />
                  <Route path='addsubmission' element={<UC />} />
                  <Route path='tracksubmission' element={<TrackSubmission />} />
                  <Route path='trackinterview' element={<TrackInterview />} />
                  <Route path='mysubmissions' element={<MySubmissions />} />
                  <Route path='submissionapproval' element={<SubmissionApproval />} />
                  <Route path='viewsubmission' element={<ViewSubmission />} />
                </Route>
              )}

              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='candidatelibrary' element={<Layout />}>
                  <Route path='mycandidate' element={<MyCandidate />} />
                  <Route path='candidatehistory' element={<CandidateHistory />} />
                  <Route index path='managecandidates' element={<CandidateListing />} />
                  <Route path='viewcandidate' element={<ViewCandidate />} />
                  <Route path='editcandidate' element={<EditCandidate />} />
                </Route>
              )}

          
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='masterlist' element={<Layout />}>
                  <Route path='add' element={<UC />} />
                  <Route path='manageclient' element={<ClientListing />} />
                  <Route path='editclient' element={<EditClient />} />
                  <Route path='manageskill' element={<SkillListing />} />
                  <Route path='editskill' element={<EditSkill />} />
                  <Route path='managevendor' element={<VendorListing />} />
                  <Route path='editvendor' element={<EditVendor />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='employee' element={<Layout />}>
                  <Route path='addemployee' element={<UC />} />
                  <Route path='Manageemployee' element={<EmployeeListing />} />
                  <Route path='editemployee' element={<EditEmployee />} />
                </Route>
              )}
              {token === null ? (<Route path='/' element={<Navigate to='/login' />} />) : (
                <Route path='managedeals' element={<Layout />}>
                  <Route path='manageopportunity' element={<ManageOpportunity />} />
                  <Route path='trackopportunity' element={<TrackOpportunity />} />
                </Route>

              )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='Interview' element={<Layout />}>
                <Route path='interviewlisting' element={<InterviewListing />} />

              </Route>
                           )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='submission' element={<Layout />}>
                <Route index path='managesubmissions' element={<SubmissionListing />} />
                <Route path='addsubmission' element={<UC />} />
                <Route path='tracksubmission' element={<TrackSubmission />} />
                <Route path='trackinterview' element={<TrackInterview />} />
                <Route path='mysubmissions' element={<MySubmissions />} />
                <Route path='submissionapproval' element={<SubmissionApproval />} />
                <Route path='viewsubmission' element={<ViewSubmission />} />
              </Route>
               )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='candidatelibrary' element={<Layout />}>
                <Route path='mycandidate' element={<MyCandidate/>} />
                <Route path='candidatehistory' element={<CandidateHistory/>} />
                <Route path='candidateComments' element={<CandidateComments/>} />
                <Route index path='managecandidates' element={<CandidateListing />} />
                <Route path='viewcandidate' element={<ViewCandidate />} />
                <Route path='editcandidate' element={<EditCandidate/>}/>
              </Route>
               )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='Interview' element={<Layout />}>
                <Route path='interviewlisting' element={<InterviewListing />} />

              </Route>
                           )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='reports' element={<Layout />}>
               <Route path='submissionreport' element={<SubmissionReport />} />
                 <Route path='leaddemand' element={<UC />} />
                <Route path='accountmanager' element={<UC />} />
                <Route path='clientreport' element={<LogReport />} />
                <Route path='subvendorsubmission' element={<UC />} />
                <Route path='clientreportcount' element={<UC />} />
                <Route path='logreport' element={<LogReport />} />
              </Route>
               )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='masterlist' element={<Layout />}>
                <Route path='add' element={<UC />} />
                <Route path='manageclient' element={<ClientListing />} />
                <Route path='editclient' element={<EditClient />} />
                <Route path='manageskill' element={<SkillListing />} />
                <Route path='editskill' element={<EditSkill />} />
                <Route path='managevendor' element={<VendorListing />} />
                <Route path='editvendor' element={<EditVendor />} />
                 <Route path='viewclient' element= {<ViewDealsModal />} />
              </Route> 
               )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='employee' element={<Layout />}>
                <Route path='addemployee' element={<UC />} />
                <Route path='Manageemployee' element={<EmployeeListing />} />
                <Route path='editemployee' element={<EditEmployee />} />
                <Route path='viewemployee' element={<ViewEmployee/>} />
              </Route>
               )}
               {token === null ? (  <Route path='/' element={<Navigate to='/login' />} /> ) : (
              <Route path='managedeals' element={<Layout />}>
                <Route path='manageopportunity' element={<ManageOpportunity />} />
                <Route path='viewandeditopportunity' element={<ViewandEditOpportunityModel/>} />
                <Route path='trackopportunity' element={<TrackOpportunity />} />
                <Route path='editclient' element={<EditClient />} />
              </Route>
               )}
            </Routes>
          </SearchResultsProvider>
        </UserProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
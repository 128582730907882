import React, { useEffect, useState, useRef } from "react";
import AssignedDemandModal from "./AssignedDemandModal";
import DemandHistory from "./DemandHistory";
import styles from "./ViewAndEditDemand.module.css";
import Editicon from "../assets/editnote.png";
import { useDispatch } from "react-redux";
import { setIsEdit } from "../redux_library/slice/isDemandEdit.slice";
import { getNumberFromString, isEmpty, isNumOnly } from "../utils/validation";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { PrimaryButton, DatePicker, CommandBarButton, Label } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { TextField } from "@fluentui/react/lib/TextField";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { axiosPrivateCall } from "../constants";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./DraftEditorResetFix.css";
import boldicon from "../../src/assets/boldicon.svg";
import undoicon from "../../src/assets/undoicon.svg";
import redoicon from "../../src/assets/redoicon.svg";
import ComboBox from "../components/ComboBox/ComboBox";
import {ISOdateToCustomDate} from '../utils/helpers.js'

const vendorRegex = /^[a-zA-Z0-9 @,.()-]*$/;
const jobRRRegex = /^[A-Z0-9]*$/;

const historyIconClass = mergeStyles({
  fontSize: 16,
  height: 20,
  width: 26,
  cursor: "pointer",
  color: "#5B5F62",
  fontWeight: 500,
});

const closeIconClass = mergeStyles({
  fontSize: 16,
  height: 20,
  width: 20,
  cursor: "pointer",
});

const getTextFieldStyles = (width, padding, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#a80000" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#a80000" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      width: width,
      marginTop: padding,
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: titleColor,
      fontSize: "12px",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
          paddingLeft: "2px",
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};

const getDropdownStyles = (width, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = value.length ? "#484848" : error ? "#D24545" : "LightGray";

  const borderRadius = "4px";
  return {
    title: {
      height: "29px",
      width: width,
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      borderRadius: borderRadius,
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: borderRadius,
        },
      },
    },
  };
};

const calendarClass = (value) => {
  let titleColor = value && value?.length === 0 ? "LightGray" : "black";
  return mergeStyleSets({
    root: {
      "*": {
        fontSize: 13,
        height: "29px !important",
        lineHeight: "20px !important",
        borderColor: "#E1E5E8 !important",
        borderRadius: "4px",
        lineHeight: "26px !important",
        color: value.length === 0 ? "LightGray !important" : "#484848 !important",
        backgroundColor: "transparent !important",
      },
    },
    field: {
      color: value.length === 0 ? "LightGray !important" : "black !important",
      fontSize: 12,
      input: {
        color: value.length === 0 ? "LightGray !important" : "black !important",
        "&::placeholder": {
          color: value.length === 0 ? "LightGray !important" : "black !important",
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "8px !important",
      left: "90%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};

const addButtonStyles = {
  icon: {
    color: "rgb(50, 49, 48)",
    fontSize: 14,
  },
};

const removeIconClass = mergeStyles({
  fontSize: 10,
  height: "20px",
  width: "12px",
  paddingTop: "11px",
  paddingLeft: "20px",
  cursor: "pointer",
  color: "red",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const editorToolbarOptions = {
  options: ["inline", "list", "link", "history"],
  inline: {
    bold: { icon: boldicon, className: undefined },
    options: ["bold", "italic", "underline"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
  link: {
    options: ["link"],
  },
  history: {
    options: ["undo", "redo"],
    undo: { icon: undoicon },
    redo: { icon: redoicon },
  },
};

const dropDownStatus = [
  { key: "Open", text: "Open" },
  { key: "Close", text: "Close" },
  { key: "On Hold", text: "On Hold" },
  { key: "In Progress", text: "In Progress" },
];

const dropDownPriority = [
  { key: "Low", text: "Low" },
  { key: "Medium", text: "Medium" },
  { key: "High", text: "High" },
];

const dropDownNoticePeriod = [
  { key: "Immediate", text: "Immediate" },
  { key: "< 15 Days", text: "< 15 Days" },
  { key: "< 30 Days", text: "< 30 Days" },
  { key: "< 60 Days", text: "< 60 Days" },
  { key: "> 60 Days", text: "> 60 Days" },
];

const modeOfHireDropdown = [
  { key: "C2H (contract to Hire)", text: "C2H (contract to Hire)" },
  { key: "C2H (contract to Hire) - Client side ", text: "C2H (contract to Hire) - Client side " },
  { key: "Permanent", text: "Permanent" },
  { key: "Permanent  - Internal recruitment", text: "Permanent  - Internal recruitment" },
];

const initialState = {
  job_title: "",
  assigned_to: [],
  status: "",
  no_of_positions: "",
  priority: "",
  client: "",
  job_description: "",
  additional_details: "",
  due_date: "",
  notice_period: "",
  minimum_experience_months: "",
  minimum_experience_years: "",
  maximum_experience_months: "",
  maximum_experience_years: "",
  mode_of_hire: "",
  vendor_name: "",
  poc_vendor: "",
  job_rr_id: "",
};

const convertSantizedToInitial = (data) => {
  const state = {};

  Object.keys(data).forEach((key) => {
    if (key === "skillset") {
      state[key] = [];
      data["skillset"].map((skillObj, index) => {
        state[key].push({});
        state[key][index]["skill"] = skillObj["skill"];
        state[key][index]["years"] = Number(Math.floor(skillObj["exp"] / 12));
        state[key][index]["months"] = Number(Math.floor(skillObj["exp"] % 12));
      });
      return;
    }

    if (key === "assigned_to") {
      state[key] = data[key];
      return;
    }

    if (key === "minimum_experience") {
      state["minimum_experience_months"] = `${Math.floor(data["minimum_experience"] % 12)} months`;
      state["minimum_experience_years"] = `${Math.floor(data["minimum_experience"] / 12)} years`;
      return;
    }

    if (key === "maximum_experience") {
      state["maximum_experience_months"] = `${Math.floor(data["maximum_experience"] % 12)} months`;
      state["maximum_experience_years"] = `${Math.floor(data["maximum_experience"] / 12)} years`;
      return;
    }

    state[key] = data[key];
  });

  return state;
};

const ViewAndEditDemand = () => {
  const demand_location = useLocation();
  const mydemandValidate = demand_location.state;
  const getDemandData = demand_location.state.demandData ? demand_location.state.demandData : [];
  const [pageChange, setPageChange] = useState(false);
  const navigateTo = useNavigate();
  const [currentHover, setCurrentHover] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAssignedDemandmodal, setShowAssignedDemandModal] = useState(false);
  const [dropDownClients, setDropDownClients] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [linkedData, setLinkedData] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const demandId = searchParams.get("demand_id");
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
  const [editorState2, setEditorState2] = React.useState(() => EditorState.createEmpty());
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchOptions, setFetchOptions] = useState({ skip: 0, limit: 15, sort_field: "updatedAt", sort_type: -1 });
  const [isEdit, setIsEditLocal] = useState(demand_location.state?.isEditMode || false);
  const dispatch = useDispatch();
  localStorage.setItem("demandViewId", getDemandData.DemandId);
  let minDate = new Date();

  const [demandData, setDemandData] = useState({
    ...initialState,
    ...convertSantizedToInitial(getDemandData),
    minimum_experience_years: Math.floor(getDemandData.minimum_experience / 12),
    minimum_experience_months: getDemandData.minimum_experience % 12,
    maximum_experience_years: Math.floor(getDemandData.maximum_experience / 12),
    maximum_experience_months: getDemandData.maximum_experience % 12,
  });

  const handlePageChange = () => {
    setPageChange(!pageChange);
  };

  useEffect(() => {
    axiosPrivateCall
      .get(`/api/v1/client/listClients`)
      .then((res) => {
        let buffer = res.data;
        let dropdown_data = buffer.map((obj) => {
          return { key: obj.company_name, text: obj.company_name };
        });
        setDropDownClients(dropdown_data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const contentLength = editorState.getCurrentContent().getPlainText().trim().length;
    if (contentLength === 0 || contentLength >= 10) {
      setErrors((prevData) => {
        return {
          ...prevData,
          job_description: "",
        };
      });
      setDemandData((prevData) => {
        return {
          ...prevData,
          job_description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          additional_details: draftToHtml(convertToRaw(editorState2.getCurrentContent())),
        };
      });
    } else if (contentLength < 10) {
      setDemandData((prevData) => {
        return {
          ...prevData,
          job_description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          additional_details: draftToHtml(convertToRaw(editorState2.getCurrentContent())),
        };
      });
    }

    if (demandData.job_description.length > 18) {
      setErrors((prevData) => {
        return {
          ...prevData,
          job_description: "",
        };
      });
    }
  }, [editorState, editorState2]);

  const handleEditorStateChange = (newEditorState) => {
    const content = newEditorState.getCurrentContent().getPlainText().trim();
    if (content.length === 0) {
      setErrorMessage("");
    } else if (content.length < 10) {
      setErrorMessage("Minimum 10 characters Required");
    } else {
      setErrorMessage("");
    }
    setEditorState(newEditorState);
  };

  useEffect(() => {
    const jobHTML = demandData.job_description;
    const additionalHTML = demandData.additional_details;

    const contentBlock = htmlToDraft(jobHTML);
    const contentBlock2 = htmlToDraft(additionalHTML);

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    const contentState2 = ContentState.createFromBlockArray(contentBlock2.contentBlocks);
    const editorState2 = EditorState.createWithContent(contentState2);

    setEditorState(EditorState.createWithContent(contentState));
    setEditorState2(EditorState.createWithContent(contentState2));
  }, []);

  const escKeyHandler = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      escKeyHandler(event);
    };
    document.addEventListener("keydown", handleKeyDown, { capture: true });
    return () => {
      document.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (getDemandData) {
      setDemandData((prevData) => ({
        ...prevData,
        ...convertSantizedToInitial(getDemandData),
        minimum_experience_years: Math.floor(getDemandData.minimum_experience / 12),
        minimum_experience_months: getDemandData.minimum_experience % 12,
        maximum_experience_years: Math.floor(getDemandData.maximum_experience / 12),
        maximum_experience_months: getDemandData.maximum_experience % 12,
      }));
    }
  }, [getDemandData]);

  const [errors, setErrors] = useState({
    ...initialState,
    skillset: [
      {
        skill: "",
        years: "",
        months: "",
      },
    ],
  });

  const dropDownHandler = (e, item, name) => {
    setDemandData((prevData) => {
      return {
        ...prevData,
        [name]: item.text,
      };
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const skillSetInputHandler = (e, name, index, arr) => {
    const skillsetArr = [...arr];
    let value = e.target.value;

    let isSkillInputValid = true;

    if (name === "years") {
      if (!isNumOnly(value) || value > 99) {
        isSkillInputValid = false;
      }

      if (isEmpty(value)) {
        isSkillInputValid = true;
      }
    }

    if (name === "months") {
      if (!isNumOnly(value) || value > 12) {
        isSkillInputValid = false;
      }

      if (isEmpty(value)) {
        isSkillInputValid = true;
      }
    }

    if (isSkillInputValid) {
      if (!skillsetArr[index]) {
        skillsetArr[index] = {
          skill: "",
          years: "",
          months: "",
        };
      }

      skillsetArr[index][name] = value;

      setDemandData((prevData) => {
        return {
          ...prevData,
          skillset: skillsetArr,
        };
      });

      setErrors((prevData) => {
        const skillsetErrArr = [...prevData.skillset];
        skillsetErrArr[index] = {
          ...skillsetErrArr[index],
          [name]: "",
        };
        return {
          ...prevData,
          skillset: skillsetErrArr,
        };
      });
    }
  };

  const removeSkill = (skillData, index, arr) => {
    if (index === 0) return;
    const newskillsetArr = arr.filter((val) => val !== skillData);
    setDemandData((prevData) => {
      return {
        ...prevData,
        skillset: newskillsetArr,
      };
    });
    const newskillsetErrArr = errors.skillset.filter((val, i) => i !== index);
    setErrors((prevData) => {
      return {
        ...prevData,
        skillset: newskillsetErrArr,
      };
    });
  };

  const dateHandler = (date, name) => {
    setDemandData((prevData) => {
      return {
        ...prevData,
        [name]: date,
      };
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const inputChangeHandler = (e, inputName) => {
    const { value } = e.target;
    let inputValue = value;

    let isInputValid = true;

    if (inputName === "no_of_positions") {
      if (!isNumOnly(value)) {
        isInputValid = false;
      }

      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (inputName === "vendor_name" || inputName === "poc_vendor" || inputName === "job_rr_id") {
      isInputValid = !isEmpty(value);
    }    

    if (inputName === "no_of_positions") {
      inputValue = Number(inputValue);

      if (isEmpty(value)) {
        inputValue = "";
      }
    }

    if (inputName === "maximum_experience_years") {
      if (!isNumOnly(value) || value > 99) {
        isInputValid = false;
      }

      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (inputName === "minimum_experience_years") {
      if (!isNumOnly(value) || value > 99) {
        isInputValid = false;
      }

      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (inputName === "maximum_experience_months") {
      if (!isNumOnly(value) || value > 12) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (inputName === "minimum_experience_months") {
      if (!isNumOnly(value) || value > 12) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (isInputValid) {
      setDemandData({
        ...demandData,
        [inputName]: inputValue,
      });
      setErrors({
        ...errors,
        [inputName]: "",
      });
    }
  };

  const addSkillSet = () => {
    const skillsetArr = demandData.skillset;
    skillsetArr.push({
      skill: "",
      years: "",
      months: "",
    });
    setDemandData((prevData) => {
      return {
        ...prevData,
        skillset: skillsetArr,
      };
    });
    const skillsetErrArr = errors.skillset;
    skillsetErrArr.push({
      skill: "",
      years: "",
      months: "",
    });

    setErrors((prevData) => {
      return {
        ...prevData,
        skillset: skillsetErrArr,
      };
    });
  };

  useEffect(() => {
    setHasMore(true);
    setFetchOptions({ ...fetchOptions, skip: 0, limit: 15 });
  }, [fetchOptions.sort_field, fetchOptions.sort_typ]);

  const getErrorObj = (obj) => {
    const errorObj = {};
    let min_month = parseInt(getNumberFromString(obj.minimum_experience_months));
    let max_month = parseInt(getNumberFromString(obj.maximum_experience_months));
    let min_year = parseInt(getNumberFromString(obj.minimum_experience_years));
    let max_year = parseInt(getNumberFromString(obj.maximum_experience_years));
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        errorObj[key] = [];
        value.map((data, index) => {
          let newErrObj = {};

          Object.keys(data).map((key) => {
            if (isEmpty(data[key])) {
              return (newErrObj[key] = " ");
            } else {
              return (newErrObj[key] = "");
            }
          });
          return (errorObj[key][index] = newErrObj);
        });
      } else if (isEmpty(value)) {
        errorObj[key] = " ";
      } else {
        errorObj[key] = "";
      }
      if (min_year > max_year) {
        errorObj["maximum_experience_years"] = " Min exp exceeds max exp.";
      } else if (key === "maximum_experience_months") {
        const min_experience_total = min_year * 12 + min_month;
        const max_experience_total = max_year * 12 + max_month;
        if (min_experience_total > max_experience_total) {
          errorObj[key] = "Min exp exceeds max exp.";
        }
      }
    }

    return errorObj;
  };

  const sanitizer = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
      if (key === "skillset") {
        sanitizedData[key] = [];

        data["skillset"].map((skillObj, index) => {
          sanitizedData[key].push({});
          sanitizedData[key][index]["skill"] = skillObj["skill"];
          sanitizedData[key][index]["exp"] = Number(skillObj["years"] * 12) + Number(skillObj["months"]);
        });
        return;
      }

      if (key === "minimum_experience_years") {
        return (sanitizedData["minimum_experience"] = sanitizedData["minimum_experience"]
          ? parseInt(data[key]) * 12 + sanitizedData["minimum_experience"]
          : parseInt(getNumberFromString(data[key]) * 12));
      }

      if (key === "minimum_experience_months") {
        return (sanitizedData["minimum_experience"] = sanitizedData["minimum_experience"]
          ? parseInt(data[key]) + sanitizedData["minimum_experience"]
          : parseInt(getNumberFromString(data[key])));
      }

      if (key === "maximum_experience_years") {
        return (sanitizedData["maximum_experience"] = sanitizedData["maximum_experience"]
          ? parseInt(data[key]) * 12 + sanitizedData["maximum_experience"]
          : parseInt(getNumberFromString(data[key]) * 12));
      }

      if (key === "maximum_experience_months") {
        return (sanitizedData["maximum_experience"] = sanitizedData["maximum_experience"]
          ? parseInt(data[key]) + sanitizedData["maximum_experience"]
          : parseInt(getNumberFromString(data[key])));
      }

      sanitizedData[key] = data[key];
    });

    return sanitizedData;
  };

  const updateDemand = () => {
    axiosPrivateCall
      .post("/api/v1/demand/updateDemand", sanitizer(demandData))
      .then((res) => {
        setIsLoading(false);
        mydemandValidate.demand === "mydemand" ? navigateTo("/demand/mydemands") : navigateTo("/demand/managedemands");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitHandler = () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const errorObj = getErrorObj(demandData);
    setErrors(errorObj);
    if (isEmpty(demandData.vendor_name) || isEmpty(demandData.poc_vendor) || isEmpty(demandData.job_rr_id)) {
      setErrors({
        ...errors,
        vendor_name: isEmpty(demandData.vendor_name) ? "Vendor Name is required" : "",
        poc_vendor: isEmpty(demandData.poc_vendor) ? "POC Vendor is required" : "",
        job_rr_id: isEmpty(demandData.job_rr_id) ? "Job/RR ID is required" : "",
      });
      setIsLoading(false); 
      return;
    }
    const isSkillsetEmpty = demandData.skillset.some(
      (skill) => skill.skill === "" || skill.years === "" || skill.months === ""
    );
    if (isDemandDataValid(demandData) && !isSkillsetEmpty) {
      updateDemand();
    } else {
      console.log("not valid");
      setIsLoading(false);
    }
  };

  const isDemandDataValid = (obj) => {
    const excludedFields = ["assigned_to"];
    let min_month = parseInt(getNumberFromString(obj.minimum_experience_months));
    let max_month = parseInt(getNumberFromString(obj.maximum_experience_months));
    let min_year = parseInt(getNumberFromString(obj.minimum_experience_years));
    let max_year = parseInt(getNumberFromString(obj.maximum_experience_years));
    for (const [key, value] of Object.entries(obj)) {
      if (excludedFields.includes(key)) {
        continue;
      }
      if (demandData?.job_description && demandData.job_description.length === 8) {
        return true;
      } else if (demandData?.job_description && demandData.job_description.length < 18) {
        setErrorMessage("Minimum 10 characters Required");
        return false;
      }
      if (Array.isArray(value)) {
        return value.every((data, index) => {
          return Object.values(data).every((val) => !isEmpty(val));
        });
      }

      if (isEmpty(value)) {
        return false;
      }
      if (errors.maximum_experience_years === "Min exp exceeds max exp.") {
        return false;
      }
      const min_experience_total = min_year * 12 + min_month;
      const max_experience_total = max_year * 12 + max_month;
      if (min_experience_total > max_experience_total) {
        return false;
      }
    }
    return true;
  };

  function isValidInput(input) {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(input);
  }

  useEffect(() => {
    axiosPrivateCall
      .get(`/api/v1/demand/linkedDetails?demandId=${demandId}`)
      .then((res) => setLinkedData(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);

 

  useEffect(() => {
    loadMoreData();
  }, []);

  const loadMoreData = () => {
    const newData = linkedData.slice(0, visibleData.length + 3);
    setVisibleData(newData);
    if (newData.length >= linkedData.length) {
      setHasMore(false);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore) {
      loadMoreData();
    }
  };

  const enabaleEditButton = () => {
    setIsEditLocal(true);
  };

  useEffect(() => {
    dispatch(setIsEdit(isEdit));
  }, [isEdit, dispatch]);

  return (
    <div className={styles.pages}>
      {showAssignedDemandmodal && (
        <AssignedDemandModal
          data={demandData.assigned_to}
          isModalOpen={showAssignedDemandmodal}
          setIsModalOpen={setShowAssignedDemandModal}
        />
      )}
      <div className={styles.adddemand_modal_header_container}>
        <div className={styles.header_tag_expand_close_icon_container}>
          <div className={styles.header_tag_container}>Demand</div>
         {!isEdit ? ( <div className={styles.header_expand_close_icon_container}>
            <div className={styles.header_close_icon_container}>
              <Icon iconName="ChromeClose" onClick={() => navigateTo(-1)} className={closeIconClass} />
            </div>
           </div>):("")
          }
        </div>

        <div className={styles.AddLabel}>Demand ID: {getDemandData.DemandId ? getDemandData.DemandId : ""}</div>

        <div className={styles.header_content_container}>
          <div className={styles.header_content_job_description_unassigned_save_container}>
            <div className={styles.header_content_job_description_unassigned_container}>
              <div className={styles.header_content_job_description_container}>
                <div className={styles.job_label} onClick={() => setCurrentHover("job_title")}>
                  <Label className={styles.name_label_style} required>
                    Job Requirement Title
                  </Label>
                  <TextField
                    value={demandData.job_title}
                    placeholder="Enter the Job Requirement Title"
                    disabled={!isEdit}
                    style={{ backgroundColor: "transparent" }}
                    onChange={(e) => {
                      inputChangeHandler(e, "job_title");
                      setCurrentHover("");
                    }}
                    resizable={false}
                    styles={(props) =>
                      getTextFieldStyles(
                        "34.2vw",
                        "2.6px",
                        "35vw",
                        props,
                        currentHover,
                        errors.job_title,
                        demandData.job_title
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className={styles.header_save_close_btns_container}>
              <div
                id="personaId"
                onClick={() => setShowAssignedDemandModal(true)}
                className={styles.unassigned_container}
              >
                <div className={styles.unassigned_title_icon_container}>
                  {`Assigned ${
                    demandData?.assigned_to?.length === 0
                      ? "0 Person"
                      : demandData?.assigned_to?.length === 1
                      ? "1 Person"
                      : `${demandData?.assigned_to?.length} Persons`
                  }`}
                </div>
              </div>



              {isEdit ? (
                <PrimaryButton
                  className={styles.btnStyle}
                  onClick={submitHandler}
                  text="Save & Close"
                  iconProps={{ iconName: "Save" }}
                />
              ) : (
                !pageChange&&
                  <div className={styles.header_Edit}>
                    <PrimaryButton onClick={enabaleEditButton}>
                    <img src={Editicon} alt="image" style={{ marginRight: "5px", width: "16px", height: "16px" }} />
                    <span style={{ fontSize: "16px", fontWeight: "600", paddingBottom: "3px" }}>Edit</span>
                    </PrimaryButton>
                  </div>
                    
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.main_filter_options_container}>
        <div className={styles.main_filter_options_sub_container}>
          <div className={styles.main_role_dropdown_container}>
            <div className={styles.main_role_title}>
              <Label className={styles.required_field} required>
                Status
              </Label>
            </div>
            <div className={styles.sec_div} onClick={() => setCurrentHover("status")}>
              {isEdit ? (
                <Dropdown
                  name="status"
                  onChange={(e, item) => {
                    dropDownHandler(e, item, "status");
                    setCurrentHover("");
                  }}
                  disabled={!isEdit}
                  style={{ backgroundColor: "transparent" }}
                  selectedKey={demandData.status}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) => getDropdownStyles("180px", currentHover, errors.status, demandData.status)}
                  options={dropDownStatus}
                />
              ) : (
                <TextField
                  name="status"
                  disabled
                  placeholder={demandData.status}
                  styles={(props) =>
                    getTextFieldStyles("180px", null, null, props, currentHover, errors.status, demandData.status)
                  }
                  style={{ backgroundColor: "transparent" }}
                />
              )}
            </div>
          </div>
          <div className={styles.main_role_dropdown_container}>
            <Label className={styles.required_field} required>
              No of Positions
            </Label>
            <div onClick={() => setCurrentHover("no_of_positions")}>
              <TextField
                value={demandData.no_of_positions}
                onChange={(e) => {
                  inputChangeHandler(e, "no_of_positions");
                }}
                disabled={!isEdit}
                style={{ backgroundColor: "transparent" }}
                placeholder={"Enter the count"}
                styles={(props) =>
                  getTextFieldStyles(
                    "180px",
                    null,
                    null,
                    props,
                    currentHover,
                    errors.no_of_positions,
                    demandData.no_of_positions
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.main_filter_options_sub_container}>
          <div className={styles.main_role_dropdown_container}>
            <div className={styles.main_role_title2}>
              <Label className={styles.required_field} required>
                Priority
              </Label>{" "}
            </div>
            <div className={styles.sec_div} onClick={() => setCurrentHover("priority")}>
              {isEdit ? (
                <Dropdown
                  onChange={(e, item) => {
                    dropDownHandler(e, item, "priority");
                    setCurrentHover("");
                  }}
                  disabled={!isEdit}
                  style={{ backgroundColor: "transparent" }}
                  selectedKey={demandData.priority}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) => getDropdownStyles("180px", currentHover, errors.priority, demandData.priority)}
                  options={dropDownPriority}
                />
              ) : (
                <div className={styles.sec_div} onClick={() => setCurrentHover("priority")}>
                  <TextField
                    disabled
                    placeholder={demandData.priority}
                    notifyOnReselect
                    styles={(props) =>
                      getTextFieldStyles("180px", null, null, props, currentHover, errors.priority, demandData.priority)
                    }
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.main_role_dropdown_container}>
            <div className={styles.main_role_title2}>
              <Label className={styles.required_field} required>
                Client
              </Label>
            </div>
            <div onClick={() => setCurrentHover("client")}>
              {isEdit ? (
                <ComboBox
                  type="text"
                  name="client"
                  inputChangeHandler={inputChangeHandler}
                  setInfo={setDemandData}
                  setInfoErrors={setErrors}
                  value={demandData.client}
                  dropdown={dropDownClients}
                  textfield="color"
                  placeholder="Enter client name"
                  comboStyles={(props) =>
                    getTextFieldStyles("180px", null, null, props, currentHover, errors.client, demandData.client)
                  }
                />
              ) : (
                <TextField
                  value={demandData.client}
                  onChange={(e) => {
                    inputChangeHandler(e, "client");
                    setCurrentHover("");
                  }}
                  disabled={!isEdit}
                  style={{ backgroundColor: "transparent" }}
                  placeholder={"Enter the Name"}
                  styles={(props) =>
                    getTextFieldStyles("180px", null, null, props, currentHover, errors.client, demandData.client)
                  }
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.demand_history_icon}>
          <Icon iconName="History" onClick={handlePageChange} className={historyIconClass} />
          <div
            onClick={handlePageChange}
            className={pageChange ? styles.demand_history_name_active : styles.demand_history_name}
          >
            History
          </div>
        </div>
      </div>

      {pageChange === true ? (
        <>
          <DemandHistory />
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.job_description_container}>
            <div className={styles.job_description}>
              <div className={styles.main_basic_information_title}>
                <Label className={styles.p_job}>Job Description</Label>
              </div>

              <div
                className={`${styles.main_wysiwyg_container} ${
                  errors.job_description ? styles.main_wysiwyg_container_error : ""
                } 
								${demandData.job_description.length > 8 ? styles.main_wysiwyg_container_focus : ""}`}
              >
                <Editor
                  wrapperClassName={styles.editor_wrapper}
                  toolbar={editorToolbarOptions}
                  toolbarOnFocus
                  toolbarClassName={styles.editor_toolbar}
                  editorClassName={styles.editor_editor}
                  placeholder="Click to add description (Minimum 10 characters)"
                  editorState={editorState}
                  onEditorStateChange={handleEditorStateChange}
                  readOnly={!isEdit}
                />
              </div>
            </div>
            <div className={styles.error}>{errorMessage && <p>{errorMessage}</p>}</div>

            <div className={styles.additional_description}>
              <div className={styles.main_basic_information_title}>
                <Label className={styles.p_additional}>Additional Information</Label>
              </div>

              <div
                className={`${styles.main_wysiwyg_container} ${
                  demandData.additional_details.length > 8 ? styles.main_wysiwyg_container_focus : ""
                }`}
              >
                <Editor
                  wrapperClassName={styles.editor_wrapper}
                  placeholder="Click to add description"
                  toolbarOnFocus
                  toolbarClassName={styles.editor_toolbar}
                  editorClassName={styles.editor_editor}
                  toolbar={editorToolbarOptions}
                  editorState={editorState2}
                  onEditorStateChange={(editorState2) => setEditorState2(editorState2)}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </div>
          <div className={styles.demand_description_container}>
            <div className={styles.demand_information}>
              <p className={styles.p_job_description}>Demand Information</p>
              <div className={styles.two_sub}>
                <div className={styles.two_sub_left}>
                  <Label className={styles.customer_label_style} required>
                    Due Date
                  </Label>

                  <div onClick={() => setCurrentHover("due_date")}>
                    <DatePicker
                      value={new Date(demandData.due_date)}
                      placeholder={"DD/MM/YYYY"}
                      minDate={minDate}
                      styles={calendarClass(demandData.due_date, errors.due_date, "due_date")}
                      onSelectDate={(date) => {
                        dateHandler(date, "due_date");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                </div>

                <div className={styles.two_sub_right}>
                  <Label className={styles.customer_label_style} required>
                    Notice Period
                  </Label>
                  <div onClick={() => setCurrentHover("notice_period")}>
                    {isEdit ? (
                      <>
                        <Dropdown
                          onChange={(e, item) => {
                            dropDownHandler(e, item, "notice_period");
                            setCurrentHover("");
                          }}
                          selectedKey={demandData.notice_period}
                          placeholder={"Select"}
                          notifyOnReselect
                          styles={(props) =>
                            getDropdownStyles(props, currentHover, errors.notice_period, demandData.notice_period)
                          }
                          options={dropDownNoticePeriod}
                          disabled={!isEdit}
                          style={{ backgroundColor: "transparent" }}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          disabled
                          placeholder={demandData.notice_period}
                          notifyOnReselect
                          errorMessage={errors.notice_period}
                          styles={(props) =>
                            getTextFieldStyles(
                              null,
                              null,
                              null,
                              props,
                              currentHover,
                              errors.notice_period,
                              demandData.notice_period
                            )
                          }
                          style={{ backgroundColor: "transparent" }}
                          options={dropDownNoticePeriod}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.data_month_container}>
                <div className={styles.minimum_exp_container}>
                  <Label className={styles.min_exp_label} required>
                    Minimum Experience
                  </Label>
                  <div className={styles.experience_div}>
                    <TextField
                      placeholder="00"
                      value={
                        typeof demandData.minimum_experience_years === "string"
                          ? demandData.minimum_experience_years.replace(/\D/g, "")
                          : demandData.minimum_experience_years
                      }
                      onChange={(e) => {
                        inputChangeHandler(e, "minimum_experience_years");
                      }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "37px",
                          1,
                          "37px",
                          props,
                          currentHover,
                          errors.minimum_experience_years,
                          demandData.minimum_experience_years
                        )
                      }
                      className={styles.year_text_box}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                    />

                    <Label className={styles.experience_text}>Years</Label>
                    <TextField
                      placeholder="00"
                      value={
                        typeof demandData.minimum_experience_months === "string"
                          ? demandData.minimum_experience_months.replace(/\D/g, "")
                          : demandData.minimum_experience_months
                      }
                      onChange={(e) => {
                        inputChangeHandler(e, "minimum_experience_months");
                      }}
                      className={styles.month_text_box}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "37px",
                          1,
                          "37px",
                          props,
                          currentHover,
                          errors.minimum_experience_months,
                          demandData.minimum_experience_months
                        )
                      }
                    />
                    <Label className={styles.experience_text}>Months</Label>
                  </div>
                </div>

                <div className={styles.maximum_exp_container}>
                  <div>
                    <Label className={styles.min_exp_label} required>
                      Maximum Experience
                    </Label>
                    <div className={styles.experience_div}>
                      <TextField
                        value={
                          typeof demandData.maximum_experience_years === "string"
                            ? demandData.maximum_experience_years.replace(/\D/g, "")
                            : demandData.maximum_experience_years
                        }
                        placeholder="00"
                        onChange={(e) => {
                          inputChangeHandler(e, "maximum_experience_years");
                        }}
                        disabled={!isEdit}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "37px",
                            1,
                            "37px",
                            props,
                            currentHover,
                            errors.maximum_experience_years,
                            demandData.maximum_experience_years
                          )
                        }
                        className={styles.year_text_box}
                      />
                      <Label className={styles.experience_text}>Years</Label>
                      <TextField
                        placeholder="00"
                        value={
                          typeof demandData.maximum_experience_months === "string"
                            ? demandData.maximum_experience_months.replace(/\D/g, "")
                            : demandData.maximum_experience_months
                        }
                        onChange={(e) => {
                          inputChangeHandler(e, "maximum_experience_months");
                        }}
                        disabled={!isEdit}
                        style={{ backgroundColor: "transparent" }}
                        className={styles.month_text_box}
                        styles={(props) =>
                          getTextFieldStyles(
                            "37px",
                            1,
                            "37px",
                            props,
                            currentHover,
                            errors.maximum_experience_months,
                            demandData.maximum_experience_months
                          )
                        }
                      />
                      <Label className={styles.experience_text}>Months</Label>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.mode_of_hire}>
                <Label className={styles.required_field} required>
                  Mode of Hire
                </Label>
              </div>
              <div className={styles.width_of_mode}>
                <div onClick={() => setCurrentHover("mode_of_hire")}>
                  {isEdit ? (
                    <Dropdown
                      onChange={(e, item) => {
                        dropDownHandler(e, item, "mode_of_hire");
                        setCurrentHover("");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      placeholder="Select"
                      selectedKey={demandData.mode_of_hire}
                      notifyOnReselect
                      styles={(props) =>
                        getDropdownStyles(props, currentHover, errors.mode_of_hire, demandData.mode_of_hire)
                      }
                      options={modeOfHireDropdown}
                    />
                  ) : (
                    <TextField
                      disabled
                      placeholder={demandData.mode_of_hire}
                      styles={(props) =>
                        getTextFieldStyles(
                          null,
                          null,
                          null,
                          props,
                          currentHover,
                          errors.mode_of_hire,
                          demandData.mode_of_hire
                        )
                      }
                      style={{ backgroundColor: "transparent" }}
                      errorMessage={errors.mode_of_hire}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.vendor_information}>
              <p className={styles.p_job_description}>Vendor Information</p>
              <div className={styles.two_sub}>
                <div className={styles.two_sub_left}>
                  <Label className={styles.customer_label_style}>Vendor Name</Label>

                  <div onClick={() => setCurrentHover("vendor_name")}>
                    <TextField
                      value={demandData.vendor_name}
                      onChange={(e) => {
                        inputChangeHandler(e, "vendor_name");
                        setCurrentHover("");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      placeholder={"Enter the Name"}
                      styles={(props) =>
                        getTextFieldStyles(
                          null,
                          null,
                          null,
                          props,
                          currentHover,
                          errors.vendor_name,
                          demandData.vendor_name
                        )
                      }
                    />
                  </div>
                </div>

                <div className={styles.two_sub_right}>
                  <Label className={styles.customer_label_style}>Point of contact Vendor</Label>
                  <div onClick={() => setCurrentHover("poc_vendor")}>
                    <TextField
                      value={demandData.poc_vendor}
                      onChange={(e) => {
                        if (isValidInput(e.target.value)) {
                          inputChangeHandler(e, "poc_vendor");
                          setCurrentHover("");
                        }
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      placeholder={"Enter the Name"}
                      styles={(props) =>
                        getTextFieldStyles(
                          null,
                          null,
                          null,
                          props,
                          currentHover,
                          errors.poc_vendor,
                          demandData.poc_vendor
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.job_rr_id}>
                <Label className={styles.required_field}>Job / RR ID</Label>

                <div onClick={() => setCurrentHover("vendor_name")}>
                  <TextField
                    value={demandData.job_rr_id}
                    onChange={(e) => {
                      inputChangeHandler(e, "job_rr_id");
                    }}
                    disabled={!isEdit}
                    style={{ backgroundColor: "transparent" }}
                    placeholder={"Enter ID"}
                    styles={(props) =>
                      getTextFieldStyles(null, null, null, props, currentHover, errors.job_rr_id, demandData.job_rr_id)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.skill_set_container}>
            <div className={styles.skill_sets}>
              <div className={styles.skill_and_add}>
                <p className={styles.p_skill}>Skill Sets</p>
                <div onClick={addSkillSet} className={styles.main_right_add_icon_container}>
                  <CommandBarButton styles={addButtonStyles} iconProps={{ iconName: "Add" }} text="Add " />
                </div>
              </div>

              <div className={styles.main_right_skill_set_experience_container}>
                <div className={styles.main_right_skill_set_title_dropdown_container}>
                  <div className={styles.main_right_skill_set_title_sub_title}>
                    <Label className={styles.required_field} required>
                      Skill Set
                    </Label>
                  </div>
                  {demandData.skillset.map((skillData, index, arr) => (
                    <div
                      key={index}
                      className={isEdit ? styles.main_right_skill_set_dropdown_container : styles.view_page_skill_set}
                    >
                      <TextField
                        disabled={!isEdit}
                        value={demandData.skillset[index]?.skill}
                        errorMessage={errors.skillset[index]?.skill}
                        placeholder={
                          index === 0 ? "Primary Skill Set" : index === 1 ? "Secondary Skill Set" : "Other Skills"
                        }
                        onChange={(e) => {
                          skillSetInputHandler(e, "skill", index, arr);
                        }}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            null,
                            null,
                            null,
                            props,
                            currentHover,
                            errors.skillset[index]?.skill,
                            demandData.skillset[index]?.skill
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.main_right_skill_experience_container}>
                  <div className={styles.main_right_skill_experience_title_sub_title}>
                    <Label className={styles.required_field} required>
                      Relevant Skill Experience
                    </Label>
                  </div>
                  {demandData.skillset.map((skillData, index, arr) => (
                    <div key={index} className={styles.main_right_skill_experience_dropdown_remove_icon_container}>
                      <div className={styles.main_right_skill_experience_dropdown_container}>
                        <div className={styles.skill_alignment} onClick={() => setCurrentHover(`years${index}`)}>
                          <TextField
                            value={demandData.skillset[index]?.years}
                            onChange={(e) => {
                              skillSetInputHandler(e, "years", index, arr);
                            }}
                            disabled={!isEdit}
                            style={{ backgroundColor: "transparent" }}
                            placeholder="00"
                            styles={(props) =>
                              getTextFieldStyles(
                                "37px",
                                null,
                                "37px",
                                props,
                                currentHover,
                                errors.skillset[index]?.years,
                                demandData.skillset[index]?.years
                              )
                            }
                          />
                          <Label className={styles.experience_text}>Years</Label>
                        </div>
                        <div className={styles.skill_alignment}>
                          <TextField
                            value={demandData.skillset[index]?.months}
                            onChange={(e) => {
                              skillSetInputHandler(e, "months", index, arr);
                            }}
                            disabled={!isEdit}
                            style={{ backgroundColor: "transparent" }}
                            placeholder="00"
                            className={styles.month_text_box}
                            styles={(props) =>
                              getTextFieldStyles(
                                "37px",
                                null,
                                "37px",
                                props,
                                currentHover,
                                errors.skillset[index]?.months,
                                demandData.skillset[index]?.months
                              )
                            }
                          />
                          <Label className={styles.experience_text}>Months</Label>
                        </div>
                        {index !== 0 && (
                          <Icon
                            iconName="ChromeClose"
                            className={removeIconClass}
                            onClick={() => removeSkill(skillData, index, arr)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.linked_candidates}>
              <p className={styles.p_linked}>Linked Candidates</p>
              <div className={styles.contentWrapper} onScroll={handleScroll}>
                {linkedData.length != 0 ? (
                  linkedData.map((data) => (
                  

                    <div className={styles.linkedboxContainer}>
                      <p className={styles.content_level}>{demandData.status}</p>
                      <div className={styles.contentContainer}>
                        <p onClick={() => navigateTo(`/candidatelibrary/viewcandidate?candidate_id=${data._Id}`)}>
                          {data.CandidateId}
                        </p>
                        <p className={styles.content}>
                          {data.CandidateName}
                          <span className={styles.skill}>{`(${data.skillset[0]?.skill})`} </span>
                        </p>
                      </div>
                      <div className={styles.contentContainer}>
                        <div className={styles.updateContent}>Updated {ISOdateToCustomDate(data.UpdatedAt)}</div>
                        <Icon
                          iconName="LocationDot"
                          style={{
                            fontSize: "16px",
                            color:
                              data.Status === "level_1_select" ||
                              data.Status === "level_2_select" ||
                              data.Status === "final_select" ||
                              data.Status === "offer_accept" ||
                              data.Status === "onboard_select" ||
                              data.Status === "bg_verification_select" ||
                              data.Status === "initial_screening_select"
                                ? "#13A10E"
                                : data.Status === "bg_verification_reject" ||
                                  data.Status === "onboard_reject" ||
                                  data.Status === "offer_denied" ||
                                  data.Status === "final_reject" ||
                                  data.Status === "Interview_Drop_3" ||
                                  data.Status === "Interview_Drop_2" ||
                                  data.Status === "Interview_Drop_1" ||
                                  data.Status === "level_2_reject" ||
                                  data.Status === "level_1_reject" ||
                                  data.Status === "initial_screening_reject"
                                ? "#DE1414"
                                : "#0078D4",
                          }}
                        />
                        <p>{data.Status}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <a href="#" className={styles.noLinked}>
                    No Linked Candidates
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAndEditDemand;

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DoughnutChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let newChart = null;
    const ctx = chartRef.current;
    const colors = ['#0263FF', '#0779B7', '#6BC2ED', '#019ADD', '#F8F8F8'];
    const values = data.map((item) => item.value);

    if (ctx) {
      if (ctx.chart) {
        ctx.chart.destroy();
      }
      newChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: values,
            backgroundColor: colors,
            hoverBackgroundColor: 'white',
            borderWidth: 0,
            hitRadius: 10,
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              displayColors: false,
              backgroundColor: 'white',
              titleColor: 'white',
              bodyColor: '#0263FF',
              bodyFont: {
                weight: 'bold',
              },
              padding: {
                top: 5,
                bottom: 5,
                left: 11,
                right: 11,
              },
              borderWidth: 1,
              cornerRadius: 0,
              borderColor: '#0263FF',
              callbacks: {
                label: function(context) {
                  return context.parsed;
                },
              },
            },
          },
          cutout: '75%',
          hover: {
            mode: 'index',
            intersect: false,
          },
          elements: {
            arc: {
              hoverOffset: 20,
            },
          },
          // Disable animation
          animation: false,
        },
      });
    }

    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default DoughnutChart;

import React, { useState, useEffect } from "react";
import { Modal, TextField, Label, MessageBar, MessageBarType } from "@fluentui/react";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import styles from "./Popup.module.css";

const messageBarStyles = {
  content: {
    maxWidth: 420,
    minWidth: 250,
  },
};

const curvedButtonSaveStyle = {
  root: {
    borderRadius: "3px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const curvedButtonCancelStyle = {
  root: {
    borderRadius: "3px",
    border: "2px solid #0078D4",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    color: "#0078D4",
  },
  rootHovered: {
    borderColor: "#0078D4",
  },
};

const Popup1 = (props) => {
  const [notes, setNotes] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (showToast) {
      const timerId = setTimeout(() => {
        setShowToast(false);
      }, 2000);
      return () => clearTimeout(timerId);
    }
  }, [showToast]);

  const lighterBorderStyle = {
    fieldGroup: {
      borderColor: isInvalid ? "#8B0000" : "#ccc",
      display: "flex",
    },
  };

  const validateNotes = () => {
    const regex = /^[a-zA-Z0-9.,'&() \-]*$/;
    const isValid = regex.test(notes.trim());
    const isLengthValid = notes.trim().length >= 2 && notes.trim().length <= 1500;
    return isValid && isLengthValid;
  };

  useEffect(() => {
    let EditPopupData = props.popupNotes;
    setNotes(EditPopupData);
  },[props])

  const handleNotesChange = (value) => {
    setNotes(value);
    setIsInvalid(false);
    setErrorMessage(""); // Clear error message when user changes input
  };
  
  const handleSave = () => {
    const trimmedNotes = notes.trim();
  
    if (trimmedNotes === "") {
      setIsInvalid(true);
      setErrorMessage("Notes are required");
      return;
    }
  
    if (!validateNotes()) {
      setIsInvalid(true);
      setErrorMessage("Enter valide Notes");
      return;
    }
  
    setShowToast(true);
    if (props.resetState) {
      props.resetState();
    }
    props.setPopupNotes(trimmedNotes);
    props.setShowPopup1(false);
    props.setIsModalOpen(!props.isModalOpen);
  
    if (props.onSaveButtonClick) {
      props.onSaveButtonClick();
    }
  };

  const handleCancel = () => {
    setNotes("");
    props.setShowPopup1(false);
  };

  let showPopup1 = props.showPopup1;
  let setShowPopup1 = props.setShowPopup1;
  
  return (
    <>
      <Modal isOpen={showPopup1} containerClassName={styles.mainContainer2}>
        <div className={styles.closePopup}>
          <div className={styles.topContainer1}>
            <div className={styles.title2}>Are you sure you want to modify the status?</div>
            <div className={styles.closeButton} onClick={() => setShowPopup1(!showPopup1)}></div>
          </div>
          <div className={styles.message1}>
            <Label required>Add Notes</Label>
          </div>
          <div className={styles.notesCommentbox}>
            <TextField
              placeholder="Enter the Notes"
              multiline
              rows={8}
              value={notes}
              onChange={(e, newValue) => handleNotesChange(newValue)}
              styles={lighterBorderStyle}
              errorMessage={isInvalid ? errorMessage : undefined}
            />
          </div>
        </div>
        <div className={styles.save_and_close_container}>
          <div className={styles.spacer1}></div>
          <div className={styles.buttonContainer1}>
            <DefaultButton text={`Cancel`} onClick={handleCancel} styles={curvedButtonCancelStyle} />
            <PrimaryButton text={`Save`} onClick={handleSave} styles={curvedButtonSaveStyle} />
          </div>
        </div>
      </Modal>
      {showToast && (
        <div>
          <MessageBar
            onDismiss={() => setShowToast(false)}
            styles={messageBarStyles}
            dismissButtonAriaLabel="Close"
            messageBarType={MessageBarType.success}
          >
            User Status updated Successfully !
          </MessageBar>
        </div>
      )}
          {" "}
    </>
  );
};

export default Popup1;

import React, { useState } from "react";
import styles from "./TargetListing.module.css";
import Nomatchimg from "../assets/no.png"
import NoDataFound from "../assets/Nodatafound.png"
import {
  PrimaryButton,
  SearchBox,
  FontIcon,
  mergeStyles,
  DefaultButton,
} from "@fluentui/react";
import {
  MessageBar,
  MessageBarType,
  TextField,
  Callout,
  DirectionalHint,
} from "@fluentui/react";
import { useEffect } from "react";
import AddTargetModal from "./AddTargetModal";
import AmTargetModal from "./AmTargetModal";
import { axiosPrivateCall } from "../constants";
import notificationbox from "../assets/notification.png";
import NotificationsBox from "../components/TargetNotification";
import { useLocation} from "react-router-dom";
import { Spinner, SpinnerSize } from '@fluentui/react';
const iconClass1 = mergeStyles({
  fontWeight: 200,
  fontSize: 12,
  height: 12,
  width: 12,
  margin: "0 ",
  marginLeft: 10,
  color: "#999DA0",
});
const calloutBtnStyles = {
  root: {
    border: "none",
    padding: "0px 10px",
    textAlign: "left",
    height: "20px",
    marginRight: "20px",
  },
};

const formatDateToDMY = (dateString) => {
const originalDate = new Date(dateString);
  if (!isNaN(originalDate)) {
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const year = originalDate.getFullYear(); 
    return `${day}/${month}/${year}`;
  }
  return '  ';
};

const addIcon = { iconName: "Add" };
const messageBarStyles = {
  content: {
    maxWidth: 620,
    minWidth: 450,
  },
};

const narrowTextFieldStyles1 = {
  fieldGroup: {
    width: 50,
    height: 15,
    marginLeft: 20,
    marginBottom: 15,
    border: "none",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    paddingLeft: "13px",
  },
  field: {
    fontSize: "12px",
    fontFamily: "Lato",
    color: "#5B5F62",
  },
};

const narrowTextFieldStyles = {
  fieldGroup: {
    width: 60,
    height: 15,
    marginLeft: 15,
    marginBottom: 15,
    border: "none",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    paddingLeft: "13px",
    background: "#CEE5F7 ", 
  },
  field: {
    fontSize: "12px",
    fontFamily: "Lato",
    color: "#5B5F62",
  },
};

const narrowTextFieldStyles2 = {
  fieldGroup: {
    width: 60,
    height: 15,
    marginLeft: 15,
    marginBottom: 15,
    border: "none",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    paddingLeft: "13px",
    background: "#FBBABE",
  },
  field: {
    fontSize: "12px",
    fontFamily: "Lato",
    color: "#5B5F62",
  },
};

const narrowTextFieldStyles3 = {
  fieldGroup: {
    width: 60,
    height: 15,
    marginLeft: 15,
    marginBottom: 15,
    border: "none",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    paddingLeft: "13px",
    background: " #87FFE3",
  },
  field: {
    fontSize: "12px",
    fontFamily: "Lato",
    color: "#5B5F62",
  },
};
function MyTarget() { 
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [showWarMessageBar, setShowWarMessageBar] = useState(false);
  const [showWarMessageBar2, setShowWarMessageBar2] = useState(false);
  const [showWarMessageBar3, setShowWarMessageBar3] = useState(false);
  const [showWarMessageBar4, setShowWarMessageBar4] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const [accountManagerTargets, setAccountManagerTargets] = useState([]);
  const [bdeTargets, setBdeTargets] = useState([]);
  const [recruiterTargets, setRecruiterTargets] = useState([]);
  const [teamLeadTargets, setTeamLeadTargets] = useState([]);
  const [match, setMatch] = useState(location.state);
  const [showEditButton, setShowEditButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAccountManagerTargets, setFilteredAccountManagerTargets] = useState([]);
  const [dataPresent, setDataPresent] = useState(false);
  const [cardNotificationStates, setCardNotificationStates] = useState(
    new Array(accountManagerTargets.length).fill(false)
  );
  const handleOpenCardNotification = (targetId) => {
    const updatedStates = new Array(cardNotificationStates.length).fill(false); 
    updatedStates[targetId] = true; 
    setCardNotificationStates(updatedStates);
  };

  const handleCloseCardNotification = (index) => {
    const updatedStates = [...cardNotificationStates];
    updatedStates[index] = false;
    setCardNotificationStates(updatedStates);
  };

  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  const [userId, setUserId] = useState(decodedValue.user_id);
  const [targetUserId, setTargetUserId] = useState();
  const [targetUserIdbde, setTargetUserIdbde] = useState();
  const [updateCallout, setUpdateCallout] = useState(false);
  const [isTargetEnabled, setIsTargetEnabled] = useState(false);
  const [isContractEnabled, setIsContractEnabled] = useState(false);
  const [isFullTimeEnabled, setIsFullTimeEnabled] = useState(false);
  const [Accept, setAccept] = useState(false);
  const [Request, setRequest] = useState(false);
  const [count,setCount]=useState({ topData: [], bottomData: [] })
  const [notificationPropup,setNotificationPropup]=useState([])
  const isTeamLead = decodedValue.user_role === 'team_lead';
  const isAdmin = decodedValue.user_role === 'admin';
  const isAccountManager = decodedValue.user_role === 'account_manager';
  const isBde = decodedValue.user_role === 'bde';
  const isRecruiter = decodedValue.user_role === 'recruiter';

  const [countLoad,setCountLoad]=useState(false)
  let topData;
  let bottomData;
  if (isAdmin) {
    topData = accountManagerTargets; 
    bottomData = bdeTargets; 
  }
  else if(isAccountManager){
    topData = accountManagerTargets; 
    bottomData = teamLeadTargets; 
  }
  else if(isBde){
    topData = bdeTargets;
  }
  else if(isTeamLead){
    topData = teamLeadTargets;
    bottomData = recruiterTargets;
  }
  else if(isRecruiter){
    topData = recruiterTargets;
  }
  const filterTargets = (data, searchTerm) => {
  if (!searchTerm) {
    return data; 
  }
  searchTerm = searchTerm.toLowerCase(); 
  return data?.filter((target) => {
    return (
      (target.designation && target.designation.toLowerCase().includes(searchTerm)) ||
      (target.name && target.name.toLowerCase().includes(searchTerm)) ||
      (target.target && target.target.toString().includes(searchTerm))
    );
  });
};
  const filteredTopData = filterTargets(topData, searchTerm);
  const filteredBottomData = filterTargets(bottomData, searchTerm);
  filteredTopData.forEach(item1 => {
  if(parseInt(item1.achieved)>item1.target){
    let additionValue=parseInt(item1.achieved)-item1.target
    item1.achieved =`${item1.target}+${additionValue} ` 
  }else{
    item1.achieved =`${item1.achieved} ` 
  }
});
  filteredBottomData?.forEach(item1 => {
  if(parseInt(item1.achieved)>item1.target){
    let additionValue=parseInt(item1.achieved)-item1.target
    item1.achieved =`${item1.target}+${additionValue} ` 
  }else{
    item1.achieved =`${item1.achieved} ` 
  }
});
  const openCallout = (cardId) => {
    setRequest(false);
    setShowEditButton(false);
    setUpdateCallout(cardId);
    if (accountManagerTargets.find((target) => target._id === cardId)) {
      setTargetUserId(cardId);
      setTargetUserIdbde(null); 
    } else {
      setTargetUserId(null); 
      setTargetUserIdbde(cardId);
    }
  };
  const closeCallout = () => {
    setUpdateCallout(null);
  };

  useEffect(() => {
    if (showWarMessageBar) {
      setTimeout(() => {
        setShowMessageBar(false);
      }, 2000);
    }
  }, [showWarMessageBar]);
   useEffect(() => {
    if (showWarMessageBar2) {
      setTimeout(() => {
        setRequest(false);
      }, 2000);
    }
  }, [showWarMessageBar2]);
  useEffect(() => {
    if (showWarMessageBar3) {
      setTimeout(() => {
        setRequest(false); 
      }, 2000);
    }
  }, [showWarMessageBar3]);
  useEffect(() => {
    if (showWarMessageBar4) {
      setTimeout(() => {
      }, 2000);
    }
  }, [showWarMessageBar4]);
  useEffect(() => {
    if (showWarMessageBar3) {
      setTimeout(() => {
        setRequest(false);
      }, 2000);
    }
  }, [showWarMessageBar3]);
  useEffect(() => {
    if (showWarMessageBar4) {
      setTimeout(() => {
        setShowEditButton(false)
      }, 2000);
    }
  }, [showWarMessageBar4]);

  const getNotificationData = async (assigned,updated,created,msg,data) => {

      let readDetails=[{user:decodedValue.user_id,read:false},{user:created._id,read:false}];
      let newNotificationData = {
        assigned: assigned,
        updated_by:updated,
        created_by:created,
        message: msg,
        data:data,
        user:userId,
        isRead:readDetails ,
      };     
try{
      await axiosPrivateCall.post('api/v1/notification/sendNotification', newNotificationData).then(res=>
      {   if(res.data==="already exists"){
         setShowWarMessageBar(true)
        }
        else if(res.data.message==="request"){
          setShowWarMessageBar2(true)         
        }
        else if(res.data.message==="accept"){
          setShowWarMessageBar3(true)        
        }
        else if(res.data.message==="request"){
          setShowWarMessageBar4(true)         
        }
        else{
          setShowMessageBar(true)         
        }}
      );
    } 
    catch (error) { console.error('Error:', error.message);
    }
  };
  useEffect(() => {
    if (showWarMessageBar2) {
      setTimeout(() => {
        setShowMessageBar(false);
        setRequest(false)
        setShowEditButton(false)
        setShowWarMessageBar2(false);       
      }, 2000);
    }
    if (showWarMessageBar3) {
      setTimeout(() => {
        setShowMessageBar(false);
        setRequest(false)
        setShowEditButton(false)
        setShowWarMessageBar3(false);       
      }, 2000);
    }
    if (showWarMessageBar4) {
      setTimeout(() => {
        setShowMessageBar(false);
        setRequest(false)
        setShowEditButton(false)
        setShowWarMessageBar4(false);      
      }, 2000);
    }
    if (showMessageBar) {
      setTimeout(() => {
        setShowMessageBar(false);
      }, 2000);
    }
    if (showWarMessageBar) {
      setTimeout(() => {
        setRequest(false)
        setShowEditButton(false)
        setShowWarMessageBar(false)
      }, 2000);
    }
  }, [userId, showMessageBar, showWarMessageBar,showWarMessageBar2, showWarMessageBar3, showWarMessageBar4, searchTerm]); 
  const handleCount=(data)=>{
  if(data==='count'){
  setCountLoad(true)
  }
  }
  const receiveFromChild = (data, id) => {
    if (data === 'review') {
      const topMatchedNotification = topData?.find((target) => target._id === id);
      if (topMatchedNotification) {
        setIsTargetEnabled(true);
        setIsContractEnabled(true);
        setIsFullTimeEnabled(true);
        setShowEditButton(true);
        setTargetUserId(topMatchedNotification._id);
      }
      const bottomMatchedNotification = bottomData?.find((target) => target._id === id);
      if (bottomMatchedNotification) {
        setTargetUserIdbde(bottomMatchedNotification._id);
      }
    }
    if (data === 'accept') {
      let assignedId=id.assigned;
      const simplifiedObject = {
        fulltime: id.data[0].fulltime,
        contract: id.data[0].contract,
        target:id.data[0].target,
       _id:assignedId
      };
       axiosPrivateCall.post(`api/v1/targetControl/updateTargetData`,simplifiedObject)
       .then(res=>{
        getTargetData()
        console.log('success') 
         })
       .catch(err=>console.log('error'))
     
    }
  };
  const getTargetData = () => {
    axiosPrivateCall
      .get(`api/v1/targetControl/getHierarchyTargetData?employee_id=${userId}`)
      .then((res) => {
        setDataPresent(true);
        const accountManagerData = res.data.filter((target) => target.designation === "account_manager");
        const bdeData = res.data.filter((target) => target.designation === "bde");
        const recruiterData = res.data.filter((target) => target.designation === "recruiter");
        const teamLeadData = res.data.filter((target) => target.designation === "team_lead");
        switch (decodedValue.user_role) {
          case "admin":
            setAccountManagerTargets(accountManagerData);
            setBdeTargets(bdeData);
            break;
          case "account_manager":
            setAccountManagerTargets(accountManagerData);
            setTeamLeadTargets(teamLeadData);
            break;
          case "recruiter":
            setRecruiterTargets(recruiterData);
            break;
          case "team_lead":
            setTeamLeadTargets(teamLeadData);
            setRecruiterTargets(recruiterData);
            break;
            default:
        }
        const filteredAccountManagerTargets = filterTargets(accountManagerData, searchTerm);
        setFilteredAccountManagerTargets(filteredAccountManagerTargets)
        if (accountManagerData.length === 0 && bdeData.length === 0 && recruiterData.length === 0 && teamLeadData.length === 0) {
        } else {
          setDataPresent(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(()=>{
    if(countLoad){
    axiosPrivateCall.get(`api/v1/notification/countNotification`).then(
      (res)=>{
        setNotificationPropup(res.data)
        setCountLoad(false)
      })
    }else{
      axiosPrivateCall.get(`api/v1/notification/countNotification`).then(
        (res)=>{
          setNotificationPropup(res.data)
        })
    }},[countLoad])
    useEffect(() => {
        getTargetData();
      }, [isModalOpen]);


  const NotificationCount=()=>{
  const matchedNotificationsTopData = topData.map(target => {
  const filteredNotifications = notificationPropup.filter(notification => {
          return (
            notification.assigned === target._id &&
            notification.isRead.some(
              (item) => item.read === false && item.user === decodedValue.user_id
            )    
            );
         
      });
      return filteredNotifications;
    });
  let matchedNotificationsBottomData = []; 

  if (bottomData) {
    matchedNotificationsBottomData = bottomData.map(target => {
      const filteredNotifications = notificationPropup.filter(notification => {
          return (
            notification.assigned === target._id &&
            notification.isRead.some(
              (item) => item.read === false && item.user === decodedValue.user_id
            )
          );
      });
      return filteredNotifications;
    });
  }
  const notificationtopCounts = matchedNotificationsTopData.map(filteredNotifications => filteredNotifications.length);
  const notificationbottomCounts = matchedNotificationsBottomData.map(filteredNotifications => filteredNotifications.length); 

 let count={
    topData: notificationtopCounts,
    bottomData: notificationbottomCounts,
  };
  setCount(count)
  }

  useEffect(() => {
  if(countLoad){
    NotificationCount();
    setCountLoad(false)
  }else{
    NotificationCount();
  }
}, [filteredAccountManagerTargets, notificationPropup, userId,countLoad]);

  const updateTargetData = async(e) => {
  const updated=e.target.innerText;
  let targetToUpdate = null;
  let targetToUpdatebde = null;
  let selectedId = null;
  if (targetUserId) {
    targetToUpdate = accountManagerTargets.find(
      (target) => target._id === targetUserId
    );
    selectedId = targetUserId;
  }
  if (targetUserIdbde) {
    targetToUpdatebde = bdeTargets.find(
      (target) => target._id === targetUserIdbde
    );
    selectedId = targetUserIdbde;
  }
  if (!selectedId) {
    console.error("No target user ID selected for update.");
    return;
  }

  const updatedTargetData = {
    _id: selectedId,
    target:
      (targetToUpdate && targetToUpdate.target) ||
      (targetToUpdatebde && targetToUpdatebde.target),
    contract:
      (targetToUpdate && targetToUpdate.contract) ||
      (targetToUpdatebde && targetToUpdatebde.contract),
    fulltime:
      (targetToUpdate && targetToUpdate.fulltime) ||
      (targetToUpdatebde && targetToUpdatebde.fulltime),
    message:(Accept === true?"accepted":"request")
  };
  if(updated==='Save'){ 
    await axiosPrivateCall
      .post(`api/v1/targetControl/updateTargetData`, updatedTargetData)
      .then((res) => {
        setShowWarMessageBar4(true);
      })
      .catch((e) => {
        console.error(e);
      });
    } 
   if(updated==='request'){
      await axiosPrivateCall
        .post(`api/v1/targetControl/updateTargetData`, updatedTargetData)
        .then((res) => {
          setShowWarMessageBar2(true);     
        })
        .catch((e) => {
          console.error(e);
        });
      } 
  if(updated==='accept'){
      await axiosPrivateCall
        .post(`api/v1/targetControl/updateTargetData`, updatedTargetData)
        .then((res) => {
          setShowWarMessageBar3(true);     
        })
        .catch((e) => {
          console.error(e);
        });
      }};

  const inputChangeHandler = (e, name, index) => {
    const { value } = e.target;
    let inputValue = value.trim();
    const updatedTargets = [...accountManagerTargets];
    updatedTargets[index][name] = inputValue;
    if (name === "contract" || name === "fulltime") {
      const contract = parseFloat(updatedTargets[index]["contract"]) || 0;
      const fulltime = parseFloat(updatedTargets[index]["fulltime"]) || 0;
      const revenue = (contract * 1.25 + fulltime * 1);
      updatedTargets[index]["revenue"] = revenue;
    }
    setBdeTargets(updatedTargets);
  };
    const inputChangeHandlerL = (e, name, index) => {
    const { value } = e.target;
    let inputValue = value.trim();
    const updatedTargets = [...teamLeadTargets];
    updatedTargets[index][name] = inputValue;
    if (name === "contract" || name === "fulltime") {
      const contract = parseFloat(updatedTargets[index]["contract"]) || 0;
      const fulltime = parseFloat(updatedTargets[index]["fulltime"]) || 0;
      const revenue = (contract * 1.25 + fulltime * 1);
      updatedTargets[index]["revenue"] = revenue;
    }
    setBdeTargets(updatedTargets);
  };
    const inputChangeHandlerR = (e, name, index) => { 
    const { value } = e.target;
    let inputValue = value.trim();
    const updatedTargets = [...recruiterTargets];
    updatedTargets[index][name] = inputValue;
    if (name === "contract" || name === "fulltime") {
      const contract = parseFloat(updatedTargets[index]["contract"]) || 0;
      const fulltime = parseFloat(updatedTargets[index]["fulltime"]) || 0;
      const revenue = (contract * 1.25 + fulltime * 1);
      updatedTargets[index]["revenue"] = revenue;
    }
    setBdeTargets(updatedTargets);
  };
    const acceptDates=(id)=>{
    const acceptedDates = [];
    notificationPropup?.forEach(notification => {
      if (notification.message === 'accept' && notification.assigned === id) {
        acceptedDates.push(formatDateToDMY(notification.updatedAt));
      }
    });
    const lastAcceptedDate = acceptedDates.length > 0 ? acceptedDates[acceptedDates.length - 1] : null;
    return lastAcceptedDate;
  } 
    const closeMessageBar = () => {
    setShowMessageBar(!showMessageBar); 
    setRequest(false); 
    setShowEditButton(false);
  };
  const closeMessageBar1 = () => {
    setShowWarMessageBar2(!showWarMessageBar2);
    setRequest(false);
    setShowEditButton(false);
  };
  const closeMessageBar2 = () => {
    setShowWarMessageBar3(!showWarMessageBar3);
    setRequest(false); 
    setShowEditButton(false);
  };
  const closeMessageBar3 = () => {
    setShowWarMessageBar4(!showWarMessageBar4);
    setRequest(false); 
    setShowEditButton(false);
  };
  const closeMessageBar4 = () => {
    setShowWarMessageBar(!showWarMessageBar);
    setRequest(false); 
    setShowEditButton(false);
  }; 
  return (
  <div >
    {!dataPresent ? (
      <Spinner className={styles.spinnerStyle} size={SpinnerSize.large} label="Loading ..."/>
    ) : (
    <div className={styles.page}>
      <div className={styles.container}>
        {decodedValue.user_role === "admin"
          ? isModalOpen && (
              <AddTargetModal
                showMessageBar={showMessageBar}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setShowMessageBar={setShowMessageBar}
              />
            )
          : isModalOpen && (
              <AmTargetModal
                showMessageBar={showMessageBar}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setShowMessageBar={setShowMessageBar}
              />
            )}
        <div className={styles.nav_container}>
          <div className={styles.title}>Target Listing</div>
          {showMessageBar ? (
            <div>        
              <MessageBar
              onDismiss={() => {setShowMessageBar(!showMessageBar)
                closeMessageBar()}}
                styles={messageBarStyles}
                dismissButtonAriaLabel="Close"
                messageBarType={MessageBarType.success}               
              >
                Target Added successfully!
              </MessageBar>
            </div>
         ) :
          showWarMessageBar2 ? (
            <div>
                <MessageBar
                onDismiss={() => {
                closeMessageBar1()}}
                styles={messageBarStyles}
                dismissButtonAriaLabel="Close"
                messageBarType={MessageBarType.success}               
              >
                Requested Successfully!
              </MessageBar>
            </div>
     ) : showWarMessageBar ? (
      <div>
        <MessageBar
          onDismiss={() => {setShowWarMessageBar(!showWarMessageBar)
            closeMessageBar4()}
          }
          styles={messageBarStyles}
          dismissButtonAriaLabel="Close"
          messageBarType={MessageBarType.error}
        >
          Target Already Updated!
        </MessageBar>
      </div>
) :showWarMessageBar3 ? (
  <div>
    <MessageBar
      onDismiss={() => {setShowWarMessageBar3(!showWarMessageBar3)
        closeMessageBar2()}
      }
      styles={messageBarStyles}
      dismissButtonAriaLabel="Close"
      messageBarType={MessageBarType.success}
    >
      Accepted Successfully!
    </MessageBar>
  </div>
) :showWarMessageBar4 ? (
  <div>
    <MessageBar
      onDismiss={() => {setShowWarMessageBar4(!showWarMessageBar4)
        closeMessageBar3()}
      }
      styles={messageBarStyles}
      dismissButtonAriaLabel="Close"
      messageBarType={MessageBarType.success}
    >
      Updated Successfully!
    </MessageBar>
  </div>
) : null}
          <div className={styles.nav_items}>
          <SearchBox
            placeholder=" "
            value={searchTerm}
            onChange={(e, newValue) => setSearchTerm(newValue)}
            styles={narrowTextFieldStyles1}
            showIcon
          />
          {!decodedValue.user_role==='recruiter'&&
            <PrimaryButton
              text="Add"
              iconProps={addIcon}
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setMatch(false);
              }}
            />
            }
          </div>
        </div>
      </div>
 
       {(searchTerm&&filteredTopData?.length===0)&&(
        <div  className={styles.image_container}>
                         <tr>
                             <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                             <img src={Nomatchimg} alt="NoDataimage" width={"230px"} height={"280px"} />
                             </td>
                           </tr>
                        </div>
      )}
      <div className={styles.mytargetContainer }>
              {(!searchTerm&&filteredTopData?.length===0)?(
            <div  className={styles.data_image_container}>
                         <tr>
                         <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                              <img src={NoDataFound} alt="Noimage" width={"400px"} height={"370px"} />
                              </td>
                           </tr>
                      </div>
      ):(
        <div className={styles.my_target_card_container}>
        {filteredTopData
        ?.map((target, index) => (<div key={target._id}>
              <div className={styles.target_my_card}>
                <div
                  className={styles.target_title}
                  style={{ display: "flex", alignItems: "center" }}
                >
                {decodedValue.user_role === 'account_manager'? "Account Manager Target": 
                 decodedValue.user_role === 'team_lead'? "Lead Target": 
                 decodedValue.user_role === 'recruiter'? "Recruiter Target": 
                 decodedValue.user_role === 'admin'? "Account Manager Target": "Account Manager Target"}
                  <div className= {decodedValue.user_role === 'team_lead'? 
                  styles.target_countL :decodedValue.user_role === 'recruiter'? 
                  styles.target_countR:null} style={{ position: 'relative' }}>                 
                 {count.topData[index] !== 0 && count.topData[index]!== '' && count.topData[index] !== undefined && (
                      <p style={{
                        fontSize: '7px',
                        position: 'absolute',
                        bottom: '15px',
                        left: '100%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        width: '12px',
                        height: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                       {count.topData[index]}
                      </p>
                    )}
                    <img src={notificationbox} alt="Notificationimage" onClick={() => handleOpenCardNotification(index)} />
                  </div>               
                  <FontIcon
                    iconName="MoreVertical"
                    className={iconClass1}
                    onClick={() => openCallout(target._id)}
                    id={`FO_${target._id}`}
                  />  
       {updateCallout === target._id && (
  <Callout
    gapSpace={0}
    target={`#FO_${target._id}`}
    onDismiss={() => closeCallout()}
    isBeakVisible={false}
    directionalHint={DirectionalHint.bottomCenter}
  >
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {decodedValue.user_role === 'admin' && (
        <DefaultButton
          text="Edit"
          onClick={() => {
            setIsTargetEnabled(true);
            setIsContractEnabled(true);
            setIsFullTimeEnabled(true);
            setShowEditButton(true);
            setTargetUserId(target._id);
          }}
          styles={calloutBtnStyles} 
        />
      )}
{(decodedValue.user_role === 'account_manager' || decodedValue.user_role === 'team_lead'|| decodedValue.user_role === 'recruiter') && (
  <>
    <DefaultButton
      text="Accept"
      styles={calloutBtnStyles}
      onClick={() => {
        setCountLoad(true)
        getNotificationData(target._id, target.assigned_to,target.assigned_by, "accept",
        (decodedValue.user_role === 'account_manager')?accountManagerTargets[index]
        :(decodedValue.user_role ==="team_lead") ? teamLeadTargets[index]: (decodedValue.user_role ==="recruiter")?recruiterTargets[index]:null )
        setAccept(true)
        setRequest(false)
      }}
    />
    <DefaultButton
      text="Request"
      styles={calloutBtnStyles}
      onClick={() => {
        setIsTargetEnabled(true)
        setIsFullTimeEnabled(true)
        setIsContractEnabled(true)
        setTargetUserId(target._id)
        setRequest(true)
        setAccept(false)
        setCountLoad(true)
     }}
    />
  </>
)}
    </div>
  </Callout>
)}
                </div>
                <div className={styles.popStyle}>   
                {cardNotificationStates[index] ? ( 
                  <NotificationsBox
                    isOpen={true}
                    handleCount={handleCount}
                    sendToParent={receiveFromChild}
                    onDismiss={() => {handleCloseCardNotification(index)
                    }}
                    targetId={target._id} 
                  /> 
               ):''}  
                </div>
                <div className={styles.target_sub_title}>
                  Designation: {target.designation}
                </div>
                <div className={styles.target_name_target}>
                <div className={decodedValue.user_role === ('account_manager')? styles.target_card1: 
                  decodedValue.user_role === ('admin')? styles.target_card1:
                  decodedValue.user_role === 'team_lead'? styles.target_card2:
                  decodedValue.user_role === 'recruiter'? styles.target_card3: null }>
                    <div className={styles.target_title_card1}>Name</div>
                    <div className={styles.target_sub_title_card1}>
                      {target.name}
                    </div>
                  </div>
                  <div className={decodedValue.user_role === ('account_manager')? styles.target_card1: 
                  decodedValue.user_role === ('admin')? styles.target_card1:
                  decodedValue.user_role === 'team_lead'? styles.target_card2:
                  decodedValue.user_role === 'recruiter'? styles.target_card3: null }>
                    <div className={styles.target_title_card1}>Target</div>
                    <div className={styles.target_sub_title_card1}>
                    {isTargetEnabled === true && targetUserId===target._id  ? (
                        <TextField
                          value={target.target}
                          styles={decodedValue.user_role === 'account_manager'? 
                          narrowTextFieldStyles: decodedValue.user_role === 'team_lead'? 
                          narrowTextFieldStyles2: decodedValue.user_role === 'recruiter'?
                          narrowTextFieldStyles3: decodedValue.user_role === 'admin'?
                           narrowTextFieldStyles: narrowTextFieldStyles}
                          onChange={(e) =>
                            decodedValue.user_role === ('account_manager')? inputChangeHandler(e, "target", index): 
                            decodedValue.user_role === ('admin')? inputChangeHandler(e, "target", index):
                            decodedValue.user_role === 'team_lead'? inputChangeHandlerL(e, "target", index):
                            decodedValue.user_role === 'recruiter'? inputChangeHandlerR(e, "target", index): null 
                          }
                          disabled={!isTargetEnabled}
                        />
                      ) : (
                        target.target
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.target_name_target}>
                <div className={decodedValue.user_role === ('account_manager')? styles.target_card1: 
                  decodedValue.user_role === ('admin')? styles.target_card1:
                  decodedValue.user_role === 'team_lead'? styles.target_card2:
                  decodedValue.user_role === 'recruiter'? styles.target_card3: null }>
                    <div className={styles.target_title_card1}>Contract</div>
                    <div className={styles.target_sub_title_card1}>
                      {isContractEnabled === true && targetUserId === target._id? (
                        <TextField
                          value={target.contract}
                          styles={decodedValue.user_role === 'account_manager'? 
                          narrowTextFieldStyles: decodedValue.user_role === 'team_lead'? 
                          narrowTextFieldStyles2: decodedValue.user_role === 'recruiter'?
                          narrowTextFieldStyles3: decodedValue.user_role === 'admin'?
                           narrowTextFieldStyles: narrowTextFieldStyles}
                           onChange={(e) =>
                            decodedValue.user_role === ('account_manager')? inputChangeHandler(e, "contract", index): 
                            decodedValue.user_role === ('admin')? inputChangeHandler(e, "contract", index):
                            decodedValue.user_role === 'team_lead'? inputChangeHandlerL(e, "contract", index):
                            decodedValue.user_role === 'recruiter'? inputChangeHandlerR(e, "contract", index): null 
                          }
                          disabled={!isContractEnabled}
                        />
                      ) : (
                        target.contract
                      )}
                    </div>
                  </div>
                  <div className={decodedValue.user_role === ('account_manager')? styles.target_card1: 
                  decodedValue.user_role === ('admin')? styles.target_card1:
                  decodedValue.user_role === 'team_lead'? styles.target_card2:
                  decodedValue.user_role === 'recruiter'? styles.target_card3: null }>
                    <div className={styles.target_title_card1}>Full Time</div>
                    <div className={styles.target_sub_title_card1}>
                      {isFullTimeEnabled === true && targetUserId===target._id ?  (
                        <TextField
                          value={target.fulltime}
                          styles={decodedValue.user_role === 'account_manager'? 
                          narrowTextFieldStyles: decodedValue.user_role === 'team_lead'? 
                          narrowTextFieldStyles2: decodedValue.user_role === 'recruiter'?
                          narrowTextFieldStyles3: decodedValue.user_role === 'admin'?
                           narrowTextFieldStyles: narrowTextFieldStyles}
                           onChange={(e) =>
                            decodedValue.user_role === ('account_manager')? inputChangeHandler(e, "fulltime", index): 
                            decodedValue.user_role === ('admin')? inputChangeHandler(e, "fulltime", index):
                            decodedValue.user_role === 'team_lead'? inputChangeHandlerL(e, "fulltime", index):
                            decodedValue.user_role === 'recruiter'? inputChangeHandlerR(e, "fulltime", index): null 
                          }
                          disabled={!isFullTimeEnabled}
                        />
                      ) : (
                        target.fulltime
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.target_name_target}>
                  <div className={decodedValue.user_role === 'account_manager'? styles.target_card1: 
                  decodedValue.user_role === ('admin')? styles.target_card1:
                  decodedValue.user_role === 'team_lead'? styles.target_card2:
                  decodedValue.user_role === 'recruiter'? styles.target_card3: null }>
                    <div className={styles.target_title_card1}>Revenue</div>
                    <div className={styles.target_sub_title_card1}> 
                    {target.revenue} 
                         </div>
                  </div>
                  <div className={decodedValue.user_role === ('account_manager')? styles.target_card1: 
                  decodedValue.user_role === ('admin')? styles.target_card1:
                  decodedValue.user_role === 'team_lead'? styles.target_card2:
                  decodedValue.user_role === 'recruiter'? styles.target_card3: null }>
                    <div className={styles.target_title_card1}>Achieved</div>
                    <div className={styles.target_sub_title_card1}> 
                       {target.achieved} 
                    </div>
                  </div>
                </div>
                <div className={styles.target_allocated_date}>
                  <div className={styles.button}>
                  {showEditButton === true && targetUserId === target._id ? (
                      <PrimaryButton
                        text="Save"
                        onClick={updateTargetData}
                        style={{ fontSize: "14px" }}
                      />
                    ) : null} {  Request === true && targetUserId === target._id ? (  // 
                      <PrimaryButton
                        text="Request"
                        onClick={()=>{
                          updateTargetData()
                          setCountLoad(true)
                        getNotificationData(target._id, target.assigned_to,target.assigned_by, "request",
                        (decodedValue.user_role === 'account_manager')?accountManagerTargets[index]
                        :(decodedValue.user_role ==="team_lead") ? teamLeadTargets[index]: (decodedValue.user_role ==="recruiter")?recruiterTargets[index]:null )}}
                        style={{ fontSize: "14px" }}             
                      />
                    ) : null}
                  </div>          
                  Allocated Date: {formatDateToDMY(target.allocated_date)}
                </div>
                <div className={styles.target_end}>
                  <div className={styles.target_id}>
                    ID: {target.employee_id}
                  </div>         
             <div className={styles.target_date}>                 
                    Accepted Date:{acceptDates(target._id)}
                  </div>                 
                </div>
              </div>
            </div>
          ))}
          </div>
          )}
        </div>
      </div>
          )}
          </div>);}
export default MyTarget;
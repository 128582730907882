import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./ViewEditClient.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { isEmpty, isNumOnly } from "../utils/validation";
import { ChoiceGroup } from "@fluentui/react";
import { TextField, PrimaryButton, DefaultButton, DatePicker } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { axiosPrivateCall, axiosJsonCall } from "../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import uploadImage from "../assets/upload_cloud_image.png";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { UploadPopup } from "../components/UploadModal";
import { Label } from "@fluentui/react/lib/Label";
import ComboBox from "../components/ComboBox/ComboBox";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {ISOdateToCustomDate} from '../utils/helpers.js'


// regex
const nameInputRegex = /^[a-zA-Z0-9- '.\u00c0-\u024f\u1e00-\u1eff]*$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileRegex = /^[6-9]\d{9}$/;
const websiteRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

const tableCloseIconClass = mergeStyles({
  fontSize: 10,
  height: "12px",
  width: "12px",
  cursor: "pointer",
  color: "red",
  marginTop: "3px",
  marginLeft: "8px",
});

//add transperant here in title: border
const dropDownStyles = mergeStyleSets({
  dropdown: { minWidth: "80px", maxWidth: "120px", minHeight: "20px" },
  title: { height: "22px", lineHeight: "18px", fontSize: "12px", border: "0.5px solid transparent" },
  caretDownWrapper: { height: "22px", lineHeight: "20px !important" },
  caretDown: { color: "grey" },
  dropdownItem: { minHeight: "22px", fontSize: 12 },
  dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
});

const dropDownActive = mergeStyleSets({
  dropdown: { minWidth: "80px", maxWidth: "120px", minHeight: "20px" },
  title: { height: "22px", lineHeight: "18px", fontSize: "12px", border: "0.5px solid black" },
  caretDownWrapper: { height: "22px", lineHeight: "20px !important" },
  caretDown: { color: "grey" },
  dropdownItem: { minHeight: "22px", fontSize: 12 },
  dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
});

//add transperant here in title: border

const dropDownErrorStyles = mergeStyleSets({
  dropdown: { minWidth: "80px", maxWidth: "120px", minHeight: "20px" },
  title: { height: "22px", lineHeight: "18px", fontSize: "12px", border: "0.5px solid #a80000" },
  caretDownWrapper: { height: "22px", lineHeight: "20px !important" },
  dropdownItem: { minHeight: "22px", fontSize: 12 },
  dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
});

const Field = mergeStyleSets({
  fieldGroup: { height: "22px", minWidth: "80px", maxWidth: "120px", border: "0.5px solid transparent", fontSize: "12px" },
  field: { fontSize: "12px" },
});

const FieldL = mergeStyleSets({
  fieldGroup: { height: "22px", minWidth: "160px", maxWidth: "240px", border: "0.5px solid transparent", fontSize: "12px" },
  field: { fontSize: "12px" },
});

const Field1 = mergeStyleSets({
  fieldGroup: { height: "22px", minWidth: "80px", maxWidth: "120px", border: "0.5px solid transparent", backgroundColor: "#EDF2F6", fontSize: "12px" },
  field: { fontSize: "12px" },
});

const FieldError = mergeStyleSets({
  fieldGroup: { height: "22px", minWidth: "80px", maxWidth: "120px", border: "0.5px solid #a80000", backgroundColor: "#EDF2F6", fontSize: "12px" },
  field: { fontSize: "12px" },
});

const dropdownTeam = [
  { key: "Tag Team", text: "Tag Team" },
  { key: "Business Team", text: "Business Team" },
  { key: "Others", text: "Others" },
];

// New Design
// regex
// const nameInputRegex = /^[a-zA-Z0-9- '.\u00c0-\u024f\u1e00-\u1eff]*$/;
// const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const mobileRegex = /^[6-9]\d{9}$/;
// const websiteRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

const empanelmentOptions = [{ key: "Yes", text: "Yes" }];

const empanelmentOptions1 = [{ key: "No", text: "No" }];

const expansionOptions = [{ key: "Yes", text: "Yes  " }];

const expansionOptions1 = [{ key: "No", text: "No" }];

const textFieldColored3 = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";

  return {
    fieldGroup: {
      display: "flex",
      // width: "340px",
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};

const textFieldColored2 = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";

  return {
    fieldGroup: {
      display: "flex",
      // width: "190px",
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};

const buttonStyles = {
  root: {
    marginTop: "3px",
    height: "30px",
    borderRadius: "2px",
    marginRight: "8px",
  },
};
const buttonStyles1 = {
  root: {
    marginTop: "10px",
    width: "145px",
    borderRadius: "8px",
  },
};
const textFieldColored = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";

  return {
    fieldGroup: {
      display: "flex",
      width: "250px",
      height: "25px",
      marginTop: "2.5px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
    root: {
      marginRight: "30px",
    },
  };
};
const textFieldColored1 = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";

  return {
    fieldGroup: {
      display: "flex",
      width: "350px",
      height: "30px",
      marginTop: "2.5px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        "&:focus": {
          borderColor: borderColor,
        },
        "&:hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
    root: {
      marginRight: "30px",
    },
  };
};
const customizedDropdown = (props, currentHover, error, value) => {
  // const borderColor = "#E1E5E8";
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  const focusBorderColor = "yellow"; // Color when focused or value entered
  let titleColor = "#484848"; // Default color when value is not empty
  if (!value) {
    titleColor = error ? "#D24545" : "#484848"; // Change the color to red if value is empty
  }

  return {
    title: {
      height: "30.5px",
      display: "flex",
      alignItems: "center",
      color: titleColor, // Apply dynamic color based on value
    },

    dropdown: {
      borderRadius: "4px", // Add border-radius
      selectors: {
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderColor: focusBorderColor, // Apply focus border color
        },
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px", // Add border-radius
          color: titleColor, // Apply dynamic color based on value
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
      },
    },
  };
};

const AddCandidateModal = (props) => {


	const [currentHover, setCurrentHover] = useState('');
	const [fileTitle, setFileTitle] = useState('');
	const [file, setFile] = useState({});
	const [btnIcon, setBtnIcon] = useState('Add');
	const [searchParams, setSearchParams] = useSearchParams();
	const [dropDownCities, setDropDownCities] = useState([]);
	const [dropDownStates, setDropDownStates] = useState([]);
	const [selectedPassthrough, setSelectedPassthrough] = useState("");
	const [validationErrors, setValidationErrors] = useState({});
	const [empanelmentOption, setEmpanelmentOption] = useState("");
  const [expansionOption, setExpansionOption] = useState("");
  const [uploading, setUploading] = useState(false);
	const [buttonText, setButtonText] = useState("Attach Template");
  const [linkedData,setLinkedData] =useState([])
  const [hasMore, setHasMore] = useState(true);
  const [visibleData, setVisibleData] = useState([]);


useEffect(()=>{
axiosPrivateCall.get(`/api/v1/client/linkedDetails/${searchParams.get( "client_id")}`)
.then(res=>setLinkedData(res.data))
.catch(err=>{
  console.log(err)
})
},[])
   const fileInputRef = useRef(null);
     function uploadDocs(files) {
    setUploading(true);
    let data = Object.values(files);
    const formData = new FormData();
    data.map((file) => {
      formData.append("files", file);
    });
    axiosPrivateCall
      .post("/api/v1/client/addClientDocuments", formData)
      .then((res) => {
        let fileuploaded = res.data.documents;
        setBasicInfo((prev) => {
          let buffer = { ...prev };
          fileuploaded.forEach((file) => {
            if (!buffer.documents.find((doc) => doc.document_name === file.document_name)) {
              buffer.documents.push(file);
            }
          });
          return buffer;
        });
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  }

  const handleEmpanelmentChange = (ev, option) => {
    setEmpanelmentOption(option.key);
    const value = option.key === "Yes" ? "Yes" : "No";
    setBasicInfo((prevInfo) => ({ ...prevInfo, empanelment: value }));
  };
  const handleExpansionChange = (ev, option) => {
    setExpansionOption(option.key);
    const value = option.key === "Yes" ? "Yes" : "No";
    setBasicInfo((prevInfo) => ({ ...prevInfo, expansion: value }));
  };

  const passthroughOptions = [
    { key: "direct", text: "Direct" },
    { key: "passthrough_company", text: "Passthrough Company" },
    { key: "passthrough_person", text: "Passthrough Person" },
  ];
  const handlePassthroughChange = (event, item) => {
    console.log("drop", item.key);
    setSelectedPassthrough(item.key);
    let passthroughValue = "";
    if (item.key === "direct") {
      passthroughValue = "Direct";
    } else {
      passthroughValue = item.key;
    }
    setBasicInfo((prevInfo) => ({
      ...prevInfo,
      passthrough: passthroughValue,
      passthrough_person: "",
    }));
  };

  const navigateTo = useNavigate();

  const hoverHandler = (name) => {
    setCurrentHover(name);
  };

  let defaultbasicInfo = {
    _id: "",
    clientId: "",
    company_name: "",
    destination: "",
    reports_to: "",
    primary_email: "",
    secondary_email: "",
    primary_mobile: "",
    secondary_mobile: "",
    company_address: "",
    point_of_contact_name: "",
    city: "",
    pincode: "",
    website: "",
    linkedin: "",
    empanelment: "",
    expansion: "",
    passthrough: "",
    passthrough_company: "",
    passthrough_person: "",
    documents: [],
    template: [],
  };

  const [basicInfo, setBasicInfo] = useState({ ...defaultbasicInfo });
  const [basicInfoerrors, setBasicInfoErrors] = useState({ ...defaultbasicInfo });

  let defaultBasicDetail = {
    contact_person: "",
    destination: "",
    reports_to: "",
    team: "",
    primary_email: "",
    alternate_email: "",
    primary_mobile: "",
    alternate_mobile: "",
  };

  const [basicDetails, setBasicDetails] = useState([{ ...defaultBasicDetail }]);
  const [basicDetailserrors, setBasicDetailserrors] = useState([{ ...defaultBasicDetail }]);

  let defaultClientDetail = {
    company_address: "",
    city: "",
    state: "",
    website: "",
    linkedin: "",
    location: "",
  };

  const [clientDetails, setClientDetails] = useState([{ ...defaultClientDetail }]);
  const [clientDetailserrors, setClientDetailserrors] = useState([{ ...defaultClientDetail }]);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [clientId, setClientId] = useState("");

  useEffect(() => {
    axiosJsonCall
      .get("/b/643fa67bebd26539d0ae2903")
      .then((res) => {
        let buffer = res.data.record;
        let dropdown_data = buffer.map((obj) => {
          return { key: obj.name, text: obj.name };
        });
        setDropDownCities(dropdown_data);
      })
      .catch((e) => {});

    axiosJsonCall
      .get("/b/643fa973ace6f33a220e556e")
      .then((res) => {
        let buffer = res.data.record;
        let dropdown_data = buffer.map((obj) => {
          return { key: obj.name, text: obj.name };
        });
        setDropDownStates(dropdown_data);
      })
      .catch((e) => {});
  }, []);

  const dropDownHandler = (e, item, name, key, setData, setErrors) => {
    setData((prevState) => {
      let update = [...prevState];
      update[key][name] = item.text;

      return update;
    });

    setErrors((prevState) => {
      let update = [...prevState];
      update[key][name] = "";

      return update;
    });
  };

  const inputChangeHandler = (e, inputName) => {
    e.preventDefault();
    const { value } = e.target;
    let inputValue = value;
    let isInputValid = true;
    let isNameValid = false;

    const validationRules = {
      client_id: /^[\w\s-]+$/,
      owner: /^[\w\s-]+$/,
      industry: /^[\w\s-]+$/,
      delivery_poc: /^[\w\s-]+$/,
      mobile_number: /^[6-9]\d{9}$/,
    };

    if (inputName === "company_name" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (inputName === "point_of_contact_name" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (inputName === "destination" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (inputName === "reports_to" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }
    if (inputName === "primary_email" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }
    if (inputName === "primary_mobile" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10);
      isNameValid = true;
    }
    if (inputName === "company_address" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }
    if (inputName === "city" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }
    if (inputName === "pincode" && /^[6-9]\d{9}$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (validationRules[inputName]) {
      isInputValid = validationRules[inputName].test(value);
    }

    if (inputName === "values") {
      if (!isNumOnly(value)) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (isInputValid || isNameValid) {
      setBasicInfo({
        ...basicInfo,
        [inputName]: inputValue,
      });

      setValidationErrors((prevErrors) => {
        return {
          ...prevErrors,
          [inputName]: "",
        };
      });
    }
  };

  // FOR VALIDATION *************************************************************************
  const validateFields = () => {
    const errors = {};

    if (!basicInfo.company_name) {
      errors.company_name = "required";
    }

    if (!basicInfo.point_of_contact_name) {
      errors.point_of_contact_name = "required";
    }

    if (!basicInfo.destination) {
      errors.destination = "required";
    }
    if (!basicInfo.primary_email) {
      errors.primary_email = "required";
    }
    if (!basicInfo.primary_mobile) {
      errors.primary_mobile = "required";
    }
    if (!basicInfo.company_address) {
      errors.company_address = "required";
    }
    if (!basicInfo.city) {
      errors.city = "required";
    }
    if (!basicInfo.pincode) {
      errors.pincode = "required";
    }

    if (selectedPassthrough === 'passthrough_company' && !basicInfo.passthrough_company) {
      errors.passthrough_company = 'required';
    }
    if (selectedPassthrough === 'passthrough_person' && !basicInfo.passthrough_person) {
      errors.passthrough_person = 'required';
    }



    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // END HERE ********************************************************************************

	useEffect(() => {
		axiosPrivateCall(
			`/api/v1/client/getClientDetails?client_id=${searchParams.get(
				"client_id"
			)}`
		)
			.then((res) => {
				setApiData(res.data);
				setClientId(res.data.ClientId)
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

  useEffect(() => {
    console.log("rendering..");
  }, [basicDetails, basicInfo, basicInfoerrors, basicDetailserrors, clientDetails, clientDetailserrors]);

  function setApiData(data) {
    // setBasicDetails([...data.basic_details])
    // setClientDetails([...data.client_details])
    setEmpanelmentOption(data.empanelment);
    setExpansionOption(data.expansion);
    setSelectedPassthrough(data.passthrough);
    setBasicInfo({
      _id: data._id,
      clientId: data.ClientId,
      company_name: data.company_name,
      destination: data.destination,
      reports_to: data.reports_to,
      primary_email: data.primary_email,
      secondary_email: data.alternate_email,
      primary_mobile: data.primary_mobile,
      secondary_mobile: data.alternate_mobile,
      company_address: data.company_address,
      city: data.city,
      pincode: data.pincode,
      website: data.website,
      linkedin: data.linkedin,
      empanelment: data.empanelment,
      expansion: data.expansion,
      point_of_contact_name: data.point_of_contact_name,
      passthrough_person: data.passthrough_person,
      passthrough_company: data.passthrough_company,
      passthrough: data.passthrough,
      documents: [...data.documents],
      // template: [...data.template]
    });
  }
  console.log(basicInfo.template);
  function validate(values) {
    const errors = {};

    if (!values.company_name) {
      errors.company_name = "Required";
    } else if (!nameInputRegex.test(values.company_name)) {
      errors.company_name = "Invalid name";
    }

    if (!nameInputRegex.test(values.passthrough_company)) {
      errors.passthrough_company = "Invalid name";
    }

    if (!values.source_person_name) {
      errors.source_person_name = "Required";
    } else if (!nameInputRegex.test(values.source_person_name)) {
      errors.source_person_name = "Invalid name";
    }

    if (!values.source_person_designation) {
      errors.source_person_designation = "Required";
    } else if (!nameInputRegex.test(values.source_person_designation)) {
      errors.source_person_designation = "Invalid name";
    }

    return errors;
  }

  function nestedValidate(values) {
    let errorArr = [];
    values.map((detail) => errorArr.push({}));

    values.map((detail, index) => {
      if (!nameInputRegex.test(detail.contact_person)) {
        errorArr[index].contact_person = "Invalid name";
      }

      if (detail.primary_email) {
        if (!emailRegex.test(detail.primary_email)) {
          errorArr[index].primary_email = "Invalid Email Id";
        }
      }

      if (detail.primary_mobile) {
        if (!mobileRegex.test(detail.primary_mobile)) {
          errorArr[index].primary_mobile = "Invalid Mobile Number";
        }
      }

      if (detail.alternate_email) {
        if (!emailRegex.test(detail.alternate_email)) {
          errorArr[index].alternate_email = "Invalid Email Id";
        }
      }

      if (detail.alternate_email) {
        if (!mobileRegex.test(detail.alternate_mobile)) {
          errorArr[index].alternate_mobile = "Invalid Mobile Number";
        }
      }
    });

    return errorArr;
  }

  function nestedValidate1(values) {
    let errorArr = [];
    values.map((detail) => errorArr.push({}));

    values.map((detail, index) => {
      if (!detail.company_address) {
        errorArr[index].company_address = "Required";
      }

      if (detail.website) {
        if (!websiteRegex.test(detail.website)) {
          errorArr[index].website = "Invalid Website format";
        }
      }

      if (!detail.state) {
        errorArr[index].state = "Required";
      }

      if (!detail.city) {
        errorArr[index].city = "Required";
      }
    });

    return errorArr;
  }

  function sanitizer(obj, arrobj1, arrobj2) {
    let payload = { ...obj };
    payload.basic_details = [...arrobj1];
    payload.client_details = [...arrobj2];
    console.log(payload);
    return payload;
  }

  function submitHandler(e) {
    e.preventDefault();
    console.log("submit clicked");
    let errorsBasicInfoSet;
    let errorsBasicDetailSet;
    let errorsClientDetailSet;
    const isValid = validateFields();
    console.log(isValid, "validate");

    if (isValid) {
      // let update = sanitizer(basicInfo, basicDetails, clientDetails);
      try {
        axiosPrivateCall
          .post("/api/v1/client/updateClient", basicInfo)
          .then((response) => {
            setBasicInfo(response.data);
            submitForm();
            // setIsModalOpen(!isModalOpen);
            // setShowMessageBar(!showMessageBar);
          })
          .catch((error) => {
            console.error("Error submitting data:", error);
            if (error.response) {
              const errors = {};
              errors.client_id = error.response.data;
              setValidationErrors(errors);
            }
          });
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    }
  }
  function removeUploadedDoc(key) {
    console.log(key);
    let buffer = [...basicInfo.documents];
    let result = [];
    buffer.map((doc) => {
      if (!(doc.document_name === key)) {
        result.push(doc);
      }
    });
    console.log(result);
    setBasicInfo((prev) => {
      let buffer = { ...prev };
      buffer.documents = [...result];
      return buffer;
    });
  }

  function submitForm() {
    navigateTo(`/masterlist/manageclient`);
  }

  function handleRemoveItem(key, setData, setErrors) {
    setData((prevState) => {
      let update = [...prevState];
      let arr1 = update.slice(0, key);
      let arr2 = update.slice(key + 1);
      let newSet = arr1.concat(arr2);
      return newSet;
    });

    setErrors((prevState) => {
      let update = [...prevState];
      let arr1 = update.slice(0, key);
      let arr2 = update.slice(key + 1);
      let newSet = arr1.concat(arr2);

      return newSet;
    });
  }

  function addField(setData, setErrors, defaultData) {
    setData((prevState) => [...prevState, { ...defaultData }]);
    setErrors((prevState) => [...prevState, { ...defaultData }]);
  }

  function UploadHandler() {
    setShowUploadPopup(true);
  }

  function handleDel() {
    setBasicInfo((prev) => {
      let buffer = { ...prev };
      buffer.documents = [];
      return buffer;
    });
  }

  function uploadExcel(e) {
    if (
      e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.target.files[0].type === "application/vnd.ms-excel" ||
      e.target.files[0].type === "application/excel"
    ) {
      setFileTitle("uploading");
      let files = e.target.files[0];
      let formdata = new FormData();
      formdata.append("files", files);

      axiosPrivateCall
        .post("/api/v1/client/addClientTemplate", formdata)
        .then((res) => {
          console.log(res, "ress");
          setBasicInfo((prevState) => {
            return { ...prevState, template: res.data.template };
          });
          setBasicInfoErrors((prevState) => {
            return { ...prevState, template: "" };
          });
          setFileTitle(" ");
          setButtonText("Attached");
          setBtnIcon("Accept");
        })
        .catch((e) => {
          console.log(e, "err");
        });

      console.log(file);
    } else {
      setBasicInfoErrors((prevState) => {
        return { ...prevState, template: "Invalid" };
      });
      setFileTitle(" ");
      setBtnIcon("Cancel");
      console.log(btnIcon, "lkkk");
    }
  }

  function deleteExcel() {
    setBasicInfo((prev) => {
      let buffer = { ...prev };
      buffer.template = [];
      return buffer;
    });
    setBtnIcon("Add");
  }

  const closeIconClass = mergeStyles({
    fontSize: 16,
    height: "20px",
    width: "20px",
    cursor: "pointer",
    //   paddingBottom: 6
  });
  const close = useCallback(() => {
    let value_temp;

    setBasicInfo((prevState) => {
      value_temp = Object.values(validate(prevState));
      if (value_temp.length === 15) {
        // closeHandler();
      } else {
        // closeHandler();
      }

      return prevState;
    });
  }, [JSON.stringify(basicInfo)]);
  const a = "No";

  const loadMoreData = () => {
    // Assuming linkedData is an array of items
    const newData = linkedData.slice(0, visibleData.length + 3);
    setVisibleData(newData);
    if (newData.length >= linkedData.length) {
      setHasMore(false); // No more data to load
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore) {
      loadMoreData();
    }
  };


  return (
    <div className={styles.pages}>
      {<UploadPopup showPopup={showUploadPopup} setShowPopup={setShowUploadPopup} basicInfo={basicInfo} setBasicInfo={setBasicInfo} />}

      {/* {<Popup showPopup={showPopup} setShowPopup={setShowPopup} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />} */}
      {/* <Modal
        scrollableContentClassName={styles.addcandidate_modal_scrollable_content}
        // containerClassName={`${isModalShrunk ? styles.addcandidate_modal_container_shrunk : styles.addcandidate_modal_container}`}
        isOpen={true}
      > */}
      <div className={styles.addcandidate_modal_header_container}>
        <div className={styles.header_tag_expand_close_icon_container}>
          <div className={styles.header_tag_container}>Client</div>

          <div className={styles.header_expand_close_icon_container}>
            <div className={styles.header_expand_icon_container}>
              {/* onClick={modalSizeHandler} */}
              {/* {isModalShrunk ? <Icon iconName="FullScreen" className={contractIconClass} /> : <Icon iconName="BackToWindow" className={contractIconClass} />} */}
            </div>
            {/* <div onClick={() => close()} className={styles.header_close_icon_container}>
                <Icon iconName="ChromeClose" className={closeIconClass} />
              </div> */}
          </div>
        </div>

        <div className={styles.header_content_container}>
          <div className={styles.header_content_title_container}>
            <div className={styles.header_content_title_container}>Client ID : {basicInfo.clientId}</div>
            <br />
            <div className={styles.dropdownRow}>
              <div className={styles.customer_name}>
                <Label className={styles.name_label_style} required>
                  Company Name
                </Label>
                <TextField
                  placeholder="Enter the Company Name"
                  onChange={(e) => {
                    inputChangeHandler(e, "company_name");
                    setCurrentHover("");
                  }}
                  styles={(props) => textFieldColored1(props, currentHover, validationErrors.company_name, basicInfo.company_name)}
                  value={basicInfo.company_name}
                />
              </div>
              <div>
                <PrimaryButton text="Save & Close" onClick={submitHandler} iconProps={{ iconName: "Save" }} styles={buttonStyles} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.addemployee_modal_main_container}>
        <div className={styles.main_filter_options_container}>
          <div className={styles.subcontainer}>
            <div className={styles.main_dropdown_container}>
              <div className={styles.main_repotingmanager_title}>
                <Label required className={styles.name_label_style}>
                  Point of Contact Name
                </Label>
              </div>
              <div className={styles.textfield}>
                <TextField
                  type="text"
                  name="point_of_contact_name"
                  onChange={(e) => {
                    inputChangeHandler(e, "point_of_contact_name");
                    setCurrentHover("");
                  }}
                  styles={(props) => textFieldColored(props, currentHover, validationErrors.point_of_contact_name, basicInfo.point_of_contact_name)}
                  value={basicInfo.point_of_contact_name}
                  placeholder={"Enter the Name"}
                  // styles={textFieldColored}
                />
              </div>
            </div>
          </div>
          <div className={styles.subcontainer}>
            <div className={styles.main_dropdown_container}>
              <div className={styles.main_repotingmanager_title}>
                <Label required className={styles.name_label_style}>
                  Designation
                </Label>
              </div>
              <div className={styles.textfield}>
                <TextField
                  type="text"
                  name="destination"
                  onChange={(e) => {
                    inputChangeHandler(e, "destination");
                    setCurrentHover("");
                  }}
                  styles={(props) => textFieldColored(props, currentHover, validationErrors.destination, basicInfo.destination)}
                  value={basicInfo.destination}
                  placeholder={`Enter the Designation`}
                />
              </div>
            </div>
          </div>
          <div className={styles.subcontainer}>
            <div className={styles.main_dropdown_container}>
              <div className={styles.main_repotingmanager_title}>
                <Label className={styles.name_label_style}>Reports to</Label>
              </div>
              <div className={styles.textfield}>
                <TextField
                  type="text"
                  name="reports_to"
                  onChange={(e) => {
                    inputChangeHandler(e, "reports_to");
                    setCurrentHover("");
                  }}
                  styles={textFieldColored}
                  value={basicInfo.reports_to}
                  placeholder={"Enter the Name"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.main_box_container}>
          <div className={styles.firstBox}>
            <div className={styles.box}>
              <p className={styles.P_customerDetains}>Customer Details</p>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style} required>
                    Primary Mail ID
                  </Label>
                  <TextField
                    placeholder="Enter the Email ID"
                    name="primary_email"
                    onChange={(e) => {
                      inputChangeHandler(e, "primary_email");
                      setCurrentHover("");
                    }}
                    styles={(props) => textFieldColored2(props, currentHover, validationErrors.primary_email, basicInfo.primary_email)}
                    // className={styles.input}
                    value={basicInfo.primary_email}
                  />
                </div>
                <div className={styles.textField_customerDetails_1}>
                  <Label className={styles.customer_label_style}>Secondary Email ID</Label>
                  <TextField
                    placeholder="Enter the Email ID"
                    name="secondary_email"
                    onChange={(e) => {
                      inputChangeHandler(e, "secondary_email");
                      setCurrentHover("");
                    }}
                    styles={textFieldColored2}
                    value={basicInfo.secondary_email}
                  />
                </div>
              </div>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style} required>
                    Primary Mobile Number
                  </Label>
                  <TextField
                    placeholder="Enter the Mobile"
                    name="primary_mobile"
                    onChange={(e) => {
                      inputChangeHandler(e, "primary_mobile");
                      setCurrentHover("");
                    }}
                    styles={(props) => textFieldColored2(props, currentHover, validationErrors.primary_mobile, basicInfo.primary_mobile)}
                    value={basicInfo.primary_mobile}
                  />
                </div>
                <div className={styles.textField_customerDetails_1}>
                  <Label className={styles.customer_label_style}>Secondary Mobile Number</Label>
                  <TextField
                    placeholder="Enter the Mobile"
                    name="secondary_mobile"
                    onChange={(e) => {
                      inputChangeHandler(e, "secondary_mobile");
                      setCurrentHover("");
                    }}
                    styles={textFieldColored2}
                    value={basicInfo.secondary_mobile}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className={styles.boxClient}>
              <p className={styles.P_AC} style={{ width: 100 }}>
                Client Details
              </p>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.C_D_container}>
                  <div className={styles.EM_container}>
                    <div className={styles.EMi}>
                      <Label className={styles.customer_label_styles}>Empanelment</Label>
                    </div>
                    <div className={styles.radioButtons}>
                      <ChoiceGroup options={empanelmentOptions} selectedKey={empanelmentOption} onChange={handleEmpanelmentChange} className={styles.radioGroup} />
                      <ChoiceGroup selectedKey={empanelmentOption} onChange={handleEmpanelmentChange} options={empanelmentOptions1} className={styles.radioGroup} />
                    </div>
                  </div>
                  <div className={styles.EM_container}>
                    <div className={styles.EMi}>
                      <Label className={styles.customer_label_style1}>Expansion</Label>
                    </div>
                    <div className={styles.radioButtonsone}>
                      <ChoiceGroup options={expansionOptions} selectedKey={expansionOption} onChange={handleExpansionChange} className={styles.radioGroup} />
                      <ChoiceGroup selectedKey={expansionOption} onChange={handleExpansionChange} options={expansionOptions1} className={styles.radioGroup} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.boxPassthrougt}>
              <p className={styles.P_AC} style={{ width: 140 }}>
                Passthrough Details
              </p>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.textField_customerDetails1}>
                  <Label className={styles.customer_label_style}>Passthrough</Label>
                  <Dropdown
                    options={passthroughOptions}
                    placeholder="Select"
                    notifyOnReselect
                    onChange={handlePassthroughChange}
                    styles={customizedDropdown}
                    selectedKey={basicInfo.passthrough === "Direct" ? "direct" : basicInfo.passthrough}
                  />
                </div>

                {selectedPassthrough === "passthrough_company" && (
                  <div className={styles.textField_customerDetails_11}>
                    <Label className={styles.customer_label_style} required>
                      Passthrough Company
                    </Label>
                    <TextField
                      placeholder="Enter the Name"
                      onChange={(e) => {
                        inputChangeHandler(e, "passthrough_company");
                        setCurrentHover("");
                      }}
                      value={basicInfo.passthrough_company}
                      styles={(props) => textFieldColored2(props, currentHover, validationErrors.passthrough_company, basicInfo.passthrough_company)}
                      style={{ "::placeholder": { color: "yellow" } }}
                    />
                  </div>
                )}

                    {selectedPassthrough === "passthrough_person" && (
                      <div className={styles.textField_customerDetails_11}>
                        <Label className={styles.customer_label_style} required>
                          Passthrough Person
                        </Label>
                        <TextField
                          placeholder="Enter the Name"
                          onChange={(e) => {
                            inputChangeHandler(e, "passthrough_person");
                            setCurrentHover("");
                          }}
                            value={basicInfo.passthrough_person}
                        
                            styles={(props) => textFieldColored2(props, currentHover, validationErrors.passthrough_person, basicInfo.passthrough_person)}
                            style={{ "::placeholder": { color: "yellow" }}}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            
            <div className={styles.firstBox}>
              <div className={styles.boxCompany}>
                <p className={styles.P_timeline}>Company Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      Company Address
                    </Label>
                    <TextField placeholder="Enter the Address" 
					name='company_address'
					 onChange={(e) => {
                            inputChangeHandler(e, "company_address");
                            setCurrentHover("");
                          }}
                    notifyOnReselect 
                  styles={(props) => textFieldColored3(props, currentHover, validationErrors.company_address, basicInfo.company_address)}
                      value={basicInfo.company_address} />
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      City
                    </Label>
                    <TextField placeholder="Enter the City" 
					 onChange={(e) => {
                            inputChangeHandler(e, "city");
                            setCurrentHover("");
                          }}
						  name='city'
                    notifyOnReselect 
                  styles={(props) => textFieldColored2(props, currentHover, validationErrors.city, basicInfo.city)}
                      value={basicInfo.city}/>
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style} required>
                      Pincode
                    </Label>
                    <TextField placeholder="Enter the Pincode" 
					name='pincode'
					 onChange={(e) => {
                            inputChangeHandler(e, "pincode");
                            setCurrentHover("");
                          }}
                    notifyOnReselect 
                  styles={(props) => textFieldColored2(props, currentHover, validationErrors.pincode, basicInfo.pincode)}
                      value={basicInfo.pincode} />
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Website</Label>
                    <TextField placeholder="Enter the Link" 
					name='website'
					 onChange={(e) => {
                            inputChangeHandler(e, "website");
                            setCurrentHover("");
                          }}
                    notifyOnReselect styles={textFieldColored2}
                      value={basicInfo.website} />
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Linkedin</Label>
                    <TextField placeholder="Enter the Link" 
					name='linkedin'
					 onChange={(e) => {
                            inputChangeHandler(e, "linkedin");
                            setCurrentHover("");
                          }}
                    notifyOnReselect styles={textFieldColored2}
                      value={basicInfo.linkedin} />
                  </div>
                </div>
              </div>
              <div className={styles.boxSubmission}>
                <p className={styles.P_AC} style={{width: 190}}>Submission Client Template</p>
                 <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <input
                      type="file"
                      id="template-upload"
                      accept=".xlsx, .xls, .xlsb, .xlsm"
                      style={{ display: "none" }}
                      onChange={(e) => uploadExcel(e)}
                    />
<div className={styles.attachbutton}>
                    <PrimaryButton
                      className={styles.attachbutton}
                      text={buttonText}
                      styles={buttonStyles1}
                      onClick={() =>
                        document.getElementById("template-upload").click()
                      }
                    />
                    {fileTitle === "uploading" && (
                      <Spinner className={styles.Spinner1} size={SpinnerSize.medium} />
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.firstBox}>
            <div className={styles.boxLinked} >
              <p className={styles.P_timeline}>Linked Opportunities</p>
              <div className={styles.contentWrapper} onScroll={handleScroll}>
                {linkedData.length!=0 ?
                  linkedData.map(data => (
                    <div className={styles.linkedboxContainer}>

                      <p className={styles.content_level}>{data.level}</p>
                      <div className={styles.contentContainer}>
                        <p onClick={() => navigateTo(`/managedeals/viewandeditopportunity?_id=${data._id}`)}>{data.Opportunity_id}</p>
                        <p className={styles.content}>{data.name}</p>
                      </div>
                      <div className={styles.contentContainer}>
                        <div className={styles.updateContent}>
                          Updated  {ISOdateToCustomDate(data.updatedAt)}</div>
                        <Icon
                          iconName="LocationDot"
                          style={{
                            fontSize: "16px",
                            color: data.status === "Won" ? "#13A10E" :
                              (data.status === "Lost" || data.status === "Drop") ? "#DE1414" : "#BEBDBD"
                          }}
                        /><p>{data.status}</p>

                      </div>
                    </div>

                  )) :
                  <a href="#" className={styles.noLinked}>
                    No Linked Opportunities
                  </a>
                }
              </div>
            </div>
                
              <div className={styles.boxRightBottom}>
                <p className={styles.P_customerDetains}>Attachments</p>
                <div className={styles.boxWithDottedLines}>
                  <img className={styles.uploadImage} src={uploadImage} />
                  <p className={styles.fileTypeText}>PDF, JPG, PNG, DOC below 1 Mb</p>
                  <p className={styles.infoText}>You can also upload files by</p>
                  <div>
                    <PrimaryButton
                      text="Browse Files"
                      className={styles.uploadButton}
                      styles={buttonStyles}
                        onClick={() => fileInputRef.current.click()}
                    />
                    <input
                      multiple
                      hidden
                        ref={fileInputRef}
                      style={{ display: "none" }}
                      type="file"
                        onChange={(e) => uploadDocs(e.target.files)}
                    />
                  </div>
                  
                  <div></div>
                </div>
                  <div className={styles.bottomContainer}>
                  {basicInfo.documents.map((doc, index) => (
                    <div className={styles.spacer} key={index}>
                      <a href={doc.document}>{doc.document_name}</a>
                      <Icon
                        iconName="ChromeClose"
                        className={tableCloseIconClass}
                        onClick={() => {
                          removeUploadedDoc(doc.document_name);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default AddCandidateModal;
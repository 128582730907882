import React from "react";
import styles from "./demandSubmissionListing.module.css"
import {SearchBox, initializeIcons, FontIcon, mergeStyles } from '@fluentui/react';
import { useState } from "react";
import { Callout, DirectionalHint } from '@fluentui/react';
import { useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosPrivateCall } from "../constants";
import Nomatchimg from "../assets/no.png"
import NoDataFound from "../assets/Nodatafound.png";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { Shimmer } from '@fluentui/react';
import { ISOdateToCustomDate, calcTotalExp } from "../utils/helpers";


const searchIcon = { iconName: 'Search' };

const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: '0 10px',
  color: '#999DA0',
  cursor: 'pointer',
  userSelect: 'none',
});

const iconClass1 = mergeStyles({
  fontSize: 12,
  height: 12,
  width: 12,
  margin: '0 ',
  color: '#999DA0',
  cursor: 'pointer'
});

const CalloutNameStyles = {
  calloutMain: {
    background: '#EDF2F6', 
    padding: '2',
  },
}



let items = Array(4).fill(null);

function DemandSubmissionListing(props) {
 let demandSubmissionData=props.submission
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hoverCallout, setHoverCallout] = useState('');
  const [fetchOptions, setFetchOptions] = useState({ skip: 0, limit: 5, sort_field: 'updatedAt', sort_type: -1 });
  const [hasMore, setHasMore] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [targetElement, setTargetElement] = useState(null);
  const [searchResult,setSearchResult]=useState([])

  const showCallout = (target) => {
    setTargetElement(target);
  };
  
  const navigateTo = useNavigate();
  const demand_id = searchParams.get('demand_id')
  const [sortIcon, setSortIcon] = useState(0);
  const [isUserSearching, setIsUserSearching] = useState(false)
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  initializeIcons();

  const columns = [
    {
      columnKey: " ",
      label: " ",
    },
    {
      columnKey: 'SubmissionID',
      label: 'Submission ID',
    }, {
      columnKey: 'SubmissionDate',
      label: 'Submission Date',
      icon: `${sortIcon ? fetchOptions.sort_type === 1 ? 'SortUp' : 'SortDown' : 'Sort'}`
    }, {
      columnKey: 'CandidateID',
      label: 'Candidate ID'
    }, {
      columnKey: 'CandidateName',
      label: 'Candidate Name'
    }, {
      columnKey: 'Mobile',
      label: 'Mobile'
    }, {
      columnKey: 'email',
      label: 'Email ID'
    }, {
      columnKey: 'TotalExperience',
      label: 'Total Experience',
    }, {
      columnKey: 'Primary Skill',
      label: 'Primary Skill '
    },
    {
      columnKey: 'SecondarySkill',
      label: 'Secondary Skill '
    },
    {
      columnKey: 'Resume',
      label: 'Resume',
    },
    {
      columnKey: 'More Options',
      label: ' '
    },
  ];
  const addEllipsisToName = (name) => {
    if (name.length > 14) {
      let new_name = name.substring(0, 12).padEnd(15, '.')
      return new_name
    }
    else return name;
  };



  const clearSearchBox = () => {
    setIsUserSearching(false)
    setFetchOptions(prevData => {
      return {
        ...prevData,
        search_field: ''
      }
    })
    setHasMore(true)
  }


  const download = () => {
    let downloadUrl=demandSubmissionData? `/api/v1/demand/downloadDemandHistroy?demandId=${demand_id}&key=submissions`:`/api/v1/submission/downloadSubmissions?&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`
    setLoading(true);
    setTimeout(() => {
      axiosPrivateCall
        .get(downloadUrl, {
          responseType: 'blob',
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 4000);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    }, 1000);
  };

  
  const handleNavigation = (demandId) => {
    navigateTo(`/demand/viewandeditdemand?demand_id=${demandId}`);
  };


  const searchInAllFields = (searchTerm) => {
  
    if (!searchTerm || !searchTerm.target) {
      setSearchTerm('');
      return;
    }
  
    const searchValue = searchTerm.target.value.toLowerCase();
    setSearchTerm(searchValue);
    searchInObject(searchValue);
  };
  
  const searchInObject = (searchValue) => {
    const restrictedKeys = [
      "_id", "status", "approval", "is_deleted", "message", "undone",
      "approved_date", "createdAt", "updatedAt", "__v"
    ];
    const filteredArrayData = [];
  
    demandSubmissionData?.forEach((data, index) => {
      const filterKeys = Object.entries(data);
  
      if (typeof filterKeys[1][1] === 'string' && filterKeys[1][1]?.toLowerCase().includes(searchValue)) {
        filteredArrayData.push(demandSubmissionData[index]);
      }
  
      filterKeys.forEach(filteredData => {
        if (restrictedKeys.includes(filteredData[0])) {
          return;
        }
  
        if (typeof(filteredData[1]) === 'object') {
          if (filteredData[0] === 'demand') {
            if (filteredData[1]?.DemandId.toLowerCase().includes(searchValue)) {
              filteredArrayData.push(demandSubmissionData[index]);
            }
          } else if (filteredData[0] === 'submitted_by') {
            const recruiterName = `${filteredData[1].first_name}${filteredData[1].last_name}`;
            const trimmedData = searchValue.split(" ").join("");
            if (recruiterName.toLowerCase().includes(trimmedData)) {
              filteredArrayData.push(demandSubmissionData[index]);
            }
          } else if (filteredData[0] === 'candidate') {
            const restrictedCandidateKeys = ["_id", "command", "resume_url"];
            const filterCandidateData = Object.entries(filteredData[1]);
  
            if (typeof filterCandidateData[1][1] === 'string' && filterCandidateData[1][1].toLowerCase().includes(searchValue)) {
              filteredArrayData.push(demandSubmissionData[index]);
            }
  
            filterCandidateData.forEach(filteredLevelThreeData => {
              if (restrictedCandidateKeys.includes(filteredLevelThreeData[0])) {
                return;
              }
  
              if (typeof(filteredLevelThreeData[1]) === 'object') {
                if (filteredLevelThreeData[0] === 'employment_details') {
                  const calculateExperience = calcTotalExp(filteredLevelThreeData[1]);
                  const years_months = `${calculateExperience.years}Years${calculateExperience.months}months`;
                  const year_month = `${calculateExperience.years}Year${calculateExperience.months}month`;
                  const trimmedData = searchValue.split(" ").join("");
                  if (year_month.toLowerCase().includes(trimmedData) || years_months.toLowerCase().includes(trimmedData)) {
                    filteredArrayData.push(demandSubmissionData[index]);
                  }
                } else if (filteredLevelThreeData[0] === 'skillset') {
                  filteredLevelThreeData[1].forEach(SkillData => {
                    const skillList = SkillData.skill;
                    if (skillList.toLowerCase().includes(searchValue)) {
                      filteredArrayData.push(demandSubmissionData[index]);
                    }
                  });
                }
              } else {
                if (filteredLevelThreeData[0] === 'first_name' || filteredLevelThreeData[0] === 'last_name') {
                  const candidateName = `${filteredData[1].first_name}${filteredData[1].last_name}`;
                  const trimmedData = searchValue.split(" ").join("");
                  if (candidateName.toLowerCase().includes(trimmedData)) {
                    filteredArrayData.push(demandSubmissionData[index]);
                  }
                } else if (typeof filteredLevelThreeData[1] === 'string' && filteredLevelThreeData[1].toLowerCase().includes(searchValue)) {
                  filteredArrayData.push(demandSubmissionData[index]);
                }
              }
            });
          }
        } else if (typeof(filteredData[1]) === 'string' && filteredData[1].toLowerCase().includes(searchValue)) {
          filteredArrayData.push(demandSubmissionData[index]);
        }
      });
    });
  
    setSearchResult([...new Set(filteredArrayData)]);
  };
  
const clickSortHandler = (key) => {
  if (key === 'SubmissionDate') {
    setSortIcon(fetchOptions.sort_type);
    setFetchOptions(
      {
        ...fetchOptions,
        sort_type: fetchOptions.sort_type === -1 ? 1 : -1,
      }
    );
  };
  if(fetchOptions.sort_type ===1){
    const sortedByCreatedAtAsc =demandSubmissionData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
   demandSubmissionData=sortedByCreatedAtAsc
  }else {
    const sortedByCreatedAtDesc = demandSubmissionData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    demandSubmissionData=sortedByCreatedAtDesc
  }
};

  const fetchMoreData = () => {
    setFetchOptions((prevOptions) => ({
      ...prevOptions,
      limit: prevOptions.limit + 5, 
    }));
  };

  return (
    <div className={styles.table_header_container}>
      <div className={styles.container}>
        <div className={styles.nav_container}>
          <div className={styles.table_header_name}>Submission Listing</div>
          <div className={styles.search_bar_container}>
            <SearchBox 
            onChange={searchInAllFields} 
            value={searchTerm}
             onSearch={searchInAllFields} 
             onClear={clearSearchBox} placeholder=" " iconProps={searchIcon} className={styles.search}
              showIcon />
            {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) :
              completed ? (<FontIcon iconName="CheckMark" className={iconClass} />) :
                (<FontIcon iconName="Download" onClick={download} className={iconClass} />)}
          </div>
        </div>
        <div id="scrollableDiv" className={styles.demand_table_container}>
          <InfiniteScroll style={{ overflow: 'visible', height: '100%' }} dataLength={demandSubmissionData.length} loader={isDataLoaded && demandSubmissionData.length >= 5 && <h4>Loading...</h4>}
            hasMore={hasMore} next={fetchMoreData}  scrollableTarget="scrollableDiv">
            <table>
              <thead className={styles.table_header}>
                <tr className={styles.table_row}>
                  {columns?.map((column) =>
                    <th 
                      className={styles.table_headerContents}
                      key={column.columnKey} onClick={() => clickSortHandler(column.columnKey)}>
                      <div className={styles.table_heading}>
                        <div>{column.label}</div>
                        {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                      </div>
                    </th>)
                  }
                </tr>
              </thead>
              <tbody>
              {demandSubmissionData?.length === 0  ? (
                  <tr>
                    <td className={styles.table_dataContents1} colSpan="13">
                      <img src={NoDataFound} alt="Noimage" />
                    </td>
                  </tr>
                ) : (
                  <>
                    { (searchTerm ? searchResult.length===0:demandSubmissionData.length) === 0 ? (
                      <tr>
                        <td className={styles.table_dataContents1} colSpan="13" >
                          <img src={Nomatchimg} alt="NoDataimage"/>
                        </td>
                      </tr>
                    ) : (
                      <>
                      { (searchTerm ? searchResult : demandSubmissionData)?.map((data, candidate_index) => (
                          <tr className={styles.table_row} >
                            <td className={styles.table_dataContents}>
                                  <div >{" "}</div>
                                </td>  
                            <td onClick={() => navigateTo(`/submission/viewsubmission?submission_id=${data?._id}`)} className={styles.table_dataContents}><span className={styles.custom_link}>{data.SubmissionId}</span></td>
                            <td className={styles.table_dataContents}>{ISOdateToCustomDate(data.createdAt)}</td>
                            <td onClick={() => navigateTo(`/candidatelibrary/viewcandidate?candidate_id=${data.candidate?._id}`)} className={styles.table_dataContents}><span className={styles.custom_link}>{data.candidate?.CandidateId}</span></td>
                            <td className={styles.table_dataContents}
                              onMouseEnter={(e) => {
                                showCallout(e.currentTarget)
                                setHoverCallout(data.candidate?.first_name)}}
                              onMouseLeave={() => setHoverCallout('')}>
                              {addEllipsisToName(`${data.candidate?.first_name} ${data.candidate?.last_name}`)}
                              {(data.candidate?.first_name + data.candidate?.last_name).length >= 14 && hoverCallout === data.candidate?.first_name && <Callout alignTargetEdge={true}
                                isBeakVisible={false} styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge} target={targetElement}>
                                {`${data.candidate?.first_name} ${data.candidate?.last_name}`}
                              </Callout>
                              }
                            </td>
                            <td className={styles.table_dataContents}>{data.candidate?.mobile_number}</td>
                            <td className={styles.table_dataContents}>{data.candidate?.email}</td>
                            <td className={styles.table_dataContents}>{calcTotalExp(data.candidate?.employment_details).years} Years {calcTotalExp(data.candidate?.employment_details).months} Months</td>
                            <td className={styles.table_dataContents}
                              onMouseEnter={(e) => {
                                showCallout(e.currentTarget)
                                setHoverCallout(data._id)}}
                              onMouseLeave={() => setHoverCallout("")}>
                              {addEllipsisToName(`${data.candidate?.skillset[0]?.skill ? (data.candidate?.skillset[0]?.skill) : '-'}`)}
                              {data.candidate?.skillset[0]?.skill && data.candidate?.skillset[0]?.skill.length >= 14 && hoverCallout === data._id && (
                                <Callout alignTargetEdge={true} isBeakVisible={false} styles={CalloutNameStyles} directionalHint={DirectionalHint.bottomLeftEdge}
                                  target={targetElement}
                                >
                                  {data.candidate?.skillset[0]?.skill ? (data.candidate?.skillset[0]?.skill) : '-'}
                                </Callout>
                              )}
                            </td>
                            <td className={styles.table_dataContents}
                              onMouseEnter={(e) => {
                                showCallout(e.currentTarget)
                                setHoverCallout(data.SubmissionId)}}
                              onMouseLeave={() => setHoverCallout("")}>
                           {addEllipsisToName(`${data.candidate?.skillset[1]?.skill ? (data.candidate?.skillset[1]?.skill) : '-'}`)}
                              {(data.candidate?.skillset[1]?.skill ? (data.candidate?.skillset[1]?.skill) : '-').length >= 14 && hoverCallout === data.SubmissionId && <Callout alignTargetEdge={true}
                                isBeakVisible={false} styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge} target={targetElement}>
                                {data.candidate?.skillset[1]?.skill ? (data.candidate?.skillset[1]?.skill) : '-'}
                              </Callout>
                              }
                            </td>
                           <td className={styles.table_dataContents}><a href={data.candidate?.resume_url} target="_blank">Link</a></td>
                          </tr>))}
                          
                    {!demandSubmissionData && items?.map(candidate => (
                      <tr className={styles.table_row} >
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}>
                          <div className={styles.moreOptions} >
                            <FontIcon iconName='MoreVertical' className={iconClass1} />
                          </div>
                        </td>
                      </tr>))
                    }
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );

};

export default DemandSubmissionListing;






import React, { useEffect, useState} from 'react';
import { Stack, Popup, Icon} from '@fluentui/react';
import styles from "../components/TargetNotification.module.css";
import MyPopup from "../components/TargetMiniNotification";
import { axiosPrivateCall } from "../constants";


const Notification = ({ title, content, status, openPopup ,data, handleCount}) => {
  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  const getStatusColor = () => {
    switch (status) {
      case 'Accepted':
        return '#08c708';
      case 'Rejected':
        return '#ffdfdd';
      case 'Reviewed':
        return '#d9d9d9';
      case 'Requested':
        return '#ffb84c';
     default:
        return '#d9d9d9';
    }
  };

  const bulletStyle = {
    width: '8px',
    height: '8px',
    backgroundColor: getStatusColor(),
    borderRadius: '50%',
    marginRight: '8px',
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [hasExpandedOnce, setHasExpandedOnce] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!hasExpandedOnce) {
      setHasExpandedOnce(true);
    }

 let user={
   isRead:
   {
  id: decodedValue.user_id,
  read: true
}
 }
   axiosPrivateCall.put(`api/v1/notification/readNotification/${data.updatedId}`,user).then(res=>{
    handleCount('count')
    }).then(err=> console.log('updated'))

    if (status === 'Requested') {
      openPopup(title,data);
    }
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={bulletStyle}></div>
        <div>
          <strong style={{ color: hasExpandedOnce || isExpanded || (data?.isRead?.some(item => item.user ===decodedValue.user_id && item.read === true))? 'gray' : 'black' }}>{title}</strong>
        </div>
        <Icon
          iconName={isExpanded ? 'ChevronUp' : 'ChevronDown'}
          onClick={toggleExpand}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
        />
      </div>
      {isExpanded ? <p style={{ color: 'gray' }}>{content}</p> : null}
    </div>
  );  
};

const NotificationsBox = ({ isOpen, onDismiss,targetId ,data,sendToParent,handleCount}) => {
  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [updatedData,setUpdatedData]=useState([])
  const [notifications, setNotifications] = useState([]);

  useEffect(()=>{
    axiosPrivateCall.get(`api/v1/notification/getNotification/${targetId}`).then(
      (res)=>{
        if(res.data.length>0&&res.data!==undefined){
          const notifications =res.data
          .filter((target) => {
            return target.message !== undefined && target.message !== null && target.message.trim() !== '';
          })
          .map((target, index) => {
            const message = target.message;
            let status, title, content;
            target.data.map(i=>
           {
            const targetName=target&&target.updated_by?`${target.updated_by.first_name} `:''
           let userName=targetName;
            switch (message) {
              case 'request':
                status = 'Requested';
                title = `${userName} Requested to change target`;
                content=' ';
                break;
              case 'accept':
                status = 'Accepted';
                title = `${userName} Accepted the target`;
                content=`${userName} Accepted the target and start working towards it `;
                break;
              case 'review':
                status = 'Reviewed';
                title = `${userName} Reviewed the target`;
                content=`${userName} Reviewed your target,Kindly check and start working towards it`;
                break;
              case 'reject':
                status = 'Rejected';
                title = `${userName} Rejected the target`;
                content=`There is no any possibilities to change the target So Kindly start working on it.`;
             
                break;
              default:
                status = 'Unknown';
                title = 'Unknown Notification';
            }
          })
                 return {
              assigned: target.assigned, 
              title,
              content,
              status,
              created_by:target.created_by,
              updated_by:target.updated_by,
              isRead:target.isRead,
              data:target.data,
              updatedId:target._id
        
            };
          });
             const reversedFilteredNotifications = notifications.slice()
              return  setNotifications(reversedFilteredNotifications );
          }
          
        }
    ).catch(err=>{
      console.log(err)
    })   
  },[])

  const openPopup = (title,data) => {
    setPopupTitle(title.split(' ')[0]);
    setUpdatedData(data)
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  }
  const  handleReview=(data,id)=>{
  if(data==='review'){
    sendToParent('review',id)
    onDismiss()
  }

  if(data==='accept'){
    sendToParent('accept',id)
    onDismiss()
  }
  }

  return (
    <Popup
      isOpen={isOpen}
      onDismiss={onDismiss}
      contentContainerProps={{ style: { padding: '20px' } }}
      offsetX={10}
      offsetY={10}
      position={{ target: 'auto', element: 'auto' }}
      className={decodedValue.user_role==="bde"?styles.notificationboxbdecontainer:styles.notificationboxcontainer}
    >
      <div  className={styles.popup1} >
        <h3>Notifications</h3>
        <div className={styles.btnCon}>
        <Icon iconName='ChromeClose' onClick={onDismiss} className={styles.closeBtn}/>
        </div>
      </div>
      <Stack tokens={{ childrenGap: 15 }} className={styles.stacks}>
        {notifications.length === 0 ? ( 
          <div>No Notifications</div>
        ) : (
          notifications.map((notification, index) => (
            <div key={index}>
              <Notification
                key={index}
                title={notification.title}
                content={notification.content}
                handleCount={handleCount}
                status={notification.status}
                data={notification}
                openPopup={openPopup}
              />
              <hr></hr>
            </div>
          ))
        )}
      </Stack>
      <MyPopup Name={popupTitle} data={updatedData} isOpen={isPopupOpen}  handleCount={handleCount} sendToParent={handleReview} onDismiss={closePopup} />
    </Popup>
  );
};

export default NotificationsBox;
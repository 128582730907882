import React from 'react';
import Layout from '../Layout';

function UC1() {

    return(
            <div style={{
                width: '100%',
                height: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
        
            </div>
    );

};

export default UC1;
import React, { useState, useRef, useEffect } from "react";
import Fuse from "fuse.js";
import { TextField } from "@fluentui/react";
import styles from "./ComboBox.module.css";

function ComboBox(props) {
  let dropdown = props.dropdown?.map((value) => {
    return { item: value };
  });
  const [isDropped, setIsDropped] = useState(false);
  const [options, setOptions] = useState([...dropdown]);
  const refDrop = useRef();
  const [value, setValue] = useState(props.value || "");
  const [currentHover, setCurrentHover] = useState("");

  const fuse = new Fuse(props.dropdown, {
    keys: ["text"],
  });

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, []);

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  function handleOutsideClick(e) {
    if (!refDrop.current?.contains(e.target)) {
      setIsDropped(false);
    }
  }

  function dropdownContents(event) {
    let data = event.target.value;
    let result = fuse.search(data);
    if (data) {
      setOptions(result.map(({ item }) => item));
    } else {
      setOptions([...dropdown]);
    }
  }

  function handleTextChange(event) {
    let data = event.target.value;
    setValue(data);
    dropdownContents(event);
  }

  function select(text, name, key) {
    setValue(text);
    props.setInfo((prevState) => ({
      ...prevState,
      [name]: key
    }));
    props.setInfoErrors((prevState) => ({
      ...prevState,
      [name]: null
    }));
    setIsDropped(false);
  }

  const keyValue = (text, key) => {
    select(text, props.name, key);
  };

  const handleFocus = () => {
    setIsDropped(true);
  };

  const handleClick = () => {
    setIsDropped(!isDropped);
  };

  return (
    <div style={{ minWidth: props.width, height: "20px" }} className={styles.container}>
      <div className={styles.combo_container}>
        <div className={styles.input_container} onClick={handleClick}>
          <TextField
            type="text"
            name={props.name}
            placeholder={props.placeholder}
            value={value}
            errorMessage={props.showError ? props.errorMessage : null}
            styles={props.comboStyles}
            onChange={(event) => {
              handleTextChange(event);
              if (props.skillArr) {
                props.inputChangeHandler(event, props.name, props.index, props.arr);
              } else {
                if (props.index === undefined) {
                  props.inputChangeHandler(event, props.name, props.setInfo, props.setInfoErrors);
                } else {
                  props.inputChangeHandler(event, props.name, props.index, props.setInfo, props.setInfoErrors);
                }
              }
            }}
            onFocus={handleFocus}
          />
        </div>
        {isDropped && (
          <div className={styles.dropdown_container} ref={refDrop}>
            {options.map((value) => (
              <div key={value.key} onClick={() => keyValue(value.text, value.key)} className={styles.dropdown_option}>
                {value.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ComboBox;

import React, { useState, useEffect, useRef } from "react";
import { Label } from "@fluentui/react";
import styles from "./OpportunityModel.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { TextField, PrimaryButton, DatePicker } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import Editicon from "../../src/assets/editnote.png";
import { useDispatch } from "react-redux";
import { setIsEdit } from "../redux_library/slice/isDemandEdit.slice";
import { Popup } from "../components/Popup";
import Popup1 from "../components/PopupCRM";
import { isEmpty, isNumOnly } from "../utils/validation";
import { axiosPrivateCall } from "../constants";
import { UploadPopup } from "../components/UploadModal";
import uploadImage from "../assets/upload_cloud_image.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MessageBar, MessageBarType } from "@fluentui/react";
import TrackOpportunity from "./TrackOpportunity";

const buttonStyles = {
  root: {
    marginTop: "3px",
    height: "30px",
    borderRadius: "2px",
    marginRight: "8px",
  },
};

const messageBarStyles = {
  content: {
    maxWidth: 620,
    minWidth: 450,
  },
};

const getCommentTextField = (width, padding, maxWidth, error, value) => {
  const borderColor = error ? "#E80B0B" : "#E1E5E8";
  const titleColor = error ? "#E80B0B" : "#484848";
  const borderRadius = "4px";
  return {
    fieldGroup: {
      width: width,
      marginTop: padding,
      height: "160px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: titleColor,
      fontSize: "12px",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
          paddingLeft: padding || 0,
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};

const getTextFieldStyles = (width, padding, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const titleColor = error ? "#D24545" : "#484848";
  const borderRadius = "4px";
  return {
    fieldGroup: {
      width: width,
      marginTop: padding,  
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: titleColor,
      fontSize: "12px",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
          paddingLeft: padding || 0,
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};

const getDropdownStyles = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#484848";
  }

  return {
    title: {
      height: "29px",
      width: "185px",
      maxWidth: "185px",
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      borderRadius: "4px",
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px",
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: "4px",
        },
      },
    },
  };
};

const calendarClass = (value) => {
  return mergeStyleSets({
    root: {
      "*": {
        width: "185px",
        fontSize: "12px !important",
        height: "29px !important",
        lineHeight: "20px !important",
        borderColor: "#E1E5E8 !important",
        borderRadius: "4px",
        lineHeight: "26px !important",
      },
    },
    field: {
      color: "black",
      fontSize: "11px",
      input: {
        color: "black",
        fontSize: "11px !important",
        "&::placeholder": {
          color: "#D7D7D7 !important",
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "8px !important",
      left: "80%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};

const secondContainerDropdown = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = "#D7D7D7";
  }
  return {
    title: {
      height: "29px",
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    root: {
      width: "190px",
    },

    dropdown: {
      width: "305px",
      borderRadius: "4px",
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px",
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: "4px",
        },
      },
    },
  };
};

const tableCloseIconClass = mergeStyles({
  fontSize: 10,
  height: "12px",
  width: "12px",
  cursor: "pointer",
  color: "red",
  marginTop: "3px",
  marginLeft: "8px",
});

const regionLocationOption = [
  { key: "North America", text: "North America" },
  { key: "Latin America & the Caribbean", text: "Latin America & the Caribbean" },
  { key: "Middle East & North", text: "Middle East & North" },
  { key: "Africa (MENA)", text: "Africa (MENA)" },
  { key: "SubSaharan Africa", text: "SubSaharan Africa" },
  { key: "Asia-Pacific (APAC)", text: "Asia-Pacific (APAC)" },
  { key: "Central Asia, Eurasia", text: "Central Asia, Eurasia" },
  { key: "East Asia", text: "East Asia" },
  { key: "Southeast Asia", text: "Southeast Asia" },
  { key: "India", text: "India" },
];

const LevelOption = [
  { key: "Prospect", text: "Prospect" },
  { key: "Lead", text: "Lead" },
  { key: "Presales", text: "Presales" },
  { key: "Sales", text: "Sales" },
  { key: "Closure", text: "Closure" },
  { key: "Negotiate", text: "Negotiate" },
];

const statusOption = [
  { key: "Won", text: "Won" },
  { key: "Lost", text: "Lost" },
  { key: "Hold", text: "Hold" },
  { key: "In Progress", text: "In Progress" },
  { key: "Drop", text: "Drop" },
  { key: "N/A", text: "N/A" },
];

const requesttypeOption = [
  { key: "Client Request", text: "Client Request" },
  { key: "Contract", text: "Contract" },
  { key: "Presentation", text: "Presentation" },
  { key: "RFP", text: "RFP" },
  { key: "POC", text: "POC" },
];

const SSBuisnessUnitOption = [
  { key: "India Staffing", text: "India Staffing" },
  { key: "US Staffing", text: "US Staffing" },
  { key: "UAE Staffing", text: "UAE Staffing" },
  { key: "Training", text: "Training" },
  { key: "Consulting & Products", text: "Consulting & Products" },
  { key: "Data", text: "Data" },
  { key: "Digital", text: "Digital" },
];

const probOfWinning = [
  { key: "10%", text: "10%" },
  { key: "20%", text: "20%" },
  { key: "30%", text: "30%" },
  { key: "40%", text: "40%" },
  { key: "50%", text: "50%" },
  { key: "60%", text: "60%" },
  { key: "70%", text: "70%" },
  { key: "80%", text: "80%" },
  { key: "90%", text: "90%" },
  { key: "100%", text: "100%" },
];

const budgetStatus = [
  { key: "Not Started", text: "Not Started" },
  { key: "In Progress", text: "In Progress" },
  { key: "Reviewed", text: "Reviewed" },
  { key: "Submitted", text: "Submitted" },
  { key: "Approved", text: "Approved" },
];

const priorityLevel = [
  { key: "High", text: "High" },
  { key: "Medium", text: "Medium" },
  { key: "Low", text: "Low" },
];

const opputunitySourceOption = [
  { key: "Referral", text: "Referral" },
  { key: "Direct Inquiry", text: "Direct Inquiry" },
  { key: "Marketing", text: "Marketing" },
];

const ViewandEditOpportunityModel = (props) => {

  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  let user_Id = decodedValue.user_id;

  const navigate = useNavigate();
  const location = useLocation();
  const opportunity_location = useLocation();
  const { isModalOpen, setIsModalOpen, showMessageBar, setShowMessageBar } = props;
  const getOpportunityData = opportunity_location.state.clientData ? opportunity_location.state.clientData : [];
  const [showPopup, setShowPopup] = useState(false);
  const [popupNotes, setPopupNotes] = useState("");
  const [showPopup1, setShowPopup1] = useState(false);
  const [currentHover, setCurrentHover] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedKeyCom, setSelectedKeyCom] = useState(null);
  const [isTrackOpportunityActive, setTrackOpportunityActive]= useState(false);
  const [isCommentVisible, setCommentVisible] = useState(false)
  const [isMaincontainerVisible, setMaincontainerVisible]= useState(true);
  const [pipelineData, setPipelineData] = useState({
    Opportunity_id: "",
    name: "",
    request_type: "",
    request_client: "",
    lead_source: "",
    tech_lead: "",
    level: "",
    status: "",
    notes: "",
    customer_name: "",
    email_id: "",
    mobile_number: "",
    start_date: "",
    close_date: "",
    probability_winning: "",
    priority_level: "",
    opportunity_source: "",
    assign_to: "",
    region_location: "",
    ss_businness_unit: "",
    currency: "",
    opportunity_value: "",
    budget_status: "",
    documents: [],
    comments: [],
    updated_by: "",
  });
  const [error, setError] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isEdit, setIsEditLocal] = useState(location.state?.isEditMode || false); 
  const [searchParams, setSearchParams] = useSearchParams();
  const [DealName, getDealName] = useState("");
  const [currency, setCurrency] = useState([]);
  const [comments, setComments] = useState("");
  const [adminSales, setAdminSales] = useState([]);
  const [messageBarType, setMessageBarType] = useState(null);
  const [messageBarText, setMessageBarText] = useState("");
  const [showcommentMessageBar, setShowcommentMessageBar] = useState(false);
  const [OpportunityComments, getOpportunityComments] = useState([]);
  const [openCommentId, setOpenCommentId] = useState(null);
 
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(getOpportunityData).length) {
      setPipelineData((prevData) => ({
        ...prevData,
        ...getOpportunityData,
        status: getOpportunityData.status,
        updated_by: user_Id,
      }));
      setPopupNotes(getOpportunityData.notes);
      setSelectedKey(getOpportunityData.request_client?._id);
      setSelectedKeyCom(getOpportunityData.assign_to?._id);
    } else {
      fetchOpportunityData();
    }
  }, [getOpportunityData]);

  const fetchOpportunityData = async () => {
    const id = new URLSearchParams(location.search).get("_id");
    const response = await fetch(`/api/opportunity/${id}`);
    const data = await response.json();
    setPipelineData({
      ...data,
      status: data.status,
    });
    setPopupNotes(data.notes);
    setSelectedKey(data.request_client?._id);
    setSelectedKeyCom(data.assign_to?._id);
  };

  useEffect(() => {
    if (!showPopup1) {
      if (popupNotes) {
        setPipelineData((prevState) => ({
          ...prevState,
          notes: popupNotes,
        }));
      } else {
        setPipelineData((prevState) => ({
          ...prevState,
        }));
      }
    }
  }, [showPopup1, popupNotes]);

  const enabaleEditButton = () => {
    setIsEditLocal(true);
  };

  const dateHandler = (date, name) => {
    setPipelineData((prevData) => {
      return {
        ...prevData,
        [name]: date,
      };
    });
    setCurrentHover("");
    setValidationErrors((prevErrors) => {
      return {
        ...prevErrors,
        [name]: "",
      };
    });
  };

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const response = await axiosPrivateCall.get("https://countriesnow.space/api/v0.1/countries/currency");
        const currencyData = response.data.data;
        const currencyOptions = currencyData.map((currency) => ({
          value: currency.currency,
          label: currency.currency,
        }));
        const uniqueCurrencyOptions = currencyOptions.filter((currency, index, array) => {
          return array
            .slice(0, index)
            .every((prevCurrency) => prevCurrency.value !== currency.value && prevCurrency.label !== currency.label);
        });
        const uniqueCurrencyOptionsWithKeyAndText = uniqueCurrencyOptions.map((currency) => ({
          key: currency.value,
          text: currency.label,
        }));
        setCurrency(uniqueCurrencyOptionsWithKeyAndText);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCurrency();
  }, []);

  const getDealNameDetails = async () => {
    return axiosPrivateCall
      .get("api/v1/crm/getDealname")
      .then((response) => {
        getDealName(response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const getAssignToDetails = async () => {
    return axiosPrivateCall
      .get("api/v1/crm/getadminsales")
      .then((response) => {
        setAdminSales(response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const validateFields = () => {
    const errors = {};

    if (!pipelineData.name) {
      errors.name = "required";
    }

    if (!pipelineData.request_type) {
      errors.request_type = "required";
    }

    if (!pipelineData.request_client) {
      errors.request_client = "required";
    }

    if (!pipelineData.level) {
      errors.level = "required";
    }

    if (!pipelineData.status) {
      errors.status = "required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    getDealNameDetails();
    getAssignToDetails();
  }, []);
  
  const submitHandler = async () => {
    const isValid = validateFields();

    if (isValid) {
    try {
        const response = await axiosPrivateCall.put(`/api/v1/crm/updateData/${pipelineData._id}`, pipelineData);
        setPipelineData(response.data);
        navigate("/managedeals/manageopportunity");
    } catch (error) {
        console.error("Error submitting data:", error);
        if (error.response) {
          const errors = {};
          errors.client_id = error.response.data;
          setValidationErrors(errors);
        }
    }
  }
};



const handleUCClick = () => {
  if (isTrackOpportunityActive) {
    setTrackOpportunityActive(false);
    setMaincontainerVisible(true); // Revert to the default page
  } else {
    setMaincontainerVisible(false);
    setTrackOpportunityActive(true);
    setCommentVisible(false);
  }
};

const handleCommentClick = () => {
  if (isCommentVisible) {
    setCommentVisible(false);
    setMaincontainerVisible(true); // Revert to the default page
  } else {
    setMaincontainerVisible(false);
    setTrackOpportunityActive(false);
    setCommentVisible(true);
  }
};
  

  const inputChangeHandler = (e, inputName) => {
    e.preventDefault();
    const { value } = e.target;
    let inputValue = value;
    let isInputValid = true;
    let isNameValid = false;

    const validationRules = {
      client_id: /^[\w\s-]+$/,
      owner: /^[\w\s-]+$/,
      industry: /^[\w\s-]+$/,
      delivery_poc: /^[\w\s-]+$/,
      mobile_number: /^[6-9]\d{9}$/,
    };

    if (inputName === "customer_name" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (inputName === "mobile_number" && (inputValue === "" || !isNaN(inputValue))) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10);
      isNameValid = true;
    }

    if (inputName === "currency" && /^[\w\s-]+$/.test(inputValue)) {
      isNameValid = true;
    }

    if (validationRules[inputName]) {
      isInputValid = validationRules[inputName].test(value);
    }

    if (inputName === "values") {
      if (!isNumOnly(value)) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (isInputValid || isNameValid) {
      setPipelineData({
        ...pipelineData,
        [inputName]: inputValue,
      });

      setValidationErrors((prevErrors) => {
        return {
          ...prevErrors,
          [inputName]: "",
        };
      });
    }
  };

  const dropDownHandlerText = (e, item, name) => {
    if (name === "request_client") {
      setSelectedKey(item.key);
    } else if (name === "assign_to") {
      setSelectedKeyCom(item.key);
    }
    setPipelineData({
      ...pipelineData,
      [name]: item.key,
    });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    if (name === "status") {
      setShowPopup1(true);
    }
  };

  function customSortDropDown(arr) {
    if (!Array.isArray(arr)) {
      return [];
    }

    const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    const special = [];
    const numerical = [];
    const alpha = [];
    const mixedAlphaNumeric = [];
    const validEntries = arr.filter((entry) => typeof entry === "object" && "text" in entry);

    validEntries.forEach((entry) => {
      const text = entry.text.toLowerCase();

      if (/^\d/.test(text)) {
        numerical.push(entry);
      } else if (/^[^\w\s]/.test(text)) {
        special.push(entry);
      } else if (/^[a-zA-ZÀ-ÖØ-öø-ÿ]/.test(text)) {
        alpha.push(entry);
      } else {
        mixedAlphaNumeric.push(entry);
      }
    });

    special.sort((a, b) => specialChars.indexOf(a.text[0]) - specialChars.indexOf(b.text[0]));
    numerical.sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true }));
    alpha.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: "base" }));
    mixedAlphaNumeric.sort((a, b) => a.text.localeCompare(b.text));

    return special.concat(numerical, alpha, mixedAlphaNumeric);
  }

  function uploadDocs(files) {
    let data = Object.values(files);
    const formData = new FormData();
    data.map((file) => {
      formData.append("files", file);
    });
    axiosPrivateCall
      .post("/api/v1/crm/addDocuments", formData)
      .then((res) => {
        let fileuploaded = res.data.documents;
        setPipelineData((prev) => {
          let buffer = { ...prev };
          buffer.documents.push(...fileuploaded);
          return buffer;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeUploadedDoc(key) {
    console.log(key);
    let buffer = [...pipelineData.documents];
    let result = [];
    buffer.map((doc) => {
      if (!(doc.document_name === key)) {
        result.push(doc);
      }
    });

    setPipelineData((prev) => {
      let buffer = { ...prev };
      buffer.documents = [...result];
      return buffer;
    });
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    dispatch(setIsEdit(isEdit));
  }, [isEdit, dispatch]);

  const getComments = () => {
    const id = new URLSearchParams(location.search).get("_id");
    axiosPrivateCall
      .get(`/api/v1/crm/getCommentsByOpportunityId/${id}`)
      .then((res) => {
        getOpportunityComments(res.data);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  };

  useEffect(() => {
    getComments();
  }, []);

  function formatTimeAgo(Createdattime) {
    const timestamp = new Date(Createdattime);
    const now = new Date();
    const timeDiff = now - timestamp;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (seconds < 5) {
      return "just now";
    } else if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else {
      const day = timestamp.getDate();
      const month = timestamp.toLocaleString("en", { month: "short" });
      const year = timestamp.getFullYear();

      return `${day} ${month} ${year}`;
    }
  }

  const handleInputChange = (e) => {
    const validInput = e.target.value.replace(/[^-,.'&()a-zA-Z0-9\s]/g, "");
    const invalidCharacters = e.target.value.replace(/[-.'&()a-zA-Z0-9\s]/g, "");
    if (invalidCharacters.length > 0) {
      setErrorText("Invalid character");
    } else {
      setErrorText("");
    }
    const limitedInput = validInput.slice(0, 1500);
    setComments(limitedInput);
    if (limitedInput.trim()) {
      setError("");
    }
  };

  const handlePost = async () => {
    if (comments.trim() === "") {
      setErrorText("Invalid Character");
      return;
    }

    const candidateData = {
      comments: { content: comments, created_by: user_Id },
    };

    try {
      await axiosPrivateCall.put(`/api/v1/crm/updateCommentData/${getOpportunityData._id}`, candidateData);
      setComments("");
      setError("");
      setErrorText("");
      getComments();
      setMessageBarType("success");
      setMessageBarText("Comment posted successfully!");
    } catch (candidateError) {
      setMessageBarType("error");
      setMessageBarText("Failed to post comment.");
    } finally {
      setShowcommentMessageBar(true);
    }
  };

  useEffect(() => {
    if (showcommentMessageBar) {
      const timer = setTimeout(() => setShowcommentMessageBar(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showcommentMessageBar]);

  const handleToggle = (id) => {
    setOpenCommentId(openCommentId === id ? null : id);
  };

  return (
    <div className={styles.editContainer}>
      <UploadPopup
        showPopup={showUploadPopup}
        setShowPopup={setShowUploadPopup}
        basicInfo={pipelineData || { documents: [] }}
        setBasicInfo={setPipelineData}
      />
      {
        <Popup
          resetState={() => ""}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }

      <div className={styles.addcandidate_modal_header_container}>
        <div className={styles.mini_header_tag_container}>
          <p className={styles.mini_title}>Opportunity</p>
        </div>
        <div className={styles.header_tag_expand_close_icon_container}>
          <div className={styles.header_tag_container}>Opportunity ID : {pipelineData.Opportunity_id}</div>
        </div>

        <div className={styles.dropdownRow}>
          <p className={styles.leftContainer}>
            <div className={styles.customer_name}>
              <Label className={styles.name_label_style} required>
                Name
              </Label>
              <TextField
                value={pipelineData.name}
                placeholder="Enter the Name"
                name="name"
                disabled={!isEdit}
                style={{ backgroundColor: "transparent" }}
                onChange={(e) => {
                  inputChangeHandler(e, "name");
                  setCurrentHover("");
                }}
                styles={(props) =>
                  getTextFieldStyles(
                    "450px", //width
                    "2.5px", //padding
                    "450px", //maxwidth
                    props,
                    currentHover,
                    validationErrors.name,
                    pipelineData.name
                  )
                }
              />
            </div>
          </p>
          <div className={styles.rightContainer}>
            <div className={styles.opportunity_type}>
              <Label className={styles.label_style} required>
                Level
              </Label>

              {isEdit ? (
                <Dropdown
                  options={LevelOption}
                  selectedKey={pipelineData.level}
                  onChange={(e, item) => {
                    dropDownHandlerText(e, item, "level");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  style={{ fontSize: "12px", color: "#5B5F62" }}
                  styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.level)}
                />
              ) : (
                <TextField
                  value={pipelineData.level}
                  placeholder="Enter the Name"
                  disabled
                  style={{ backgroundColor: "transparent" }}
                  styles={(props) =>
                    getTextFieldStyles(
                      "185px", //width
                      null, //padding
                      "185px", //maxwidth
                      props,
                      currentHover,
                      validationErrors.level,
                      pipelineData.level
                    )
                  }
                />
              )}
            </div>
            <div className={styles.opportunity_type}>
              <Label className={styles.label_style} required>
                Status
              </Label>
              {isEdit ? (
                <>
                  <Dropdown
                    options={statusOption}
                    selectedKey={pipelineData.status}
                    onChange={(e, item) => {
                      dropDownHandlerText(e, item, "status");
                      setCurrentHover("");
                    }}
                    placeholder="Select"
                    notifyOnReselect
                    style={{ fontSize: "12px", color: "#5B5F62" }}
                    styles={getDropdownStyles(props, currentHover, error, pipelineData.status)}
                  />
                  {pipelineData.status && showPopup1 && (
                    <Popup1
                      showPopup1={showPopup1}
                      setShowPopup1={setShowPopup1}
                      isModalOpen={true}
                      setIsModalOpen={() => setShowPopup1(false)}
                      setPopupNotes={setPopupNotes}
                      popupNotes={popupNotes}
                    />
                  )}
                </>
              ) : (
                <TextField
                  placeholder="Enter the Name"
                  disabled
                  value={pipelineData.status}
                  style={{ backgroundColor: "transparent" }}
                  styles={(props) =>
                    getTextFieldStyles(
                      "185px", //width
                      null, //padding
                      "185px", //maxwidth
                      props,
                      currentHover,
                      validationErrors.status,
                      pipelineData.status
                    )
                  }
                />
              )}
            </div>

            <div className={styles.submitButton}>
              {isEdit ? (
                <PrimaryButton
                  onClick={submitHandler}
                  text="Save & Close"
                  iconProps={{ iconName: "Save" }}
                />
              ) : (
                <div>
                  <PrimaryButton onClick={enabaleEditButton}>
                    <img src={Editicon} alt="image" style={{ marginRight: "5px", width: "16px", height: "16px" }} />
                    <span style={{ fontSize: "16px", fontWeight: "600", paddingBottom: "3px" }}>Edit</span>
                  </PrimaryButton>
                </div>
              )}{" "}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.add_modal_main_container}>
        <div className={styles.modal_main_container}>
          <div className={styles.sub_container}>
            <div className={styles.divider1}>
              <div className={styles.opportunity_type_1}>
                <Label className={styles.label_style_1} required>
                  Request Type
                </Label>
                {isEdit ? (
                  <Dropdown
                    options={requesttypeOption}
                    selectedKey={pipelineData.request_type}
                    onChange={(e, item) => {
                      dropDownHandlerText(e, item, "request_type");
                      setCurrentHover("");
                    }}
                    placeholder="Select"
                    notifyOnReselect
                    style={{ fontSize: "12px", color: "#5B5F62" }}
                    styles={(props) => secondContainerDropdown(props, currentHover, error, pipelineData.request_type)}
                  />
                ) : (
                  <TextField
                    value={pipelineData.request_type}
                    placeholder="Request type"
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    styles={(props) =>
                      getTextFieldStyles(
                        "305px", //width
                        "0px", //padding
                        "305px", //maxwidth
                        props,
                        currentHover,
                        validationErrors.request_type,
                        pipelineData.request_type
                      )
                    }
                  />
                )}
              </div>

              <div className={styles.opportunity_type_1}>
                <Label className={styles.label_style_1} required>
                  Request Client
                </Label>
                {isEdit ? (
                  <Dropdown
                    options={DealName}
                    selectedKey={selectedKey}
                    onChange={(e, item) => {
                      dropDownHandlerText(e, item, "request_client");
                      setCurrentHover("");
                    }}
                    placeholder="Select"
                    notifyOnReselect
                    style={{ fontSize: "12px", color: "#5B5F62" }}
                    styles={(props) => secondContainerDropdown(props, currentHover, error, pipelineData.request_client)}
                  />
                ) : (
                  <TextField
                    value={`${pipelineData.request_client.company_name} - ${pipelineData.request_client.ClientId}`}
                    placeholder="Request client"
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    styles={(props) =>
                      getTextFieldStyles(
                        "305px", //width
                        "0px", //padding
                        "305px", //maxwidth
                        props,
                        currentHover,
                        validationErrors.company_name,
                        pipelineData.request_client.company_name
                      )
                    }
                  />
                )}
              </div>

              <div className={styles.opportunity_type_1}>
                <Label className={styles.label_style}>Lead Source</Label>
                <TextField
                  value={pipelineData.lead_source}
                  placeholder="Enter the Name"
                  disabled={!isEdit}
                  style={{ backgroundColor: "transparent" }}
                  onChange={(e) => {
                    inputChangeHandler(e, "lead_source");
                    setCurrentHover("");
                  }}
                  styles={(props) =>
                    getTextFieldStyles(
                      null,
                      "5px",
                      null,
                      props,
                      currentHover,
                      validationErrors.lead_source,
                      pipelineData.lead_source
                    )
                  }
                  errorMessage={validationErrors.lead_source}
                />
              </div>
            </div>
            <div className={styles.main_dropdown_container2} >

              <div className ={`${styles.comment_icon} ${isCommentVisible ? styles.active : ''}`} onClick={handleCommentClick}>
                <div className={styles.icon}>
                  <Icon iconName="Message" />
                </div>
                <div className={styles.iconname}>Comment</div>
              </div>
              <div className ={`${styles.comment_icon} ${isTrackOpportunityActive ? styles.active : ''}`} onClick={handleUCClick}>
                <div className={styles.icon}>
                  <Icon iconName="ChangeEntitlements" />
                </div>
                <div className={styles.iconname}>Track Opportunity</div>
              </div>
            </div>
          </div>
        </div>
        {isMaincontainerVisible && (
          <div className={styles.main_box_container}>
            <div className={styles.firstBox}>
              <div className={styles.box}>
                <p className={styles.P_customerDetains}>Customer Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Customer Name</Label>
                    <TextField
                      value={pipelineData.customer_name}
                      placeholder="Enter the Name"
                      onChange={(e) => {
                        inputChangeHandler(e, "customer_name");
                        setCurrentHover("");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "185px", //width
                          null, //padding
                          "185px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.customer_name,
                          pipelineData.customer_name
                        )
                      }
                      errorMessage={validationErrors.customer_name}
                    />
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Email ID</Label>
                    <TextField
                      value={pipelineData.email_id}
                      placeholder="Enter the Email ID"
                      onChange={(e) => {
                        inputChangeHandler(e, "email_id");
                        setCurrentHover("");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "185px", //width
                          null, //padding
                          "185px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.email_id,
                          pipelineData.email_id
                        )
                      }
                      errorMessage={validationErrors.email_id}
                    />
                  </div>
                </div>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style}>Mobile Number</Label>
                  <TextField
                    value={pipelineData.mobile_number}
                    placeholder="Enter the Mobile Number"
                    onChange={(e) => {
                      inputChangeHandler(e, "mobile_number");
                      setCurrentHover("");
                    }}
                    disabled={!isEdit}
                    style={{ backgroundColor: "transparent" }}
                    styles={(props) =>
                      getTextFieldStyles(
                        "185px", //width
                        null, //padding
                        "185px", //maxwidth
                        props,
                        currentHover,
                        validationErrors.mobile_number,
                        pipelineData.mobile_number
                      )
                    }
                    errorMessage={validationErrors.mobile_number}
                  />
                </div>
              </div>
         
              <div className={styles.box}>
                <p className={styles.P_AC}>Assignment & Categorization</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Tech Lead</Label>
                    <TextField
                      value={pipelineData.tech_lead}
                      placeholder="Enter the Tech Lead"
                      onChange={(e) => {
                        inputChangeHandler(e, "tech_lead");
                        setCurrentHover("");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "185px", //width
                          null, //padding
                          "185px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.tech_lead,
                          pipelineData.tech_lead
                        )
                      }
                    />
                  </div>

                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Assigned To</Label>
                    {isEdit ? (
                      <Dropdown
                        options={customSortDropDown(adminSales)}
                        selectedKey={selectedKeyCom}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "assign_to");
                          setCurrentHover("");
                        }}
                        notifyOnReselect
                        style={{ fontSize: "12px", color: "#5B5F62" }}
                        styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.assign_to)}
                        errorMessage={validationErrors.assign_to}
                      />
                    ) : (
                      <TextField
                        value={
                          pipelineData.assign_to?.first_name && pipelineData.assign_to?.last_name
                            ? `${pipelineData.assign_to.first_name} ${pipelineData.assign_to.last_name}`
                            : pipelineData.assign_to?.first_name || pipelineData.assign_to?.last_name || ""
                        }
                        placeholder="Select"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.assign_to,
                            pipelineData.assign_to
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>SS Business Unit</Label>
                    {isEdit ? (
                      <Dropdown
                        options={SSBuisnessUnitOption}
                        selectedKey={pipelineData.ss_businness_unit}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "ss_businness_unit");
                          setCurrentHover("");
                        }}
                        placeholder="Select"
                        notifyOnReselect
                        style={{ fontSize: "12px", color: "#5B5F62" }}
                        styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.assign_to)}
                        errorMessage={validationErrors.ss_businness_unit}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.ss_businness_unit}
                        placeholder="SS Business unit"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.ss_businness_unit,
                            pipelineData.ss_businness_unit
                          )
                        }
                      />
                    )}
                  </div>

                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Region & Location</Label>
                    {isEdit ? (
                      <Dropdown
                        options={customSortDropDown(regionLocationOption)}
                        selectedKey={pipelineData.region_location}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "region_location");
                          setCurrentHover("");
                        }}
                        placeholder="Select"
                        notifyOnReselect
                        style={{ fontSize: "12px", color: "#5B5F62" }}
                        styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.region_location)}
                        errorMessage={validationErrors.region_location}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.region_location}
                        placeholder="Region Location"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.region_location,
                            pipelineData.region_location
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.firstBox}>
             <div className={styles.box}>
                <p className={styles.P_timeline}>Opportunity Timeline</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Start Date</Label>
                    {isEdit ? (
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        onSelectDate={(date) => {
                          dateHandler(date, "start_date");
                        }}
                        styles={calendarClass(pipelineData.start_date)}
                        value={pipelineData.start_date ? new Date(pipelineData.start_date) : ""}
                        errorMessage={validationErrors.start_date}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.start_date ? formatDate(pipelineData.start_date) : null}
                        placeholder="DD/MM/YYYY"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.start_date,
                            pipelineData.start_date
                          )
                        }
                      />
                    )}
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Close Date</Label>
                    {isEdit ? (
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        onSelectDate={(date) => {
                          dateHandler(date, "close_date");
                          setCurrentHover("");
                        }}
                        styles={calendarClass(pipelineData.close_date)}
                        value={pipelineData.close_date ? new Date(pipelineData.close_date) : ""}
                        errorMessage={validationErrors.close_date}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.close_date ? formatDate(pipelineData.close_date) : null}
                        placeholder="DD/MM/YYYY"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.job_title,
                            pipelineData.close_date
                          )
                        }
                      />
                    )}
                  </div>
                </div>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style}>probability of Winning %</Label>
                  {isEdit ? (
                    <Dropdown
                      options={probOfWinning}
                      selectedKey={pipelineData.probability_winning}
                      onChange={(e, item) => {
                        dropDownHandlerText(e, item, "probability_winning");
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      style={{ fontSize: "12px", color: "#5B5F62" }}
                      styles={(props) =>
                        getDropdownStyles(props, currentHover, error, pipelineData.probability_winning)
                      }
                      errorMessage={validationErrors.probability_winning}
                    />
                  ) : (
                    <TextField
                      value={pipelineData.probability_winning}
                      placeholder="Probability of Winning %"
                      disabled
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "185px", //width
                          null, //padding
                          "185px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.probability_winning,
                          pipelineData.probability_winning
                        )
                      }
                    />
                  )}
                </div>
              </div>

              <div className={styles.box}>
                <p className={styles.P_customerDetains}>Financial Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Currency</Label>
                    {isEdit ? (
                      <Dropdown
                        options={customSortDropDown(currency)}
                        selectedKey={pipelineData.currency}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "currency");
                          setCurrentHover("");
                        }}
                        placeholder="Currency"
                        notifyOnReselect
                        style={{ fontSize: "12px", color: "#5B5F62" }}
                        styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.currency)}
                        errorMessage={validationErrors.currency}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.currency}
                        placeholder="Currency"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.currency,
                            pipelineData.currency
                          )
                        }
                      />
                    )}
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Opportunity Value</Label>
                    <TextField
                      value={pipelineData.opportunity_value}
                      placeholder="opportunity_value"
                      onChange={(e) => {
                        inputChangeHandler(e, "opportunity_value");
                        setCurrentHover("");
                      }}
                      disabled={!isEdit}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "185px", //width
                          null, //padding
                          "185px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.opportunity_value,
                          pipelineData.opportunity_value
                        )
                      }
                      errorMessage={validationErrors.opportunity_value}
                    />
                  </div>
                </div>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style}>Budget Status</Label>
                  {isEdit ? (
                    <Dropdown
                      options={budgetStatus}
                      selectedKey={pipelineData.budget_status}
                      onChange={(e, item) => {
                        dropDownHandlerText(e, item, "budget_status");
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      style={{ fontSize: "12px", color: "#5B5F62" }}
                      styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.budget_status)}
                      errorMessage={validationErrors.budget_status}
                    />
                  ) : (
                    <TextField
                      value={pipelineData.budget_status}
                      placeholder="Budget Status"
                      disabled
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) =>
                        getTextFieldStyles(
                          "185px", //width
                          null, //padding
                          "185px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.budget_status,
                          pipelineData.budget_status
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.firstBox}>
              <div className={styles.rightTopBox}>
                <p className={styles.P_otherOppurtunity}>Other Opportunity Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Priority Level</Label>
                    {isEdit ? (
                      <Dropdown
                        options={priorityLevel}
                        selectedKey={pipelineData.priority_level}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "priority_level");
                          setCurrentHover("");
                        }}
                        placeholder="Select"
                        notifyOnReselect
                        style={{ fontSize: "12px", color: "#5B5F62" }}
                        styles={(props) => getDropdownStyles(props, currentHover, error, pipelineData.priority_level)}
                        errorMessage={validationErrors.priority_level}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.priority_level}
                        placeholder="Priority Level"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.priority_level,
                            pipelineData.priority_level
                          )
                        }
                      />
                    )}
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Opportunity Source</Label>
                    {isEdit ? (
                      <Dropdown
                        options={customSortDropDown(opputunitySourceOption)}
                        selectedKey={pipelineData.opportunity_source}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "opportunity_source");
                          setCurrentHover("");
                        }}
                        placeholder="Select"
                        notifyOnReselect
                        style={{ fontSize: "12px", color: "#5B5F62" }}
                        styles={(props) =>
                          getDropdownStyles(props, currentHover, error, pipelineData.opportunity_source)
                        }
                        errorMessage={validationErrors.opportunity_source}
                      />
                    ) : (
                      <TextField
                        value={pipelineData.opportunity_source}
                        placeholder="Opportunity Source"
                        disabled
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) =>
                          getTextFieldStyles(
                            "185px", //width
                            null, //padding
                            "185px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.opportunity_source,
                            pipelineData.opportunity_source
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.boxRightBottom}>
                <p className={styles.P_customerDetains}>Attachments</p>
                <div className={styles.boxWithDottedLines} style={{ marginTop: "10px" }}>
                  <img className={styles.uploadImage} src={uploadImage} />
                  <p className={styles.fileTypeText}>PDF, JPG, PNG, DOC below 1 Mb</p>
                  <p className={styles.infoText}>You can also upload files by</p>
                  <div>
                    <PrimaryButton
                      text="Browse Files"
                      disabled={!isEdit}
                      className={styles.uploadButton}
                      styles={buttonStyles}
                      onClick={() => fileInputRef.current.click()}
                    />
                    <input
                      multiple
                      hidden
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => uploadDocs(e.target.files)}
                    />
                  </div>
                  <div className={styles.bottomContainer}>
                    {pipelineData.documents.map((doc) => (
                      <div>
                        <a href={doc.document}>{doc.document_name}</a>
                        <Icon
                          iconName="ChromeClose"
                          className={tableCloseIconClass}
                          onClick={() => {
                            removeUploadedDoc(doc.document_name);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isTrackOpportunityActive && <TrackOpportunity  value={pipelineData}/>}

       {isCommentVisible && (
          <>
            <div className={styles.comment_box_border}>
              <div className={styles.comment_box_content}>
                <div className={styles.post_messagebar}>
                  <p className={styles.comment_heading}>Comments</p>
                  <div className={styles.messagebar_alignment}>
                    {showcommentMessageBar && (
                      <MessageBar
                        onDismiss={() => {
                          setShowcommentMessageBar(false);
                          setMessageBarType(null);
                          setMessageBarText("");
                        }}
                        styles={messageBarStyles}
                        dismissButtonAriaLabel="Close"
                        messageBarType={messageBarType === "success" ? MessageBarType.success : MessageBarType.error}
                      >
                        {messageBarText}
                      </MessageBar>
                    )}
                  </div>
                </div>
                <div className={styles.comment_box}>
                  <TextField
                    multiline
                    rows={4}
                    styles={() =>
                      getCommentTextField(
                        "100%", // width
                        "0px", // padding
                        "100%", // max width
                        error || errorText,
                        comments
                      )
                    }
                    value={comments}
                    placeholder="Add a Comment"
                    onChange={handleInputChange}
                    maxLength={1500}
                  />
                  {(error || errorText) && <p style={{ color: "#E80B0B" }}>{error || errorText}</p>}
                </div>
                <div className={styles.post_button_alignment}>
                  <PrimaryButton
                    text="Post"
                    className={styles.post_button}
                    styles={buttonStyles}
                    onClick={handlePost}
                  />
                </div>

                <div>
                  {OpportunityComments?.map((comment) => {
                    const { _id, content, created_by, createdAt } = comment;
                    const isOpen = openCommentId === _id;
                    const truncatedText = content?.substring(0, 135);

                    let authorName = "Unknown Author";
                    if (created_by) {
                      if (Array.isArray(created_by)) {
                        authorName = created_by.map((person) => `${person.first_name} ${person.last_name}`).join(", ");
                      } else {
                        authorName = `${created_by.first_name} ${created_by.last_name}`;
                      }
                    }

                    return (
                      <div key={_id} className={styles.post_comments}>
                        <div className={styles.post_comments_header}>
                          <div className={styles.post_title}>{authorName}</div>
                          <div className={styles.post_time}>{formatTimeAgo(createdAt)}</div>
                        </div>
                        <div className={styles.content}>
                          <p>
                            {isOpen ? content : truncatedText}
                            {!isOpen && content?.length > 170 && "..."}
                            {content?.length > 170 && (
                              <span onClick={() => handleToggle(_id)} className={styles.read_more}>
                                {isOpen ? " read less" : " read more"}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ViewandEditOpportunityModel;

import React, { useState, useCallback, useEffect } from 'react'
import styles from './InterviewModal.module.css'
import { DatePicker, Dropdown, Icon, Modal, PrimaryButton, TextField, TimePicker, ComboBox, TagPicker } from '@fluentui/react'
import videocamera from "../assets/video_camera.png"
import { axiosPrivateCall, axiosPublicCall } from "../constants";
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../utils/authConfig';
import { mergeStyles } from '@fluentui/react';
import {ISOdateToCustomDate} from '../utils/helpers.js'

const textFieldColored2 = (props, currentHover, error, value) => {
    const borderColor = "#E1E5E8";
    const hoverBorderColor = "#E1E5E8";
    return {
        fieldGroup: {
            width: "180px",
            height: "33px",
            color: '#5B5F62',
            top: "5px",
            marginBottom: '10px',
            borderColor: borderColor,
            borderRadius: "3px",
            '&:hover': {
                borderColor: hoverBorderColor,
            }
        },
        field: {
            color: "#5B5F62",
            fontSize: 12,
            textAlign: 'center',
            input: {
                color: "red",
                '&::placeholder': {
                    color: "#5B5F62"
                },
            },

        },
    };
};
const textFieldColored = (props, currentHover, error, value) => {
    const borderColor = "#E1E5E8";
    const hoverBorderColor = "#E1E5E8";

    return {
        fieldGroup: {
            width: "365px",
            height: "33px",
            color: '#5B5F62',
            top: "5px",
            borderColor: borderColor,
            borderRadius: "3px",
            marginBottom: '10px',
            '&:hover': {
                borderColor: hoverBorderColor,
            }
        },
        field: {
            color: "#5B5F62",
            fontSize: 12,
            input: {
                color: "#5B5F62",
                '&::placeholder': {
                    color: "#5B5F62"
                }
            },
        },
    };
};

const textFieldColored1 = (props, currentHover, error, value) => {
    const borderColor = "#E1E5E8";
    const hoverBorderColor = "#E1E5E8";
    return {
        fieldGroup: {
            width: "175px",
            height: "33px",
            color: '#5B5F62',
            top: "5px",
            marginBottom: '10px',
            borderColor: borderColor,
            borderRadius: "3px",
            '&:hover': {
                borderColor: hoverBorderColor,
            }
        },
        field: {
            color: "#5B5F62",
            fontSize: 12,
            input: {
                color: "red",
                '&::placeholder': {
                    color: "#5B5F62"
                }
            },

        },
    };
};

const textFieldColored3 = (props, currentHover, error, value) => {
    const borderColor = "#E1E5E8";
    const hoverBorderColor = "#E1E5E8";
    return {
        fieldGroup: {
            width: "180px",
            height: "27px",
            color: '#5B5F62',
            top: "5px",
            marginBottom: '10px',
            borderColor: borderColor,
            borderRadius: "3px",
            '&:hover': {
                borderColor: hoverBorderColor,
            }
        },
        field: {
            color: "#5B5F62",
            fontSize: 12,
            textAlign: 'center',
            input: {
                color: "red",
                '&::placeholder': {
                    color: "#5B5F62"
                },
            },

        },
    };
};
const iconStyle = {
    marginTop: "3px"
}

const InterviewReschedule = (props) => {
    const { showPopup1, setShowPopup1, meetObj,
        setCancelPop, cancelPop, setReScheduleValue } = props;

    localStorage.setItem('meetObjValue',JSON.stringify(meetObj));
    const [showPopup, setShowPopup] = useState(false)
    const navigateTo = useNavigate();
    const { instance, accounts } = useMsal();

  
    const ISOdateToCustomTime = (value) => {
        const date = new Date(value);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const period = hours >= 12 ? "pm" : "am";
        const formattedHours = hours % 12 || 12;
        const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
        return timeString;
    }
    const [meetobjValue, setMeetObjValue] = useState({
        demand_id: "",
        candidate_id: "",
        mobile_no: "",
        candidate_email: "",
        candidate_name: "",
        interviewer_email: [],
        company_name: "",
        status: "",
        title: "",
        schedule_meet_id: "",
        scheduled_by: "",
        passcode: "",
        meet_url: "",
        duration: "",
        level: "",
        level_title: "",
        startDateTime: new Date().toISOString(),
        event_id: "",
        submission_id: "",
    })
    const [copyText, setCopyText] = useState('Copy')
    const [isDropdownSelected, setIsDropdownSelected] = useState(false);

    const handleDropdownChange = (event, item) => {
        if (item.key) {
            setIsDropdownSelected(true);
        } else {
            setIsDropdownSelected(false);
        }
    };

    const [inputValue, setInputValue] = useState('');
    const [validationMessage, setValidationMessage] = useState('');

    const handleInputChange1 = (event) => {
        const value = event.target.value;

        const regex = /^[a-zA-Z0-9À-ž\s\-\.,'&()]*$/;

        if (value.length > 65) {
            setValidationMessage('Maximum 65 characters');
        } else if (!regex.test(value)) {
            setValidationMessage('Invalid Characters');
        } else {
            setValidationMessage('');
        }

        setInputValue(value);
    };

    const copyToClipboard = (...texts) => {
        const textToCopy = texts.join('\n');
        navigator.clipboard.writeText(textToCopy);
        setCopyText("Copied")
        setTimeout(() => {
            setCopyText("Copy")
        }, 3000)
    };
    const dropDownStylesActive1 = (props, currentHover, error, value) => {
        return {
            dropdown: {
                width: "88px",
                minWidth: "88px",
                minHeight: "27px",
                top: "5px",
                color: "#5B5F62",
                borderColor: error ? "#a80000" : "#E1E5E8",
                marginBottom: "10px",
                selectors: {
                    ":focus": {
                        borderColor: "#E1E5E8",
                    },
                    ":hover": {
                        borderColor: "#E1E5E8 !important",
                    },
                },
            },
            dropdownItemsWrapper:{
                height:"100px"
              },
            title: {
                height: "27px",
                lineHeight: "25px",
                fontSize: "12px",
                color: "#5B5F62",
                borderColor: '#E1E5E8',
                borderRadius:"3px",
                },
    
            caretDownWrapper: { height: "22px", lineHeight: "25px !important" },
            dropdownItem: { minHeight: "20px", fontSize: 12 },
            dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
        };
    };
    const dropDownStylesActive2 = (props, currentHover, error, value) => {
        return {
            dropdown: {
                width: "74px",
                minWidth: "74px",
                minHeight: "27px",
                top: "5px",
                color: "#5B5F62",
                borderColor: error ? "#a80000" : "#E1E5E8",
                marginBottom: "10px",
                selectors: {
                    ":focus": {
                        borderColor: "#E1E5E8",
                    },
                    ":hover": {
                        borderColor: "#E1E5E8 !important",
                    },
                },
            },
            dropdownItemsWrapper:{
                height:"100px"
              },
            title: {
                height: "27px",
                lineHeight: "25px",
                fontSize: "12px",
                color: "#5B5F62",
                borderColor: '#E1E5E8',
                borderRadius:"3px",
                 },
    
            caretDownWrapper: { height: "22px", lineHeight: "25px !important" },
            dropdownItem: { minHeight: "20px", fontSize: 12 },
            dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
        };
    };
    const durationOptions = [
        { key: '00.15.00', text: '0.15 mins' },
        { key: '00.30.00', text: '0.30 mins' },
        { key: '00.45.00', text: '0.45 mins' },
        { key: '01.00.00', text: '1.00 hrs' },
        { key: '01.15.00', text: '1.15 hrs' },
        { key: '01.30.00', text: '1.30 hrs' },
        { key: '01.45.00', text: '1.45 hrs' },
        { key: '02.00.00', text: '2.00 hrs' },
    ]
    
    const durationOptions2 = [
        { key: 'level_1', text: 'Level 1' },
        { key: 'level_2', text: 'Level 2' },
        { key: 'level_3', text: 'Level 3' },
        { key: 'level_4', text: 'Level 4' },
        { key: 'level_5', text: 'Level 5' },
        { key: 'level_6', text: 'Level 6' },
    ]    

    const rescheduleDuration = durationOptions.find(option => option.key === meetobjValue.duration);

    useEffect(() => {

        if (meetObj) {
            setMeetObjValue(prevState => ({
                ...prevState,
                demand_id: meetObj.demand_id,
                schedule_meet_id: meetObj.schedule_meet_id,
                passcode: meetObj.passcode,
                meet_url: meetObj.meet_url,
                candidate_id: meetObj.candidate_id,
                mobile_no: meetObj.mobile_no,
                candidate_email: meetObj.candidate_email,
                candidate_name: meetObj.candidate_name,
                interviewer_email: meetObj.interviewer_email,
                company_name: meetObj.company_name,
                duration: meetObj.duration,
                level: meetObj.level,
                level_title: meetObj.level_title,
                status: meetObj.status,
                title: meetObj.title,
                startDateTime: meetObj.startDateTime,
                event_id: meetObj.event_id,
                scheduled_by: meetObj.scheduled_by,
                submission_id: meetObj.submission_id
            }));
        }

    }, [meetObj]);

    const rescheduleMeet = async () => {
        try {
            const meetObjJson = encodeURIComponent(JSON.stringify(meetObj));
            navigateTo(`/submission/viewsubmission?submission_id=${meetObj?.submission_id}&showPopup1=true&meetObj=${meetObjJson}`)
        } catch (err) {
            throw err;
        }

    }

    const deleteEvent = () => {
        navigateTo(`/Interview/InterviewListing`, { state: { meetObj } })
        setCancelPop(!cancelPop)
        setShowPopup1(!showPopup1)
        setReScheduleValue(meetObj)
    }
    return (
        <div>

            <Modal isOpen={showPopup1} containerClassName={styles.main_PopUp_Container}>
                <div className={styles.border} >
                    <div className={styles.main_container}>

                        <div className={styles.chromeclose} onClick={() => setShowPopup1(!showPopup1)}><Icon
                            iconName='ChromeClose'

                        /></div>



                        <div className={styles.sub_container}>
                            <span className={styles.sub_title}>Interview Title</span>
                            <TextField
                                type="text"
                                name="title"
                                placeholder="Interview Title"
                                styles={textFieldColored}
                                value={meetobjValue.title}
                            />
                        </div>
                        <div className={styles.sub_container}>
                            <span className={styles.sub_title}>Start Time</span>
                            <div className={styles.start_time}>

                                <TextField
                                    type="text"
                                    name="title"
                                    placeholder="Interview Title"
                                    styles={textFieldColored1}
                                    value={ISOdateToCustomDate(meetobjValue.startDateTime)}

                                />
                                <TextField
                                    type="text"
                                    name="title"
                                    placeholder="Interview Title"
                                    styles={textFieldColored2}
                                    value={ISOdateToCustomTime(meetobjValue.startDateTime)}
                                />
                            </div>
                            <div className={styles.sub_container1}>
                            <span className={styles.sub_title1} style={{ marginRight: '20px' }}>Duration</span>
                            <span className={styles.sub_title14} style={{ marginRight: '20px' }}>Level</span>
                            <span className={styles.sub_title15}>Level Title</span>
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
                            <Dropdown placeholder="Select"
                                options={durationOptions}
                                selectedKey={meetobjValue.duration}
                                onChange={(e, item) => setMeetObjValue(prevState => ({ ...prevState, duration: item.key }))}
                                styles={dropDownStylesActive1}
                            />
                            </div>
                            <div className={styles.sub_title1}>
                            <Dropdown placeholder="Select"
                                options={durationOptions2}
                                styles={dropDownStylesActive2}
                                selectedKey={meetobjValue.level}
                                onChange={(e, item) => setMeetObjValue(prevState => ({ ...prevState, level: item.key }))}                                />
                            </div>
                            <div className={styles.sub_title1}>
                            <TextField type="text"
                                name="level_title"
                                placeholder="Enter the Title"
                                styles={textFieldColored3}
                                onChange={(e, newValue) => {
                                    setMeetObjValue(prevState => ({
                                        ...prevState,
                                        level_title: newValue
                                    }));
                                }}
                                value={meetobjValue.level_title}                                />
                             {validationMessage && (
                            <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                            {validationMessage}
                            </div>
                            )}
                            </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title_}>Participants</span>
                                <div className={styles.participant_list}>
                                    <div className={styles.participant}>
                                        <span>Recruiter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</span>
                                        <span>&nbsp;{meetobjValue.scheduled_by}</span>
                                    </div>
                                    <div className={styles.participant}>
                                        <span>Candidate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</span>
                                        <span>&nbsp;{meetobjValue.candidate_name} <span style={{ color: '#909090' }}>({meetobjValue.candidate_email})</span></span>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                            <span>Interviewer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</span>
                                        </div>
                                        <div className={styles.participantMail}>
                                            <span> {meetobjValue.interviewer_email.map(participant => (
                                                <span key={participant.name}>
                                                    <div className={styles.participantNameValue}>
                                                        {`${participant.key} `}
                                                        <span style={{ color: '#909090' }}>({participant.name})</span>
                                                    </div>
                                                </span>
                                            ))}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className={styles.sub_container}>

                                <span className={styles.sub_title}>Meeting Details</span>
                                <div className={styles.meeting_details}>
                                    <div className={styles.detail_row}>
                                        <span className={styles.copy_icon}
                                            onClick={() => copyToClipboard(meetobjValue.schedule_meet_id, meetobjValue.passcode, meetobjValue.meet_url)}
                                        >
                                            <Icon
                                                iconName="Copy"
                                                title="Copy"
                                            />{copyText}</span>

                                        <span className={styles.meet_title}>Meeting ID&nbsp;:</span>
                                        <span className={styles.meet_value}>&nbsp;{meetobjValue.schedule_meet_id}</span>

                                    </div>
                                    <div className={styles.detail_row}>
                                        <span className={styles.meet_title}>Passcode&nbsp;:</span>
                                        <span className={styles.meet_value}>&nbsp;&nbsp;&nbsp;&nbsp;{meetobjValue.passcode}</span>

                                    </div>
                                    <div className={styles.detail_rows}>
                                        <span className={styles.meet_titles}>URL&nbsp;:</span>
                                        <span className={styles.meet_urls}>{meetobjValue.meet_url}</span>

                                    </div>
                                </div>
                            </div>

                            <div className={styles.schedule_button}>

                                <div className={styles.cancel_meet}>
                                    <button className={styles.cancel_btn} onClick={() => deleteEvent()}>Cancel</button>
                                </div>
                                <div>
                                    <PrimaryButton text={`Reschedule`}
                                        className={styles.reschedule_btn}
                                        onClick={rescheduleMeet}
                                        iconProps={{ imageProps: { src: videocamera }, style: { marginTop: "5px" } }}
                                    />
                                </div>
                            </div >

                        </div >

                    </div >
                </div >
            </Modal >
        </div >
    )
}

export default InterviewReschedule
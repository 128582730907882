import React, { useState, useEffect } from 'react';
import { Dialog, DialogType, DialogFooter, TextField, Stack} from '@fluentui/react';
import styles from "../components/TargetMiniNotification.module.css";
import { ReactComponent as Check2} from "../assets/check.svg"
import { ReactComponent as Reject} from "../assets/cross.svg"
import { ReactComponent as Progress} from "../assets/progress.svg"
import { axiosPrivateCall } from "../constants";

const MyPopup = ({ Name,data, isOpen, onDismiss, sendToParent , handleCount}) => {
  const [updatedBy, setUpdatedBy] = useState(null); 

  useEffect(() => {
    const token = localStorage.getItem("token");
    let base64Url = token.split(".")[1];
    let decodedValue = JSON.parse(window.atob(base64Url));
    setUpdatedBy(decodedValue.user_id); 
  }, []);

  const handleAcceptClick = async(obj) => {
  const {assigned,updated_by,data}=obj

const datas={
  message:'accept',
  assigned,
  isRead: [{user:updatedBy,read:false},{user:updated_by._id,read:false}],
  created_by:updated_by,
  updated_by:updatedBy,
  data
}
sendToParent('accept',datas)
 postedData(datas)
 onDismiss()
  };
 
  const postedData=(updated)=>{
    try{
   axiosPrivateCall.post('api/v1/notification/sendNotification',updated).then(res=>console.log(res,'response'));
   handleCount('count')
      }catch{
        console.log('error')
      }
  }
  const handleRejectClick = (obj) => {
    const {assigned,updated_by,data}=obj
    const datas={
      message: 'reject',
      assigned,
      isRead: [{user:updatedBy,read:false},{user:updated_by._id,read:false}],
      created_by:updated_by,
      updated_by:updatedBy,
      data
    }
     postedData(datas)
     onDismiss()
  };

  const handleReviewClick = (obj) => {
    const {assigned,updated_by,data}=obj
    const datas={
      message: 'review',
      assigned,
      isRead: [{user:updatedBy,read:false},{user:updated_by._id,read:false}],
      created_by:updated_by,
      updated_by:updatedBy,
      data
    }
     postedData(datas)
    sendToParent('review',obj.assigned);
    onDismiss()
  
  }
  return (   
     
    <Dialog
      hidden={!isOpen}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.close,
        title: `${Name} requested to change the target`,
        data:data
      }}
      modalProps={{
        isBlocking: false,
        containerClassName: 'ms-dialogMainOverride',
        styles: { main: 
        { minWidth: '400px !important',
        boxShadow:'0px 0px 10px 5px #888888',
       } },
      }}
     
    >
     { data.data&&data.data.length > 0 && data.data.map(obj => (
      <div key={obj._id}>
    <Stack horizontal>
     <div className={`${styles.labelInputContainer}`}>
    <p>Target</p>
    <TextField
      value={obj.target}
      borderless={true}
      className={styles.inputStyle}
    />
  </div>
  <div className={`${styles.labelInputContainer}`}>
  <p>FullTime</p>
    <TextField
      value={obj.fulltime}
      borderless={true}
      className={styles.inputStyle}
    />
  </div>
</Stack>

  <Stack horizontal>
  <div className={`${styles.labelInputContainer1}`}>
    <p>Contract</p>
    <TextField
      value={obj.contract}
      borderless={true}
      className={styles.inputStyle}
    />

  </div>
  <div className={`${styles.labelInputContainer}`}>
    <p>Revenue</p>
    <TextField
      value={obj.revenue}
      borderless={true}
      className={styles.inputStyle}
    />
  </div>
</Stack>

      <DialogFooter>
        <div className={styles.buttonContainer}>
    <button className={styles.acceptButton} onClick={()=>handleAcceptClick(data)}>
      <Check2 /> Accept
    </button>
    <button className={styles.reviewButton} onClick={()=>handleReviewClick(data)}>
      <Progress /> Review
    </button>
    <button className={styles.rejectButton} onClick={()=>handleRejectClick(data)}>
      <Reject /> Reject
    </button>
  </div>
      </DialogFooter>
      </div> 
      ))}
    </Dialog>
    
  );
};

export default MyPopup;
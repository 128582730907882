import React, { useState, useEffect } from 'react';
import { Modal, TextField, MessageBar, MessageBarType } from '@fluentui/react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import styles from './Popup.module.css';

const messageBarStyles={
    content:{
        maxWidth: 420,
        minWidth: 250,
    }
}

const curvedButtonSaveStyle = {
    root: {
        borderRadius: '3px',
        height: '25px', 
        display: 'flex',
    },
};

const curvedButtonCancelStyle = {
    root: {
        borderRadius: '3px', 
        border: '2px solid #0078D4',
        height: '25px',
        display: 'flex',
    },
    textContainer: {
        color: '#0078D4',
    },
    rootHovered: {
        borderColor: '#0078D4', 
    },
};

export function Popup(props) {
    const [notes, setNotes] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (showToast) {
            const timerId = setTimeout(() => {
                setShowToast(false);
            }, 2000);
            return () => clearTimeout(timerId); 
        }
    }, [showToast]);

    const lighterBorderStyle = {
        fieldGroup: {
            borderColor: isInvalid ? '#8B0000' : '#ccc',
            display: 'flex',
        },
    };

    const validateNotes = () => {
        const regex = /^[a-zA-Z0-9.,'&() \-]*$/;
        
        const isValid = regex.test(notes.trim());
    
        const isLengthValid = notes.trim().length >= 2 && notes.trim().length <= 1500;
    
        return isValid && isLengthValid;
    };    

    const handleNotesChange = (value) => {
        setNotes(value);
        setIsInvalid(false);
        
    };

    const handleSave = () => {
        if (notes.trim() === '' || !validateNotes()) {
            setIsInvalid(true);
            return;
        }

        setShowToast(true);

        if (props.resetState) {
            props.resetState();
        }
        props.setPopupNotes(notes)
        props.setShowPopup(false);
        props.setIsModalOpen(!props.isModalOpen);

        if (props.onSaveButtonClick) {
            props.onSaveButtonClick();
        }
    };

    const handleCancel = () => {
        setNotes(''); 
        props.setShowPopup(false); // Corrected to use props.setShowPopup
    };
    

    let showPopup = props.showPopup;
    let setShowPopup = props.setShowPopup;
    let isModalOpen = props.isModalOpen;
    let setIsModalOpen = props.setIsModalOpen;
    let resetState = props?.resetState;

    return (
        
        <>
            <Modal isOpen={showPopup} containerClassName={styles.track_submission_popup}>
                <div className={styles.closePopup}>
                    <div className={styles.track_submission_popup_heading}>
                        <div className={styles.title1}>Are you sure you want to modify the status?</div>
                        <div className={styles.closeButton} onClick={() => setShowPopup(!showPopup)}>
                        </div>
                    </div>
                    <div className={styles.message}>Add Notes</div>
                    <div className={styles.commentbox}>
                        <TextField
                            placeholder="Enter the Notes"
                            multiline
                            rows={8} 
                            value={notes}
                            onChange={(e, newValue) => handleNotesChange(newValue)}
                            styles={lighterBorderStyle}
                            errorMessage={isInvalid ? 'Enter valid Notes' : undefined} />
                    </div>

                    <div className={styles.bottomContainer}>
                        <div className={styles.spacer1}></div>
                        <div className={styles.buttonContainer}>
                            <DefaultButton text={`Cancel`} onClick={handleCancel} styles={curvedButtonCancelStyle}/>
                            <PrimaryButton text={`Save`} onClick={handleSave} styles={curvedButtonSaveStyle} />
                        </div>
                    </div>
                </div>
            </Modal>
            {showToast && (
                <div>
                    <MessageBar
                        onDismiss={() => setShowToast(false)}
                        styles={messageBarStyles}
                        dismissButtonAriaLabel="Close"
                        messageBarType={MessageBarType.success}
                    >
                        User Status updated Successfully !
                    </MessageBar>
                </div>
            )}
        </>
    );
}
import { createSlice } from '@reduxjs/toolkit';

const isDemandEdit = createSlice({
  name: 'isEdit',
  initialState: false,
  reducers: {
    setIsEdit: (state, action) => action.payload,
  },
});

export const { setIsEdit } = isDemandEdit.actions;

export default isDemandEdit.reducer;

import { useState, useEffect, useCallback } from "react";
import { Modal } from "@fluentui/react";
import styles from "./AccountSettingModal.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { TextField, PrimaryButton, DatePicker } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { Popup } from "../components/Popup";
import { axiosPrivateCall } from "../constants";
import { useNavigate } from "react-router-dom";

const pinInputRegex = /^[1-9]{1}[0-9]{0,5}$/;
const addressRegex = /^[a-zA-Z0-9 .,\/\\\-#&:()[\]]*$/;

const contractIconClass = mergeStyles({
  fontSize: 20,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});

const closeIconClass = mergeStyles({
  fontSize: 16,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});

const calendarClass = (props, currentHover, error, value) => {
  return {
    root: {
      "*": {
        width: "100%",
        fontSize: "12px !important",
        height: "22px !important",
        lineHeight: "20px !important",
        borderColor: error
          ? "rgb(168,0,0)"
          : currentHover === value
          ? "rgb(50, 49, 48) !important "
          : "transparent !important",
        selectors: {
          ":hover": {
            borderColor: "rgb(50, 49, 48) !important",
          },
        },
      },
    },

    icon: { height: 10, width: 10, left: "85%", padding: "0px 0px" },
    statusMessage: { marginBottom: "-25px" },
  };
};

const dropDownStyles = (props, currentHover, error, value) => {
  return {
    dropdown: { minWidth: "160px", minHeight: "20px" },
    title: {
      height: "22px",
      lineHeight: "18px",
      fontSize: "12px",
      borderColor: error ? "#a80000" : currentHover === value ? "rgb(96, 94, 92)" : "transparent",
    },
    caretDownWrapper: { height: "22px", lineHeight: "20px !important" },
    dropdownItem: { minHeight: "22px", fontSize: 12 },
    dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
  };
};

const textField = (props, currentHover, error, value) => {
  return {
    fieldGroup: {
      height: "22px",
      width: "100%",
      borderColor: error ? "#a80000" : "transparent",
      selectors: {
        ":focus": {
          borderColor: "rgb(96, 94, 92)",
        },
      },
    },
    field: { lineHeight: "24px", fontSize: 12 },
  };
};

const dropDownMaritalStatus = [
  { key: "Single", text: "Single" },
  { key: "Married", text: "Married" },
  { key: "Divorced", text: "Divorced" },
  { key: "Widow", text: "Widow" },
  { key: `I don't want to day`, text: `I don't want to say` },
];

const dropDownGender = [
  { key: "Male", text: "Male" },
  { key: "Female", text: "Female" },
  { key: "Others", text: "Others" },
];

const AccountSettingModal = (props) => {

  const initialValues = {
    role: "",
    jobRole: "",
    status: "",
    reportsTo: "",
    location: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    dateOfHire: "",
    dateOfJoin: "",
    dateOfBirth: "",
    maritalStatus: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    gender: "",
    panNumber: "",
    adhaarNumber: "",
    password: "",
  };

  const sanitizeObject = {
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
    mobile: "mobile_number",
    dateOfHire: "date_of_hire",
    dateOfJoin: "date_of_joining",
    dateOfBirth: "date_of_birth",
    maritalStatus: "marital_Status",
    gender: "gender",
    address1: "address_line_1",
    address2: "address_line_2",
    city: "city",
    pincode: "pincode",
    panNumber: "pan_number",
    adhaarNumber: "aadhaar_number",
    password: "password_hash",
    role: "role",
    reportsTo: "reports_to",
    status: "status",
    jobRole: "job_role",
    location: "location",
  };
 
  const { isModalOpen, setIsModalOpen } = props;
  let isSubmitSuccess = props.isSubmitSuccess;
  const setSubmitSuccess = props.setSubmitSuccess;
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [employeeData, setEmployeeData] = useState(initialValues);
  const [initialEmployeeData, setInitialEmployeeData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [currentHover, setCurrentHover] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [reportsToId, getReportsToId] = useState("");
  const token = localStorage.getItem("token");
  const base64Url = token.split(".")[1];
  const decodedValue = JSON.parse(window.atob(base64Url));

  function modalSizeHandler() {
    setIsModalShrunk(!isModalShrunk);
  }

  const reportsToHandler = (e, item) => {
    dropDownRoleHandler(e, item, "reportsTo");
  };

  const dropDownRoleHandler = (e, item, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: item.key,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const sanitizer = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
      if (key === "reportsTo" && data["role"] === "admin") return;
      if (key === "reportsTo" && data["role"] === "account_manager") return;
      sanitizedData[sanitizeObject[key]] = data[key];
    });

    sanitizedData["_id"] = decodedValue.user_id;

    return sanitizedData;
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const sanitizeApiData = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((value) => {
      if (value === "reports_to") {
        if (data["reports_to"]) {
          const reportToObj = {
            key: data["reports_to"]["_id"],
            text: data["reports_to"]["first_name"] + " " + data["reports_to"]["last_name"],
          };
          sanitizedData["reportsTo"] = reportToObj.key;
          getReportsToId(reportToObj.text)
        } else {
          sanitizedData["reportsTo"] = "No data";
        }
        return;
      }

      const sanitizedKey = getKeyByValue(sanitizeObject, value);
      if (sanitizedKey) {
        sanitizedData[sanitizedKey] = data[value];
      }
    });

    return sanitizedData;
  };

  useEffect(() => {
    const employee_list = JSON.parse(localStorage.getItem("employee_list_data"));
    if (employee_list == null) {
      return;
    } else {
      const sanitizedData = sanitizeApiData(employee_list);
      setEmployeeData(sanitizedData);
      setInitialEmployeeData(sanitizedData);
    }
  }, [isModalOpen, updateTrigger]);

  const navigateTo = useNavigate();

  const hoverHandler = (name) => {
    setCurrentHover(name);
  };

  const dropDownHandler = (e, item, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: item.text,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const dateHandler = (date, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: date,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const inputChangeHandler = (e, name) => {
    const { value } = e.target;
    let inputValue = value;

    let isNameValid = false;

    if (name === "mobile" && (inputValue === "" || !isNaN(inputValue))) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10);
      isNameValid = true;
    }

    if (name === "address1" && addressRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "address2" && addressRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "city") {
      isNameValid = true;
    }

    if (
      name === "pincode" &&
      (inputValue === "" || !isNaN(inputValue)) &&
      (pinInputRegex.test(inputValue) || inputValue === "") &&
      inputValue.length < 7
    ) {
      isNameValid = true;
    }

    if (isNameValid) {
      setEmployeeData({
        ...employeeData,
        [name]: inputValue,
      });

      setErrors({
        ...errors,
        [name]: null,
      });
    } else {
      setIsSaveVisible(true);
    }
  };

  useEffect(() => {
    errorCheck();
  }, [employeeData]);

  const errorCheck = () => {
    const hasChanges = Object.keys(employeeData).some((key) => {
      const initialVal = initialEmployeeData[key] || "";
      const currentVal = employeeData[key] || "";
      if (initialVal instanceof Date && currentVal instanceof Date) {
        return initialVal.getTime() !== currentVal.getTime();
      }
      const initialValStr = typeof initialVal === 'string' ? initialVal.trim() : String(initialVal).trim();
      const currentValStr = typeof currentVal === 'string' ? currentVal.trim() : String(currentVal).trim();
      return initialValStr !== currentValStr;
    });
    setIsSaveVisible(hasChanges);
  };

  function submitHandler(e) {
    e.preventDefault();
    axiosPrivateCall
      .post("/api/v1/employee/updateEmployee", sanitizer(employeeData))
      .then((res) => {
        const existingData = JSON.parse(localStorage.getItem("employee_list_data")) || {};
        const sanitizedExistingData = sanitizeApiData(existingData);
        const updatedData = {
          ...sanitizedExistingData,
          ...sanitizer(employeeData),
        };
        setSubmitSuccess(true);
        setIsModalOpen(false);
        localStorage.setItem("employee_list_data", JSON.stringify(updatedData));
        setUpdateTrigger((prev) => !prev); 
        submitForm();
        reportsToHandler();
      })
      .catch((e) => {
        console.log(e);
      });
  }
  
  const closeHandler = () => {
    if (isSaveVisible) {
      setShowPopup(true);
    } else {
      setIsModalOpen(false);
    }
  };

  function submitForm() {
    resetState();
    setIsModalOpen(false);
  }

  const escKeyHandler = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      escKeyHandler(event);
    };

    document.addEventListener("keydown", handleKeyDown, { capture: true });

    return () => {
      document.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, []);

  return (
    <div>
      {
        <Popup
          resetState={resetState}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }

      <Modal
        scrollableContentClassName={styles.addemployee_modal_scrollable_content}
        containerClassName={
          isModalShrunk ? styles.addemployee_modal_container_shrunk : styles.addemployee_modal_container
        }
        isOpen={isModalOpen}
      >
        <form>
          <div className={styles.addemployee_modal_header_container}>
            <div className={styles.header_tag_expand_close_icon_container}>
              <div className={styles.header_tag_container}>Account Settings</div>

              <div className={styles.header_expand_close_icon_container}>
                <div onClick={modalSizeHandler} className={styles.header_expand_icon_container}>
                  {isModalShrunk ? (
                    <Icon iconName="FullScreen" className={contractIconClass} />
                  ) : (
                    <Icon iconName="BackToWindow" className={contractIconClass} />
                  )}
                </div>

                <div onClick={()=>closeHandler()} className={styles.header_close_icon_container}>
                  <Icon iconName="ChromeClose" className={closeIconClass} />
                </div>
              </div>
            </div>

            <div className={styles.header_content_container}>
              <div className={styles.header_content_title_role_container}>
                <div className={styles.header_content_role_save_container}>
                  <div className={styles.main_role_dropdown_container}>
                    <div className={styles.header_content_title_container}>
                      {employeeData.firstName} {employeeData.lastName}
                    </div>
                  </div>

                  <div className={styles.header_employeeid_save_close_container}>
                    <div className={styles.header_save_close_btns_container}>
                      <PrimaryButton
                        text={`Save & Close`}
                        iconProps={{ iconName: "Save" }}
                        disabled={!isSaveVisible}
                        onClick={submitHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.addemployee_modal_main_container}>
            <div className={styles.main_filter_options_container}>
              <div className={styles.subcontainer}>
                <div className={styles.main_dropdown_container}>
                  <div className={styles.main_repotingmanager_title}>Employee ID</div>
                  <div id="employeeID" onClick={() => hoverHandler("employeeID")}>
                    <TextField
                      type="text"
                      name="employeeID"
                      value={decodedValue.employee_id}
                      disabled
                      className={styles.loc_dropdown_teamlead}
                      styles={(props) => textField(props, currentHover, errors._id, "employeeID")}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.subcontainer}>
                <div className={styles.main_dropdown_container}>
                  <div className={styles.main_teamlead_title}>Reports To</div>
                  <div id="reportsTo" onClick={() => hoverHandler("reportsTo")}>
                    <TextField
                      placeholder="Enter value"
                      styles={(props) => textField(props, currentHover, errors.reportsTo, "reportsTo")}
                      value={reportsToId}
                      disabled
                      className={styles.loc_dropdown_teamlead}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.main_information_container}>
              <div className={styles.main_basic_information_container}>
                <div className={styles.main_basic_information_title}>BASIC INFORMATION</div>

                <div className={styles.main_basic_information_content_container}>
                  <div className={styles.main_from_field}>
                    <div className={styles.main_sub_from_field}>
                      <div>First Name</div>
                      <div onClick={() => setCurrentHover("firstName")}>
                        <TextField
                          type="text"
                          name="firstName"
                          disabled
                          value={employeeData.firstName}
                          errorMessage={errors.firstName}
                          styles={(props) => textField(props, currentHover, errors.firstName, "firstName")}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Last Name</div>
                      <div onClick={() => setCurrentHover("lastName")}>
                        <TextField
                          type="text"
                          name="lastName"
                          disabled
                          styles={(props) => textField(props, currentHover, errors.lastName, "lastName")}
                          value={employeeData.lastName}
                          errorMessage={errors.lastName}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Designation</div>
                      <div onClick={() => setCurrentHover("lastName")}>
                        <TextField
                          type="text"
                          name="lastName"
                          disabled
                          styles={(props) => textField(props, currentHover, errors.lastName, "lastName")}
                          value={employeeData.jobRole}
                          errorMessage={errors.lastName}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Email ID</div>
                      <div onClick={() => setCurrentHover("email")}>
                        <TextField
                          type="text"
                          name="email"
                          disabled
                          errorMessage={errors.email}
                          styles={(props) => textField(props, currentHover, errors.email, "email")}
                          value={employeeData.email}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Date of hire</div>
                      <div id="dateOfHire" onClick={() => hoverHandler("dateOfHire")}>
                        {employeeData.dateOfHire ? (
                          <DatePicker
                            placeholder="DD/MM/YYYY"
                            disabled
                            styles={(props) => calendarClass(props, currentHover, errors.dateOfHire, "dateOfHire")}
                            value={new Date(employeeData.dateOfHire)}
                          />
                        ) : (
                          <DatePicker
                            placeholder="No data"
                            disabled
                            styles={(props) => calendarClass(props, currentHover, errors.dateOfHire, "dateOfHire")}
                          />
                        )}
                      </div>
                      <div className={styles.errorfield}>{errors.dateOfHire}</div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Date of Joining</div>
                      <div id="dateOfJoin" onClick={() => hoverHandler("dateOfJoin")}>
                      {employeeData.dateOfJoin ? (
                          <DatePicker
                            placeholder="DD/MM/YYYY"
                            disabled
                            styles={(props) => calendarClass(props, currentHover, errors.dateOfJoin, "dateOfJoin")}
                            value={new Date(employeeData.dateOfJoin)}
                          />
                        ) : (
                          <DatePicker
                          placeholder="DD/MM/YYYY"
                          disabled
                            styles={(props) => calendarClass(props, currentHover, errors.dateOfJoin, "dateOfJoin")}
                          />
                        )}
                      </div>
                      <div className={styles.errorfield}>{errors.dateOfJoin}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.main_personal_information_container}>
                <div className={styles.main_personal_information_title}>PERSONAL DETAILS</div>

                <div className={styles.main_personal_information_content_container}>
                  <div className={styles.main_from_field}>
                    <div className={styles.main_sub_from_field}>
                      <div>Date of Birth</div>
                      <div id="dateOfBirth" onClick={() => hoverHandler("dateOfBirth")}>
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          onSelectDate={(date) => {
                            dateHandler(date, "dateOfBirth");
                            setCurrentHover("");
                          }}
                          styles={(props) => calendarClass(props, currentHover, errors.dateOfBirth, "dateOfBirth")}
                          value={
                            employeeData.dateOfBirth ? new Date(employeeData.dateOfBirth) : null
                          }
                        />
                      </div>
                      <div className={styles.errorfield}>{errors.dateOfBirth}</div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Marital Status</div>
                      <div
                        id="maritalStatus"
                        onClick={() => hoverHandler("maritalStatus")}
                        className={
                          employeeData.maritalStatus || errors.maritalStatus || currentHover === "maritalStatus"
                            ? styles.showfield
                            : styles.hidefield
                        }
                      >
                        <Dropdown
                          placeholder="select an option"
                          options={dropDownMaritalStatus}
                          onChange={(e, item) => {
                            dropDownHandler(e, item, "maritalStatus");
                            setCurrentHover("");
                          }}
                          errorMessage={errors.maritalStatus}
                          styles={(props) => dropDownStyles(props, currentHover, errors.maritalStatus, "maritalStatus")}
                          selectedKey={employeeData.maritalStatus}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>Gender</div>
                      <div
                        id="gender"
                        onClick={() => hoverHandler("gender")}
                        className={
                          employeeData.gender || errors.gender || currentHover === "gender"
                            ? styles.showfield
                            : styles.hidefield
                        }
                      >
                        <Dropdown
                          placeholder="select an option"
                          options={dropDownGender}
                          onChange={(e, item) => {
                            dropDownHandler(e, item, "gender");
                            setCurrentHover("");
                          }}
                          errorMessage={errors.gender}
                          styles={(props) => dropDownStyles(props, currentHover, errors.gender, "gender")}
                          selectedKey={employeeData.gender}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Mobile Number</div>
                      <div onClick={() => setCurrentHover("mobile")}>
                        <TextField
                          type="text"
                          name="mobile"
                          styles={(props) => textField(props, currentHover, errors.mobile, "mobile")}
                          errorMessage={errors.mobile}
                          onChange={(e) => {
                            inputChangeHandler(e, "mobile");
                          }}
                          value={employeeData.mobile}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>City</div>
                      <div>
                        <TextField
                          type="text"
                          name="city"
                          styles={(props) => textField(props, currentHover, errors.city, "city")}
                          onChange={(e) => {
                            inputChangeHandler(e, "city");
                          }}
                          value={employeeData.city}
                          errorMessage={errors.city}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Pincode</div>
                      <div>
                        <TextField
                          type="text"
                          name="pincode"
                          styles={(props) => textField(props, currentHover, errors.pincode, "pincode")}
                          onChange={(e) => {
                            inputChangeHandler(e, "pincode");
                          }}
                          value={employeeData.pincode}
                          errorMessage={errors.pincode}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.main_from_field}>
                    <div className={styles.main_sub_from_field}>
                      <div>Address Line 1</div>
                      <div onClick={() => setCurrentHover("address1")}>
                        <TextField
                          type="text"
                          name="address1"
                          styles={(props) => textField(props, currentHover, errors.city, "address1")}
                          onChange={(e) => {
                            inputChangeHandler(e, "address1");
                          }}
                          value={employeeData.address1}
                          errorMessage={errors.address1}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>Address Line 2</div>
                      <div onClick={() => setCurrentHover("address2")}>
                        <TextField
                          type="text"
                          name="address2"
                          styles={(props) => textField(props, currentHover, errors.city, "address2")}
                          onChange={(e) => {
                            inputChangeHandler(e, "address2");
                          }}
                          value={employeeData.address2}
                          errorMessage={errors.address2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );

  function resetState() {
    setErrors({});
    setCurrentHover("");
  }
};
export default AccountSettingModal;

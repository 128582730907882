import React, { useState, useCallback, useEffect } from 'react'
import styles from './InterviewModal.module.css'
import { DatePicker, Dropdown, Icon, Modal, PrimaryButton, TextField, TimePicker, TagPicker } from '@fluentui/react'
import videocamera from "../assets/video_camera.png"
import { graphConfig } from "../utils/authConfig";
import { axiosPrivateCall } from "../constants";
import { useSearchParams } from 'react-router-dom';
import { mergeStyles } from '@fluentui/react';
import  mailContent  from '../utils/emailContent';
import { useMsal } from '@azure/msal-react';

const textFieldColored = (props, currentHover, error, value) => {
    return {
        fieldGroup: {
            width: "294px",
            height: "33px",
            color: '#5B5F62',
            borderColor: "#E1E5E8",
            top: "5px",
            borderRadius: '3px',
            marginBottom: '10px',
        },
        field: {
            color: "#5B5F62",
            fontSize: '12px',
            borderColor: "#E1E5E8",
            lineHeight: '18px',
            input: {

                '&::placeholder': {
                    color: "rgba(179, 179, 179, 1)"
                }
            },

        },
    };
};
const textFieldColored2 = (error) => {
    return {
        fieldGroup: {
            width: "112px",
            height: "27px",
            color: '#5B5F62',
            borderColor:error? "#D24545":"#E1E5E8",
            top: "5px",
            borderRadius: '3px',
            marginBottom: '10px',
        },
        field: {
            color: "#5B5F62",
            fontSize: '12px',
            borderColor: "#E1E5E8",
            lineHeight: '18px',
            input: {

                '&::placeholder': {
                    color: "rgba(179, 179, 179, 1)"
                }
            },

        },
    };
};


const calendarClass = (props, currentHover, error, value) => {
    return {
        root: {

            "*": {
                width: "140px",
                fontSize: "12px !important",
                height: "27px !important",
                lineHeight: "25px !important",
                color: '#5B5F62',
                borderColor: "#E1E5E8",    
                borderRadius:"3px",
                top: "3px",
                marginBottom: '10px',
            },
        },

        icon: { height: 10, width: 10, left: "85%", padding: "0px 0px", lineHeight: "22px !important", },
    };
};



const textFieldColored1 = ( error,value) => {
    const borderColor =error?"#D24545": "#E1E5E8";
    const hoverBorderColor = "#E1E5E8"; // Same color for hover state

    return {
        text: {
            border: "1px solid #E1E5E8",
            borderColor:borderColor,
            borderRadius:"3px",
            width:"295px",
            selectors: {
                '&:hover': {
                    border: '1px solid rgb(96, 94, 92)',
                },
                '&:focus': {
                    border: '1px solid yellow',
                },
            },
        },
    }
};

const requiredSpanClass = mergeStyles({
    color: 'red', 
    '::after': {
      content: '" *"',
      color: 'red',
    },
  });

const dropDownStylesActive = (props, currentHover, error, value) => {
    return {
        dropdown: {
            width: "140px",
                fontSize: "12px !important",
                height: "27px !important",
                lineHeight: "25px !important",
                color: "#5B5F62",
                borderColor: '#E1E5E8',
                borderRadius:"3px",
                top: "6px",
            selectors: {
                ":focus": {
                    borderColor: "#E1E5E8",
                },
                ":hover": {
                    borderColor: "#E1E5E8 !important",
                },
            },
        },
        dropdownItemsWrapper:{
            height:"100px"
          },
        title: {
            height: "27px",
            lineHeight: "25px",
            fontSize: "12px",
            color: "#5B5F62",
            borderColor: '#E1E5E8',
            borderRadius:"3px",
        },

        caretDownWrapper: { height: "22px", lineHeight: "25px !important" },
        dropdownItem: { minHeight: "20px", fontSize: 12 },
        dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
    };
};

const dropDownStylesActive1 = ( error) => {
    return {
        dropdown: {
            width: "88px",
            minWidth: "88px",
            minHeight: "27px",
            top: "5px",
            color: "#5B5F62",
            borderColor: !error ? "#E1E5E8" : "#D24545"  ,
            marginBottom: "10px",
            selectors: {
                ":focus": {
                    borderColor: !error ? "#E1E5E8" : "#D24545" ,
                },
                ":hover": {
                    borderColor: "#E1E5E8 !important",
                },
            },
        },
        dropdownItemsWrapper:{
            height:"100px"
          },
        title: {
            height: "27px",
            lineHeight: "25px",
            fontSize: "12px",
            color: "#5B5F62",
            borderColor: !error ? "#E1E5E8" : "#D24545" ,
            borderRadius:"3px",
            },

        caretDownWrapper: { height: "22px", lineHeight: "25px !important" },
        dropdownItem: { minHeight: "20px", fontSize: 12 },
        dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
    };
};
const dropDownStylesActive2 = ( error) => {
    return {
        dropdown: {
            width: "74px",
            minWidth: "74px",
            minHeight: "27px",
            top: "5px",
            color: "#5B5F62",
            borderColor: !error ? "#E1E5E8" : "#D24545" ,
            marginBottom: "10px",
            selectors: {
                ":focus": {
                    borderColor:  !error ? "#E1E5E8" : "#D24545" ,
                },
                ":hover": {
                    borderColor: "#E1E5E8 !important",
                },
            },
        },
        dropdownItemsWrapper:{
            height:"100px"
          },
        title: {
            height: "27px",
            lineHeight: "25px",
            fontSize: "12px",
            color: "#5B5F62",
            borderColor: !error ? "#E1E5E8" : "#D24545" ,
            borderRadius:"3px",
             },

        caretDownWrapper: { height: "22px", lineHeight: "25px !important" },
        dropdownItem: { minHeight: "20px", fontSize: 12 },
        dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
    };
};
const durationOptions = [
    { key: '00.15.00', text: '0.15 mins' },
    { key: '00.30.00', text: '0.30 mins' },
    { key: '00.45.00', text: '0.45 mins' },
    { key: '01.00.00', text: '1.00 hrs' },
    { key: '01.15.00', text: '1.15 hrs' },
    { key: '01.30.00', text: '1.30 hrs' },
    { key: '01.45.00', text: '1.45 hrs' },
    { key: '02.00.00', text: '2.00 hrs' },
]

const durationOptions2 = [
    { key: 'level_1', text: 'Level 1' },
    { key: 'level_2', text: 'Level 2' },
    { key: 'level_3', text: 'Level 3' },
    { key: 'level_4', text: 'Level 4' },
    { key: 'level_5', text: 'Level 5' },
    { key: 'level_6', text: 'Level 6' },
]

const InterviewSchedule = (props) => {
    let showPopup = props.showPopup;
    let setShowPopup = props.setShowPopup;
    let candidateObj = props.basicInfo
    let candidateId = props.candidate_Id
    let demand_id = props.demandId;
    let title = props.whatDemand.job_title;
    let { showMessageBar, setShowMessageBar } = props;
    let employmentDetails = props.employmentDetails;
    const company_name = employmentDetails.map(client => client.company_name).join(", ");
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const submission_id = searchParams.get("submission_id")

    const [meetobjValue, setMeetObjValue] = useState({
        demand_id: "",
        submission_id: "",
        candidate_id: "",
        candidateObjID: "",
        demandObjID: "",
        mobile_no: "",
        candidate_email: "",
        candidate_name: "",
        interviewer_email: [],
        company_name: "",
        duration: "",
        level: "",
        level_title: "",
        status: "",
        title: "",
        schedule_meet_id: "",
        passcode: "",
        timings:"",
        meet_url: "",
        startDateTime: new Date().toISOString(),
        event_id: "",
        scheduled_by: "",
        interview_schedule_details: []

    })
  
    useEffect(() => {
        const interviewerEmail = selectedTags;
        setMeetObjValue((prevState) => ({
            ...prevState,
            interviewer_email: interviewerEmail,
        }));
    }, [selectedTags]);

    const { instance, accounts } = useMsal();
    const [userList, setUserList] = useState(null);

    const [tempInputValue, setTempInputValue] = useState("");
    const [borderWidth, setBorderWidth] = useState(329);
    const [isDropdownSelected, setIsDropdownSelected] = useState(false);
    const handleDropdownChange = (event, item) => {
        if (item.key) {
            setIsDropdownSelected(true);
        } else {
            setIsDropdownSelected(false);
        }
    };
    const handleCombinedChange = (event, item) => {
        setMeetObjValue(prevState => ({ ...prevState, level: item.key }));
        handleDropdownChange(event, item);
    };
    const [inputValue, setInputValue] = useState('');
    const [validationMessage, setValidationMessage] = useState('');

    const handleInputChange1 = (event) => {
        const value = event.target.value;

        const regex = /^[a-zA-Z0-9À-ž\s\-\.,'&()]*$/;

        if (value.length > 65) {
            setValidationMessage('Maximum 65 characters');
        } else if (!regex.test(value)) {
            setValidationMessage('Invalid Characters');
        } else {
            setValidationMessage('');
        }

        setInputValue(value);
    };
    const handleCombinedChange1 = (event) => {
        handleInputChange1(event);
        const value = event.target.value;
        setMeetObjValue(prevState => ({ ...prevState, level_title: value }));
    };

    const handleInputChange = (newValue) => {
        if (newValue === "") {
            setUserList([]);
        }
        setTempInputValue(newValue);
        setSelectedTags((prevTags) =>
            prevTags.map((tag) => ({ ...tag }))
        );

    };

    const updateDateTime = (selectedDate, selectedTime) => {

        const combinedDateTime = new Date(
            Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                selectedTime.getHours(),
                selectedTime.getMinutes(),
                selectedTime.getSeconds()
            )
        );

        // Convert the combined date and time to ISO 8601 format
        const isoDateTime = combinedDateTime.toISOString();
        setMeetObjValue(prevState => ({
            ...prevState,
            startDateTime: isoDateTime
        }));
    };


    const handleTabKeyPress = (event) => {
        if ((event.key === "Tab" || event.key === ",") && tempInputValue !== "") {
            event.preventDefault();
            const tags = tempInputValue
                .split(",")
                .map((tag) => tag.trim())
                .filter((tag) => tag !== "");
            if (tags.length > 0) {
                const newTags = tags.map((tag) => ({ key: tag, name: tag }));
                setSelectedTags([...selectedTags, ...newTags]);
            }
            setTempInputValue("");
        }
    };

    const contactAutocomplete = async (keys) => {
        const headers = new Headers();
        const bearer = localStorage.getItem("accessToken");
        if (!keys) {
            console.log("Empty keys provided");
            return null;
        }

        if (!bearer) {
            throw new Error("Bearer token is missing");
        }

        headers.append("Authorization", `Bearer ${bearer}`);
        headers.append("Content-Type", "application/json");

        try {
            const response = await fetch(`https://graph.microsoft.com/v1.0/myorganization/users?$filter=(accountEnabled eq true) and (userType eq 'Guest') and
    (startswith(displayName, '${keys}') or startswith(givenName, '${keys}') or startswith(surname, '${keys}') or startswith(mail, '${keys}') or startswith(userPrincipalName, '${keys}'))&$top=20`, {
                headers: headers,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const listContact = await response.json();
            if (listContact.value && Array.isArray(listContact.value)) {
                const emails = listContact.value.map(contact => ({
                    email: contact.mail,
                    displayName: contact.displayName
                }))
                setUserList(emails); // Update the state with the array of emails
                return emails;
            }
            return listContact;
        } catch (err) {
            console.error("Error fetching contacts:", err);
            throw err;
        }
    };

    const autoCompletework = useCallback(async () => {
        try {
            if (selectedTags !== null) {
                const res = await contactAutocomplete(tempInputValue);
                if (res.value !== null) {
                    const maxWidth = Math.max(...res.value.map(email => email.length), 0);
                    const calculatedWidth = Math.max(329, maxWidth * 8);
                    setBorderWidth(calculatedWidth);
                } else {
                    setBorderWidth(329);

                }
            }
        } catch (error) {
            console.log("Error fetching contact autocomplete:", error);
        }
    }, [selectedTags, tempInputValue])

    useEffect(() => {
        if (tempInputValue === "") {
            setUserList([]);
        } else {
            autoCompletework();
        }
    }, [tempInputValue, autoCompletework]);

    useEffect(() => {
        let first_name = localStorage.getItem('first_name');
        if (candidateObj) {
            setMeetObjValue(prevState => ({
                ...prevState,
                demand_id: demand_id,
                candidateObjID: candidateId,
                demandObjID: candidateObj.demand_id,
                submission_id: submission_id,
                title: title,
                candidate_email: candidateObj?.email,
                candidate_name: candidateObj?.first_name,
                candidate_id: candidateObj.CandidateId,
                mobile_no: candidateObj.mobile_number,
                company_name: company_name,
                scheduled_by: first_name
            }));
        }

    }, [candidateObj, company_name, demand_id, submission_id, title]);

    useEffect(() => {
        autoCompletework();
    }, [selectedTags, autoCompletework]);

    const [invalidFields, setInvalidFields] = useState([]);


    const validateFields = () => {
        const invalids = [];

        if (!meetobjValue.duration) invalids.push('duration');
        if (!meetobjValue.level) invalids.push('level');
        if (!meetobjValue.level_title) invalids.push('level_title');
        if (!meetobjValue.interviewer_email.length) invalids.push('interviewer_email');
        
        setInvalidFields(invalids);
        return invalids.length === 0;
    };

    const handleSubmit = () => {
        if (validateFields()) {
            submitHandler();
        }
    };

    const getFieldStyle = (fieldName) => invalidFields.includes(fieldName) ? { borderColor: 'red' } : {};


    const submitHandler = async () => {
        try{
        const startDateTime = new Date(meetobjValue.startDateTime.replace("Z", ""));
        const durationMilliseconds = parseFloat(meetobjValue.duration) * 60 * 60 * 1000;
        const endDateTime = new Date(startDateTime.getTime() + durationMilliseconds);
        const accessToken = localStorage.getItem('accessToken');
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");        

        const attendees = selectedTags.map(interviewer => ({
            upn: interviewer.name 
        }));
        
        attendees.push({
            upn: meetobjValue.candidate_email // Adding the candidate's email
        });        

        const joinLnk = "https://www.microsoft.com/en-in/microsoft-teams/join-a-meeting"      

            const emailData = {
                "subject": meetobjValue.title,
                "startDateTime": startDateTime.toISOString(),
                "endDateTime": endDateTime.toISOString(),
                "participants": {
                    "attendees": attendees,
                },
                "joinMeetingIdSettings": {
                "isPasscodeRequired": true  
                },
                "lobbyBypassSettings": {
                    "isDialInBypassEnabled": true,
                    "scope": "organization"
                }
            }

            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(emailData),
            };

        const response = await fetch(graphConfig.graphOnlinemeetings, options);
        const eventResponse = await response.json();

        const attendeesObj = selectedTags.map(interviewer => ({
            emailAddress: {
                address: interviewer.name // Replace with the actual property name where email is stored
            }
        }));
        attendeesObj.push({
            emailAddress: {
                address: meetobjValue.candidate_email
            }
        });

        function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${day}-${month}-${year}`;
        }            
        //Function to format time in HH:MM:SS format
        function formatTime(date) {
            let hours = String(date.getHours()).padStart(2, '0');
            let minutes = String(date.getMinutes()).padStart(2, '0');
            hours = parseInt(hours, 10);
            let period = hours >= 12 ? "PM" : "AM";
            hours = hours % 12 || 12; // Convert 0 to 12 for midnight
            return `${hours}:${minutes} ${period}`;
        }

        const startDate = formatDate(startDateTime);
        const startTime = formatTime(startDateTime);
        const endTime = formatTime(endDateTime);
        const data =localStorage.getItem("employee_list_data")
        const parsedData = JSON.parse(data)

        const meetingID = eventResponse.joinMeetingIdSettings.joinMeetingId;
        const passCode= eventResponse.joinMeetingIdSettings.passcode; 
        const joinWebUrl = eventResponse.joinWebUrl;

        const emailBodyContent = mailContent(meetingID, passCode, joinWebUrl,startDateTime, endDateTime)
        const sendMailPayload = {
            message: {
                subject: meetobjValue.title,
                body: {
                    contentType: "HTML",
                    content: emailBodyContent.creatOnlineMeeting
                },
                toRecipients: attendeesObj,
            },
            saveToSentItems: "true"
        };

        const optionsSendMail = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(sendMailPayload),
          };        

          const number = [meetobjValue.mobile_no];
                const text = {
                    meet_url: joinLnk,
                    date_w: startDate,
                    time_w: `${startTime} - ${endTime}`,
                    schedule_meet_id: meetingID,
                    passcode: passCode,
                    job:meetobjValue.title,
                    name: meetobjValue.candidate_name
                }
                await axiosPrivateCall.post(`/api/v1/interview/sendmessage?template=sight_interview_schedule_temp`,{number:number,text:text}).then((res)=>{
                    console.log("Whatsapp scheduled message send successfully!")
                }).catch((err)=>{
                    console.log("error: ",err)
                })

        const sendMailResponse = await fetch(graphConfig.graphMailEndpoint, optionsSendMail); //send email for attandees
            if(meetingID && passCode){
                await axiosPrivateCall.post("/api/v1/interview/scheduleMeet", {
                    ...meetobjValue,
                    meet_url: joinLnk,
                    event_id: eventResponse.id,
                    schedule_meet_id: meetingID,
                    passcode: passCode,
                    timings: `${startTime} - ${endTime}`,
                    status: 'Scheduled', 
                    interview_schedule_details:
                        {
                            title: meetobjValue.title,
                            level: meetobjValue.level,
                            level_title: meetobjValue.level_title,
                            startDateTime: meetobjValue.startDateTime,
                            duration: meetobjValue.duration,
                            timings: `${startTime} - ${endTime}`,
                            interviewer_email: meetobjValue.interviewer_email,
                            status: 'Scheduled',
                            updated_by: parsedData.first_name+" "+parsedData.last_name,
                            created_by: parsedData._id                        
                        }                    
                });

            }
            setShowPopup(!showPopup);
            setShowMessageBar(!showMessageBar);

        } catch (error) {
            console.error("Error creating event:", error);
        }
    }

    const onTagChange = async (items) => {
        setSelectedTags(items);
        const selectedInterviewerEmails = items.map((interviewer) => interviewer.name);

        const interviewerEmailString = selectedInterviewerEmails.join(', ');
        setMeetObjValue(prevState => ({
            ...prevState,
            interviewer_email: interviewerEmailString
        }));

        await autoCompletework();
    };

    const onResolveSuggestions = async (filterText, tagPickerSuggestionsProps) => {
        // Use the userList state to provide suggestions
        const filteredSuggestions = userList ? userList.filter(email => email.includes(filterText)) : [];
        return filteredSuggestions.map((suggestion) => ({
            key: suggestion,
            text: suggestion,
        }));
    };

    const handleMap = (item) => {
        const { email, displayName } = item.item;
        const tags = email
            .split(",")
            .map((tag) => tag.trim())
            .filter((tag) => tag !== "");
        if (tags.length > 0) {
            const newTags = tags.map((tag) => ({ key: displayName, name: tag }));
            setSelectedTags([...selectedTags, ...newTags]);
        }
        setTempInputValue("");
    };

    const handleGetText = (item) => {
        return item.key
    }

    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const hourString = hour.toString().padStart(2, '0');
                const minuteString = minute.toString().padStart(2, '0');
                const key = `${hourString}.${minuteString}.00`;
                const text = `${hourString}:${minuteString}`;
                options.push({ key, text });
            }
        }
        return options;
    };

    const durationOptions1 = generateTimeOptions();
    const findKeyByTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}.00`;

        // Find the option with the matching text
        const matchingOption = durationOptions1.find(option => option.key === formattedTime);
        return matchingOption ? matchingOption.key : null;
    };

    const updateIsoTime = (selectedDate, selectedTime) => {
        const timeParts = selectedTime.key.split('.');
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);

        // Combine the selected date with the extracted time
        const isoDateTime = new Date(
            Date.UTC(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                hours,
                minutes,
                0 // Set seconds to 0
            )).toISOString();
        setMeetObjValue(prevState => ({
            ...prevState,
            startDateTime: isoDateTime
        }));
    }


    return (
        <div>
            <Modal isOpen={showPopup} container >

                <div className={styles.border}
                    style={{ width: `${borderWidth}px` }}
                >
                    <div className={styles.main_container}>

                        <div className={styles.chromeclose1} onClick={() => setShowPopup(!showPopup)}><Icon
                            iconName='ChromeClose'

                        /></div>
                        <div className={styles.sub_container}>
                            <span className={styles.sub_title}>Interview Title<span className={`${requiredSpanClass}`}></span></span>
                            <TextField readOnly
                                type="text"
                                name="title"
                                placeholder="Interview Title"
                                styles={textFieldColored}
                                value={meetobjValue.title}
                            />


                        </div>
                        <div className={styles.sub_container}>
                            <span className={styles.sub_title} >Start Time<span className={`${requiredSpanClass}`}></span></span>
                            <div className={styles.start_time}>
                                <DatePicker
                                    value={new Date(meetobjValue.startDateTime.replace("Z", ""))}
                                    onSelectDate={(selectedDate) => updateDateTime(selectedDate, new Date(meetobjValue.startDateTime.replace("Z", "")))}
                                    ariaLabel="Date picker"
                                    placeholder="DD/MM/YYYY"
                                    styles={calendarClass}
                                />
                                <Dropdown
                                    placeholder="Select"
                                    options={durationOptions1}
                                    selectedKey={findKeyByTime(meetobjValue.startDateTime.replace("Z", ""))}
                                    onChange={(_ev, date) => updateIsoTime(new Date(meetobjValue.startDateTime.replace("Z", "")), date)}
                                    styles={dropDownStylesActive}
                                />
                            </div >
                            <div className={styles.sub_container1}>
                            <span className={styles.sub_title1} style={{ marginRight: '20px' }}>Duration<span className={`${requiredSpanClass}`}></span></span>
                            <span className={styles.sub_title12} style={{ marginRight: '20px' }}>Level<span className={`${requiredSpanClass}`}></span></span>
                            {isDropdownSelected && (
                            <span className={styles.sub_title13}>Level Title<span className={`${requiredSpanClass}`}></span></span> )}
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
                            <Dropdown placeholder="Select"
                                options={durationOptions}
                                onChange={(e, item) => setMeetObjValue(prevState => ({ ...prevState, duration: item.key }))}
                                styles={dropDownStylesActive1(invalidFields.includes('duration'),meetobjValue.duration)}
                            />
                            </div>
                            <div className={styles.sub_title1}>
                            <Dropdown placeholder="Select"
                                name="level"
                                options={durationOptions2}
                                styles={dropDownStylesActive2(invalidFields.includes('level'),meetobjValue.level)}
                                onChange={handleCombinedChange}
                            />
                            </div>
                            {isDropdownSelected && (
                <div className={styles.sub_title1}>
                    <TextField
                        type="text"
                        name="level_title"
                        placeholder="Enter the Title"
                        
                        styles={textFieldColored2(invalidFields.includes('level_title'),meetobjValue.level_title)}

                        value={inputValue}
                        onChange={handleCombinedChange1}
                    />
                     {validationMessage && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
                        {validationMessage}
                    </div>
                )}
                </div>
            )}
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Candidate<span className={`${requiredSpanClass}`}></span></span>
                                <TextField readOnly
                                    type="text"
                                    name="email"
                                    placeholder="Enter the mail ID"
                                    styles={textFieldColored}
                                    value={meetobjValue.candidate_email}

                                />

                            </div>

                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Add Interviewer<span className={`${requiredSpanClass}`}></span></span>
                                
                                <TagPicker
                                      className={styles.tagPickerSpacing}
                                    removeButtonAriaLabel="Remove"
                                    onResolveSuggestions={onResolveSuggestions}
                                    getTextFromItem={handleGetText}
                                    selectedItems={selectedTags}
                                    onChange={onTagChange}
                                    onInputChange={handleInputChange}
                                    inputProps={{
                                        placeholder:
                                            selectedTags.length > 0
                                                ? "Enter another Mail ID"
                                                : "Enter the Mail ID",
                                        className: styles.inputContainerWithTags,
                                        onKeyDown: handleTabKeyPress,
                                        value: tempInputValue,

                                    }}
                                    styles={textFieldColored1(invalidFields.includes('interviewer_email'),meetobjValue.interviewer_email)}
                                    autoComplete="on"
                                />
                                <div className={styles.dropdown_container}>
                                    {userList?.map?.((item, index) => (
                                        <div
                                            key={`${item}-${index}`}
                                            onClick={() => handleMap({ item })}
                                            className={styles.dropdown_option}
                                        >
                                            <div className={styles.popUpItems}>{item.email}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={styles.schedule_button}>
                                <PrimaryButton text={`Schedule`}
                                    onClick={handleSubmit}
                                    className={styles.sechduleButton}
                                    iconProps={{ imageProps: { src: videocamera }, style: { marginTop: "5px" } }}

                                />

                            </div>

                        </div >

                    </div >
                </div >
            </Modal >
        </div >
    )
}

export default InterviewSchedule
import React, { useState} from "react";
import styles from "./demandSubmissionListing.module.css"
import { SearchBox, FontIcon, mergeStyles,Callout, DirectionalHint,} from '@fluentui/react';
import {useNavigate, useSearchParams  } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosPrivateCall } from "../constants";
import Nomatchimg from "../assets/no.png"
import NoDataFound from "../assets/Nodatafound.png";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { Shimmer } from '@fluentui/react';
import { ISOdateToCustomDate } from "../utils/helpers";

const searchIcon = { iconName: 'Search' };


const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px',
    color: '#999DA0',
    cursor: 'pointer',
    userSelect: 'none',
});

const iconClass1 = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    margin: '0 ',
    color: '#999DA0',
    cursor: 'pointer'
});

const CalloutNameStyles = {
    calloutMain: {
        background: '#EDF2F6',
        padding: '2',

    },
}


function DemandInterviewListing(props) {
    const demandInterviewData=props.interview;
    const [searchTerm, setSearchTerm] = useState('');
    const[searchResult,setSearchResult]=useState([])
    const [showPopup1, setShowPopup1] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [updateCallout, setUpdateCallout] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const [meetObjValue, setMeetObjValue] = useState()
    const [hoverCallout, setHoverCallout] = useState('');
    const navigateTo = useNavigate();
    const demand_id = searchParams.get('demand_id')

    const addEllipsisToName = (name) => {

        if (name?.length > 13) {
            let new_name = name.substring(0, 13).padEnd(16, '.')
            return new_name
        }
        else return name

    }
    const columns = [
        {
            columnKey: ' ',
            label: ' ',
        },
        {
            columnKey: 'Meeting ID',
            label: 'Meeting ID',
        }, {
            columnKey: 'Candidate ID',
            label: 'Candidate ID',
        }, {
            columnKey: 'Candidate Name',
            label: 'Candidate Name',
        },
        {
            columnKey: 'Mobile',
            label: 'Mobile'
        }, {
            columnKey: 'Candidate Email',
            label: 'Candidate Email'
        }, {
            columnKey: 'Interview Email',
            label: 'Interview Email',
        }, {
            columnKey: 'Scheduled By',
            label: 'Scheduled By',
        },

        {
            columnKey: 'Client',
            label: 'Client',
        },
        {
            columnKey: 'Interview Date',
            label: 'Interview Date',
        },
        {
            columnKey: 'Start time',
            label: 'Start time',
        },
        {
            columnKey: 'Duration',
            label: 'Duration',
        }, {
            columnKey: 'Status',
            label: 'Status',
        },

        {
            columnKey: 'More Options',
            label: ' '
        }];

    const clearSearchBox = () => {
        setSearchTerm('');
        setHasMore(true)
    }
   
  
    const ISOdateToCustomTime = (value) => {
        const date = new Date(value);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
        return timeString;
    }

    const scheduleMeet = async (id) => {
        setMeetObjValue(id)
        setUpdateCallout(!updateCallout)
        setShowPopup1(!showPopup1);
    }

 

    const searchInAllFields = (searchTerm) => {
        if (!searchTerm || !searchTerm.target) {
          setSearchTerm('');
          return;
        }
        const searchValue = searchTerm.target.value.toLowerCase()
        setSearchTerm(searchValue);
        searchInObject(searchValue);
    };
    
    const searchInObject = (searchValue) => {
        let restrictedKeys = ["_id","is_deleted","createdAt","updatedAt","__v",'meet_url',"event_id","passcode","schedule_meet_id","title","candidateObjID","submission_id","demandObjID"]
        let filteredArrayData = [];
        demandInterviewData.map((data,index)=>{
        let filterKeys = Object.entries(data);

        filterKeys.map(filteredData => {
            if(filteredData[0]=='startDateTime'){
                  let timeFilter=ISOdateToCustomTime(filteredData[1])
                  let dateFilter=ISOdateToCustomDate(filteredData[1])
                  let trimedData=searchValue.split(" ").join("")
               if(timeFilter.toString().split(" ").join("").toLocaleLowerCase().includes(trimedData)){
                filteredArrayData.push(demandInterviewData[index]);
               }
               if(dateFilter.toString().split(" ").join("").toLocaleLowerCase().includes(searchValue)){
                filteredArrayData.push(demandInterviewData[index]);
               }
            }
            //level 1    
            if(restrictedKeys.includes(filteredData[0])){
              return;
            }  else{
                if(typeof(filteredData[1]) == 'object'){
                    if(filteredData[0]=='interviewer_email'){
                       filteredData[1].map(value=>{
                        let filterinterviewKeys = Object.entries(value);
                       filterinterviewKeys.map(s=>{
                        if(s[1].toLocaleLowerCase().includes(searchValue)){
                            filteredArrayData.push(demandInterviewData[index]);
                           }
                        })
                       })
                    }
                }else{
                    if(filteredData[1].toString().toLocaleLowerCase().includes(searchValue)){
                        filteredArrayData.push(demandInterviewData[index]);
                      }
                }
            }
        
        })
       })
       setSearchResult([...new Set(filteredArrayData)])
    };

        const download = () => {
        let downloadUrl=`/api/v1/demand/downloadDemandHistroy?demandId=${demand_id}&key=interviews`
        setLoading(true);
        setTimeout(() => {
          axiosPrivateCall
            .get(downloadUrl, {
              responseType: 'blob',
            })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${Date.now()}.xlsx`);
              document.body.appendChild(link);
              link.click();
              setCompleted(true);
              setTimeout(() => {
                setCompleted(false);
              }, 4000);
              setLoading(false);
            })
            .catch(e => {
              console.log(e);
              setLoading(false);
            });
        }, 1000);
      };
      let items = Array(4).fill(null);
    return (
        <div className={styles.table_header_container}>
      <div className={styles.container}>

        <div className={styles.nav_container}>
          <div className={styles.table_header_name}>Interview Listing</div>


          <div className={styles.search_bar_container}>
            <SearchBox onChange={(e)=> searchInAllFields(e)} value={searchTerm}
             onSearch={(e) => { searchInAllFields(e)}} 
             onClear={clearSearchBox} placeholder=" " iconProps={searchIcon} className={styles.search}
              showIcon />
            {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) :
              completed ? (<FontIcon iconName="CheckMark" className={iconClass} />) :
                (<FontIcon iconName="Download" onClick={download} className={iconClass} />)}
          </div>
        </div>
                <div id="scrollableDiv" className={styles.demand_table_container}>
                    <InfiniteScroll style={{ overflow: 'visible', height: '100%' }} dataLength={demandInterviewData.length >= 20 && <h4>Loading...</h4>}
                        hasMore={hasMore} scrollableTarget="scrollableDiv">
                        <table>
                            <thead className={styles.table_header}>
                                <tr className={styles.table_row}>
                                    {columns.map((column) =>
                                        <th
                                            // onClick={() => clickSortHandler(column.columnKey)} 
                                            className={styles.table_headerContents} key={column.columnKey}>
                                            <div
                                                className={styles.table_heading}>
                                                <div>{column.label}</div>
                                                {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                                            </div>
                                        </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                { demandInterviewData?.length === 0 ? (
                                    <tr>
                                        <td className={styles.table_dataContents1} colSpan="13" >
                                            <img src={NoDataFound} alt="Noimage" />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        { (searchTerm?searchResult:demandInterviewData.length) === 0 ? (
                                            <tr>
                                                <td className={styles.table_dataContents1} colSpan="13" >
                                                    <img src={Nomatchimg} alt="NoDataimage" />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {
                                              (searchTerm?searchResult:demandInterviewData)
                                                ?.map((data) => (
                                                    <tr className={styles.table_row} key={data._id}>
                                                    <td className={styles.table_dataContents}>
                                                      <div>{""}</div>
                                                    </td>
                                                    <td onClick={() => scheduleMeet(data)} className={styles.table_dataContents}>
                                                      <span className={styles.custom_link}>{data.MeetID || '-'}</span>
                                                    </td>
                                                    <td onClick={() => navigateTo(`/candidatelibrary/editcandidate?candidate_id=${data?.candidateObjID}`)} className={styles.table_dataContents}>
                                                      <span className={styles.custom_link}>{data.candidate_id || '-'}</span>
                                                    </td>
                                                    <td className={styles.table_dataContents}>{data.candidate_name || '-'}</td>
                                                    <td className={styles.table_dataContents}>{data.mobile_no || '-'}</td>
                                                    <td className={styles.table_dataContents}>{data.candidate_email || '-'}</td>
                                                    <td
                                                      onMouseEnter={() => setHoverCallout(data._id)}
                                                      onMouseLeave={() => setHoverCallout('')}
                                                      id={`ME${data._id}`}
                                                      className={styles.table_dataContents}
                                                    >
                                                      {addEllipsisToName(data.interviewer_email.map((e) => e.name).join(", ") || '-')}
                                                  
                                                      {data.interviewer_email.length > 0 && data.interviewer_email.map((e) => e.name).join(", ").length >= 13 && hoverCallout === data._id && (
                                                        <Callout
                                                          alignTargetEdge={true}
                                                          bounds={(e) => {
                                                            console.log('log', e);
                                                          }}
                                                          isBeakVisible={false}
                                                          styles={CalloutNameStyles}
                                                          directionalHint={DirectionalHint.bottomLeftEdge}
                                                          target={`#ME${data._id}`}
                                                        >
                                                          {data.interviewer_email.map((e) => e.name)}
                                                        </Callout>
                                                      )}
                                                    </td>
                                                    <td className={styles.table_dataContents}>{data.scheduled_by || '-'}</td>
                                                    <td className={styles.table_dataContents}>{data.company_name || '-'}</td>
                                                    <td className={styles.table_dataContents}>{ISOdateToCustomDate(data.startDateTime) || '-'}</td>
                                                    <td className={styles.table_dataContents}>{ISOdateToCustomTime(data.startDateTime) || '-'}</td>
                                                    <td className={styles.table_dataContents}>{data.duration || '-'}</td>
                                                    <td
                                                      className={styles.table_dataContents}
                                                    style={{ color: data.status === "Scheduled"
                                                        ? "#0078D4"
                                                        : data.status === "Completed"
                                                        ? "#13A10E"
                                                        : data.status === "On Progress"
                                                        ? "#FF540B"
                                                        : data.status === "Cancelled"
                                                        ? "#DE0D0D"
                                                        : data.status === "Rescheduled"
                                                        ? "#0078D4" 
                                                        : "white",
                                                        fontWeight: "450" }}
                                                    >
                                                      {data.status || '-'}
                                                    </td>
                                                  </tr>                                                  
                                                ))}
                                            </>
                                        )}
                                    {!demandInterviewData && items?.map(candidate => (
                      <tr className={styles.table_row} >
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}>
                          <div className={styles.moreOptions} >
                            <FontIcon iconName='MoreVertical' className={iconClass1} />
                          </div>
                        </td>
                      </tr>))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
         </div>
    );

};

export default DemandInterviewListing;








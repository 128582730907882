import React, { useState, useEffect } from 'react';
import styles from './TrackInterview.module.css';
import bigArrow from "../assets/bigArrow.png";
import { FontIcon, Callout, DirectionalHint, TextField } from "@fluentui/react";
import NoDataFound from "../assets/Nodatafound.png";
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosPrivateCall } from "../constants";
import {ISOdateToCustomDate} from '../utils/helpers.js'

const textFieldColored = (props, currentHover, error, value) => {
  return {
      fieldGroup: {
          width: "350px",
          height: "33px",
          color: '#5B5F62',
          borderColor: "#E1E5E8",
          top: "5px",
          borderRadius: '3px',
          marginBottom: '10px',
      },
      field: {
          color: "#5B5F62",
          fontSize: '12px',
          borderColor: "#E1E5E8",
          lineHeight: '18px',
          input: {
              '&::placeholder': {
                  color: "rgba(179, 179, 179, 1)"
              }},},};};
const textFieldColored1 = (props, currentHover, error, value) => {
  return {
      fieldGroup: {
          width: "170px",
          height: "33px",
          color: '#5B5F62',
          borderColor: "#E1E5E8",
          top: "5px",
          borderRadius: '3px',
          marginBottom: '10px',
          },
      field: {
          color: "#5B5F62",
          fontSize: '12px',
          borderColor: "#E1E5E8",
          lineHeight: '18px',
          input: {
              '&::placeholder': {
                  color: "rgba(179, 179, 179, 1)"
              }},},};};
const textFieldColored2 = (props, currentHover, error, value) => {
  return {
      fieldGroup: {
          width: "110px",
          height: "33px",
          color: '#5B5F62',
          borderColor: "#E1E5E8",
          top: "5px",
          borderRadius: '3px',
          marginBottom: '10px',
          },
      field: {
          color: "#5B5F62",
          fontSize: '12px',
          borderColor: "#E1E5E8",
          lineHeight: '18px',
          input: {
              '&::placeholder': {
                  color: "rgba(179, 179, 179, 1)"
              }},},};};
  const formatTextWithLineBreaks = (text, charLimit, wordLimit) => {
                if (!text) return text;  
                const formattedText = [];
                let currentText = '';
                let wordCount = 0;
                const words = text.split(' ');
                words.forEach(word => {
                  if ((currentText.length + word.length + 1 > charLimit) || (wordCount >= wordLimit)) {
                    formattedText.push(currentText.trim());
                    currentText = '';
                    wordCount = 0;
                  }
                  currentText += `${word} `;
                  wordCount += 1;
                });
                if (currentText.length > 0) {
                  formattedText.push(currentText.trim());
                }
                return formattedText.join('\n');
              };         
function TrackInterview({ interviewData }) {
  const [showHiText, setShowHiText] = useState(null);
  const [showHiTextCompleted, setShowHiTextCompleted] = useState(null);
  const [showHiTextRescheduledEdited, setShowHiTextRescheduledEdited] = useState(null);
  const [showHiTextRescheduled, setShowHiTextRescheduled] = useState(null);
  const [showHiTextEdited, setShowHiTextEdited] = useState(null);
  const result = JSON.parse(localStorage.getItem('participants'));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedDetailIndex, setSelectedDetailIndex] = useState(null);
  const calculateDuration = (startDateTime, endDateTime) => {
    const startDate = ISOdateToCustomDate(startDateTime);
    const endDate = ISOdateToCustomDate(endDateTime);
    const durationSeconds = Math.floor((endDate - startDate) / 1000);
    return durationSeconds;
  };
  const secondsToMinutes = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} Mins ${secs} Secs`;
  };
  
  
  const convertDuration = (duration) => {
    if (!duration) return '00 mins';
    const [hours, minutes, seconds] = duration.split('.').map(Number);
    const totalMinutes = hours * 60 + minutes + Math.round(seconds / 60);
    const displayHours = Math.floor(totalMinutes / 60);
    const displayMinutes = totalMinutes % 60;
    return displayHours > 0
      ? `${displayHours} hrs ${displayMinutes < 10 ? '0' + displayMinutes : displayMinutes} mins`
      : `${displayMinutes < 10 ? '0' + displayMinutes : displayMinutes} mins`;
  };
  const addDuration = (startDateTime, duration) => {
    if (!duration) return '';
    const date = new Date(startDateTime);
    const [hours, minutes] = duration.split('.').map(Number);
    date.setUTCHours(date.getUTCHours() + hours);
    date.setUTCMinutes(date.getUTCMinutes() + minutes);
    let endHours = date.getUTCHours();
    const endMinutes = date.getUTCMinutes();
    const endAmpm = endHours >= 12 ? 'PM' : 'AM';
    endHours = endHours % 12;
    endHours = endHours ? endHours : 12;
    return `${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes} ${endAmpm}`;
  };
  const convertISOtoTime = (isoString) => {
    const date = new Date(isoString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
  };
  const convertISOtoTime1 = (isoString) => {
    const date = new Date(isoString);
    const ISTOffset = 5.5 * 60 * 60 * 1000; 
    const ISTTime = new Date(date.getTime() + ISTOffset);
    let hours = ISTTime.getUTCHours();
    const minutes = ISTTime.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
  };  
  const getLevelText = (level) => {
    switch (level) {
      case 'level_1': return 'Level 1';
      case 'level_2': return 'Level 2';
      case 'level_3': return 'Level 3';
      case 'level_4': return 'Level 4';
      case 'level_5': return 'Level 5';
      case 'level_6': return 'Level 6';
      default: return level;
    }};
  const getRoleText = (role) => {
    switch (role) {
      case 'account_manager': return 'Account Manager';
      case 'admin': return 'Admin';
      case 'team_lead': return 'Team Lead';
      case 'sales': return 'Sales';
      case 'bde': return 'BDE';
      case 'recruiter': return 'Recruiter';
      default: return role;
    }};
    const handleButtonBoxClick = (index) => {
      setShowHiText(index === showHiText ? null : index);
      setShowHiTextCompleted(null);
      setShowHiTextRescheduled(null);
      setShowHiTextRescheduledEdited(null);
      setShowHiTextEdited(null);
    };
    const handleButtonBoxClickCompleted = (index) => {
      setShowHiTextCompleted(index === showHiTextCompleted ? null : index);
      setShowHiText(null);
      setShowHiTextRescheduled(null);
      setShowHiTextRescheduledEdited(null);
      setShowHiTextEdited(null);
    };
    const [scheduledDetails, setScheduledDetails] = useState([]);
    const [rescheduledDetails, setRescheduledDetails] = useState([]);
    const [CompletedDetails, setCompletedDetails] = useState([]);
    const [rescheduledEditedDetails, setRescheduledEditedDetails] = useState([]);
    const [titUpdated, setTitUpdated] = useState([])
    const [prevData, setPrevData] = useState([])    
    async function getCompleteStatusData(){
      const filteredEvents = interviewData.filter(event => event.status !== 'Completed' && event.status !== 'Cancelled' && event.status !== 'On Progress');
      console.log(filteredEvents,'geetha')
      if(filteredEvents.length > 0){
        const accessToken = localStorage.getItem('accessToken')
        const headers = new Headers();  
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');         
    const attendanceReports = await Promise.all(filteredEvents.map(async (obj) => {
        try {

          console.log(obj.event_id,'id')
        const event_id = obj.event_id;
        const attendanceReportURL = `https://graph.microsoft.com/v1.0/me/onlineMeetings/${event_id}/attendanceReports`;                             
           const attendanceReportResponse = await fetch(attendanceReportURL, {
                method: 'GET',
                headers: headers
            });
            const reportData = await attendanceReportResponse.json();  //attendance reports response        
            if(reportData.value.length > 0){
            const attendanceRecordsURL = `https://graph.microsoft.com/v1.0/me/onlineMeetings/${event_id}/attendanceReports/${reportData.value[0].id}/attendanceRecords`;    
            const attendanceRecordsResponse = await fetch(attendanceRecordsURL, {
                    method: 'GET',
                    headers: headers
                });
                const recordsData =await attendanceRecordsResponse.json();  // attendance records response
                if(recordsData.value.length > 0){
                let attendance_records=[];
                for(let i=0;i<recordsData.value.length; i++){
                    let email = recordsData.value[i].emailAddress !== null
                    ? recordsData.value[i].emailAddress.replace(/_/g, '@').replace(/#.*$/, '')
                    : recordsData.value[i].emailAddress;
                    const records={                                    
                        name: recordsData.value[i].identity.displayName,
                        emailAddress: email,
                        joinDateTime: recordsData.value[i].attendanceIntervals[0].joinDateTime,
                        leaveDateTime: recordsData.value[i].attendanceIntervals[0].leaveDateTime,
                        duration: recordsData.value[i].totalAttendanceInSeconds
                    }                               
                    attendance_records.push(records);                                  
                }
                const reportsObject ={
                attendance_reports_id: reportData.value[0].id,
                meetingStartDateTime: reportData.value[0].meetingStartDateTime,
                meetingEndDateTime: reportData.value[0].meetingEndDateTime,
                totalParticipantCount: reportData.value[0].totalParticipantCount,
                attendance_records: attendance_records
                }  
                //Update the status Completed and store the attendance records response to the Mango DB
                 await axiosPrivateCall.patch(`/api/v1/interview/updateStatus/${obj._id}`, { status: 'Completed', online_meeting_completed_status: reportsObject }).then((res)=>{
                    console.log(res,'Interview Status Updated Successfully!')
                }).catch((err)=>{
                    console.log("error: ",err)
                }) 
              }  
            }                                          
        } catch (error) {                        
            return {error: error.message }; 
        }
    }));
}}
    getCompleteStatusData();
    useEffect(() => {
      if (interviewData && Array.isArray(interviewData.interview_schedule_details)) {
        const scheduled = [];
        const rescheduled = [];
        const completed = [];
        const reschedulededited = [];
        interviewData.interview_schedule_details.forEach(interview => {
          if (interview.status === 'Scheduled') {
            scheduled.push(interview);
          } else if (interview.status === 'Rescheduled') {
            rescheduled.push(interview);
          } else if (interview.status === 'Rescheduled & Edited') {
            reschedulededited.push(interview);
          } else if (interview.status === 'Completed' || interview.status === 'Cancelled') {
            completed.push(interview);
          }
        });
        setScheduledDetails(scheduled);
        setRescheduledDetails(rescheduled);
        setRescheduledEditedDetails(reschedulededited);
        setCompletedDetails(completed);
      }
    }, [interviewData]);
    const handleButtonBoxClickRescheduled = (index, interviewData, currentData, previousData, datas) => {
      setSelectedIndex(interviewData);
      setSelectedDetailIndex(index);
      setTitUpdated(currentData);
      setPrevData(previousData)
      setShowHiTextRescheduled(interviewData === showHiTextRescheduled ? null : interviewData);
      setShowHiText(null);
      setShowHiTextCompleted(null);
      setShowHiTextRescheduledEdited(null);
      setShowHiTextEdited(null);
      const scheduled = [];
      const rescheduled = [];
      const reschedulededited = [];
      if (interviewData && Array.isArray(interviewData.interview_schedule_details?._id)) {
        interviewData.interview_schedule_details._id.forEach(interview => {
          if (interview.status === 'Scheduled') {
            scheduled.push(interview);
          } else if (interview.status === 'Rescheduled') {
            rescheduled.push(interview);
          } else if (interview.status === 'Rescheduled & Edited') {
            reschedulededited.push(interview);
          }
        });
      }
      setRescheduledDetails(index);
      setRescheduledEditedDetails(datas);
    };
    const handleButtonBoxClickEdited = (index, interviewData, currentData, previousData, datas) => {
      setSelectedIndex(interviewData);
      setSelectedDetailIndex(index);
      setShowHiTextEdited(interviewData === showHiTextEdited ? null : interviewData);
      setTitUpdated(currentData);
      setPrevData(previousData)
      setShowHiText(null);
      setShowHiTextCompleted(null);
      setShowHiTextRescheduled(null);
      setShowHiTextRescheduledEdited(null);
      const scheduled = [];
      const rescheduled = [];
      const reschedulededited = [];
      setRescheduledDetails(index);
      setRescheduledEditedDetails(datas);
    };
    const handleButtonBoxClickRescheduledEdited = (index,interviewData, currentData,previousData, datas) => {
      setSelectedIndex(interviewData);
      setSelectedDetailIndex(index);
      setTitUpdated(currentData);
      setPrevData(previousData)
      setShowHiTextRescheduledEdited(interviewData === showHiTextRescheduledEdited ? null : interviewData);
      setShowHiText(null);
      setShowHiTextCompleted(null);
      setShowHiTextRescheduled(null);
      setShowHiTextEdited(null);
      const scheduled = [];
      const rescheduled = [];
      const reschedulededited = [];
      if (interviewData && Array.isArray(interviewData.interview_schedule_details?._id)) {
        interviewData.interview_schedule_details._id.forEach(interview => {
          if (interview.status === 'Scheduled') {
            scheduled.push(interview);
          } else if (interview.status === 'Rescheduled') {
            rescheduled.push(interview);
          } else if (interview.status === 'Rescheduled & Edited') {
            reschedulededited.push(interview);
          }
        });
      }
      setScheduledDetails(currentData);
      setRescheduledDetails(index);
      setRescheduledEditedDetails(datas);
    };
  const getTitleStyle = () => {
    if (prevData.title !== titUpdated.title) {
      return {
        color: 'green', 
      };
    } else {
      return {
        color: '#5B5F62', 
      };
    }
  }; 
  const getPrevDataStyle = () => {
    if (prevData.title !== titUpdated.title) {
      return {
        color: 'red',         
        textDecoration: 'line-through', 
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  };
  const getStartTimeDateStyle = () => {
    if (ISOdateToCustomDate(prevData.startDateTime) !== ISOdateToCustomDate(titUpdated.startDateTime)) {
      return {
        color: 'green', 
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  }; 
  const getPrevStartTimeDateStyle = () => {
    if (ISOdateToCustomDate(prevData.startDateTime)!== ISOdateToCustomDate(titUpdated.startDateTime)) {
      return {
        color: 'red',        
        textDecoration: 'line-through', 
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  };
  const getStartTimeStyle = () => {
    if (convertISOtoTime(prevData.startDateTime) !== convertISOtoTime(titUpdated.startDateTime)) {
      return {
        color: 'green', 
      };
    } else {
      return {
        color: '#5B5F62', 
      };
    }
  }; 
  const getPrevStartTimeStyle = () => {
    if (convertISOtoTime(prevData.startDateTime) !== convertISOtoTime(titUpdated.startDateTime)) {
      return {
        color: 'red',         
        textDecoration: 'line-through', 
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  };
  const getDurationStyle = () => {
    if (convertDuration(prevData.duration) !== convertDuration(titUpdated.duration)) {
      return {
        color: 'green',  
      };
    } else {
      return {
        color: '#5B5F62', 
      };
    }
  }; 
  const getPrevDurationStyle = () => {
    if (convertDuration(prevData.duration) !==convertDuration(titUpdated.duration)) {
      return {
        color: 'red',        
        textDecoration: 'line-through', 
      };
    } else {
      return {
        color: '#5B5F62', 
      };
    }
  };
  const getLevelStyle = () => {
    if (getLevelText(prevData.level) !== getLevelText(titUpdated.level)) {
      return {
        color: 'green', 
      };
    } else {
      return {
        color: '#5B5F62', 
      };
    }
  }; 
  const getPrevLevelStyle = () => {
    if (getLevelText(prevData.level) !== getLevelText(titUpdated.level)) {
      return {
        color: 'red',         
        textDecoration: 'line-through',  
      };
    } else {
      return {
        color: '#5B5F62', 
      };
    }
  };
  const getLevelTitleStyle = () => {
    if (capitalizeFirstLetter(prevData.level_title) !== capitalizeFirstLetter(titUpdated.level_title)) {
      return {
        color: 'green',  
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  }; 
  const getPrevLevelTitleStyle = () => {
    if (capitalizeFirstLetter(prevData.level_title) !== capitalizeFirstLetter(titUpdated.level_title)) {
      return {
        color: 'red',      
        textDecoration: 'line-through', 
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  };
  const getInterviewerStyle = (email) => {
    const foundInPrev = prevData.interviewer_email.find(item => item.key === email.key);
    const foundInUpdated = titUpdated.interviewer_email.find(item => item.key === email.key);
    if (!foundInPrev) {
      return {
        color: 'green',  
      };
    } else if (!foundInUpdated) {
      return {
        color: 'red',  
        textDecoration: 'line-through',
      };
    } else if (foundInPrev.name !== foundInUpdated.name) {
      return {
        color: 'green',  
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  };
  const getPrevInterviewerStyle = (email) => {
    const foundInPrev = prevData.interviewer_email.find(item => item.key === email.key);
    const foundInUpdated = titUpdated.interviewer_email.find(item => item.key === email.key);
    if (!foundInUpdated) {
      return {
        color: 'red', 
        textDecoration: 'line-through',
      };
    } else if (!foundInPrev) {
      return {
        color: 'red',  
        textDecoration: 'line-through',
      };
    } else {
      return {
        color: '#5B5F62',  
      };
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header_content_title_container}>
        Interview Tracker
      </div> 
      <div id="scrollableDiv" className={styles.table_container}>
      <InfiniteScroll 
          style={{ overflow: 'visible', height: '100%' }} 
          dataLength={interviewData?.length || 0} 
          loader={interviewData?.length >= 5 && <h4>Loading...</h4>} 
          scrollableTarget="scrollableDiv" >      
        {interviewData.length === 0 ? (
        <div className={styles.noDataContainer}>
          <img src={NoDataFound} alt="No Data Found" className={styles.nodatafoundimg}/>        
          </div>
      ) : (
        interviewData.map((item, indexRescheduled) =>  {
          const meetingStartDateTime = item.online_meeting_completed_status?.[0]?.meetingStartDateTime;
          const meetingEndDateTime = item.online_meeting_completed_status?.[0]?.meetingEndDateTime;
          const durationSeconds = item.online_meeting_completed_status?.[0]?.attendance_records?.[0]?.duration;
          const happeneddurationSeconds = meetingStartDateTime && meetingEndDateTime ? calculateDuration(meetingStartDateTime, meetingEndDateTime) : undefined;
          const createdBy = item.created_by || {};
          const fullName = `${capitalizeFirstLetter(createdBy.first_name || '-')} ${capitalizeFirstLetter(createdBy.last_name || '-')}`;
          const truncatedName = truncateText(fullName, 14);
          const cancelComments = item.interview_schedule_details.length > 0 
          ? (item.interview_schedule_details[item.interview_schedule_details.length - 1].cancel_comments || "-")
          : "-";
          const formattedCancelComments = formatTextWithLineBreaks(cancelComments, 90, 20);
          const latestInterviewDetails = item.interview_schedule_details[item.interview_schedule_details.length - 1];
          return (
          <div key={indexRescheduled} className={styles.rectangle}>
            <div className={styles.row}>
              <p className={styles.companyName}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")} ({capitalizeFirstLetter(getLevelText(item.level ? item.level : "-"))} - {capitalizeFirstLetter(item.level_title ? item.level_title : "-")})
              </p>
            </div>
            <div className={styles.row1}>
              <div className={styles.buttonBox}>
                <div className={styles.right}>
                  <p className={styles.demandIDText}>Demand ID</p>
                  <p className={styles.demandID}>{item.demand_id ? item.demand_id : "-"}</p>
                </div>
              </div>
              <div className={styles.arrowMark}>
                <p className={styles.updatedName}>Scheduled by</p>
                <img src={bigArrow} className={styles.arrowImage} alt="arrow" />
                <p className={styles.updatedName}>
                {capitalizeFirstLetter(item.interview_schedule_details[0]?.updated_by || '-')}
                </p>
                <p className={styles.updatedName}>                        
                  {item.interview_schedule_details[0]?.createdAt ? ISOdateToCustomDate(item.interview_schedule_details[0].createdAt) : '-'}
                </p>
              </div>
              <div className={`${styles.buttonBox1} ${showHiText === indexRescheduled ? styles.selected : ''}`}
                onClick={() => handleButtonBoxClick(indexRescheduled)}>
                   {item.interview_schedule_details[0].title.length > 14 && (
            <div className={styles.tooltip}>
            {item.interview_schedule_details[0].title}
            </div>
              )}
                <div className={styles.left}>
                  <FontIcon iconName="EventAccepted" className={styles.icon} />
                </div>
                <div className={styles.right}>
                  <p className={styles.approvedText1}>
                  <p className={styles.approvedText1}>{truncateText(capitalizeFirstLetter(
                  item.interview_schedule_details[0].title
                  ? (item.interview_schedule_details[0].title)
                  : "-"
        ))}
                  </p>   
                  </p>
                  <p className={styles.approvedText}>                     
                    On {item.interview_schedule_details[0]?.startDateTime ? ISOdateToCustomDate(item.interview_schedule_details[0].startDateTime) : '-'}
                  </p>
                  <p className={styles.approvedText}>
                  {item.interview_schedule_details[0]?.startDateTime ? convertISOtoTime(item.interview_schedule_details[0].startDateTime) : '-'} - {item.interview_schedule_details[0]?.startDateTime && item.interview_schedule_details[0]?.duration ? addDuration(item.interview_schedule_details[0].startDateTime, item.interview_schedule_details[0].duration) : '-'}
                  </p>
                </div>
              </div>
                {item.interview_schedule_details.map((detail, index,array) => {
                  const status = detail.status;
                  const previousData = index > 0 ? array[index - 1] : null; 
                  const isSelected = selectedIndex === indexRescheduled && selectedDetailIndex === index && showHiTextRescheduled === indexRescheduled;
                  const isSelected1 = selectedIndex === indexRescheduled && selectedDetailIndex === index && showHiTextEdited === indexRescheduled;       
                  const isSelected2 = selectedIndex === indexRescheduled && selectedDetailIndex === index && showHiTextRescheduledEdited === indexRescheduled;       
                  const renderRescheduled = (
    <>
      <div key={index} className={styles.arrowMark}>
        <p className={styles.updatedName}>Rescheduled By</p>
        <img src={bigArrow} className={styles.arrowImage} alt="arrow" />
        <p className={styles.updatedName}>{capitalizeFirstLetter(detail.updated_by || '-')}</p>
        <p className={styles.updatedName}>{detail.createdAt ? ISOdateToCustomDate(detail.createdAt) : '-'}</p>
      </div>
      <div className={`${styles.buttonBox1} ${isSelected ? styles.selected : ''}`}
     onClick={() => handleButtonBoxClickRescheduled(index, indexRescheduled, detail, previousData, item.interview_schedule_details[0])}>
  {detail.title.length > 14 && (
    <div className={styles.tooltip}>
      {detail.title}
    </div>
  )}
  <div className={styles.left}>
    <FontIcon iconName="ForwardEvent" className={styles.icon} />
  </div>
  <div className={styles.right}>
    <p className={styles.approvedText1}>{truncateText(capitalizeFirstLetter(detail.title ? detail.title : "-"))}</p>
    <p className={styles.approvedText}>On {ISOdateToCustomDate(detail.startDateTime)}</p>
    <p className={styles.approvedText}>
      {convertISOtoTime(detail.startDateTime)} - {addDuration(detail.startDateTime, detail.duration)}
    </p>
  </div>
</div>
    </>);
    const renderEdited = (
    <>
      <div key={index} className={styles.arrowMark}>
        <p className={styles.updatedName}>Edited By</p>
        <img src={bigArrow} className={styles.arrowImage} alt="arrow" />
        <p className={styles.updatedName}>{capitalizeFirstLetter(detail.updated_by || '-')}</p>
      </div>
      <div className={`${styles.buttonBox1} ${isSelected1 ? styles.selected : ''}`}
      onClick={() => handleButtonBoxClickEdited(index,indexRescheduled,detail,previousData, item.interview_schedule_details[0])}
      >
          <div className={styles.left}>
          <FontIcon iconName="Edit" className={styles.icon} />
        </div>
        <div className={styles.right}>
          <p className={styles.approvedText2}>Edited</p>
          <p className={styles.approvedText2}>On {detail.createdAt ? ISOdateToCustomDate(detail.createdAt) : '-'}</p>
          <p className={styles.approvedText2}>{detail.createdAt ? convertISOtoTime1(detail.createdAt) : "-"}</p>
        </div>
      </div>
    </>);
    const renderRescheduledEdited = (
    <>
      <div key={index} className={styles.arrowMark}>
      <div className={styles.updatedNameWrapper}>
  <p className={styles.updatedName}>
    {truncateText("Rescheduled & Edited By")}
  </p>
  <div className={styles.tooltip}>
    Rescheduled & Edited By
  </div>
</div>
 <img src={bigArrow} className={styles.arrowImage} style={{ width: '115px' }} alt="arrow" />
        <p className={styles.updatedName}>{capitalizeFirstLetter(detail.updated_by || '-')}</p>
        <p className={styles.updatedName}>{detail.createdAt ? ISOdateToCustomDate(detail.createdAt) : '-'}</p>
      </div>
      <div className={`${styles.buttonBox1} ${isSelected2 ? styles.selected : ''}`}
        onClick={() => handleButtonBoxClickRescheduledEdited(index,indexRescheduled,detail,previousData, item.interview_schedule_details[0])}
      >
        {detail.title.length > 14 && (
    <div className={styles.tooltip}>
      {capitalizeFirstLetter(detail.title)}
    </div>
  )}
        <div className={styles.left}>
          <FontIcon iconName="PublicCalendar" className={styles.icon3} />
        </div>
        <div className={styles.right}>
          <p className={styles.approvedText1}>{truncateText(capitalizeFirstLetter(detail.title ? detail.title : "-"))}</p>
          <p className={styles.approvedText}>On {ISOdateToCustomDate(detail.startDateTime)}</p>
          <p className={styles.approvedText}>
            {convertISOtoTime(detail.startDateTime)} - {addDuration(detail.startDateTime, detail.duration)}
          </p>
        </div>
      </div>
    </>
                  );
  if (status === "Rescheduled") {
    return renderRescheduled;
  } else if (status === "Edited") {
    return renderEdited;
  } else if (status === "Rescheduled & Edited") {
    return renderRescheduledEdited;
  } else {
    return null; 
  }})}
          {item.interview_schedule_details.length > 0 && 
          (item.status === "Completed" ||
          item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled") && (
          <>
          <div key={item.interview_schedule_details[item.interview_schedule_details.length - 1]._id} className={styles.arrowMark}>
          <p className={styles.updatedName}>
          {item.status === "Completed" 
          ? "Progress" 
          : item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" 
          ? "Cancelled by" 
          : ""}
        </p>
      <img src={bigArrow} className={styles.arrowImage} alt="arrow" />
      {item.status !== "Completed" && (
  <p className={styles.updatedName}>
    {capitalizeFirstLetter(item.interview_schedule_details[item.interview_schedule_details.length - 1].updated_by)}
  </p>
)}
    </div>
    <div className={`${styles.buttonBox1} ${showHiTextCompleted === indexRescheduled ? styles.selected : ''}`} onClick={() => handleButtonBoxClickCompleted(indexRescheduled)}>
      <div className={styles.left}>
        {item.status === "Completed" && <FontIcon iconName="Accept" className={styles.icon2} />}
        {item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" && <FontIcon iconName="Cancel" className={styles.icon1} />}
      </div>
      <div className={styles.right}>
        <p className={styles.approvedText}>
          {item.status}
        </p>
        <p className={styles.approvedText}>On {ISOdateToCustomDate(item.interview_schedule_details[item.interview_schedule_details.length - 1].createdAt)}</p>
        <p className={styles.approvedText}>
        {convertISOtoTime1(item.interview_schedule_details[item.interview_schedule_details.length - 1].createdAt)}
        </p>
      </div>
    </div>
    </>
    )}
        </div>
            {showHiText === indexRescheduled && (
     <div className={styles.container1}>
     <div className={styles.columnS}><strong>Scheduled Details</strong></div>
     <div className={styles.table1}>
       <div className={styles.rowCD}>
         <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}><p><strong className={styles.details}>Client Details</strong></p></div>
         <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%'}}><p><strong className={styles.details}>Organizer Details</strong></p></div>
         <div className={styles.column} style={{ width: '33%' }}><p><strong className={styles.details}>Interview Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
         <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNested} style={{ width: '11%' }}>
                <p><strong>Client Name</strong></p>  
            <div className={styles.tooltipWrapper}>
  {item.company_name.length > 14 && (
    <div className={styles.tooltip}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")}
              </div>
  )}
  <p>
  {truncateText(capitalizeAllLetters(item.company_name ? item.company_name : "-"))}
  </p>
</div>
            </div>
             <div className={styles.columnNested} style={{ width: '11%' }}><p><strong>Requirement</strong></p>
             <div className={styles.tooltipWrapper}>
  {result.demand.job_title.length > 14 && (
    <div className={styles.tooltip}>
      {result.demand.job_title}
    </div>
  )}
  <p>
    {truncateText(capitalizeFirstLetter(result.demand.job_title ? result.demand.job_title : "-"))}
  </p>
</div>
            </div>
             <div className={`${styles.columnNested} ${styles.noRightBorder}`} style={{ width: '11%' }}><p><strong>Requirement date</strong></p><p>{ISOdateToCustomDate(result.demand.due_date)}</p></div>
           </div>
         </div>
         <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}>
           <div className={styles.columnDetails}>
             <div className={styles.columnNested} style={{ width: '11%' }}><p><strong>Organizer</strong></p>
             <div className={styles.tooltipWrapper}>
  {fullName.length > 14 && (
    <div className={styles.tooltip}>
                {capitalizeFirstLetter(item.interview_schedule_details[0]?.updated_by || '-')}
                </div>
  )}
  <p>
  {truncatedName}
  </p>
</div>  
             </div>
             <div className={styles.columnNested} style={{ width: '11%' }}><p><strong>Email</strong></p>
              <div className={styles.tooltipWrapper}>
  {item.created_by.email.length > 14 && (
    <div className={styles.tooltip}>
              {item.created_by.email ? item.created_by.email : "-"}
              </div>
  )}
  <p>
  {truncateText(item.created_by.email ? item.created_by.email : "-")}
  </p>
</div>             
                </div>
             <div className={`${styles.columnNested} ${styles.noRightBorder}`} style={{ width: '11%' }}><p><strong>Mobile</strong></p>
             <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}
            </p>               
            </div>
           </div>
         </div>
         <div className={styles.column} style={{ width: '33%' }}>
           <div className={styles.columnDetails}>
             <div className={styles.columnNested} style={{ width: '11%' }}><p><strong>Interview Date</strong></p><p>{ISOdateToCustomDate(item.startDateTime)}</p></div>
             <div className={styles.columnNested} style={{ width: '11%' }}><p><strong>Start Time</strong></p><p>{item.interview_schedule_details[0]?.startDateTime ? convertISOtoTime(item.interview_schedule_details[0].startDateTime) : '-'}</p></div>
             <div className={`${styles.columnNested} ${styles.noRightBorder}`} style={{ width: '11%' }}>
            <p><strong>Scheduled Duration</strong></p>
            <p>{convertDuration(item.interview_schedule_details[0]?.duration)}</p>
            </div>
           </div>
         </div>
       </div>
     </div>
     <div className={styles.table11}>
  <h3 className={styles.rowAttendees}><strong className={styles.details}>Attendees</strong></h3>
  <div className={styles.rowP}>
    <div className={`${styles.column} ${styles.columnParticipants}`}>
      <p><strong>Participants</strong></p>
      <p>{`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)}`}</p>
      <p>{`${capitalizeFirstLetter(item.candidate_name || '-')}`}</p>
      {item.interviewer_email && item.interviewer_email.length > 0 ? (
        item.interviewer_email.map((interviewer, index) => (
          <p key={index}>{capitalizeFirstLetter(interviewer.key)}</p>
        ))
      ) : (
        <p>-</p>
      )}
    </div>
    <div className={`${styles.column} ${styles.columnParticipants}`}>
      <p><strong>Role</strong></p>
      <p>{item.created_by.role ? getRoleText(item.created_by.role) : "-"}</p>
      <p>Candidate</p>
      {item.interviewer_email && item.interviewer_email.length > 0 ? (
        item.interviewer_email.map((interviewer, index) => (
          <p>Interviewer</p>
        ))
      ) : (
        <p>-</p>
      )}
    </div>
    <div className={`${styles.column} ${styles.columnParticipants}`}>
      <p><strong>Email</strong></p>
      <p>{item.created_by.email ? item.created_by.email : "-"}</p>
      <p>{item.candidate_email ? item.candidate_email : "-"}</p>
      {item.interviewer_email && item.interviewer_email.length > 0 ? (
        item.interviewer_email.map((interviewer, index) => (
          <p key={index}>{interviewer.name ? interviewer.name : "-"}</p>
        ))
      ) : (
        <p>-</p>
      )}
    </div>
    <div className={`${styles.column} ${styles.columnParticipants}`}>
      <p><strong>Mobile</strong></p>
      <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}</p>
      <p>{item.mobile_no ? item.mobile_no : "-"}</p>
      {item.interviewer_email && item.interviewer_email.length > 0 ? (
        item.interviewer_email.map((interviewer, index) => (
          <p>-</p>
        ))
      ) : (
        <p>-</p>
      )}    
      </div>
    <div className={styles.columnL}>
      <p><strong>Location</strong></p>
      <p>{item.created_by.location ? item.created_by.location : "-"}</p>
      <p>{result.candidate.current_location ? result.candidate.current_location : "-"}</p>
      {item.interviewer_email && item.interviewer_email.length > 0 ? (
        item.interviewer_email.map((interviewer, index) => (
          <p>-</p>
        ))
      ) : (
        <p>-</p>
      )}      </div>
  </div>
</div>
</div>        
            )}
        {showHiTextCompleted === indexRescheduled && (
  <div className={styles.container1}>
    <div className={styles.columnS}>
      <strong>
        {item.status === "Completed" ? "Completed Details" : 
          (item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" ? "Cancelled Details" : "")}
      </strong>
    </div>
    {item.status === "Completed" && (
    <div className={styles.table1Completed}>
      <div className={styles.rowCD1}>
      <div className={`${styles.column} ${styles.rightBorder1}`}>
      <p><strong className={styles.details}>Client Details</strong></p>
        </div>
        <div className={`${styles.column} ${styles.rightBorder2}`}>
          <p><strong className={styles.details}>Organizer Details</strong></p>
        </div>
        <div className={`${styles.column} ${styles.move_left}`}>
        <p><strong className={styles.details}>Interview Details</strong></p>
        </div>
      </div>
      <div className={styles.rowCN}>
        <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '10%' }}>
          <div className={styles.columnDetails1}>
            <div className={styles.columnNested}>
              <p><strong>Client Name</strong></p>
              <div className={styles.tooltipWrapper}>
  {item.company_name.length > 14 && (
    <div className={styles.tooltip}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")}
              </div>
  )}
  <p>
  {truncateText(capitalizeAllLetters(item.company_name ? item.company_name : "-"))}
  </p>
</div>
            </div>
            <div className={styles.columnNested}>
              <p><strong>Requirement</strong></p>
              <div className={styles.tooltipWrapper}>
  {result.demand.job_title.length > 14 && (
    <div className={styles.tooltip}>
      {result.demand.job_title}
    </div>
  )}
  <p>
    {truncateText(capitalizeFirstLetter(result.demand.job_title ? result.demand.job_title : "-"))}
  </p>
</div>
            </div>
            <div className={`${styles.columnNested} ${styles.noRightBorder}`}>
              <p><strong>Requirement date</strong></p>
              <p>{ISOdateToCustomDate(result.demand.due_date)}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '10%' }}>
          <div className={styles.columnDetails1}>
            <div className={styles.columnNested}>
              <p><strong>Organizer</strong></p>
              <div className={styles.tooltipWrapper}>
  {fullName.length > 14 && (
    <div className={styles.tooltip}>
              {fullName}
              </div>
  )}
  <p>
  {truncatedName}
  </p>
</div>  
            </div>
            <div className={styles.columnNested}>
              <p><strong>Email</strong></p>
              <div className={styles.tooltipWrapper}>
  {item.created_by.email.length > 14 && (
    <div className={styles.tooltip}>
              {item.created_by.email ? item.created_by.email : "-"}
              </div>
  )}
  <p>
  {truncateText(item.created_by.email ? item.created_by.email : "-")}
  </p>
</div>
            </div>
            <div className={`${styles.columnNested} ${styles.noRightBorder}`}>
              <p><strong>Mobile</strong></p>
              <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}</p>
            </div>
          </div>
        </div>
        <div className={styles.column} style={{ width: '40%' }}>
          <div className={styles.columnDetails}>
            <div className={styles.columnNested}>
              <p><strong>Interview Date</strong></p>
              <p>{ISOdateToCustomDate(item.startDateTime)}</p>
            </div>
            <div className={styles.columnNested}>
              <p><strong>Start Time</strong></p>
              <p>{item.interview_schedule_details[0]?.startDateTime ? convertISOtoTime(item.interview_schedule_details[0].startDateTime) : '-'}</p>
            </div>
            <div className={item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" ? `${styles.columnNested} ${styles.noRightBorder}` : styles.columnNested}>
              <p><strong>Scheduled Duration</strong></p>
              <p>{convertDuration(item.interview_schedule_details[0]?.duration)}</p>
            </div>
            {item.status === "Completed" && (
              <div className={`${styles.columnNested} ${styles.noRightBorder}`}>
                <p><strong>Happened Duration</strong></p>
                {happeneddurationSeconds !== undefined && (
                  <p>{secondsToMinutes(durationSeconds)}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    )}
    {item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" && (
    <div className={styles.table1Completed}>
      <div className={styles.rowCD}>
        <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}>
          <p><strong className={styles.details}>Client Details</strong></p>
        </div>
        <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}>
          <p><strong className={styles.details}>Organizer Details</strong></p>
        </div>
        <div className={styles.column} style={{ width: '33%' }}>
          <p><strong className={styles.details}>Interview Details</strong></p>
        </div>
      </div>
      <div className={styles.rowCN}>
        <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}>
          <div className={styles.columnDetails}>
            <div className={styles.columnNested}>
              <p><strong>Client Name</strong></p>
              <div className={styles.tooltipWrapper}>
  {item.company_name.length > 14 && (
    <div className={styles.tooltip}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")}
              </div>
  )}
  <p>
  {truncateText(capitalizeAllLetters(item.company_name ? item.company_name : "-"))}
  </p>
</div>
            </div>
            <div className={styles.columnNested}>
              <p><strong>Requirement</strong></p>
              <div className={styles.tooltipWrapper}>
  {result.demand.job_title.length > 14 && (
    <div className={styles.tooltip}>
      {result.demand.job_title}
    </div>
  )}
  <p>
    {truncateText(capitalizeFirstLetter(result.demand.job_title ? result.demand.job_title : "-"))}
  </p>
</div>
            </div>
            <div className={`${styles.columnNested} ${styles.noRightBorder}`}>
              <p><strong>Requirement date</strong></p>
              <p>{ISOdateToCustomDate(result.demand.due_date)}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '33%' }}>
          <div className={styles.columnDetails}>
            <div className={styles.columnNested}>
              <p><strong>Organizer</strong></p>
              <div className={styles.tooltipWrapper}>
  {fullName.length > 14 && (
    <div className={styles.tooltip}>
              {fullName}
              </div>
  )}
  <p>
  {truncatedName}
  </p>
</div>  
            </div>
            <div className={styles.columnNested}>
              <p><strong>Email</strong></p>
              <div className={styles.tooltipWrapper}>
  {item.created_by.email.length > 14 && (
    <div className={styles.tooltip}>
              {item.created_by.email ? item.created_by.email : "-"}
              </div>
  )}
  <p>
  {truncateText(item.created_by.email ? item.created_by.email : "-")}
  </p>
</div>
            </div>
            <div className={`${styles.columnNested} ${styles.noRightBorder}`}>
              <p><strong>Mobile</strong></p>
              <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}</p>
            </div>
          </div>
        </div>
        <div className={styles.column} style={{ width: '33%' }}>
          <div className={styles.columnDetails}>
            <div className={styles.columnNested}>
              <p><strong>Interview Date</strong></p>
              <p>{ISOdateToCustomDate(item.startDateTime)}</p>
            </div>
            <div className={styles.columnNested}>
              <p><strong>Start Time</strong></p>
              <p>{item.interview_schedule_details[0]?.startDateTime ? convertISOtoTime(item.interview_schedule_details[0].startDateTime) : '-'}</p>
            </div>
            <div className={item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" ? `${styles.columnNested} ${styles.noRightBorder}` : styles.columnNested}>
              <p><strong>Scheduled Duration</strong></p>
              <p>{convertDuration(item.interview_schedule_details[0]?.duration)}</p>
            </div>
            {item.status === "Completed" && (
              <div className={`${styles.columnNested} ${styles.noRightBorder}`}>
                <p><strong>Happened Duration</strong></p>
                {happeneddurationSeconds !== undefined && (
                  <p>{secondsToMinutes(durationSeconds)}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    )}
    <div className={styles.attendesswholecontainer}>
      <div className={styles.table11Completed} style={{ width: item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" ? '55%' : '106.5%' }}>
        <h3 className={styles.rowAttendees}><strong className={styles.details}>Attendees</strong></h3>
        <div className={styles.rowP}>
          <div className={`${styles.column} ${styles.columnParticipants}`}>
            <p><strong>Participants</strong></p>
            <p>{`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)}`}</p>
            <p>{`${capitalizeFirstLetter(item.candidate_name || '-')}`}</p>
            {latestInterviewDetails.interviewer_email && latestInterviewDetails.interviewer_email.length > 0 ? (
              latestInterviewDetails.interviewer_email.map((interviewer, index) => (
                <p key={index}>{capitalizeFirstLetter(interviewer.key)}</p>
              ))
            ) : (
              <p>-</p>
            )}
          </div>
          <div className={`${styles.column} ${styles.columnParticipants}`}>
            <p><strong>Role</strong></p>
            <p>{item.created_by.role ? getRoleText(item.created_by.role) : "-"}</p>
            <p>Candidate</p>
            {latestInterviewDetails.interviewer_email && latestInterviewDetails.interviewer_email.length > 0 ? (
              latestInterviewDetails.interviewer_email.map((interviewer, index) => (
                <p key={index}>Interviewer</p>
              ))
            ) : (
              <p>-</p>
            )}
          </div>
          <div className={`${styles.column} ${styles.columnParticipants}`}>
            <p><strong>Email</strong></p>
            <p>{item.created_by.email ? item.created_by.email : "-"}</p>
            <p>{item.candidate_email ? item.candidate_email : "-"}</p>
            {latestInterviewDetails.interviewer_email && latestInterviewDetails.interviewer_email.length > 0 ? (
              latestInterviewDetails.interviewer_email.map((interviewer, index) => (
                <p key={index}>{capitalizeFirstLetter(interviewer.name)}</p>
              ))
            ) : (
              <p>-</p>
            )}
          </div>
          <div className={`${styles.column} ${styles.columnParticipants}`}>
            <p><strong>Mobile</strong></p>
            <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}</p>
            <p>{item.mobile_no ? item.mobile_no : "-"}</p>
            {latestInterviewDetails.interviewer_email && latestInterviewDetails.interviewer_email.length > 0 ? (
              latestInterviewDetails.interviewer_email.map((interviewer, index) => (
                <p key={index}>-</p>
              ))
            ) : (
              <p>-</p>
            )}
          </div>
          <div className={item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" ? `${styles.columnL}` : `${styles.column} ${styles.columnParticipants}`}>
            <p><strong>Location</strong></p>
            <p>{item.created_by.location ? item.created_by.location : "-"}</p>
            <p>{result.candidate.current_location ? result.candidate.current_location : "-"}</p>
            {latestInterviewDetails.interviewer_email && latestInterviewDetails.interviewer_email.length > 0 ? (
              latestInterviewDetails.interviewer_email.map((interviewer, index) => (
                <p key={index}>-</p>
              ))
            ) : (
              <p>-</p>
            )}
          </div>
          {item.status === "Completed" && (
            <>
              <div className={`${styles.column} ${styles.columnParticipants}`}>
                <p><strong>Join Time</strong></p>
                {[
                  { key: "created_by.first_name", email: item.created_by.email },
                  { key: "candidate_name", email: item.candidate_email },
                  ...latestInterviewDetails.interviewer_email.map(interviewer => ({ key: interviewer.key, email: interviewer.name }))
                ].map(({ key, email }, index) => {
                  const record = item.online_meeting_completed_status[0].attendance_records.find(rec => {
                    return rec.emailAddress === email || (rec.emailAddress === null && key === "candidate_name");
                  });
                  return <p key={index}>{record ? convertISOtoTime(record.joinDateTime) : "-"}</p>;
                })}
              </div>
              <div className={`${styles.column} ${styles.columnParticipants}`}>
                <p><strong>Leave Time</strong></p>
                {[
                  { key: "created_by.first_name", email: item.created_by.email },
                  { key: "candidate_name", email: item.candidate_email },
                  ...latestInterviewDetails.interviewer_email.map(interviewer => ({ key: interviewer.key, email: interviewer.name }))
                ].map(({ key, email }, index) => {
                  const record = item.online_meeting_completed_status[0].attendance_records.find(rec => {
                    return rec.emailAddress === email || (rec.emailAddress === null && key === "candidate_name");
                  });
                  return <p key={index}>{record ? convertISOtoTime(record.leaveDateTime) : "-"}</p>;
                })}
              </div>
              <div className={`${styles.column} ${styles.columnParticipants}`}>
                <p><strong>Duration</strong></p>
                {[
                  { key: "created_by.first_name", email: item.created_by.email },
                  { key: "candidate_name", email: item.candidate_email },
                  ...latestInterviewDetails.interviewer_email.map(interviewer => ({ key: interviewer.key, email: interviewer.name }))
                ].map(({ key, email }, index) => {
                  const record = item.online_meeting_completed_status[0].attendance_records.find(rec => {
                    return rec.emailAddress === email || (rec.emailAddress === null && key === "candidate_name");
                  });
                  return <p key={index}>{record ? secondsToMinutes(record.duration) : "-"}</p>;
                })}
              </div>
              <div className={styles.columnL}>
                <p><strong>Status</strong></p>
                {[
                  { key: "created_by.first_name", email: item.created_by.email },
                  { key: "candidate_name", email: item.candidate_email },
                  ...latestInterviewDetails.interviewer_email.map(interviewer => ({ key: interviewer.key, email: interviewer.name }))
                ].map(({ key, email }, index) => {
                  const record = item.online_meeting_completed_status[0].attendance_records.find(rec => {
                    return rec.emailAddress === email || (rec.emailAddress === null && key === "candidate_name");
                  });
                  return (
                    <p key={index} className={record && record.duration > 0 ? styles.icon2Completed : styles.icon1Completed}>
                      {record && record.duration > 0 ? 'Joined' : 'Not Joined'}
                    </p>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      {item.interview_schedule_details[item.interview_schedule_details.length - 1].status === "Cancelled" && (
        <div className={styles.leftCancelled}>
          <div className={styles.table11Cancelled}>
            <h3 className={styles.rowAttendees}><strong className={styles.details}>Cancelled Feedback</strong></h3>
            <br />
            <div className={styles.columnCancelled}>
              {formattedCancelComments.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
)}
        {showHiTextRescheduledEdited === indexRescheduled && (
     <div className={styles.container1}>
     <div className={styles.columnS}> <strong>
        {titUpdated.status === "Edited" 
          ? "Edited Details" 
          : titUpdated.status === "Rescheduled & Edited" 
          ? "Rescheduled & Edited Details" 
          : "Rescheduled Details"}
      </strong></div>
     <div className={styles.table_container1}>
     <div className={styles.table1Rescheduled}>
     <div className={styles.rowCDRescheduled}>
     <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '30%' }}><p><strong className={styles.details}><span className={styles.status_inactive}></span>Previous Data</strong></p></div>
     <div className={styles.column} style={{ width: '30%'}}><p><strong className={styles.details}><span className={styles.status}></span>Updated Data</strong></p></div>
    </div>
    <div className={styles.rowCN}>
         <div className={styles.column} style={{ width: '30%'}}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNestedCD}>
                <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Interview Title</span>
                                <TextField
          type="text"
          name="title"
          value={prevData.title || "-"}
          style={getPrevDataStyle()}          
          styles={textFieldColored}
          readOnly
        />
                                 </div>
                                 <div className={styles.sub_container}>
                                 <span className={styles.sub_title} >Start Time</span>
                                <div className={styles.start_time}>
  <TextField name="start_date"
  styles={textFieldColored1}
  value={ISOdateToCustomDate(prevData.startDateTime)}
  style={getPrevStartTimeDateStyle()}
  readOnly
/>
  <TextField
  name="start_time"
  styles={textFieldColored1}
  value={convertISOtoTime(prevData.startDateTime)}
  style={getPrevStartTimeStyle()}
  readOnly
/>
                                </div> 
                                </div>
                                <div className={styles.sub_container1}>
                            <span className={styles.sub_title1} style={{ marginRight: '42px' }}>Duration</span>
                            <span className={styles.sub_title12} >Level</span>
                            <span className={styles.sub_title13} style={{ marginLeft: '76px' }}>Level Title</span> 
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
  <TextField
  name="duration"
                                    styles={textFieldColored2}
                                    value={convertDuration(prevData.duration)}
                                    style={getPrevDurationStyle()}
  readOnly
/>
                            </div>
                            <div className={styles.sub_title1}>
  <TextField 
  value={getLevelText(prevData.level)}
  style={getPrevLevelStyle()}
  styles={textFieldColored2} />
                            </div>
                <div className={styles.sub_title1}>
                    <TextField type="text"
                        name="level_title" 
                        value={capitalizeFirstLetter(prevData.level_title)}
                        style={getPrevLevelTitleStyle()}
                        styles={textFieldColored2} />
                </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Participants</span>
                                <div className={styles.participant_list}>
                                <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Recruiter
                                        </div>
                                        <div className={styles.participantMail}>
                                        <span><div className={styles.participantNameValue}>- {`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)} (${item.created_by.email ? item.created_by.email : "-"})`}
                                        </div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Candidate
                                        </div>
                                        <div className={styles.participantMail}>
                                          <span ><div className={styles.participantNameValue}>- {`${`${capitalizeFirstLetter(item.candidate_name || '-')}`} (${item.candidate_email ? item.candidate_email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                        Interviewer
                                        </div>
                                        <div className={styles.participantMail}>
  {prevData.interviewer_email && prevData.interviewer_email.length > 0 ? (
    prevData.interviewer_email.map((interviewer, index) => (
      <span key={index}>
        <div className={styles.participantNameValue} style={getPrevInterviewerStyle(interviewer)}>
          - {capitalizeFirstLetter(interviewer.key ? interviewer.key : "-")} ({interviewer.name ? interviewer.name : "-"})
        </div>
      </span>
    ))
  ) : (
    <div className={styles.participantNameValue}>-</div>
  )}
</div>
                                    </div>
                                </div>
                            </div>           
                             </div>   
         <div className={`${styles.columnNestedCD} ${styles.noRightBorder}`} style={{ width: '30%'}}>               
         <div className={styles.sub_container}>
                <span className={styles.sub_title}>Interview Title</span>
                <TextField
          type="text"
          name="title"
          value={titUpdated.title || "-"}
          style={getTitleStyle()}          
          styles={textFieldColored}
          readOnly
        />
                                 </div>
                                 <div className={styles.sub_container}>
                                 <span className={styles.sub_title} >Start Time</span>
                                <div className={styles.start_time}>
                                  <TextField styles={textFieldColored1}
                                    value = {ISOdateToCustomDate(titUpdated.startDateTime)}
                                    style={getStartTimeDateStyle()}
                                    readOnly />
                                  <TextField styles={textFieldColored1}
                                    value = {convertISOtoTime(titUpdated.startDateTime)}
                                    style={getStartTimeStyle()}
                                    readOnly />
                                </div> 
                                </div>
                                <div className={styles.sub_container1}>
                                <span className={styles.sub_title1} style={{ marginRight: '42px' }}>Duration</span>
                            <span className={styles.sub_title12} >Level</span>
                            <span className={styles.sub_title13} style={{ marginLeft: '76px' }}>Level Title</span>
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
                                  <TextField styles={textFieldColored2} 
                                            style={getDurationStyle()}
                                            value={convertDuration(titUpdated.duration)} />
                            </div>
                            <div className={styles.sub_title1}>
                            <TextField name="level" styles={textFieldColored2} 
                                      style={getLevelStyle()}
                                      value={getLevelText(titUpdated.level)}/>
                            </div>
                <div className={styles.sub_title1}>
                    <TextField
                        type="text"
                                    name="level_title"
                                    style={getLevelTitleStyle()}
                                    value={capitalizeFirstLetter(titUpdated.level_title)}
                        styles={textFieldColored2} />
                </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Participants</span>
                                <div className={styles.participant_list}>
                                <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Recruiter
                                        </div>
                                        <div className={styles.participantMail}>
                                            <span ><div className={styles.participantNameValue}>- {`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)} (${item.created_by.email ? item.created_by.email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                        Candidate
                                        </div>
                                        <div className={styles.participantMail}>
                                          <span ><div className={styles.participantNameValue}>- {`${`${capitalizeFirstLetter(item.candidate_name || '-')}`} (${item.candidate_email ? item.candidate_email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Interviewer
                                        </div>
                                        <div className={styles.participantMail}>
  {titUpdated.interviewer_email && titUpdated.interviewer_email.length > 0 ? (
    titUpdated.interviewer_email.map((interviewer, index) => (
      <span key={index}>
        <div className={styles.participantNameValue} style={getInterviewerStyle(interviewer)}>
          - {capitalizeFirstLetter(interviewer.key ? interviewer.key : "-")} ({interviewer.name ? interviewer.name : "-"})
        </div>
      </span>
    ))
  ) : (
    <div className={styles.participantNameValue}>-</div>
  )}
</div>
            </div>
             </div>
             </div>               
             </div>
              </div>
              </div>
              </div>
     </div>
     <div className={styles.ClientDetails}>
     <div className={styles.table1Rescheduled1}>
     <div className={styles.rowCD}>
         <div className={styles.column} style={{ width: '60%' }}><p><strong className={styles.details}>Client Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
         <div className={styles.column} style={{ width: '60%' }}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNested} style={{ width: '20%' }}>
                <p><strong>Client Name</strong></p>
                <div className={styles.tooltipWrapper}>
  {item.company_name.length > 14 && (
    <div className={styles.tooltip}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")}
              </div>
  )}
  <p>
  {truncateText(capitalizeAllLetters(item.company_name ? item.company_name : "-"))}
  </p>
</div>   
            </div>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Requirement</strong></p>
             <div className={styles.tooltipWrapper}>
  {result.demand.job_title.length > 14 && (
    <div className={styles.tooltip}>
      {result.demand.job_title}
    </div>
  )}
  <p>
    {truncateText(capitalizeFirstLetter(result.demand.job_title ? result.demand.job_title : "-"))}
  </p>
</div>  
            </div>
             <div className={styles.columnNestedO} style={{ width: '20%' }}><p><strong>Requirement date</strong></p><p>{ISOdateToCustomDate(result.demand.due_date)}</p></div>
           </div>
         </div>
         </div>
    </div>
    <br/>
    <div className={styles.table1Rescheduled1}>
     <div className={styles.rowCD}>
         <div className={styles.column} style={{ width: '60%' }}><p><strong className={styles.details}>Organizer Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
       <div className={styles.column} style={{ width: '60%' }}>
           <div className={styles.columnDetails}>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Organizer</strong></p>
              <div className={styles.tooltipWrapper}>
  {fullName.length > 14 && (
    <div className={styles.tooltip}>
              {fullName}
              </div>
  )}
  <p>
  {truncatedName}
  </p>
</div>  
             </div>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Email</strong></p>
             <div className={styles.tooltipWrapper}>
  {item.created_by.email.length > 14 && (
    <div className={styles.tooltip}>
              {item.created_by.email ? item.created_by.email : "-"}
              </div>
  )}
  <p>
  {truncateText(item.created_by.email ? item.created_by.email : "-")}
  </p>
</div>              
                </div>
             <div  className={styles.columnNestedO} style={{ width: '20%' }}><p><strong>Mobile</strong></p>
             <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}
            </p>               
            </div>
           </div>
         </div>
         </div>
    </div>
    </div>
    </div>
        </div>        
          )}
           {showHiTextRescheduled === indexRescheduled && (
     <div className={styles.container1}>
     <div className={styles.columnS}> <strong>
        {titUpdated.status === "Edited" 
          ? "Edited Details" 
          : titUpdated.status === "Rescheduled & Edited" 
          ? "Rescheduled & Edited Details" 
          : "Rescheduled Details"}
      </strong></div>
     <div className={styles.table_container1}>
     <div className={styles.table1Rescheduled}>
     <div className={styles.rowCDRescheduled}>
     <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '30%' }}><p><strong className={styles.details}><span className={styles.status_inactive}></span>Previous Data</strong></p></div>
     <div className={styles.column} style={{ width: '30%'}}><p><strong className={styles.details}><span className={styles.status}></span>Updated Data</strong></p></div>
    </div>
    <div className={styles.rowCN}>
         <div className={styles.column} style={{ width: '30%'}}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNestedCD}>
                <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Interview Title</span>
                                <TextField
          type="text"
          name="title"
          value={prevData.title || "-"}
          style={getPrevDataStyle()}          
          styles={textFieldColored}
          readOnly
        />
                                 </div>
                                 <div className={styles.sub_container}>
                                 <span className={styles.sub_title} >Start Time</span>
                                <div className={styles.start_time}>
  <TextField name="start_date"
  styles={textFieldColored1}
  value={ISOdateToCustomDate(prevData.startDateTime)}
  style={getPrevStartTimeDateStyle()}
  readOnly
/>
  <TextField
  name="start_time"
  styles={textFieldColored1}
  value={convertISOtoTime(prevData.startDateTime)}
  style={getPrevStartTimeStyle()}
  readOnly
/>
                                </div> 
                                </div>
                                <div className={styles.sub_container1}>
                            <span className={styles.sub_title1} style={{ marginRight: '42px' }}>Duration</span>
                            <span className={styles.sub_title12} >Level</span>
                            <span className={styles.sub_title13} style={{ marginLeft: '76px' }}>Level Title</span> 
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
  <TextField
  name="duration"
                                    styles={textFieldColored2}
                                    value={convertDuration(prevData.duration)}
                                    style={getPrevDurationStyle()}
  readOnly
/>
                            </div>
                            <div className={styles.sub_title1}>
  <TextField 
  value={getLevelText(prevData.level)}
  style={getPrevLevelStyle()}
  styles={textFieldColored2} />
                            </div>
                <div className={styles.sub_title1}>
                    <TextField type="text"
                        name="level_title" 
                        value={capitalizeFirstLetter(prevData.level_title)}
                        style={getPrevLevelTitleStyle()}
                        styles={textFieldColored2} />
                </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Participants</span>
                                <div className={styles.participant_list}>
                                <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Recruiter
                                        </div>
                                        <div className={styles.participantMail}>
                                        <span><div className={styles.participantNameValue}>- {`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)} (${item.created_by.email ? item.created_by.email : "-"})`}
                                        </div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Candidate
                                        </div>
                                        <div className={styles.participantMail}>
                                          <span ><div className={styles.participantNameValue}>- {`${`${capitalizeFirstLetter(item.candidate_name || '-')}`} (${item.candidate_email ? item.candidate_email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                        Interviewer
                                        </div>
                                        <div className={styles.participantMail}>
  {prevData.interviewer_email && prevData.interviewer_email.length > 0 ? (
    prevData.interviewer_email.map((interviewer, index) => (
      <span key={index}>
        <div className={styles.participantNameValue} style={getPrevInterviewerStyle(interviewer)}>
          - {capitalizeFirstLetter(interviewer.key ? interviewer.key : "-")} ({interviewer.name ? interviewer.name : "-"})
        </div>
      </span>
    ))
  ) : (
    <div className={styles.participantNameValue}>-</div>
  )}
</div>
                                    </div>
                                </div>
                            </div>           
                             </div>   
         <div className={`${styles.columnNestedCD} ${styles.noRightBorder}`} style={{ width: '30%'}}>               
         <div className={styles.sub_container}>
                <span className={styles.sub_title}>Interview Title</span>
                <TextField
          type="text"
          name="title"
          value={titUpdated.title || "-"}
          style={getTitleStyle()}          
          styles={textFieldColored}
          readOnly
        />
                                 </div>
                                 <div className={styles.sub_container}>
                                 <span className={styles.sub_title} >Start Time</span>
                                <div className={styles.start_time}>
                                  <TextField styles={textFieldColored1}
                                    value = {ISOdateToCustomDate(titUpdated.startDateTime)}
                                    style={getStartTimeDateStyle()}
                                    readOnly />
                                  <TextField styles={textFieldColored1}
                                    value = {convertISOtoTime(titUpdated.startDateTime)}
                                    style={getStartTimeStyle()}
                                    readOnly />
                                </div> 
                                </div>
                                <div className={styles.sub_container1}>
                                <span className={styles.sub_title1} style={{ marginRight: '42px' }}>Duration</span>
                            <span className={styles.sub_title12} >Level</span>
                            <span className={styles.sub_title13} style={{ marginLeft: '76px' }}>Level Title</span>
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
                                  <TextField styles={textFieldColored2} 
                                            style={getDurationStyle()}
                                            value={convertDuration(titUpdated.duration)} />
                            </div>
                            <div className={styles.sub_title1}>
                            <TextField name="level" styles={textFieldColored2} 
                                      style={getLevelStyle()}
                                      value={getLevelText(titUpdated.level)}/>
                            </div>
                <div className={styles.sub_title1}>
                    <TextField
                        type="text"
                                    name="level_title"
                                    style={getLevelTitleStyle()}
                                    value={capitalizeFirstLetter(titUpdated.level_title)}
                        styles={textFieldColored2} />
                </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Participants</span>
                                <div className={styles.participant_list}>
                                <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Recruiter
                                        </div>
                                        <div className={styles.participantMail}>
                                            <span ><div className={styles.participantNameValue}>- {`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)} (${item.created_by.email ? item.created_by.email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                        Candidate
                                        </div>
                                        <div className={styles.participantMail}>
                                          <span ><div className={styles.participantNameValue}>- {`${`${capitalizeFirstLetter(item.candidate_name || '-')}`} (${item.candidate_email ? item.candidate_email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Interviewer
                                        </div>
                                        <div className={styles.participantMail}>
  {titUpdated.interviewer_email && titUpdated.interviewer_email.length > 0 ? (
    titUpdated.interviewer_email.map((interviewer, index) => (
      <span key={index}>
        <div className={styles.participantNameValue} style={getInterviewerStyle(interviewer)}>
          - {capitalizeFirstLetter(interviewer.key ? interviewer.key : "-")} ({interviewer.name ? interviewer.name : "-"})
        </div>
      </span>
    ))
  ) : (
    <div className={styles.participantNameValue}>-</div>
  )}
</div>
            </div>
                                </div>
                            </div>               
                            </div>
              </div>
              </div>
              </div>
     </div>
     <div className={styles.ClientDetails}>
     <div className={styles.table1Rescheduled1}>
     <div className={styles.rowCD}>
         <div className={styles.column} style={{ width: '60%' }}><p><strong className={styles.details}>Client Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
         <div className={styles.column} style={{ width: '60%' }}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNested} style={{ width: '20%' }}>
                <p><strong>Client Name</strong></p>
                <div className={styles.tooltipWrapper}>
  {item.company_name.length > 14 && (
    <div className={styles.tooltip}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")}
              </div>
  )}
  <p>
  {truncateText(capitalizeAllLetters(item.company_name ? item.company_name : "-"))}
  </p>
</div>  
            </div>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Requirement</strong></p>
             <div className={styles.tooltipWrapper}>
  {result.demand.job_title.length > 14 && (
    <div className={styles.tooltip}>
      {result.demand.job_title}
    </div>
  )}
  <p>
    {truncateText(capitalizeFirstLetter(result.demand.job_title ? result.demand.job_title : "-"))}
  </p>
</div>  
            </div>
             <div className={styles.columnNestedO} style={{ width: '20%' }}><p><strong>Requirement date</strong></p><p>{ISOdateToCustomDate(result.demand.due_date)}</p></div>
           </div>
         </div>
         </div>
    </div>
    <br/>
    <div className={styles.table1Rescheduled1}>
     <div className={styles.rowCD}>
         <div className={styles.column} style={{ width: '60%' }}><p><strong className={styles.details}>Organizer Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
       <div className={styles.column} style={{ width: '60%' }}>
           <div className={styles.columnDetails}>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Organizer</strong></p>
             <div className={styles.tooltipWrapper}>
  {fullName.length > 14 && (
    <div className={styles.tooltip}>
              {fullName}
              </div>
  )}
  <p>
  {truncatedName}
  </p>
</div>  
             </div>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Email</strong></p>
             <div className={styles.tooltipWrapper}>
  {item.created_by.email.length > 14 && (
    <div className={styles.tooltip}>
              {item.created_by.email ? item.created_by.email : "-"}
              </div>
  )}
  <p>
  {truncateText(item.created_by.email ? item.created_by.email : "-")}
  </p>
</div>             
                </div>
             <div  className={styles.columnNestedO} style={{ width: '20%' }}><p><strong>Mobile</strong></p>
             <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}
            </p>               
            </div>
           </div>
         </div>
         </div>
    </div>
    </div>
    </div>
        </div>        
          )}
           {showHiTextEdited === indexRescheduled && (
     <div className={styles.container1}>
     <div className={styles.columnS}> <strong>
        {titUpdated.status === "Edited" 
          ? "Edited Details" 
          : titUpdated.status === "Rescheduled & Edited" 
          ? "Rescheduled & Edited Details" 
          : "Rescheduled Details"}
      </strong></div>
     <div className={styles.table_container1}>
     <div className={styles.table1Rescheduled}>
     <div className={styles.rowCDRescheduled}>
     <div className={`${styles.column} ${styles.rightBorder}`} style={{ width: '30%' }}><p><strong className={styles.details}><span className={styles.status_inactive}></span>Previous Data</strong></p></div>
     <div className={styles.column} style={{ width: '30%'}}><p><strong className={styles.details}><span className={styles.status}></span>Updated Data</strong></p></div>
    </div>
    <div className={styles.rowCN}>
         <div className={styles.column} style={{ width: '30%'}}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNestedCD}>
                <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Interview Title</span>
                                <TextField
          type="text"
          name="title"
          value={prevData.title || "-"}
          style={getPrevDataStyle()}          
          styles={textFieldColored}
          readOnly
        />
                                 </div>
                                 <div className={styles.sub_container}>
                                 <span className={styles.sub_title} >Start Time</span>
                                <div className={styles.start_time}>
  <TextField name="start_date"
  styles={textFieldColored1}
  value={ISOdateToCustomDate(prevData.startDateTime)}
  style={getPrevStartTimeDateStyle()}
  readOnly
/>
  <TextField
  name="start_time"
  styles={textFieldColored1}
  value={convertISOtoTime(prevData.startDateTime)}
  style={getPrevStartTimeStyle()}
  readOnly
/>
                                </div> 
                                </div>
                                <div className={styles.sub_container1}>
                            <span className={styles.sub_title1} style={{ marginRight: '42px' }}>Duration</span>
                            <span className={styles.sub_title12} >Level</span>
                            <span className={styles.sub_title13} style={{ marginLeft: '76px' }}>Level Title</span> 
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
  <TextField
  name="duration"
                                    styles={textFieldColored2}
                                    value={convertDuration(prevData.duration)}
                                    style={getPrevDurationStyle()}
  readOnly
/>
                            </div>
                            <div className={styles.sub_title1}>
  <TextField 
  value={getLevelText(prevData.level)}
  style={getPrevLevelStyle()}
  styles={textFieldColored2} />
                            </div>
                <div className={styles.sub_title1}>
                    <TextField type="text"
                        name="level_title" 
                        value={capitalizeFirstLetter(prevData.level_title)}
                        style={getPrevLevelTitleStyle()}
                        styles={textFieldColored2} />
                </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Participants</span>
                                <div className={styles.participant_list}>
                                <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Recruiter
                                        </div>
                                        <div className={styles.participantMail}>
                                        <span><div className={styles.participantNameValue}>- {`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)} (${item.created_by.email ? item.created_by.email : "-"})`}
                                        </div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Candidate
                                        </div>
                                        <div className={styles.participantMail}>
                                          <span ><div className={styles.participantNameValue}>- {`${`${capitalizeFirstLetter(item.candidate_name || '-')}`} (${item.candidate_email ? item.candidate_email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                        Interviewer
                                        </div>
                                        <div className={styles.participantMail}>
  {prevData.interviewer_email && prevData.interviewer_email.length > 0 ? (
    prevData.interviewer_email.map((interviewer, index) => (
      <span key={index}>
        <div className={styles.participantNameValue} style={getPrevInterviewerStyle(interviewer)}>
          - {capitalizeFirstLetter(interviewer.key ? interviewer.key : "-")} ({interviewer.name ? interviewer.name : "-"})
        </div>
      </span>
    ))
  ) : (
    <div className={styles.participantNameValue}>-</div>
  )}
</div>
                                    </div>
                                </div>
                            </div>           
                             </div>   
         <div className={`${styles.columnNestedCD} ${styles.noRightBorder}`} style={{ width: '30%'}}>               
         <div className={styles.sub_container}>
                <span className={styles.sub_title}>Interview Title</span>
                <TextField
          type="text"
          name="title"
          value={titUpdated.title || "-"}
          style={getTitleStyle()}          
          styles={textFieldColored}
          readOnly
        />
                                 </div>
                                 <div className={styles.sub_container}>
                                 <span className={styles.sub_title} >Start Time</span>
                                <div className={styles.start_time}>
                                  <TextField styles={textFieldColored1}
                                    value = {ISOdateToCustomDate(titUpdated.startDateTime)}
                                    style={getStartTimeDateStyle()}
                                    readOnly />
                                  <TextField styles={textFieldColored1}
                                    value = {convertISOtoTime(titUpdated.startDateTime)}
                                    style={getStartTimeStyle()}
                                    readOnly />
                                </div> 
                                </div>
                                <div className={styles.sub_container1}>
                                <span className={styles.sub_title1} style={{ marginRight: '42px' }}>Duration</span>
                            <span className={styles.sub_title12} >Level</span>
                            <span className={styles.sub_title13} style={{ marginLeft: '76px' }}>Level Title</span>
                            </div>
                            <div className={styles.sub_container1}>
                            <div className={styles.sub_title1}>
                                  <TextField styles={textFieldColored2} 
                                            style={getDurationStyle()}
                                            value={convertDuration(titUpdated.duration)} />
                            </div>
                            <div className={styles.sub_title1}>
                            <TextField name="level" styles={textFieldColored2} 
                                      style={getLevelStyle()}
                                      value={getLevelText(titUpdated.level)}/>
                            </div>
                <div className={styles.sub_title1}>
                    <TextField
                        type="text"
                                    name="level_title"
                                    style={getLevelTitleStyle()}
                                    value={capitalizeFirstLetter(titUpdated.level_title)}
                        styles={textFieldColored2} />
                </div>
                            </div>
                            <div className={styles.sub_container}>
                                <span className={styles.sub_title}>Participants</span>
                                <div className={styles.participant_list}>
                                <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Recruiter
                                        </div>
                                        <div className={styles.participantMail}>
                                            <span ><div className={styles.participantNameValue}>- {`${capitalizeFirstLetter(item.created_by.first_name)} ${capitalizeFirstLetter(item.created_by.last_name)} (${item.created_by.email ? item.created_by.email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                        Candidate
                                        </div>
                                        <div className={styles.participantMail}>
                                          <span ><div className={styles.participantNameValue}>- {`${`${capitalizeFirstLetter(item.candidate_name || '-')}`} (${item.candidate_email ? item.candidate_email : "-"})`}</div></span>
                                        </div>
                                    </div>
                                    <div className={styles.participantEmailBlock}>
                                        <div className={styles.participantNames}>
                                          Interviewer
                                        </div>
                                        <div className={styles.participantMail}>
  {titUpdated.interviewer_email && titUpdated.interviewer_email.length > 0 ? (
    titUpdated.interviewer_email.map((interviewer, index) => (
      <span key={index}>
        <div className={styles.participantNameValue} style={getInterviewerStyle(interviewer)}>
          - {capitalizeFirstLetter(interviewer.key ? interviewer.key : "-")} ({interviewer.name ? interviewer.name : "-"})
        </div>
      </span>
    ))
  ) : (
    <div className={styles.participantNameValue}>-</div>
  )}
</div>
            </div>
                                </div>
                            </div>               
                            </div>
              </div>
              </div>
              </div>
     </div>
     <div className={styles.ClientDetails}>
     <div className={styles.table1Rescheduled1}>
     <div className={styles.rowCD}>
         <div className={styles.column} style={{ width: '60%' }}><p><strong className={styles.details}>Client Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
         <div className={styles.column} style={{ width: '60%' }}>
           <div className={styles.columnDetails}>
           <div className={styles.columnNested} style={{ width: '20%' }}>
                <p><strong>Client Name</strong></p>
                <div className={styles.tooltipWrapper}>
  {item.company_name.length > 14 && (
    <div className={styles.tooltip}>
              {capitalizeAllLetters(item.company_name ? item.company_name : "-")}
              </div>
  )}
  <p>
  {truncateText(capitalizeAllLetters(item.company_name ? item.company_name : "-"))}
  </p>
</div> 
            </div>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Requirement</strong></p>
             <div className={styles.tooltipWrapper}>
  {result.demand.job_title.length > 14 && (
    <div className={styles.tooltip}>
      {result.demand.job_title}
    </div>
  )}
  <p>
    {truncateText(capitalizeFirstLetter(result.demand.job_title ? result.demand.job_title : "-"))}
  </p>
</div> 
            </div>
             <div className={styles.columnNestedO} style={{ width: '20%' }}><p><strong>Requirement date</strong></p><p>{ISOdateToCustomDate(result.demand.due_date)}</p></div>
           </div>
         </div>
         </div>
    </div>
    <br/>
    <div className={styles.table1Rescheduled1}>
     <div className={styles.rowCD}>
         <div className={styles.column} style={{ width: '60%' }}><p><strong className={styles.details}>Organizer Details</strong></p></div>
       </div>
       <div className={styles.rowCN}>
       <div className={styles.column} style={{ width: '60%' }}>
           <div className={styles.columnDetails}>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Organizer</strong></p>
             <div className={styles.tooltipWrapper}>
  {fullName.length > 14 && (
    <div className={styles.tooltip}>
              {fullName}
              </div>
  )}
  <p>
  {truncatedName}
  </p>
</div>  
             </div>
             <div className={styles.columnNested} style={{ width: '20%' }}><p><strong>Email</strong></p>
             <div className={styles.tooltipWrapper}>
  {item.created_by.email.length > 14 && (
    <div className={styles.tooltip}>
              {item.created_by.email ? item.created_by.email : "-"}
              </div>
  )}
  <p>
  {truncateText(item.created_by.email ? item.created_by.email : "-")}
  </p>
</div>             
                </div>
             <div  className={styles.columnNestedO} style={{ width: '20%' }}><p><strong>Mobile</strong></p>
             <p>{item.created_by.mobile_number ? item.created_by.mobile_number : "-"}
            </p>               
            </div>
           </div>
         </div>
         </div>
    </div>
    </div>
    </div>
        </div>        
          )}
          </div>
        )}))}
      </InfiniteScroll>
      </div>
    </div>
  );}
function truncateText(text, maxLength = 16) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
}
function capitalizeFirstLetter(text) {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
}
function capitalizeAllLetters(text) {
  if (typeof text !== 'string') return '';
  return text.toUpperCase();
}
export default TrackInterview;
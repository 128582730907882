import { createSlice } from '@reduxjs/toolkit';

const bdeBDEHierarchyDetails = createSlice({
  name: 'bde_Hierarchy_details_data',
  initialState: {
    response: null,
  },
  reducers: {
    saveBDEHierarchyDetails: (state, action) => {
      state.response = action.payload;
    },
    resetBDEHierarchyDetails: (state) => {
      state.response = null;
    }
  }
});

export const { saveBDEHierarchyDetails, resetBDEHierarchyDetails } = bdeBDEHierarchyDetails.actions;

export const bdeHierarchyDetailsDataReducer = bdeBDEHierarchyDetails.reducer; // Exporting dataReducer as named export
export default bdeBDEHierarchyDetails.reducer;

import React, { useEffect, useState } from "react";
import styles from "./ManageEmployee.module.css";
import {
  PrimaryButton,
  SearchBox,
  FontIcon,
  mergeStyles,
  mergeStyleSets,
  DefaultButton,
  Callout,
  DirectionalHint,
  MessageBar,
  MessageBarType,
  Dropdown,
} from "@fluentui/react";
import { Shimmer } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { axiosPrivateCall } from "../constants";
import AddOpportunity from "./AddOpportunityModal";
import { DeletePopup } from "../components/DeletePopup";
import { EditUploadPopup } from "../components/EditUploadModal";
import Nomatchimg from "../assets/no.png";
import { Spinner, SpinnerSize } from "@fluentui/react";

const addIcon = { iconName: "Add" };
const searchIcon = { iconName: "Search" };

const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: "0 10px",
  color: "#999DA0",
  cursor: "pointer",
});

const iconClass1 = mergeStyles({
  fontSize: 12,
  height: 12,
  width: 12,
  margin: "0 ",
  color: "#999DA0",
  cursor: "pointer",
});

const messageBarStyles = {
  content: {
    maxWidth: 620,
    minWidth: 450,
  },
};

const searchFieldStyles = mergeStyleSets({
  root: { width: "185px" },
});

const calloutBtnStyles = {
  root: {
    border: "none",
    padding: "0px 10px",
    textAlign: "left",
    height: "20px",
  },
};

const dropDownStylesActive = (props, currentHover, error, value) => {
  return {
    dropdown: {
      width: "90px",
      minWidth: "60px",
      minHeight: "20px",
    },
    title: {
      height: "22px",
      lineHeight: "18px",
      fontSize: "12px",
      borderColor: error ? "#a80000" : currentHover === value ? "rgb(96, 94, 92)" : "transparent",
    },
    caretDownWrapper: { height: "22px", lineHeight: "20px !important" },
    dropdownItem: { minHeight: "20px", fontSize: 12 },
    dropdownItemSelected: { minHeight: "22px", fontSize: 12 },
  };
};

const shortStatusOptions = [
  { key: "Prospect", text: "Prospect" },
  { key: "Lead", text: "Lead" },
  { key: "Presales", text: "Presales" },
  { key: "Sales", text: "Sales" },
  { key: "Closure", text: "Closure" },
  { key: "Negotiate", text: "Negotiate" },
  { key: "Won", text: "Won" },
  { key: "Lost", text: "Lost" },
  { key: "Hold", text: "Hold" },
  { key: "Drop", text: "Drop" },
  { key: "N/A", text: "N/A" },
];

const dropdownOptions = [
  { key: "Opportunity_id", text: "Opportunity ID" },
  { key: "request_client", text: "Requested Client" },
  { key: "ss_businness_unit", text: "SS Business Unit" },
  { key: "level", text: "Level" },
];

const dropdownStyles = mergeStyles({
  dropdown: { width: "200" },
});

let items = Array(4).fill(null);

function ManageOpportunity(props) {
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [showSubStauts, setShowSubStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [clientData, setClientData] = useState("");
  const [deleteId, setDeleteID] = useState("");
  const [updateId, setUpdateId] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [rowId, setRowId] = useState("");
  const [updateCallout, setUpdateCallout] = useState(false);
  const [showdeleteMessageBar, setShowDeleteMessageBar] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [dropdownSearch, setDropdownSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pipelineData, setPipelineData] = useState({
    name: "",
    request_type: "",
    request_client: "",
    level: "",
    status: "",
    customer_name: "",
    email_id: "",
    mobile_number: "",
    start_date: "",
    close_date: "",
    probability_winning: "",
    priority_level: "",
    opportunity_source: "",
    assign_to: "",
    region_location: "",
    ss_businness_unit: "",
    currency: "",
    opportunity_value: "",
    budget_status: "",
    documents: [],
    comments: [],
  });
    
  const [fetchOptions, setFetchOptions] = useState({
    skip: 0,
    limit: 15,
    sort_field: "updatedAt",
    sort_type: -1,
    search_field: "",
  });
  const [loading, setLoading] = useState(false);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [SearchData, setSearchData] = useState("");
  const [SplitedData, setSplitedData] = useState("");
  const navigateTo = useNavigate();

  const opportunityColumns = [
    {
      columnKey: "Opportunity ID",
      label: "Opportunity ID",
    },
    {
      columnKey: "Requested Client",
      label: "Requested Client",
    },
    {
      columnKey: "Title",
      label: "Title",
    },
    {
      columnKey: "Request Type",
      label: "Request Type",
    },
    {
      columnKey: "Customer Name",
      label: "Customer Name",
    },
    {
      columnKey: "SS Business Unit",
      label: "SS Business Unit",
    },
    {
      columnKey: "Start Date",
      label: "Start Date",
    },
    {
      columnKey: "Close Date",
      label: "Close Date",
    },
    {
      columnKey: "Level",
      label: "Level",
    },
    {
      columnKey: "Status",
      label: "Status",
    },
    {
      columnKey: "More Option",
      label: " ",
    },
  ];

  useEffect(() => {
    getClientData();
  }, [isModalOpen]);

  const getClientData = () => {
    setIsDataLoaded(false);
    axiosPrivateCall
      .get(
        `/api/v1/crm/getOpportunity?skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`
      )
      .then((res) => {
        setClientData(res.data);
        setIsDataLoaded(true);
      });
  };

  const fetchMoreData = () => {
    if (isUserSearching) {
      const moreClientData = SearchData.slice(SplitedData, SplitedData + 15);
      setSplitedData(SplitedData + 15);
      setClientData([...clientData, ...moreClientData]);
      if (SplitedData >= SearchData.length) {
        setHasMore(false);
      }
    } else {
      axiosPrivateCall
        .get(
          `/api/v1/crm/getOpportunity?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${
            fetchOptions.limit
          }&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`
        )
        .then((res) => {
          const moreClientData = res.data;
          if (moreClientData.length < 15 || moreClientData.length === 0) {
            setHasMore(false);
          }
          setFetchOptions((prevState) => {
            return {
              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    console.log("getting more data");
  };

  const handleDropdownChange = (event, option) => {
    setDropdownSearch(option.key);
    setSearchTerm("");
  };

  const handleSearchInputChange = (event) => {
    if (!event || !event.target) {
      setSearchTerm("");
      return;
    }
    const value = event.target.value;

    switch (dropdownSearch) {
      case "request_client":
        if (value && !/^[a-zA-Z\s]+$/.test(value)) {
          return;
        }
        break;
      case "client_id":
        if (value && !/^[0-9a-zA-Z]+$/.test(value)) {
          return;
        }
        break;
      case "Opportunity_id":
        if (value && !/^[0-9a-zA-Z]+$/.test(value)) {
          return;
        }
        break;
      case "ss_businness_unit":
        if (value && !/^[a-zA-Z\s]+$/.test(value)) {
          return;
        }
      case "level":
        if (value && !/^[a-zA-Z\s]+$/.test(value)) {
          return;
        }
        break;
      default:
        break;
    }

    setSearchTerm(value);
  };

  const searchClientList = (e) => {
    const searchValue = e;

    if (searchValue === "") {
      getClientData();
      return;
    }

    setIsDataLoaded(false);
    setIsUserSearching(true);

    setFetchOptions((prevData) => {
      return {
        ...prevData,
        search_field: searchValue,
      };
    });

    axiosPrivateCall
      .get(`/api/v1/crm/searchClients?field_name=${dropdownSearch}&field_value=${searchValue}`)
      .then((res) => {
        setClientData(res.data);
        setIsDataLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearSearchBox = () => {
    setIsUserSearching(false);

    setFetchOptions((prevData) => {
      return {
        ...prevData,
        search_field: "",
      };
    });
  };

  const deletePipeline = (id) => {
    setUpdateCallout(!updateCallout);
    setShowPopup(!showPopup);
    const deleteObj = { _id: id.opportunity_id };
    setDeleteID(deleteObj);
    setUpdateId({ _id: id._id });
  };
  useEffect(() => {
    if (showdeleteMessageBar) {
      setTimeout(() => {
        setShowDeleteMessageBar(!showdeleteMessageBar);
      }, 3500);
    }
  }, [showdeleteMessageBar]);

  useEffect(() => {
    if (showMessageBar) {
      setTimeout(() => {
        setShowMessageBar(!showMessageBar);
      }, 3500);
    }
  }, [showMessageBar]);

  const downloadClients = () => {
    setLoading(true);
    setTimeout(() => {
      axiosPrivateCall
        .get(`api/v1/crm/downloadClient?&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 4000);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }, 1000);
  };

  const handleUpdate = (showpop) => {
    const deleteObj = { _id: updateId };
    if (!showpop) {
      setShowPopup(!showPopup);
      axiosPrivateCall
        .post(`/api/v1/crm/deleteClientData/${deleteObj._id._id}`)
        .then((res) => {
          setShowDeleteMessageBar(!showdeleteMessageBar);
          const demandArrList = clientData;
          setClientData(demandArrList.filter((clientData) => clientData._id !== deleteObj._id._id));
        })
        .catch((e) => {
          setUpdateCallout(false);
        });
    }
  };

  function UploadHandler(data) {
    setShowUploadPopup(true);
    setPipelineData(data);
  }

  const handleStatusCellClick = async (rowId, initialStatus) => {
    setSelectedRowId(rowId);
    setIsEditing(true);
  };

  const handleStatusChange = async (selectedStatus) => {
    const updatedData = clientData.map((data) => {
      if (data._id === selectedRowId) {
        return { ...data, status: selectedStatus };
      }
      return data;
    });

    try {
      await axiosPrivateCall.put(`/api/v1/crm/updateStatus/${selectedRowId}`, { status: selectedStatus });
      setClientData(updatedData);
    } catch (error) {
      console.error("Error updating status:", error);
    }
    setIsEditing(false);
  };

  const dropDownHandler = (e, item, name) => {
    setClientData((prevData) => {
      return {
        ...prevData,
        [name]: item.text,
      };
    });
  };

  const renderStatusCell = (data) => {
    return (
      <td className={styles.table_dataContents} onClick={() => handleStatusCellClick(data._id)}>
        {isEditing && selectedRowId === data._id ? (
          <Dropdown
            onChange={(e, item) => {
              dropDownHandler(e, item, "status");
              handleStatusChange(item.key);
              setClientData({ ...clientData, status: item.key });
            }}
            placeholder="Select"
            options={shortStatusOptions}
            selectedKey={data.status}
            notifyOnReselect
            styles={dropDownStylesActive}
          />
        ) : (
          <div onClick={() => handleStatusCellClick(data._id)}>{data.status}</div>
        )}
      </td>
    );
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.container}>
          {
            <EditUploadPopup
              showPopup={showUploadPopup}
              setShowPopup={setShowUploadPopup}
              basicInfo={pipelineData}
              setBasicInfo={setPipelineData}
            />
          }
          <DeletePopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            handleUpdate={handleUpdate}
            deleteId={deleteId}
            updateCallout={updateCallout}
            setUpdateCallout={setUpdateCallout}
          />

          {isModalOpen && (
            <AddOpportunity
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isSubmitSuccess={isSubmitSuccess}
              setSubmitSuccess={setSubmitSuccess}
              setShowMessageBar={setShowMessageBar}
              showMessageBar={showMessageBar}
            />
          )}

          <div className={styles.nav_container}>
            <div className={styles.title}> Opportunity Listing</div>

            {showMessageBar && (
              <div>
                <MessageBar
                  onDismiss={() => setShowMessageBar(!showMessageBar)}
                  styles={messageBarStyles}
                  dismissButtonAriaLabel="Close"
                  messageBarType={MessageBarType.success}
                >
                  Added Successfully
                </MessageBar>
              </div>
            )}

            {showdeleteMessageBar && (
              <div>
                <MessageBar
                  onDismiss={() => setShowDeleteMessageBar(showSubStauts)}
                  styles={messageBarStyles}
                  dismissButtonAriaLabel="Close"
                  messageBarType={MessageBarType.success}
                >
                  Deleted successfully
                </MessageBar>
              </div>
            )}

            <div className={styles.nav_items}>
              <Dropdown
                placeholder="Select Search Field"
                options={dropdownOptions}
                styles={dropdownStyles}
                onChange={handleDropdownChange}
                selectedKey={dropdownSearch}
                className={styles.customDropdown}
              />

              <SearchBox
                styles={searchFieldStyles}
                onChange={handleSearchInputChange}
                onSearch={(e) => searchClientList(e)}
                onClear={clearSearchBox}
                disabled={dropdownSearch === ""}
                placeholder=" "
                iconProps={searchIcon}
                className={styles.search}
                value={searchTerm}
                showIcon
              />

              <PrimaryButton
                text="Add"
                iconProps={addIcon}
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setSubmitSuccess(false);
                }}
              />
              {loading ? (
                <Spinner size={SpinnerSize.medium} className={iconClass} />
              ) : completed ? (
                <FontIcon iconName="CheckMark" className={iconClass} />
              ) : (
                <FontIcon iconName="Download" onClick={downloadClients} className={iconClass} />
              )}
            </div>
          </div>

          <div id="scrollableDiv" className={styles.table_container}>
            <InfiniteScroll
              style={{ overflow: "visible", height: "100%" }}
              dataLength={clientData.length}
              next={fetchMoreData}
              loader={isDataLoaded && clientData.length >= 15}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
            >
              <table>
                <thead className={styles.table_header}>
                  <tr className={styles.table_row}>
                    {opportunityColumns.map((column) => (
                      <th className={styles.table_headerContents} key={column.columnKey}>
                        <div className={styles.table_heading}>
                          <div>{column.label}</div>
                          {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isDataLoaded && clientData.length === 0 ? (
                    <tr>
                      <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                        <img src={Nomatchimg} alt="NoMatchimage" width={"180px"} height={"200px"} />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {isDataLoaded &&
                        Array.isArray(clientData) &&
                        clientData.map((data) => (
                          <tr
                            className={data.status ? `${styles.table_row}` : `${styles.table_row_idle}`}
                            key={data._id}
                          >
                            <td
                              onClick={() =>
                                navigateTo(`/managedeals/viewandeditopportunity?_id=${data._id}`, {
                                  state: { clientData: data, opportunityListing: "opportunityListing" },
                                })
                              }
                              className={styles.table_dataContents}
                            >
                              <span className={styles.custom_link}>{data.Opportunity_id || "-"}</span>
                            </td>
                            <td
                              onClick={() => navigateTo(`/masterlist/viewclient?client_id=${data.request_client?._id}`)}
                              className={styles.table_dataContents}
                            >
                              <span className={styles.custom_link}>
                                {`${data?.request_client?.company_name}-${data.request_client?.ClientId}` || "-"}
                              </span>
                            </td>
                            <td className={styles.table_dataContents}>{data.name || "-"}</td>
                            <td className={styles.table_dataContents}>{data.request_type || "-"}</td>
                            <td className={styles.table_dataContents}>{data.customer_name || "-"}</td>
                            <td className={styles.table_dataContents}>{data.ss_businness_unit || "-"}</td>
                            <td className={styles.table_dataContents}>
                              {data.start_date ? formatDate(data.start_date) : "-"}
                            </td>
                            <td className={styles.table_dataContents}>
                              {data.start_date ? formatDate(data.close_date) : "-"}
                            </td>
                            <td className={styles.table_dataContents}>{data.level || "-"}</td>
                            {renderStatusCell(data)}
                            <td className={styles.table_dataContents}>
                              <div
                                id={`Dp${data._id}`}
                                onClick={() => {
                                  setRowId(data._id);
                                  setUpdateCallout(true);
                                }}
                                className={styles.moreOptions}
                              >
                                <FontIcon iconName="MoreVertical" className={iconClass1} />
                                {rowId === data._id && updateCallout && (
                                  <Callout
                                    gapSpace={0}
                                    target={`#Dp${data._id}`}
                                    onDismiss={() => setRowId("")}
                                    isBeakVisible={false}
                                    directionalHint={DirectionalHint.bottomCenter}
                                  >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                    <DefaultButton
  onClick={() =>
    navigateTo(`/managedeals/viewandeditopportunity?_id=${data._id}`, {
      state: { 
        clientData: data, 
        opportunityListing: "opportunityListing",
        isEditMode: true 
      },
    })
  }
  text="Edit"
  styles={calloutBtnStyles}
/>
                                      <DefaultButton
                                        onClick={() => UploadHandler(data)}
                                        text="Upload"
                                        styles={calloutBtnStyles}
                                      />
                                      <DefaultButton
                                        onClick={() => deletePipeline(data)}
                                        text="Delete"
                                        styles={calloutBtnStyles}
                                      />
                                    </div>
                                  </Callout>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}

                      {!isDataLoaded &&
                        items.map((client) => (
                          <tr className={styles.table_row}>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}><Shimmer /></td>
                            <td className={styles.table_dataContents}>
                              <div className={styles.moreOptions}>
                                <FontIcon iconName="MoreVertical" className={iconClass1} />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
}
export default ManageOpportunity;

import React, { useState,useEffect } from 'react';
import DoughnutChart from "./DoughtnutChart.js"
import styles from "./TargetListing.module.css";
import {ISOdateToCustomDate} from '../utils/helpers.js'

import {
    PrimaryButton,
    SearchBox,
    FontIcon,
    mergeStyles,
    Label,
    TextField,
    MessageBar,
    MessageBarType,
    Spinner, 
    SpinnerSize,
  } from "@fluentui/react";
import { axiosPrivateCall } from "../constants";
const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: '0 10px',
  color: '#999DA0',
  cursor: 'pointer',
  userSelect: 'none',
});
const narrowTextFieldStyles1 = {
  fieldGroup: {
    width: 40,
    height: 20,
    border: "none",
    textAlign: "center",
    marginTop: "2px",
    marginRight: "-12px",
  },
  field: {
    fontSize: "15px",
    color: "#5B5F62",
  },
};
const narrowTextFieldStyles = {
fieldGroup: {
  width: 40,
  height: 20,
  border: "none",
  textAlign: "center",
  marginTop: "2px",
  marginRight: "-12px",
},
field: {
  fontSize: "15px",
  color: "#5B5F62",
},
};
const messageBarStyles = {
content: {
  maxWidth: 620,
  minWidth: 450,
},
};
function BdTargetListing () {
const searchIcon = { iconName: 'Search' };
      const [isLoading, setIsLoading] = useState(true);
      const [isCalloutVisible, setIsCalloutVisible] = useState(false);
      const [calloutTarget, setCalloutTarget] = useState(null);
      const [bdeData,setBdeData]=useState([])
      const [showRequest,setShowRequest]=useState(false)
      const [targetId,setTargetId]=useState()
     const editEnable={ isEmplacementEnabled: false, isContractEnabled: false, isFullTimeEnabled: false }
     const [showAcceptMessageBar, setShowAcceptMessageBar] = useState(false);
     const [notification,setNotification]=useState([])
      const [editField,setEditField]=useState(editEnable)
      const token = localStorage.getItem("token");
      let base64Url = token.split(".")[1];
      let decodedValue = JSON.parse(window.atob(base64Url));
      const userId=decodedValue.user_id
      const [countLoad,setCountLoad]=useState(false)
      const [count,setCount]=useState({ topData: [] })
      const [cardNotificationStates, setCardNotificationStates] = useState(
        new Array(bdeData.length).fill(false)
      );
      const [notificationPropup,setNotificationPropup]=useState([])
    useEffect(()=>{
    axiosPrivateCall.get('api/v1/BDE/getBdeData').then(res=>{
    setBdeData(res.data)
    setIsLoading(false);
    console.log(res.data,'kkk')
   }).catch(err=>{
    console.log(err)
    setIsLoading(false);
   })
     },[])
     const [achievedData, setAchievedData] = useState(0); 
     const [fullTimeCount, setFullTimeCount] = useState(0);
   const [contractCount, setContractCount] = useState(0);
     useEffect(() => {
      axiosPrivateCall.get('api/v1/BDE/getAchivedDatas')
          .then(response => {
            const { totalAcheive, fullTime, contract } = response.data;
            setAchievedData(totalAcheive);
            setFullTimeCount(fullTime);
            setContractCount(contract);
              })
          .catch(error => {
              console.error('Error fetching achieved data:', error);
          });
  }, []); 
     const [searchValue, setSearchValue] = useState("");
  const [filteredBdeData, setFilteredBdeData] = useState([]);
  const filterBdeData = () => {
    const filteredData = bdeData.filter(bde => bde.name.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredBdeData(filteredData);
  };
  useEffect(() => {
    filterBdeData();
  }, [searchValue, bdeData]);
  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };
     useEffect(()=>{
      if(countLoad){
      axiosPrivateCall.get(`api/v1/notification/countNotification`).then(
        (res)=>{
          setNotificationPropup(res.data)
          setCountLoad(false)
        })
      }else{
        axiosPrivateCall.get(`api/v1/notification/countNotification`).then(
          (res)=>{
            setNotificationPropup(res.data)
          })
      }
        },[countLoad])
     const NotificationCount=()=>{
      const matchedNotificationsTopData = bdeData.map(target => {
     const filteredNotifications = notificationPropup.filter(notification => {
            const ne =notification.isRead.read
            if (notification.isRead.id === userId) {
              return notification.assigned === target._id && notification.isRead.read === 'false';
            } 
          });
          return filteredNotifications;
        });
      const notificationtopCounts = matchedNotificationsTopData.map(filteredNotifications => filteredNotifications.length);
     let count={
        topData: notificationtopCounts,
      };
      setCount(count)
    }
     useEffect(() => {
      if(countLoad){
        NotificationCount();
        setCountLoad(false)
      }else{
        NotificationCount();
      }
    }, [bdeData,countLoad]);
      const showCallout = (event,id) => {
        setShowRequest(false)
        setTargetId(id)
        setIsCalloutVisible(true);
        setCalloutTarget(event.target);
      };
      const hideCallout = () => {
        setIsCalloutVisible(false);
      };
     
      const handleAccept=async(assigned,updated,created,msg,data)=>{
        setEditField({
        isTargetEnabled: false,
        isContractEnabled: false,
        isFullTimeEnabled: false
      });
      setShowRequest(false)
      let readDetails={id:decodedValue.user_id,read:false};
      let newNotificationData = {
         assigned: assigned,
         updated_by:updated,
         created_by:created,
         message: msg,
         data:data,
         user:userId,
         isRead:readDetails ,
       };
       try{
        await axiosPrivateCall.post('api/v1/notification/sendNotification', newNotificationData).then(res=>{
          setShowAcceptMessageBar(!showAcceptMessageBar)
          console.log(res,'working')})
          .catch(err=>console.log(err))
       }catch{
        console.log('error ')
       }     
    }
    const handleRequest=async(id)=>{
      setIsCalloutVisible(false);
      setTargetId(id)
      setShowRequest(true)
      setEditField({
        isEmplacementEnabled: true,
        isContractEnabled: true,
        isFullTimeEnabled: true
        });
        }
      const handleInputChange=(e,name,index)=>{
      const { value } = e.target;
      let inputValue = value.trim();
      const updatedTargets = [...bdeData];
      updatedTargets[index][name] = inputValue;
      if (name === "contract" || name === "fulltime") {
      const contract = parseFloat(updatedTargets[index]["contract"]) || 0;
      const fulltime = parseFloat(updatedTargets[index]["fulltime"]) || 0;
      const revenue = (contract * 1.25 + fulltime * 1);
        updatedTargets[index]["revenue"] = revenue;
      }
      setBdeData(updatedTargets);
    }
  const handleupdateRequest=(assigned,updated,created,msg,data)=>{
    handleAccept(assigned,updated,created,msg,data)
  }
  const handleNotification=(id)=>{
    axiosPrivateCall.get(`api/v1/BDE/getBdeDataById/${id}`).then(res=>{
    console.log(res.data,'id response')
   }).catch(err=>{
    console.log(err)
   })
  }
  const handleOpenCardNotification = (targetId) => {
    const updatedStates = new Array(cardNotificationStates.length).fill(false);
    updatedStates[targetId] = true;
    setCardNotificationStates(updatedStates);
  };
  const handleCloseCardNotification = (index) => {
    const updatedStates = [...cardNotificationStates];
    updatedStates[index] = false;
    setCardNotificationStates(updatedStates);
  };
  const handleCount=(data)=>{
    if(data==='count'){
      setCountLoad(true)
    }
      }
      const formatDateToDMY = (dateString) => {
        const originalDate = new Date(dateString);
        if (!isNaN(originalDate)) {
          const day = originalDate.getDate().toString().padStart(2, '0');
          const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
          const year = originalDate.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return '  ';
      };
  const acceptDates=(id)=>{
    const acceptedDates = [];
    notificationPropup.forEach(notification => {
      if (notification.message === 'accept' && notification.assigned === id) {
        acceptedDates.push(formatDateToDMY(notification.updatedAt));
      }
    });
    const lastAcceptedDate = acceptedDates.length > 0 ? acceptedDates[acceptedDates.length - 1] : null;
    return lastAcceptedDate;
  }
return (
        <>
        <div className={styles.nav_container}>
        <div className={styles.title}>Target Listing</div>
        <div className={styles.nav_items}>
        <SearchBox
            placeholder=" "
            styles={narrowTextFieldStyles1}
            iconProps={searchIcon}
            showIcon
            onChange={(e, newValue) => handleSearchChange(newValue)}
        />
        {/* <FontIcon iconName="Breadcrumb" className={iconClass} />
        <FontIcon iconName="Download" className={iconClass} /> */}
        </div>
        </div>
        <div className={styles.successMessage}>
        { showAcceptMessageBar &&(
  <div>
    <MessageBar
      onDismiss={() => {setShowAcceptMessageBar(!showAcceptMessageBar)
      }
      }
      styles={messageBarStyles}
      dismissButtonAriaLabel="Close"
      messageBarType={MessageBarType.success}
    >
      Accepted Successfully!
    </MessageBar>
  </div>
)}
</div>
        {isLoading && 
        <Spinner className={styles.spinnerStyle} size={SpinnerSize.large} label="Loading ..."/>
            }
        {!isLoading && (
        <div className={styles.wholecontainer}>
        <div className={styles.firstcontainer}> 
        {filteredBdeData.map((bde, index) => {
        
        let totalUnAchieved = bde.target >achievedData ? bde.target - achievedData : "";

        const colorsData = [
            { label: '#0263FF', value: bde.revenue }, 
            { label: '#0779B7', value:fullTimeCount},
            { label: '#6BC2ED', value: bde.empanelment }, 
            { label: '#019ADD', value: contractCount},  
            { label: '#F8F8F8', value: totalUnAchieved}, 
        ]
        const achievedNumberColorClass = achievedData !== null && parseInt(achievedData) <= bde.target ? styles.AchievedNumberNormal : styles.AchievedNumberSuccess;
        return (
        <div className={styles.card1}>
        <div className={styles.header}>
        <Label className={styles.titlename}>BD Target</Label> 
        </div>
        <div className={styles.header1}>
        <p className={styles.names}>Name: {bde.name}</p>
        </div>
        <div className={styles.header2}>
        <div className={styles.bar}>
        <div style={{ width: '150px', height: '150px', marginTop: '10px',  marginLeft:'8%'}} >
        <DoughnutChart data={colorsData} count={achievedData}/>
        <p style={{ position: 'absolute', top: '50%', left: '45%', transform: 'translate(-50%, -50%)', fontSize: '15px', color: '#236BFE', fontWeight: '500' }} className={styles.Achieved1}>Achieved</p>
        <p style={{ position: 'absolute', top: '50%', left: '45%', transform: 'translate(-50%, -50%)', fontSize: '15px', color: '#5B5F62', fontWeight: '500', marginTop: '20px' }} className={styles.Achieveddata1}>
    <p className={achievedNumberColorClass}>{achievedData !== null ? achievedData : ' '}</p> /{bde.target}
  </p>
        {achievedData !== null && achievedData !== bde.target && achievedData > bde.target && (
      <div className={styles.overachieved}>
        <p className={styles.achievednumbers}>
          {`+${Math.max(achievedData - bde.target, 0)}`}
        </p>
        <p className={styles.achievedtext}>Overachieved</p>
      </div>
    )}
        </div></div>
        <div className={styles.bardetails}>
        <Label className={styles.target}>Target Details</Label>
        <p className={styles.targetdetails}>
        <span className={styles.square}></span>
        <p className={styles.contract}>Contract</p>
        <span className={styles.details}>
          {editField.isContractEnabled&& targetId===bde._id  ?( 
        <TextField value={bde.contract} styles={narrowTextFieldStyles} onChange={(e)=>handleInputChange(e, "contract", index)}/>
            ):(bde.contract)}</span>
        </p>
        <p className={styles.targetdetails}>
        <span className={styles.square1}></span>
        <p className={styles.contract1}>Full Time</p>
        <span className={styles.details1}>
          {editField.isContractEnabled&& targetId===bde._id  ?(
        <TextField value={bde.fulltime} styles={narrowTextFieldStyles} onChange={(e)=>handleInputChange(e,'fulltime',index)}/> )
            :(bde.fulltime)}</span></p>
        <p className={styles.targetdetails}>
        <span className={styles.square2}></span>
        <p className={styles.contract2}>Emplacement</p>
        <span className={styles.details2}>
          {editField.isEmplacementEnabled&& targetId===bde._id  ?(
        <TextField value={bde.empanelment} styles={narrowTextFieldStyles} onChange={(e)=>handleInputChange(e,'empanelment',index)}/>)
            :(bde.empanelment)} </span></p>
        <p className={styles.targetdetails}>
        <span className={styles.square3}></span>
        <p className={styles.contract3}>Revenue</p>
        <span className={styles.details3}>{bde.revenue}</span></p>
        <p className={styles.targetdetails1}>Total<span className={styles.details4}>{bde.target}</span></p></div></div>
        <div className={styles.header3}>
        <div className={styles.designation}>
        <p>Designation: BDM</p>
        <p>ID : {bde.employee_id}</p>
        </div>
         {(showRequest===true && targetId === bde._id)?(
        <div className={styles.request}>
        <PrimaryButton text="Request"  onClick={()=>handleupdateRequest(bde._id,bde.assigned_to,bde.assigned_by, "request",bdeData[index])} />
        </div>
        ):null}
          <div className={styles.allocationdate}>
            <p>Allocated Date: {ISOdateToCustomDate(bde.allocated_date)}</p>
            <p>Accepted Date:  {acceptDates(bde._id)}</p></div>
        </div>
        </div>
          )
      })}
        </div>
        </div>
        )}
        </>
        )
        }
export default BdTargetListing;
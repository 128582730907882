import React, { useEffect, useState, useId } from "react";
import styles from "./ManageEmployee.module.css"
import { SearchBox, FontIcon, mergeStyles, mergeStyleSets, Dropdown } from '@fluentui/react';
import { DefaultButton, Callout, DirectionalHint } from '@fluentui/react';
import { Shimmer } from '@fluentui/react';
import { useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosPrivateCall } from "../constants";
import Nomatchimg from "../assets/no.png"
import { Spinner, SpinnerSize } from "@fluentui/react";
import {ISOdateToCustomDate} from '../utils/helpers.js'



const searchIcon = { iconName: 'Search' };


const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px',
    color: '#999DA0',
    cursor: 'pointer',
    userSelect: 'none',
});

const iconClass1 = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    margin: '0 ',
    color: '#999DA0',
    cursor: 'pointer'
});

const iconClassToast = mergeStyles({
    fontSize: 24,
    height: 24,
    width: 24,
    color: '#107C10',
});

const searchFieldStyles = mergeStyleSets({
    root: { width: '185px', },
});

const calloutBtnStyles = {
    root: {
        border: 'none',
        padding: '0px 10px',
        textAlign: 'left',
        height: '20px'

    }
}

const dropdownStyles = {
    dropdown: { width: 200 },
};

const options = [
    { key: 'employee_id', text: 'Employee Id' },
    { key: 'email', text: 'Email' },
    { key: 'mobile_number', text: 'Mobile' },
    { key: 'first_name', text: 'Employee Name' },
];
let items = Array(4).fill(null);
function MyInterview(props) {
    const [showPopup, setShowPopup] = useState(false)
    const [updateId, setUpdateId] = useState('')
    const [deleteId, setDeleteID] = useState('')
    const [isSubmitDel, setSubmitDel] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubmitSuccess, setSubmitSuccess] = useState(false);
    const [interviewList, setInterviewList] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [rowId, setRowId] = useState('');
    const [updateCallout, setUpdateCallout] = useState(false);
    const [isUserSearching, setIsUserSearching] = useState(false)
    const [fetchOptions, setFetchOptions] = useState({
        skip: 0,
        limit: 15,
        sort_field: 'updatedAt',
        sort_type: -1,
        search_field: ''
    })
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);

    const [hasMore, setHasMore] = useState(true)

    const navigateTo = useNavigate();

    const columns = [

        {
            columnKey: 'Meeting ID',
            label: 'Meeting ID',
        }, {
            columnKey: 'Demand ID',
            label: 'Demand ID',

        }, {
            columnKey: 'Candidate ID',
            label: 'Candidate ID',
        }, {
            columnKey: 'Candidate Name',
            label: 'Candidate Name',
        },
        {
            columnKey: 'Mobile',
            label: 'Mobile'
        }, {
            columnKey: 'Candidate Email',
            label: 'Candidate Email'
        }, {
            columnKey: 'Interview Email',
            label: 'Interview Email',
        }, {
            columnKey: 'Scheduled By',
            label: 'Scheduled By',
        },
        // {
        //     columnKey: 'createdAt',
        //     icon: `${fetchOptions.sort_field === 'createdAt' && fetchOptions.sort_type === 1 ? 'SortUp' : 'SortDown'}`,
        //     label: 'Registered Date',
        // },
        {
            columnKey: 'Client',
            label: 'Client',
        },
        {
            columnKey: 'Interview Date',
            label: 'Interview Date',
        },
        {
            columnKey: 'Start time',
            label: 'Start time',
        },
        {
            columnKey: 'Duration',
            label: 'Duration',
        }, {
            columnKey: 'Status',
            label: 'Status',
        },

        {
            columnKey: 'More Options',
            label: ' '
        }];
    useEffect(() => {
        getInterviewData()
        setHasMore(true)
        setFetchOptions({ ...fetchOptions, skip: 0, limit: 15 })
    }, [isModalOpen, fetchOptions.sort_field, fetchOptions.sort_type])





    const getInterviewData = () => {
        setIsDataLoaded(false)
        axiosPrivateCall.get(`api/v1/interview/myinterview?skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`).then(res => {
            console.log(res.data);
            setInterviewList(res.data);
            setIsDataLoaded(true)
        }).catch(e => {
            console.log(e)
        })
    }
    const [DropdownSearch, setDropdownSearch] = useState('')
    const [searchTerm, setSearchTerm] = useState('');
    const [SearchData, setSearchData] = useState('')
    const [SplitedData, setSplitedData] = useState('')
    const handleDropdownChange = (e, item) => {
        setDropdownSearch(item.key)
        setSearchTerm('')
    }

    const clearSearchBox = () => {
        setIsUserSearching(false)
        setFetchOptions(prevData => {
            return {
                ...prevData,
                search_field: ''
            }
        })
        setSearchTerm('');
        getInterviewData();
        setHasMore(true)
    }
    const fetchMoreData = () => {
        if (isUserSearching) {
            const moreInterviews = SearchData.slice(SplitedData, SplitedData + 15)
            setSplitedData(SplitedData + 15)
            setInterviewList([...interviewList, ...moreInterviews])
            if (SplitedData >= SearchData.length) {
                setHasMore(false)
            }
        }
        else {
            axiosPrivateCall.get(`api/v1/interview/myinterview?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
                .then(res => {
                    const moreInterviews = res.data;
                    console.log(moreInterviews.length)
                    setInterviewList([...interviewList, ...moreInterviews])
                    if (moreInterviews.length < 15 || moreInterviews.length === 0) {
                        setHasMore(false)
                    }
                    setFetchOptions((prevState) => {

                        return {
                            ...prevState,
                            skip: fetchOptions.skip + fetchOptions.limit,
                        }
                    })
                }).catch(e => {
                    console.log(e)
                })
        }
        console.log('getting more data')
    }

   
    const ISOdateToCustomTime = (value) => {
        const date = new Date(value);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const timeString = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
        return timeString;
    }


    return (
        <div className={styles.page}>
            <div className={styles.container}>
                {/* <DeletePopup showPopup={showPopup} setShowPopup={setShowPopup}
                    //  handleUpdate={handleUpdate} 
                    //  deleteId={deleteId}
                    updateCallout={updateCallout} setUpdateCallout={setUpdateCallout} /> */}

                <div className={styles.nav_container}>
                    <div className={styles.title}>Interview Listing</div>

                    <div className={styles.nav_items}>
                        <Dropdown placeholder='Select Search Field' onChange={handleDropdownChange} options={options} styles={dropdownStyles} />
                        <SearchBox
                            // onChange={handleSearchInputChange}
                            value={searchTerm}
                            //  onSearch={(e) =>
                            //     searchEmployeeList(e)} 
                            disabled={DropdownSearch == "" ? true : false} onClear={clearSearchBox} placeholder=" " iconProps={searchIcon} className={styles.search} styles={searchFieldStyles}
                            showIcon />
                        <FontIcon iconName="Breadcrumb" className={iconClass} />

                        {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) :
                            completed ? (<FontIcon iconName="CheckMark" className={iconClass} />) :
                                (<FontIcon iconName="Download"
                                    // onClick={downloadEmployees}
                                    className={iconClass} />)}
                    </div>
                </div>

                <div id="scrollableDiv" className={styles.table_container}>
                    <InfiniteScroll style={{ overflow: 'visible', height: '100%' }} dataLength={interviewList.length} loader={isDataLoaded && interviewList.length >= 15 && <h4>Loading...</h4>}
                        hasMore={hasMore} next={fetchMoreData} scrollableTarget="scrollableDiv">
                        <table>
                            <thead className={styles.table_header}>
                                <tr className={styles.table_row}>
                                    {columns.map((column) =>
                                        <th
                                            // onClick={() => clickSortHandler(column.columnKey)} 
                                            className={styles.table_headerContents} key={column.columnKey}>
                                            <div
                                                className={styles.table_heading}>
                                                <div>{column.label}</div>
                                                {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                                            </div>
                                        </th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {isDataLoaded && interviewList.length === 0 ? (
                                    <tr>
                                        <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                                            <img src={Nomatchimg} alt="image" width={"180px"} height={"200px"} />
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {isDataLoaded && interviewList.length === 0 ? (
                                            <tr>
                                                <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                                                    <img src={Nomatchimg} alt="image" width={"190px"} height={"200px"} />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {isDataLoaded && interviewList.map((data) => (
                                                    <tr className={styles.table_row} key={data._id}>
                                                        <td className={styles.table_dataContents}>{data.MeetID}</td>
                                                        <td className={styles.table_dataContents}>{data.demand_id}</td>
                                                        <td className={styles.table_dataContents}>{data.candidate_id}</td>
                                                        <td className={styles.table_dataContents}>{data.company_name}</td>
                                                        <td className={styles.table_dataContents}>{data.mobile_no}</td>
                                                        <td className={styles.table_dataContents}>{data.candidate_email}</td>
                                                        <td className={styles.table_dataContents}>{data.interviewer_email}</td>
                                                        <td className={styles.table_dataContents}>{"scheduled by"}</td>
                                                        <td className={styles.table_dataContents}>{data.company_name}</td>
                                                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(data.startDateTime)}</td>
                                                        <td className={styles.table_dataContents}>{ISOdateToCustomTime(data.startDateTime)}</td>
                                                        <td className={styles.table_dataContents}>{data.duration}</td>
                                                        <td className={styles.table_dataContents}>{data.status}</td>
                                                        <td className={styles.table_dataContents}>
                                                            <div id={`FO_${data._id}`} onClick={() => { setRowId(data._id); setUpdateCallout(true) }} className={styles.moreOptions}>
                                                                <FontIcon iconName='MoreVertical' className={iconClass1} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                        {!isDataLoaded && items.map(data => (
                                            <tr className={styles.table_row} >
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}><Shimmer /></td>
                                                <td className={styles.table_dataContents}>
                                                    <div className={styles.moreOptions} >
                                                        <FontIcon iconName='MoreVertical' className={iconClass1} />
                                                    </div>
                                                </td>
                                            </tr>))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );

};

export default MyInterview;








import React, { useRef, useState, useEffect } from 'react';
import { Modal } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import styles from './HierarchyView.module.css'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HierarchyCard from '../components/HierarchyCard';
import HierarchyArrow from '../components/HieraryArrow';
import { axiosPrivateCall } from '../constants';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { mergeStyles } from '@fluentui/react';
import DemandListing from './DemandListing';

const contractIconClass = mergeStyles({
	fontSize: 20,
	height: 20,
	width: 20,
	cursor: 'pointer',
});

const closeIconClass = mergeStyles({
	fontSize: 16,
	height: 20,
	width: 20,
	cursor: 'pointer'
})

const HierarchyView = (props) => {

	const token = localStorage.getItem('token');
	let base64Url = token.split('.')[1];
	let decodedValue = JSON.parse(window.atob(base64Url));

	const [userId, setUserId] = useState(decodedValue.user_id)
	const { isModalOpen, setIsModalOpen, showMessageBar, setShowMessageBar, startDate, endDate } = props;
	const [isLoading, setIsLoading] = useState(false)
	const [isModalShrunk, setIsModalShrunk] = useState(true)
	const [hierarchyDemandData, setHierarchyDemandData] = useState([]);

	const getHierarchyDemandData = () => {
		setIsLoading(true);
		
		if (startDate && endDate) {
			axiosPrivateCall.get(`/api/v1/aggregate/getHierarchyDemandData?user_id=${userId}&start_date=${formateDate(startDate)}&end_date=${formateDate(endDate)}`)
				.then(res => {
					setHierarchyDemandData(res.data);
					setIsLoading(false);
				}).catch(e => {
					console.log(e);
					setIsLoading(false)
				})
		} else{
			axiosPrivateCall.get(`/api/v1/aggregate/getHierarchyDemandData?user_id=${userId}`).then(res => {
				setHierarchyDemandData(res.data);
				setIsLoading(false);
			}).catch(e => {
				console.log(e);
				setIsLoading(false);
			})
		}
	}

	const formateDate = (date) => {
		if (date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const formattedDate = `${year}-${month}-${day}`;
			return formattedDate
		}
	}

	useEffect(() => {
		getHierarchyDemandData()
	}, [userId, startDate, endDate])

	const modalSizeHandler = () => {
		setIsModalShrunk(!isModalShrunk)
	}

	const hierarchyCardClickHandler = (empId) => {
		setUserId(empId)
	}

	return (
		<div>
			<DemandListing startDate={startDate} endDate={endDate} />
			<Modal id="Modal12" scrollableContentClassName={styles.adddemand_modal_scrollable_content} containerClassName={`${isModalShrunk ? styles.adddemand_modal_container_shrunk : styles.adddemand_modal_container}`}
				isOpen={isModalOpen}>
				<div className={styles.adddemand_modal_header_container}>
					<div className={styles.header_tag_expand_close_icon_container}>
						<div className={styles.header_tag_container}>
							View Hierarchy
						</div>
						<div className={styles.header_expand_close_icon_container}>
							<div onClick={modalSizeHandler} className={styles.header_expand_icon_container}>
								{isModalShrunk ? <Icon iconName='FullScreen' className={contractIconClass} /> :
									<Icon iconName='BackToWindow' className={contractIconClass} />}
							</div>
							<div onClick={() => setIsModalOpen(false)} className={styles.header_close_icon_container}>
								<Icon iconName='ChromeClose' className={closeIconClass} />
							</div>
						</div>
					</div>
					<div className={styles.header_content_container}>
						<div className={styles.header_content_job_description_unassigned_save_container}>
							<div className={styles.header_save_close_btns_container}>
								Demand :  {hierarchyDemandData?.total_demands}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.main_filter_options_container}>

				</div>
				{isLoading ? (
					<Spinner className={styles.spinnerStyle} size={SpinnerSize.large} label="Loading ..." />

				) : (
					<div className={styles.main_information_container}>

						<HierarchyCard name={hierarchyDemandData?.current_user?.first_name} role={hierarchyDemandData?.current_user?.employee?.role}
							count={hierarchyDemandData?.total_demands} countName={"Demand"} type={'demand'} userId={userId}
							startDate={startDate} endDate={endDate} loading={isLoading}
						/>
						{hierarchyDemandData?.employeeDemands?.length ? <HierarchyArrow /> : ''}

						{
							hierarchyDemandData?.employeeDemands?.length ? hierarchyDemandData.employeeDemands.map((data, index) => {

								return (
									<div onClick={() => hierarchyCardClickHandler(data.employee._id)}>
										<HierarchyCard
											name={`${data.employee.first_name} ${data.employee.last_name}`}
											role={data.employee.role}
											type={'demand'}
											userId={data.employee._id}
											count={data?.total_demands}
											countName={"Demand"}
											startDate={startDate}
											endDate={endDate}
											loading={isLoading}
										/>
										{hierarchyDemandData?.employeeDemands?.length !== index + 1 ? <HierarchyArrow /> : ''}
									</div>
								);

								return null;
							}) : ''
						}
					</div>
				)}
			</Modal>
		</div>
	)
}

export default HierarchyView;

import React, { useState, useEffect, useCallback } from 'react'
import styles from './ViewEditClient.module.css'
import { Icon } from '@fluentui/react/lib/Icon';
import Editicon from "../assets/editnote.png"
import { TextField, PrimaryButton, DefaultButton } from '@fluentui/react';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import { axiosPrivateCall, axiosJsonCall } from '../constants';
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { UploadPopup } from '../components/UploadModal';
import { Label } from "@fluentui/react/lib/Label";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { ChoiceGroup } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import uploadImage from "../assets/upload_cloud_image.png";
const nameInputRegex = /^[a-zA-Z0-9- '.\u00c0-\u024f\u1e00-\u1eff]*$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobileRegex = /^[6-9]\d{9}$/;
const websiteRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
const customizedDropdown = (props, currentHover, error, value) => {
  const borderColor = error ? "D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  const focusBorderColor = "yellow"; 
  let titleColor = "#484848"; 
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7"; 
  }
  return {
    title: {
      height: "30.5px",
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    root: {
      width: "160px", 
    },
    dropdown: {
      width: "160px", 
      borderRadius: "4px", 
      selectors: {
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderColor: focusBorderColor, 
        },
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px",
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
      },
    },
  };
};
const empanelmentOptions = [{ key: "Yes", text: "Yes" },];
const empanelmentOptions1 = [{ key: "No", text: "No" }];
const expansionOptions = [{ key: "Yes", text: "Yes  " }];
const expansionOptions1 = [{ key: "No", text: "No" }];
const textFieldColored3 = (props, currentHover, error, value) => {
  const borderColor = "#D9DEE5";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};
const textFieldColored2 = (props, currentHover, error, value) => {
  const borderColor = "#D9DEE5";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};
const buttonStyles = {
  root: {
    marginTop: "3px",
    height: "30px",
    borderRadius: "2px",
    marginRight: "8px",
  },
};
const buttonStyles1 = {
  root: {
    marginTop: "10px",
    width: "145px",
    borderRadius: "8px",
  },
};
const textFieldColored = (props, currentHover, error, value) => {
  const borderColor = "#D9DEE5";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "250px",
      height: "25px",
      marginTop: "2.5px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
    root: {
      marginRight: "30px",
    },
  };
};
const textFieldColored1 = (props, currentHover, error, value) => {
  const borderColor = error ? "D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "350px",
      height: "30px",
      marginTop: "2.5px",
      backgroundColor: "#FFFFFF",
      color: "rgba(102, 102, 102, 1)",
      borderColor: borderColor,
      borderRadius: borderRadius,
      selectors: {
        "&:focus": {
          borderColor: borderColor,
        },
        "&:hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#5B5F62",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
    root: {
      marginRight: "30px",
    },
  };
};
const passthroughOptions = [
  { key: "direct", text: "Direct" },
  { key: "passthrough_company", text: "Passthrough Company" },
  { key: "passthrough_person", text: "Passthrough Person" },
];
const tableCloseIconClass = mergeStyles({
  fontSize: 10,
  height: '12px',
  width: '12px',
  cursor: 'pointer',
  color: 'red',
  marginTop: "3px",
  marginLeft: "8px"
});
const dropDownStyles = mergeStyleSets({
  dropdown: { minWidth: '80px', maxWidth: '120px', minHeight: '20px', },
  title: { height: '22px', lineHeight: '18px', fontSize: '12px', border: '0.5px solid transparent', },
  caretDownWrapper: { height: '22px', lineHeight: '20px !important', },
  caretDown: { color: 'grey' },
  dropdownItem: { minHeight: '22px', fontSize: 12 },
  dropdownItemSelected: { minHeight: '22px', fontSize: 12, },
});
const dropDownActive = mergeStyleSets({
  dropdown: { minWidth: '80px', maxWidth: '120px', minHeight: '20px', },
  title: { height: '22px', lineHeight: '18px', fontSize: '12px', border: '0.5px solid black', },
  caretDownWrapper: { height: '22px', lineHeight: '20px !important', },
  caretDown: { color: 'grey' },
  dropdownItem: { minHeight: '22px', fontSize: 12 },
  dropdownItemSelected: { minHeight: '22px', fontSize: 12, },
});
const dropDownErrorStyles = mergeStyleSets({
  dropdown: { minWidth: '80px', maxWidth: '120px', minHeight: '20px', },
  title: { height: '22px', lineHeight: '18px', fontSize: '12px', border: '0.5px solid #a80000' },
  caretDownWrapper: { height: '22px', lineHeight: '20px !important' },
  dropdownItem: { minHeight: '22px', fontSize: 12 },
  dropdownItemSelected: { minHeight: '22px', fontSize: 12 },
});
const Field = mergeStyleSets({
  fieldGroup: { height: '22px', minWidth: '80px', maxWidth: '120px', border: '0', fontSize: '12px', },
  field: { fontSize: '12px', }
});
const FieldL = mergeStyleSets({
  fieldGroup: { height: '22px', minWidth: '160px', maxWidth: '240px', border: '0 px solid transparent', fontSize: '12px', },
  field: { fontSize: '12px', }
});
const Field1 = mergeStyleSets({
  fieldGroup: { height: '22px', minWidth: '80px', maxWidth: '120px', border: '0.5px solid transparent', backgroundColor: '#EDF2F6', fontSize: '12px' },
  field: { fontSize: '12px' }
});
const FieldError = mergeStyleSets({
  fieldGroup: { height: '22px', minWidth: '80px', maxWidth: '120px', border: '0.5px solid #a80000', backgroundColor: '#EDF2F6', fontSize: '12px' },
  field: { fontSize: '12px' }
});
const dropdownTeam = [
  { key: 'Tag Team', text: 'Tag Team' },
  { key: 'Business Team', text: 'Business Team' },
  { key: 'Others', text: 'Others' },
];
const ViewDealsModal = (props) => {
  const [currentHover, setCurrentHover] = useState('');
  const [fileTitle, setFileTitle] = useState('');
  const [file, setFile] = useState({});
  const [btnIcon, setBtnIcon] = useState('Add');
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropDownCities, setDropDownCities] = useState([]);
  const [dropDownStates, setDropDownStates] = useState([]);
  const [empanelmentOption, setEmpanelmentOption] = useState("");
  const [expansionOption, setExpansionOption] = useState("");
  const navigateTo = useNavigate();
  const hoverHandler = (name) => {
    setCurrentHover(name);
  }
  let defaultbasicInfo = {
    _id: '',
    clientId: '',
    company_name: '',
    designation: '',
    reports_to: '',
    primary_email: '',
    secondary_email: '',
    primary_mobile: '',
    secondary_mobile: '',
    company_address: '',
    point_of_contact_name: '',
    city: '',
    pincode: '',
    website: '',
    linkedin: '',
    empanelment: '',
    expansion: '',
    passthrough: '',
    passthrough_company: "",
    passthrough_person: "",
    documents: [],
    template: []
  }
  const [basicInfo, setBasicInfo] = useState({ ...defaultbasicInfo });
  const [basicInfoerrors, setBasicInfoErrors] = useState({ ...defaultbasicInfo });
  let defaultBasicDetail = {
    contact_person: '',
    designation: '',
    reports_to: '',
    team: '',
    primary_email: '',
    alternate_email: '',
    primary_mobile: '',
    alternate_mobile: '',
  }
  const [basicDetails, setBasicDetails] = useState([{ ...defaultBasicDetail },])
  const [basicDetailserrors, setBasicDetailserrors] = useState([{ ...defaultBasicDetail }]);
  let defaultClientDetail = {
    company_address: '',
    city: '',
    state: '',
    website: '',
    linkedin: '',
    location: '',
  }
  const [clientDetails, setClientDetails] = useState([{ ...defaultClientDetail },])
  const [clientDetailserrors, setClientDetailserrors] = useState([{ ...defaultClientDetail }]);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [clientId, setClientId] = useState("");
  const [resId, setResID] = useState("");
  const [selectedPassthrough, setSelectedPassthrough] = useState("");
  const [linkedData, setLinkedData] = useState([])
  const [visibleData, setVisibleData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    axiosPrivateCall.get(`/api/v1/client/linkedDetails/${searchParams.get("client_id")}`)
      .then(res => setLinkedData(res.data))
      .catch(err => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    axiosJsonCall
      .get("/b/643fa67bebd26539d0ae2903")
      .then((res) => {
        let buffer = res.data.record;
        let dropdown_data = buffer.map((obj) => { return { key: obj.name, text: obj.name } });
        setDropDownCities(dropdown_data)
      })
      .catch((e) => { });
    axiosJsonCall
      .get("/b/643fa973ace6f33a220e556e")
      .then((res) => {
        let buffer = res.data.record;
        let dropdown_data = buffer.map((obj) => { return { key: obj.name, text: obj.name } });
        setDropDownStates(dropdown_data)
      })
      .catch((e) => { });
  }, [])
  const dropDownHandler = (e, item, name, key, setData, setErrors) => {
    setData((prevState) => {
      let update = [...prevState];
      update[key][name] = item.text;
      return update;
    });
    setErrors((prevState) => {
      let update = [...prevState];
      update[key][name] = '';
      return update;
    });
  };
  const ISOdateToCustomDate = (value) => {
    const dateFormat = new Date(value);
    let year = dateFormat.getFullYear();
    let month = dateFormat.getMonth() + 1;
    let date = dateFormat.getDate();
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return date + '/' + month + '/' + year;
  };
  const inputChangeHandler = (e, name, setData, setErrors) => {
    const { value } = e.target
    let inputValue = value
    let isNameValid = false
    if (name === 'company_name' && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40)
      isNameValid = true
    }
    if (name === 'passthrough_company'|| name==='passthrough_person' && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40)
      isNameValid = true
    }
    if (name === 'source_person_name' && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40)
      isNameValid = true
    }
    if (name === 'source_person_designation' && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40)
      isNameValid = true
    }
    if (name === 'expansion' && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40)
      isNameValid = true
    }
    if (name === 'empanelment' && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40)
      isNameValid = true
    }
    if (isNameValid) {
      setData((prevState) => {
        return {
          ...prevState,
          [name]: inputValue
        }
      })
      setErrors((prevState) => {
        return {
          ...prevState,
          [name]: null
        }
      })
    }
  };
 const closeIconClass = mergeStyles({
    fontSize: 16,
    height: "20px",
    width: "20px",
    cursor: "pointer",
  });
  const inputChangeHandler1 = (e, name, key, setData, setErrors) => {
    const { value } = e.target
    let inputValue = value
    let isNameValid = false
    if (name === 'contact_person') {
      isNameValid = true
    }
    if (name === 'company_address') {
      isNameValid = true
    }
    if (name === 'designation') {
      isNameValid = true
    }
    if (name === 'reports_to') {
      isNameValid = true
    }
    if (name === 'primary_email') {
      if (inputValue.length > 320) inputValue = inputValue.slice(0, 320)
      isNameValid = true
    }
    if (name === 'primary_mobile' && (inputValue === '' || !isNaN(inputValue))) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10)
      isNameValid = true
    }
    if (name === 'alternate_email') {
      if (inputValue.length > 320) inputValue = inputValue.slice(0, 320)
      isNameValid = true
    }
    if (name === 'alternate_mobile' && (inputValue === '' || !isNaN(inputValue))) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10)
      isNameValid = true
    }
    if (name === 'state') {
      isNameValid = true
    }
    if (name === 'city') {
      isNameValid = true
    }
    if (name === 'location') {
      isNameValid = true
    }
    if (name === 'linkedin') {
      isNameValid = true
    }
    if (name === 'website') {
      isNameValid = true
    }
    if (isNameValid) {
      setData((prevState) => {
        let update = [...prevState];
        update[key][name] = inputValue;
       return update;
      });
      setErrors((prevState) => {
        let errorupdate = [...prevState];
        errorupdate[key][name] = null;

        return errorupdate;
      });
    }
  };
  useEffect(() => {
    axiosPrivateCall(
      `/api/v1/client/getClientDetails?client_id=${searchParams.get(
        "client_id"
      )}`
    )
      .then((res) => {
        setApiData(res.data);
        setClientId(res.data.ClientId)
        setResID(res.data._id)
        setSelectedPassthrough(res.data.passthrough)
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  function setApiData(data) {
    setEmpanelmentOption(data.empanelment)
    setExpansionOption(data.expansion)
    setSelectedPassthrough(data.passthrough)
    setBasicInfo({
      _id: data._id,
      clientId: data.ClientId,
      company_name: data.company_name,
      designation: data.destination,
      reports_to: data.reports_to,
      primary_email: data.primary_email,
      secondary_email: data.alternate_email,
      primary_mobile: data.primary_mobile,
      secondary_mobile: data.alternate_mobile,
      company_address: data.company_address,
      city: data.city,
      pincode: data.pincode,
      website: data.website,
      linkedin: data.linkedin,
      empanelment: data.empanelment,
      expansion: data.expansion,
      point_of_contact_name: data.point_of_contact_name,
      passthrough_company: data.passthrough_company,
      passthrough_person: data.passthrough_person,
      passthrough: data.passthrough,
      documents: [...data.documents],
    })
  }
  function validate(values) {
    const errors = {}
    if (!values.company_name) {
      errors.company_name = 'Required'
    } else if (!nameInputRegex.test(values.company_name)) {
      errors.company_name = 'Invalid name'
    }
    if (!nameInputRegex.test(values.passthrough_company_name)) {
      errors.passthrough_company_name = 'Invalid name'
    }
    if (!values.source_person_name) {
      errors.source_person_name = 'Required'
    } else if (!nameInputRegex.test(values.source_person_name)) {
      errors.source_person_name = 'Invalid name'
    }
    if (!values.source_person_designation) {
      errors.source_person_designation = 'Required'
    } else if (!nameInputRegex.test(values.source_person_designation)) {
      errors.source_person_designation = 'Invalid name'
    }
    return errors;
  }
 function nestedValidate(values) {
    let errorArr = [];
    values.map((detail) => errorArr.push({}));
    values.map((detail, index) => {
      if (!nameInputRegex.test(detail.contact_person)) {
        errorArr[index].contact_person = 'Invalid name'
      }
      if (detail.primary_email) {
        if (!emailRegex.test(detail.primary_email)) {
          errorArr[index].primary_email = 'Invalid Email Id'
        }
      }
      if (detail.primary_mobile) {
        if (!mobileRegex.test(detail.primary_mobile)) {
          errorArr[index].primary_mobile = 'Invalid Mobile Number'
        }
      }
      if (detail.alternate_email) {
        if (!emailRegex.test(detail.alternate_email)) {
          errorArr[index].alternate_email = 'Invalid Email Id'
        }
      }
      if (detail.alternate_email) {
        if (!mobileRegex.test(detail.alternate_mobile)) {
          errorArr[index].alternate_mobile = 'Invalid Mobile Number'
        }
      }
    })
    return errorArr;
  }
  function nestedValidate1(values) {
    let errorArr = [];
    values.map((detail) => errorArr.push({}));
    values.map((detail, index) => {
      if (!detail.company_address) {
        errorArr[index].company_address = 'Required'
      }
      if (detail.website) {
        if (!websiteRegex.test(detail.website)) {
          errorArr[index].website = 'Invalid Website format'
        }
      }
      if (!detail.state) {
        errorArr[index].state = 'Required'
      }
      if (!detail.city) {
        errorArr[index].city = 'Required'
      }
    })
    return errorArr;
  }
  function sanitizer(obj, arrobj1, arrobj2) {
    let payload = { ...obj };
    payload.basic_details = [...arrobj1]
    payload.client_details = [...arrobj2];
    console.log(payload)
    return payload;
  }
  function submitHandler(e) {
    e.preventDefault();
    let errorsBasicInfoSet;
    let errorsBasicDetailSet;
    let errorsClientDetailSet;
    function analyseError(errorDataSet) {
      let answer = true;
      for (let i = 0; i < errorDataSet.length; i++) {
        if (!(Object.keys(errorDataSet[i]).length === 0)) {
          answer = false;
          break;
        }
      };
      return answer;
    }
    errorsBasicInfoSet = validate(basicInfo)
    errorsBasicDetailSet = nestedValidate(basicDetails);
    errorsClientDetailSet = nestedValidate1(clientDetails);
    let stage1 = (Object.keys(errorsBasicInfoSet).length === 0);
    let stage2 = analyseError(errorsBasicDetailSet);
    let stage3 = analyseError(errorsClientDetailSet);
    if (stage1 && stage2 && stage3) {
      let update = sanitizer(basicInfo, basicDetails, clientDetails);
      axiosPrivateCall.post('/api/v1/client/updateClient', update).then(res => {
        console.log(res)
        submitForm();
      }).catch(e => {
        console.log(e);
      })
    }
    else {
      setBasicInfoErrors(errorsBasicInfoSet);
      setBasicDetailserrors([...errorsBasicDetailSet]);
      setClientDetailserrors([...errorsClientDetailSet]);
      console.log('error');
    }
  }
  function submitForm() {
   navigateTo(`/masterlist/manageclient`);
  };
  function handleRemoveItem(key, setData, setErrors) {
    setData((prevState) => {
      let update = [...prevState];
      let arr1 = update.slice(0, key);
      let arr2 = update.slice((key + 1));
      let newSet = arr1.concat(arr2);
      return newSet;
    });
    setErrors((prevState) => {
      let update = [...prevState];
      let arr1 = update.slice(0, key);
      let arr2 = update.slice((key + 1));
      let newSet = arr1.concat(arr2);
      return newSet;
    });
  };
  function addField(setData, setErrors, defaultData) {
    setData((prevState) => [...prevState, { ...defaultData }]);
    setErrors((prevState) => [...prevState, { ...defaultData }]);
  }
  function UploadHandler() {
    setShowUploadPopup(true);
  }
  function handleDel() {
    setBasicInfo((prev) => {
      let buffer = { ...prev }
      buffer.documents = [];
      return buffer;
    })
  }
  function uploadExcel(e) {
    if (
      e.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.target.files[0].type === "application/vnd.ms-excel" ||
      e.target.files[0].type === "application/excel"
    ) {
      setFileTitle("uploading");
      let files = e.target.files[0];
      let formdata = new FormData();
      formdata.append("files", files);
      axiosPrivateCall
        .post("/api/v1/client/addClientTemplate", formdata)
        .then((res) => {
          console.log(res, "ress")
          setBasicInfo((prevState) => {
            return { ...prevState, template: res.data.template };
          });
          setBasicInfoErrors((prevState) => {
            return { ...prevState, template: "" };
          });
          setFileTitle(" ");
          setBtnIcon("Accept");
        })
        .catch((e) => {
          console.log(e, "err");
        });
      console.log(file);
    } else {
      setBasicInfoErrors((prevState) => {
        return { ...prevState, template: "Invalid" };
      });
      setFileTitle(" ");
      setBtnIcon("Cancel");
      console.log(btnIcon, "lkkk");
    }
  }
  function deleteExcel() {
    setBasicInfo((prev) => {
      let buffer = { ...prev }
      buffer.template = [];
      return buffer;
    })
    setBtnIcon("Add")
  }
  const handlePassthroughChange = (event, item) => {
    setSelectedPassthrough(item.key);
  };
  const close = useCallback(() => {
    let value_temp;
    setBasicInfo((prevState) => {
      value_temp = Object.values(validate(prevState));
      if (value_temp.length === 15) {
      } else {
      }
      return prevState;
    });
  }, [JSON.stringify(basicInfo)]);
  useEffect(() => {
    loadMoreData();
  }, []);
  const loadMoreData = () => {
    const newData = linkedData.slice(0, visibleData.length + 3);
    setVisibleData(newData);
    if (newData.length >= linkedData.length) {
      setHasMore(false); 
    }
  };
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore) {
      loadMoreData();
    }
  };
  return (
    <div className={styles.pages}>
      <div className={styles.addcandidate_modal_header_container}>
        <div className={styles.header_tag_expand_close_icon_container}>
          <div className={styles.header_tag_container}>Client</div>
          <div className={styles.header_expand_close_icon_container}>
            <div className={styles.header_expand_icon_container}>
            </div>
            <div onClick={() => navigateTo(-1)} className={styles.header_close_icon_container}>
              <Icon iconName="ChromeClose" className={closeIconClass} />
            </div>
          </div>
        </div>
        <div className={styles.header_content_container}>
          <div className={styles.header_content_title_container}>
            <div className={styles.header_content_title_container}>Client ID : {basicInfo.clientId}</div>
            <br />
            <div className={styles.dropdownRow}>
              <div className={styles.customer_name}>
                <Label className={styles.name_label_style} required>
                  Company Name
                </Label>
                <TextField placeholder="Enter the Company Name" styles={textFieldColored1} value={basicInfo.company_name} disabled
                  style={{ backgroundColor: "transparent" }} />
              </div>
              <div>
                <PrimaryButton
                  onClick={() => navigateTo(`/masterlist/editclient?client_id=${searchParams.get("client_id")}`)}
                >
                  <img src={Editicon} alt="image" style={{ marginRight: "5px", width: "16px", height: "16px" }} />
                  <span style={{ fontSize: "16px", fontWeight: "600", paddingBottom: "3px" }}>Edit</span>
                </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.addemployee_modal_main_container}>
          <div className={styles.main_filter_options_container}>
            <div className={styles.subcontainer}>
              <div className={styles.main_dropdown_container}>
                <div className={styles.main_repotingmanager_title}>
                  <Label required className={styles.name_label_style}>
                    Point of Contact Name
                  </Label>
                </div>
                <div className={styles.textfield}>
                  <TextField
                    type="text"
                    name="company_name"
                    onChange={(e) => {
                      inputChangeHandler(e, "company_name", setBasicInfo, setBasicInfoErrors);
                    }}
                     disabled
                      style={{backgroundColor:"transparent"}}
                    value={basicInfo.point_of_contact_name}
                    placeholder={"Enter the Name"}
                    styles={textFieldColored}
                  />
                </div>
              </div>
            </div>
            <div className={styles.subcontainer}>
              <div className={styles.main_dropdown_container}>
                <div className={styles.main_repotingmanager_title}>
                  <Label required className={styles.name_label_style}>
                    Designation
                  </Label>
                </div>
                <div className={styles.textfield}>
                  <TextField
                    type="text"
                    name="source_person_name"
                    onChange={(e) => {
                      inputChangeHandler(e, "source_person_name", setBasicInfo, setBasicInfoErrors);
                    }}
                     disabled
                      style={{backgroundColor:"transparent"}}
                    value={basicInfo.designation}
                    placeholder={`Enter the Designation`}
                    styles={textFieldColored}
                  />
                </div>
              </div>
            </div>
            <div className={styles.subcontainer}>
              <div className={styles.main_dropdown_container}>
                <div className={styles.main_repotingmanager_title}>
                  <Label className={styles.name_label_style}>Reports to</Label>
                </div>
                <div className={styles.textfield}>
                  <TextField
                    type="text"
                    name="source_person_designation"
                    onChange={(e) => {
                      inputChangeHandler(e, "source_person_designation", setBasicInfo, setBasicInfoErrors);
                    }}
                    value={basicInfo.reports_to}
                     disabled
                      style={{backgroundColor:"transparent"}}
                    placeholder={"Enter the Name"}
                    styles={textFieldColored}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.main_box_container}>
            <div className={styles.firstBox}>
              <div className={styles.box}>
                <p className={styles.P_customerDetains}>Customer Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      Primary Mail ID
                    </Label>
                    <TextField
                      placeholder="Enter the Email ID"
                      onChange={(e) => {
                        inputChangeHandler(e, "customer_name");
                        setCurrentHover("");
                      }}
                      styles={textFieldColored2}
                      className={styles.input}
                      disabled
                      style={{backgroundColor:"transparent"}}
                      value={basicInfo.primary_email}
                    />
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Secondary Email ID</Label>
                    <TextField
                      placeholder="Enter the Email ID"
                      onChange={(e) => {
                        inputChangeHandler(e, "email_id");
                        setCurrentHover("");
                      }}
                      styles={textFieldColored2}
                       disabled
                      style={{backgroundColor:"transparent"}}
                      value={basicInfo.secondary_email? basicInfo.secondary_email : " "}
                    />
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      Primary Mobile Number
                    </Label>
                    <TextField
                      placeholder="Enter the Mobile"
                      onChange={(e) => {
                        inputChangeHandler(e, "mobile_number");
                        setCurrentHover("");
                      }}
                      styles={textFieldColored2}
                      style={{ "::placeholder": { color: "yellow" } ,backgroundColor:"transparent"}}
                       disabled
                      value={basicInfo.primary_email? basicInfo.primary_mobile : " "}
                    />
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Secondary Mobile Number</Label>
                    <TextField
                      placeholder="Enter the Mobile"
                      onChange={(e) => {
                        inputChangeHandler(e, "email_id");
                        setCurrentHover("");
                      }}
                      styles={textFieldColored2}
                       disabled
                      style={{backgroundColor:"transparent"}}
                      value={basicInfo.secondary_mobile? basicInfo.secondary_mobile : " "}
                    />
                  </div>
                </div>
                </div>
                <br />
                <div className={styles.boxClient}>
                  <p className={styles.P_AC} style={{width: 100}}>Client Details</p>
                  <div className={styles.customerDetailsContainer}>
                    <div className={styles.C_D_container}>
                      <div className={styles.EM_container}>
                      <div className={styles.EMi}>
                        <Label className={styles.customer_label_styles}>Empanelment</Label></div>
                      <div className={styles.radioButtons}>
                        <ChoiceGroup
                          selectedKey={empanelmentOption}
                          options={empanelmentOptions} className={styles.radioGroup} />
                        <ChoiceGroup
                          selectedKey={empanelmentOption}
                          options={empanelmentOptions1} className={styles.radioGroup} />
                      </div>
                    </div>
                    <div className={styles.EM_container}>
                      <div className={styles.EMi}>
                        <Label className={styles.customer_label_style1}>Expansion</Label></div>
                        <div className={styles.radioButtonsone}>
                          <ChoiceGroup selectedKey={expansionOption} options={expansionOptions} className={styles.radioGroup} />
                          <ChoiceGroup selectedKey={expansionOption} options={expansionOptions1} className={styles.radioGroup} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.boxPassthrougt}>
                  <p className={styles.P_AC} style={{width: 140}}>Passthrough Details</p>
                  <div className={styles.customerDetailsContainer}>
                    <div className={styles.textField_customerDetails1}>
                      <Label className={styles.customer_label_style}>Passthrough</Label>
                    <TextField
                      placeholder="Enter the Mobile"
                      onChange={(e) => {
                        inputChangeHandler(e, "email_id");
                        setCurrentHover("");
                      }}
                      styles={textFieldColored2}
                      disabled
                      style={{ backgroundColor: "transparent" }}
                      value={basicInfo.passthrough ? basicInfo.passthrough : " "}
                    />
                  </div>
                  {selectedPassthrough === "passthrough_company" && (
                    <div className={styles.textField_customerDetails_11}>
                      <Label className={styles.customer_label_style} required>
                        Passthrough Company
                      </Label>
                      <TextField
                        placeholder="Enter the Name"
                        onChange={(e) => {
                          inputChangeHandler(e, "passthrough_company");
                          setCurrentHover("");
                        }}
                        value={basicInfo.passthrough_company}
                        disabled
                        styles={textFieldColored2}
                        style={{ "::placeholder": { color: "yellow" }, backgroundColor: "transparent" }}
                      />
                    </div>
                  )}
                    {selectedPassthrough === "passthrough_person" && (
                      <div className={styles.textField_customerDetails_11}>
                        <Label className={styles.customer_label_style} required>
                          Passthrough Person
                        </Label>
                        <TextField
                          placeholder="Enter the Name"
                          onChange={(e) => {
                            inputChangeHandler(e, "passthrough_person");
                            setCurrentHover("");
                          }}
                            value={basicInfo.passthrough_person}
                          disabled
                          styles={textFieldColored2}
                          style={{ "::placeholder": { color: "yellow" },backgroundColor:"transparent" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>  
            <div className={styles.firstBox}>
              <div className={styles.boxCompany}>
                <p className={styles.P_timeline}>Company Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails2}>
                    <Label className={styles.customer_label_style} required>
                      Company Address
                    </Label>
                    <TextField placeholder="Enter the Address" 
                    notifyOnReselect styles={textFieldColored3}
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    value={basicInfo.company_address ? basicInfo.company_address : " "} />
                </div>
              </div>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style} required>
                    City
                  </Label>
                  <TextField placeholder="Enter the City"
                    notifyOnReselect styles={textFieldColored2}
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    value={basicInfo.city ? basicInfo.city : " "} />
                </div>
                <div className={styles.textField_customerDetails_1}>
                  <Label className={styles.customer_label_style} required>
                    Pincode
                  </Label>
                  <TextField placeholder="Enter the Pincode"
                    notifyOnReselect styles={textFieldColored2}
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    value={basicInfo.pincode ? basicInfo.pincode : " "} />
                </div>
              </div>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.textField_customerDetails}>
                  <Label className={styles.customer_label_style}>Website</Label>
                  <TextField placeholder="Enter the Link"
                    notifyOnReselect styles={textFieldColored2}
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    value={basicInfo.website ? basicInfo.website : " "} />
                </div>
                <div className={styles.textField_customerDetails_1}>
                  <Label className={styles.customer_label_style}>Linkedin</Label>
                  <TextField placeholder="Enter the Link"
                    notifyOnReselect styles={textFieldColored2}
                    disabled
                    style={{ backgroundColor: "transparent" }}
                    value={basicInfo.linkedin ? basicInfo.linkedin : " "} />
                </div>
              </div>
            </div>
            <div className={styles.boxSubmission}>
              <p className={styles.P_AC} style={{ width: 190 }}>Submission Client Template</p>
              <div className={styles.customerDetailsContainer}>
                <div className={styles.textField_customerDetails}>
                  <PrimaryButton text="Attach Template" styles={buttonStyles1} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.firstBox}>
            <div className={styles.boxLinked} >
              <p className={styles.P_timeline}>Linked Opportunities</p>
              <div className={styles.contentWrapper} onScroll={handleScroll}>
                {linkedData.length!=0 ?
                  linkedData.map(data => (
                    <div className={styles.linkedboxContainer}>
                      <p className={styles.content_level}>{data.level}</p>
                      <div className={styles.contentContainer}>
                        <p onClick={() => navigateTo(`/managedeals/viewandeditopportunity?_id=${data._id}`)}>{data.Opportunity_id}</p>
                        <p className={styles.content}>{data.name}</p>
                      </div>
                      <div className={styles.contentContainer}>
                        <div className={styles.updateContent}>
                          Updated  {ISOdateToCustomDate(data.updatedAt)}</div>
                        <Icon
                          iconName="LocationDot"
                          style={{
                            fontSize: "16px",
                            color: data.status === "Won" ? "#13A10E" :
                              (data.status === "Lost" || data.status === "Drop") ? "#DE1414" : "#BEBDBD"
                          }}
                        /><p>{data.status}</p>
                      </div>
                    </div>
                  )) :
                  <a href="#" className={styles.noLinked}>
                    No Linked Opportunities
                  </a>
                }
              </div>
            </div>
            <div className={styles.boxRightBottom}>
                <p className={styles.P_customerDetains}>Attachments</p>
                <div className={styles.boxWithDottedLines}>
                  <img className={styles.uploadImage} src={uploadImage} />
                  <p className={styles.fileTypeText}>PDF, JPG, PNG, DOC below 1 Mb</p>
                  <p className={styles.infoText}>You can also upload files by</p>
                  <div>
                    <PrimaryButton
                      text="Browse Files"
                      className={styles.uploadButton}
                      styles={buttonStyles}
                    />               
                  </div>              
                  <div></div>
                </div>
                  <div className={styles.bottomContainer}>
                  {basicInfo.documents.map((doc, index) => (
                    <div className={styles.spacer} key={index}>
                      <a href={doc.document}>{doc.document_name}</a>                  
                    </div>
                  ))}
                </div>
              </div>
</div>
        </div>
      </div>
    </div>
  )
}
export default ViewDealsModal;
import React, { useEffect, useState, useRef } from "react";
import AddSubmissionModal from "./AddSubmissionModal";
import { DirectionalHint } from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import DemandSubmissionListing from "./demandSubmissionListing";
import DemandInterviewListing from "./DemandInterviewListing";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { axiosPrivateCall } from "../constants";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { PrimaryButton, DatePicker, FontIcon, Stack, Callout, CommandBarButton,SearchBox, Label, Shimmer } from "@fluentui/react";
import styles from "./DemandHistory.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import InfiniteScroll from "react-infinite-scroll-component";
import Nomatchimg from "../assets/no.png";
import NoDataFound from "../assets/Nodatafound.png";
import Orange_dot from "../assets/Green_dot.svg";
import Green_dot from "../assets/Orange_dot.svg";
import { ISOdateToCustomDate, calcTotalExp } from "../utils/helpers";

const searchIcon = { iconName: "Search" };


const iconClass = mergeStyles({
  fontSize: 20,
  width: 20,
  color: "#999DA0",
  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const iconClass1 = mergeStyles({
  fontSize: 12,
  height: 12,
  width: 12,
  margin: "0 ",
  color: "#999DA0",
  cursor: "pointer",
});

const iconClassToast = mergeStyles({
  fontSize: 24,
  height: 24,
  width: 24,
});

const iconErrorClassToast = mergeStyles({
  ...iconClassToast,
  color: "#FDE7E9",
});

const messageBarStyles = {
  content: {
    maxWidth: 220,
    minWidth: 250,
  },
};

const textFieldColored = (props, currentHover, error, value) => {
  const borderColor = error ? "D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#484848";
  }

  const borderRadius = "4px";

  return {
    fieldGroup: {
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#484848",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
        },
      },
    },
  };
};

const DropdownStyles = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#484848";
  }
  return {
    title: {
      height: "29px",
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      borderRadius: "4px",
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px",
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: "4px",
        },
      },
    },
  };
};

const submitStyle = (props, currentHover, error, value) => {
  const borderColor = "#0078D4";
  const hoverColor = "#E1E5E8";
  const fixedWidth = "155px";
  const importantRule = "!important";

  return {
    title: {
      height: "27.5px",
      background: "#0078D4",
      display: "flex",
      alignItems: "center",
      color: "#FFFFFF" + importantRule,
      position: "relative",
      fontSize: "12px",
      width: "155px",
      minWidth: fixedWidth,
      minHeight: "27px",
      overflowX: "hidden",
      textOverflow: "ellipsis",
      marginTop: "1px",
    },
    dropdown: {
      width: "155px",
      minWidth: fixedWidth,
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-option": {
          borderColor: borderColor,
          color: "#FFFFFF" + importantRule,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderColor: hoverColor,
          color: "#FFFFFF" + importantRule,
        },
        ":focus": {
          borderColor: borderColor,
          color: "#FFFFFF" + importantRule,
        },
        ":hover": {
          borderColor: hoverColor,
        },
      },
    },
    fieldGroup: {
      ":hover": {
        borderColor: hoverColor,
      },
    },
    dropdownItem: {
      minHeight: "22px",
      fontSize: 12,
      width: "155px",
      maxWidth: fixedWidth,
    },
    dropdownItemSelected: {
      minHeight: "24px",
      fontSize: 12,
    },
    caretDown: {
      color: "#FFFFFF" + importantRule,
      ":hover": {
        color: "#FFFF00",
      },
    },
  };
};

const CalloutNameStyles = {
  calloutMain: {
    background: "#EDF2F6",
    padding: "2",
  },
};

const searchField = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      paddingLeft: "20px",
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      input: {
        color: "red",
        "&::placeholder": {
          color: "#5B5F62",
          fontSize: 12,
        },
      },
    },
    icon: {
      left: "8px",
      top: "6px",
      pointerEvents: "none",
      position: "absolute",
      color: "#201F1E",
    },
  };
};

const submitOption = [
  { key: "advance submission", text: "Advance Submission " },
  { key: "quick submission", text: "Quick Submission" },
];

let Shimmeritems = Array(4).fill(null);

const filterOption = [
  { key: "approval_status", text: "Approval Status" },
  { key: "candidate_id", text: "Candidate ID" },
  { key: "email_id", text: "Email ID" },
  { key: "status", text: "Status" },
];

const approvalStatusOption = [
  { key: "waiting", text: "Waiting" },
  { key: "selected", text: "Selected" },
  { key: "rejected", text: "Rejected" },
];

const statusOption = [
  { key: "Active", text: "Active" },
  { key: "In progress", text: "In progress" },
];

function DemandHistory() {
  const [activeArrow, setActiveArrow] = useState("sourced");
  const [historyData, setHistoryData] = useState([]);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [hoverCallout, setHoverCallout] = useState("");
  const [submissionData, setSubmissionData] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [isInterviewLoaded, setIsInterviewDataLoaded] = useState(false);
  const [isSubmissionDataLoaded, setIsSubmissionDataLoaded] = useState(false);
  const [offeredData, setOfferedData] = useState([]);
  const [isOfferedDataLoaded, setIsOfferedDataLoaded] = useState(false);
  const [joinedData, setJoinedData] = useState([]);
  const [isJoinedLoaded, setIsJoinedDataLoaded] = useState(false);
  const [selectedStatusOption, setSelectedStatusOption] = useState("");
  const [selectedApprovalStatusOption, setSelectedApprovalStatusOption] = useState("");
  const [commentPopups, setCommentPopups] = useState({});
  const [interviewCount, setInterviewCount] = useState(0);
  const [isSubRejError, setSubRejError] = useState(false);
  const [rejCommand, setRejeCommand] = useState("");
  const [isSubAdd, setSubAdd] = useState(false);
  const [isSubRej, setSubRej] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const demandId = searchParams.get("demand_id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const demandViewId = localStorage.getItem("demandViewId");
  const [submitStatus, setSubmitStatus] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [fetchOptions, setFetchOptions] = useState({ skip: 0, limit: 15, sort_field: "updatedAt", sort_type: -1 });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [showSubStauts, setShowSubStatus] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [acceptHovered, setAcceptHovered] = useState(false);
  const [rejectHovered, setRejectHovered] = useState(false);
  const [hoverTarget, setHoverTarget] = useState(null);
  const [currentHover, setCurrentHover] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [candidateObjId, setCandidateObjID] = useState("");
  const [candidateId, setCandidateID] = useState("");
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [submissionMade, setSubmissionMade] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [load,setLoad]=useState(false);


  const navigateTo = useNavigate();
  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  let user_Id = decodedValue.user_id;
  const isTeamLead = decodedValue.user_role === "team_lead";
  const isAdmin = decodedValue.user_role === "admin";
  const isAccountManager = decodedValue.user_role === "account_manager";

  const [errors, setErrors] = useState({
    skillset: [
      {
        skill: "",
        years: "",
        months: "",
      },
    ],
  });

  const approvalColumn = (isAdmin || isAccountManager || isTeamLead) && {
    columnKey: "Approval",
    label: "Approval",
  };

  const columns = [
    {
      columnKey: " ",
      label: " ",
    },
    {
      columnKey: "CandidateID",
      label: "Candidate ID",
    },
    {
      columnKey: "CandidateName",
      label: "Candidate Name",
    },
    {
      columnKey: "DateofSourcing",
      label: "Date of Sourcing",
    },
    {
      columnKey: "Mobile",
      label: "Mobile",
    },
    {
      columnKey: "email",
      label: "Email ID",
    },
    {
      columnKey: "Recruiter",
      label: "Recruiter",
    },
    {
      columnKey: "Primary Skill",
      label: "Primary Skill ",
    },

    {
      columnKey: "SecondarySkill",
      label: "Secondary Skill ",
    },

    {
      columnKey: "TotalExperience",
      label: "Total Experience",
    },
    {
      columnKey: "Resume",
      label: "Resume",
    },
    {
      columnKey: "Status",
      label: "Status",
    },
    {
      columnKey: "Approval Status",
      label: "Approval Status",
    },
    approvalColumn,
  ];

  const handleAcceptIconHover = (event, commandId) => {
    setHoverTarget({ x: event.clientX, y: event.clientY });
    setAcceptHovered(commandId);
  };

  const handleAcceptIconLeave = () => {
    setAcceptHovered(null);
  };

  const handleRejectIconHover = (event, commandId) => {
    setHoverTarget({ x: event.clientX, y: event.clientY });
    setRejectHovered(commandId);
  };

  const handleRejectIconLeave = () => {
    setRejectHovered(null);
  };

  const handleIconSelect = (iconName) => {
    if (selectedIcon !== iconName) {
      setSelectedIcon(iconName);
    } else {
      setSelectedIcon(null);
    }
  };

  useEffect(() => {
    getCandidateData();
    setHasMore(true);
    setFetchOptions({ ...fetchOptions, skip: 0, limit: 15 });
  }, [fetchOptions.sort_field, fetchOptions.sort_type]);


  useEffect(() => {
    if (isSubAdd) {
      setTimeout(() => {
        setSubAdd(false);
      }, 3000);
    } else if (isSubRej) {
      setTimeout(() => {
        setSubRej(false);
      }, 3000);
    } else if (isSubRejError) {
      setTimeout(() => {
        setSubRejError(false);
      }, 3000);
    } else if (showSubStauts) {
      setTimeout(() => {
        setShowSubStatus(false);
      }, 2000);
    }
  }, [isSubAdd, isSubRej, isSubRejError, showSubStauts]);

  const getCandidateData = () => {
    setIsDataLoaded(false);
    if (user_Id) {
      axiosPrivateCall
        .get(`/api/v1/submission/getSubmissionApproval?id=${user_Id}&skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then((res) => {
          setIsDataLoaded(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleRejectSubmission = (demandId) => {
    setCommentPopups((prevCommentPopups) => {
      if (prevCommentPopups[demandId]) {
        return prevCommentPopups;
      }
      const updatedPopups = {
        ...prevCommentPopups,
        [demandId]: true,
      };
      Object.keys(updatedPopups).forEach((key) => {
        if (key !== demandId) {
          updatedPopups[key] = false;
        }
      });

      return updatedPopups;
    });
  };

  const handleSaveCommentBox = async (demandId, candidateId, demand) => {
    const submissionData = {
      _id: demandId,
      message: "reject",
    };
    try {
      await axiosPrivateCall.post("/api/v1/submission/getSubmissionApprovalUpdate", submissionData);
      const candidateData = {
        _id: candidateId,
        command: { content: rejCommand, created_by: user_Id, demand: demand, profile_status: "Rejected" },
      };
      try {
        const candidateResponse = await axiosPrivateCall.post("/api/v1/candidate/updateCandidateCommand", candidateData);
        handleCloseCommentBox(candidateId);
        setRejeCommand("");
        setSubRej(true);
      } catch (candidateError) {
        console.log("Candidate Error:", candidateError);
      }
      setSubRej(true);
      demandHandler();
    } catch (err) {
      setSubRejError(true);
      console.log("Error:", err);
    }
    setCommentPopups((prevCommentPopups) => ({
      ...prevCommentPopups,
      [demandId]: false,
    }));
    updateStatus(demandId, "Rejected", rejCommand);
    handleCloseCommentBox(demandId);
  };

  const handleCloseCommentBox = async (demandId) => {
    setCommentPopups((prevCommentPopups) => ({
      ...prevCommentPopups,
      [demandId]: false,
    }));
  };

  const handleClick = (id) => {
    setActiveArrow(id);
  };

  const offeredjoinedcolumns = [
    {
      columnKey: " ",
      label: " ",
    },
    {
      columnKey: "DemandID",
      label: "Demand ID",
    },
    {
      columnKey: "CandidateID",
      label: "Candidate ID",
    },
    {
      columnKey: "CandidateName",
      label: "Candidate Name",
    },
    {
      columnKey: "Mobile",
      label: "Mobile",
    },
    {
      columnKey: "email",
      label: "Email ID",
    },
    {
      columnKey: "Client",
      label: "Client",
    },
    {
      columnKey: "OnboardDate",
      label: "Onboard Date",
    },

    {
      columnKey: "ExpectedCTC",
      label: "Expected CTC",
    },

    {
      columnKey: "OfferedCTC",
      label: "Offered CTC",
    },
    {
      columnKey: "BillingRate",
      label: "Billing Rate",
    },
    {
      columnKey: "%Fee",
      label: "% Fee",
    },
    {
      columnKey: "Status",
      label: "Status",
    },
  ];

  const getColorForStatus = (status) => {
    switch (status) {
      case "Selected":
        return "#13A10E";
      case "Rejected":
        return "#E80B0B";
      default:
        return "#0078D4";
    }
  };

  const highlightText = (text, query) => {
    if (!query) {
      return text;
    }
    const regex = new RegExp(`(${query})`, "gi");
    return text.split(regex).map((part, index) => (regex.test(part) ? <span key={index}>{part}</span> : part));
  };

  const submitDropdownHandler = (e, item, action) => {
    if (item.key === "advance submission") {
      setIsModalOpen(true);
      setSubmitStatus(item.key);
      setSelectedKey(null);
      setSubmitStatus("")
    } else {
      setIsModalOpen(false);
      setSubmitStatus(item.key);
      setSelectedKey(item.key); 

    }
  };

  function HandleSubmission() {
    setIsDataLoaded(false);
    setIsInterviewDataLoaded(false);
    setIsSubmissionDataLoaded(true);
    setIsOfferedDataLoaded(false);
    setIsJoinedDataLoaded(false);
  }

  function HandleSouredProfile() {
    setIsDataLoaded(true);
    setIsInterviewDataLoaded(false);
    setIsSubmissionDataLoaded(false);
    setIsOfferedDataLoaded(false);
    setIsJoinedDataLoaded(false);
  }

  function HandleInterview() {
    setIsDataLoaded(false);
    setIsSubmissionDataLoaded(false);
    setIsInterviewDataLoaded(true);
    setIsOfferedDataLoaded(false);
    setIsJoinedDataLoaded(false);
  }

  function HandleOffered() {
    setIsDataLoaded(false);
    setIsSubmissionDataLoaded(false);
    setIsInterviewDataLoaded(false);
    setIsOfferedDataLoaded(true);
    setIsJoinedDataLoaded(false);
  }

  function HandleJoined() {
    setIsDataLoaded(false);
    setIsSubmissionDataLoaded(false);
    setIsInterviewDataLoaded(false);
    setIsOfferedDataLoaded(false);
    setIsJoinedDataLoaded(true);
  }

  function formatIndianNumber(number) {
    let num = number.toString().split("");
    let lastThree = num.slice(-3);
    let otherNumbers = num.slice(0, -3);
    if (otherNumbers.length > 0) {
      lastThree.unshift(",");
    }
    let formattedNumber = otherNumbers.join("").replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree.join("");
    return formattedNumber;
  }

  const handleDownload = (key) => {
    setLoading(true);
    setTimeout(() => {
      let downloadUrl = `/api/v1/demand/downloadDemandHistroy?demandId=${demandId}&key=${key}`;
      axiosPrivateCall
        .get(downloadUrl, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 4000);
        })
        .catch((error) => {
          console.log(`Download Error (${key}):`, error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
  };

  const demandHandler = () => {
    setLoad(true);
    axiosPrivateCall(`/api/v1/demand/demandHistroyController?demand_id=${searchParams.get("demand_id")}&skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
      .then((res) => {
        const selectedCommands = res.data.data.match.filter((data) => data.command.profile_status === "Selected").map((data) => data.submissionData);
        setSubmissionData(selectedCommands);
        setInterviewData(res.data?.data?.demand?.interviewData);
        setInterviewCount(res.data?.data?.demand?.interviews?.length);
        setOfferedData(res.data.data.demand.offered);
        setJoinedData(res.data.data.demand.joined);
        setHistoryData(res.data.data);
        setHistoryTableData(res.data.data.match);
        setIsDataLoaded(true);
        setLoad(false)
      })
      .catch((err) => {
        console.log(err);
      });
    };
      
  useEffect(() => {
    demandHandler();
  }, [submissionMade,isSubAdd, isSubRej]);

  const handleSearchChange = (e, newValue) => {
    setSearchQuery(newValue || "");
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const statusOptionSelect = (e, item) => {
    setSelectedStatusOption(item.text);
  };

  const approvalStatusSelect = (e, item) => {
    setSelectedApprovalStatusOption(item.text);
  };

  function openResume(resumeString) {
    const lines = resumeString.split(/\r?\n/);
    const promptLines = [];
    for (let i = 0; i < lines.length; i++) {
      let line = lines[i].trim();
      if (line.startsWith("?")) {
        promptLines.push(`\n${line.replace("?", "")}`);
      } else if (line.includes(":")) {
        const [key, value] = line.split(":");
        promptLines.push(`\n- ${key.trim()}: ${value.trim()}`);
      } else if (line.length > 0) {
        promptLines.push(line);
      }
    }
    var newTab = window.open();
    newTab.document.open();
    newTab.document.write(promptLines.join("\n"));
    newTab.document.close();
  }

  const filteredData = historyTableData.filter((item) => {
    const searchLower = searchQuery.toLowerCase().trim();
    const filterOptionValue = searchFilter;
    const totalExperience = calcTotalExp(item.employment_details);
    const totalExperienceString = `${totalExperience.years} Years ${totalExperience.months} Months`;

    const getString = (str) => (str ? str.toLowerCase() : "");

    if (!filterOptionValue) {
      return (
        getString(item.CandidateId).includes(searchLower) ||
        getString(`${item.first_name} ${item.last_name}`).includes(searchLower) ||
        getString(item.email).includes(searchLower) ||
        getString(item.mobile_number).includes(searchLower) ||
        item.skillset.some((skill) => getString(skill.skill).includes(searchLower)) ||
        getString(item.status).includes(searchLower) ||
        getString(item.approvedStatus).includes(searchLower) ||
        getString(totalExperienceString).includes(searchLower)
      );
    } else if (filterOptionValue === "candidate_id") {
      if (!searchQuery && filterOptionValue === "candidate_id") {
        return true;
      } else {
        return getString(item.CandidateId).includes(searchLower);
      }
    } else if (filterOptionValue === "email_id") {
      if (!searchQuery && filterOptionValue === "email_id") {
        return true;
      } else {
        return getString(item.email).includes(searchLower);
      }
    } else if (filterOptionValue === "status") {
      if (!selectedStatusOption) {
        return true;
      }
      if (getString(selectedStatusOption) === "active") {
        return getString(item.status) === "active" || getString(item.status) === "available";
      }
      return getString(item.status) === getString(selectedStatusOption);
    } else if (filterOptionValue === "approval_status") {
      if (!selectedApprovalStatusOption) {
        return true;
      }
      return getString(item.command.profile_status) === getString(selectedApprovalStatusOption);
    } else {
      return false;
    }
  });

 
  const dropDownFilterChange = (e, item) => {
    setSearchFilter(item.key);
    setSelectedStatusOption("");
    setSelectedApprovalStatusOption("");
  };

  const clickSortHandler = (key) => {
    if (!isDataLoaded) return;
    if (key === "first_name") {
      setFetchOptions({
        ...fetchOptions,
        sort_field: key,
        sort_type: fetchOptions.sort_type === -1 ? 1 : -1,
      });
    }
    if (key === "createdAt") {
      setFetchOptions({
        ...fetchOptions,
        sort_field: key,
        sort_type: fetchOptions.sort_type === -1 ? 1 : -1,
      });
    }
  };

  function submissionSanitizer(candidateObjId) {
    return { demand: demandId, candidate: candidateObjId, approval: true };
  }

  async function createSubmission(e) {
    e.preventDefault();
    if (!candidateId) {
      setHasError(true);
      return;
    }
    setHasError(false);
    const candidateObjId = await getCandidateDetails(candidateId);

    if (demandId && candidateObjId) {
      try {
        const res = await axiosPrivateCall.post("/api/v1/submission/createSubmission", submissionSanitizer());
        if (res.status === 200) {
          setShowSubStatus(true);
          demandHandler();
          setShowSubStatus(true);
          setSubmitStatus("")
          setSelectedKey("")
          setSubmissionMade((prev) => !prev);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("Required data is missing, cannot submit");
    }
  }

  async function getCandidateDetails(id) {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/candidate/getCandidateViewDetails?candidate_id=${id}`);
      setCandidateObjID(res.data._id);
      return res.data._id;
    } catch (e) {
      console.log(e);
    } finally {
      setCandidateID("");
    }
  }

  useEffect(() => {
    if (candidateId) {
      getCandidateDetails(candidateId);
    }
  }, []);

  const addEllipsisToName = (name) => {
    if (name.length > 15) {
      return `${name.substring(0, 12)}...`;
    }
    return name;
  };

 

  const handleAcceptSubmission = async (commandId, candidate, demand) => {
    const submission_data = {
      _id: commandId,
      message: "accept",
    };
    try {
      const submissionResponse = await axiosPrivateCall.post("/api/v1/submission/getSubmissionApprovalUpdate", submission_data);
      const candidateData = {
        _id: candidate,
        command: { content: rejCommand, created_by: user_Id, demand: demand, profile_status: "Selected" },
      };
      try {
        const candidateResponse = await axiosPrivateCall.post("/api/v1/candidate/updateCandidateCommand", candidateData);
      } catch (candidateError) {
        console.log("Candidate Error:", candidateError);
      }
      demandHandler();
      setSubAdd(true);
    } catch (submissionError) {
      console.log("Submission Error:", submissionError);
    }
    const index = historyTableData.findIndex((history) => history.command._id === commandId);
    if (index !== -1) {
      const updatedHistoryTableData = [...historyTableData];
      updatedHistoryTableData[index] = {
        ...updatedHistoryTableData[index],
        command: {
          ...updatedHistoryTableData[index].command,
          profile_status: "Selected",
        },
      };
      setHistoryTableData(updatedHistoryTableData);
      fetch("/api/v1/candidate/updateCandidateCommand", {
        method: "POST",
        body: JSON.stringify({
          commandId: commandId,
          candidate: candidate,
          demand: demand,
          status: "Selected",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Status updated successfully:", data);
        })
        .catch((error) => {
          console.error("Error updating status:", error);
        });
    } else {
      console.error("Item not found in historyTableData");
    }
  };

  const updateStatus = (id, status, comment = "") => {
    const updatedHistory = historyTableData.map((item) => {
      if (item._id === id) {
        const updatedItem = { ...item };
        const lastCommand = updatedItem.command[updatedItem.command.length - 1];
        updatedItem.command[updatedItem.command.length - 1] = { ...lastCommand, profile_status: status, comment };
        return updatedItem;
      }
      return item;
    });
    setHistoryTableData(updatedHistory);
  };

  return (
    <div>
      <>
        <div className={styles.image_container}>
          <div className={styles.tag_wrap}>
            <div className={styles.white_arrow}>
              <div className={styles.demand_id_header}>Demand ID</div>
              <div className={styles.demand_id}>{demandViewId ? demandViewId : ""}</div>
            </div>
          </div>

          <div className={styles.tag_wrap1}>
            <div className={activeArrow === "sourced" ? styles.blue_arrow : styles.white_arrow}>
              <img className={styles.source_icon} src={historyTableData.length !== 0 ? Orange_dot : Green_dot} alt="status" />
              <div
                className={styles.source_profile_header}
                onClick={() => {
                  handleClick("sourced");
                  HandleSouredProfile();
                }}
              >
                <span>Sourced profiles</span>
              </div>
              <div className={styles.source_profile_value}>{`${submissionData.length}/${historyTableData.length}`}</div>
            </div>
          </div>

          <div className={styles.tag_wrap2}>
            <div className={activeArrow === "submission" ? styles.blue_arrow : styles.white_arrow}>
              <img className={styles.submission_icon} src={submissionData.length !== 0 ? Orange_dot : Green_dot} alt="status" />
              <div
                className={styles.submission_id_header}
                onClick={() => {
                  handleClick("submission");
                  HandleSubmission();
                }}
              >
                {submissionData.length !== 0 ? "Submissions" : "Submission"}
              </div>
              <div className={styles.submission_id}>{submissionData.length}</div>
            </div>
          </div>

          <div className={styles.tag_wrap3}>
            <div className={activeArrow === "interviews" ? styles.blue_arrow : styles.white_arrow}>
              <img className={styles.interviews_icon} src={interviewCount !== 0 ? Orange_dot : Green_dot} alt="status" />
              <div
                className={styles.interviews_header}
                onClick={() => {
                  handleClick("interviews");
                  HandleInterview();
                }}
              >
                {interviewCount !== 0 ? "Interviews" : "Interview"}
              </div>
              <div className={styles.interviews_value}>{interviewCount}</div>
            </div>
          </div>

          <div className={styles.tag_wrap4}>
            <div
              className={activeArrow === "offered" ? styles.blue_arrow : styles.white_arrow}
              onClick={() => {
                handleClick("offered");
                HandleOffered();
              }}
            >
              <img className={styles.offered_icon} src={historyData.offered !== 0 ? Orange_dot : Green_dot} alt="status" />
              <div className={styles.offered_header}>Offered</div>
              <div className={styles.offered_value}>{historyData.offered}</div>
            </div>
          </div>

          <div className={styles.tag_wrap5}>
            <div
              className={activeArrow === "joined" ? styles.blue_arrow : styles.white_arrow}
              onClick={() => {
                handleClick("joined");
                HandleJoined();
              }}
            >
              <img className={styles.joined_icon} src={historyData.joined !== 0 ? Orange_dot : Green_dot} alt="status" />
              <div className={styles.joined_header}>Joined</div>
              <div className={styles.joined_value}>{historyData.joined}</div>
            </div>
          </div>
        </div>

        {!isDataLoaded && !isSubmissionDataLoaded && !isInterviewLoaded && !isOfferedDataLoaded && !isJoinedLoaded && (
  <div className={styles.spinnerContainer}>
    <Spinner size={SpinnerSize.large} label="Loading..." />
  </div>
)}
      {isDataLoaded && (
          <>
            <div >
              <div className={styles.header_row}>
                <div className={styles.sourced_profile_container}>
                  <div className={styles.table_header_container}>
                    <div className={styles.table_header_name}>Sourced Profiles</div>
                    {showSubStauts && (
                      <div className={styles.messagebar}>
                        <MessageBar onDismiss={() => setShowSubStatus(!showSubStauts)} styles={messageBarStyles} dismissButtonAriaLabel="Close" messageBarType={MessageBarType.success}>
                          Submission added successfully
                        </MessageBar>
                      </div>
                    )}
                    {isSubAdd && (
                      <div className={styles.toast}>
                        <div className={styles.toast_title}>
                          <FontIcon iconName="StatusCircleCheckmark" className={iconClassToast} />
                          <div>Submission Added Successfully !</div>
                        </div>
                        <FontIcon iconName="StatusCircleErrorX" className={iconClass} onClick={() => setSubAdd(false)} />
                      </div>
                    )}
                    {isSubRej && (
                      <div className={styles.toast_error}>
                        <div className={styles.toast_title}>
                          <FontIcon iconName="StatusCircleCheckmark" className={iconErrorClassToast} />
                          <div>Submission Rejected Successfully !</div>
                        </div>
                        <FontIcon iconName="StatusCircleErrorX" className={iconClass} onClick={() => setSubRej(false)} />
                      </div>
                    )}
                    {isSubRejError && (
                      <div className={styles.toast_error}>
                        <div className={styles.toast_title}>
                          <FontIcon iconName="StatusCircleCheckmark" className={iconClass} />
                          <div>Failed to Add Submission !</div>
                        </div>
                        <FontIcon iconName="StatusCircleErrorX" className={iconClass} onClick={() => setSubRejError(false)} />
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.table_options}>
                  <Dropdown
                    className={styles.filter_dropdown}
                    onChange={(e, item) => {
                      setSearchQuery("");
                      dropDownFilterChange(e, item, "dropdownFilter");
                      setSearchFilter(item.key);
                      setCurrentHover("");
                    }}
                    placeholder="Select"
                    notifyOnReselect
                    styles={(props) => DropdownStyles(props, currentHover, errors.dropdownSearch, searchQuery, "dropdownSearch")}
                    options={filterOption}
                  />

                  {searchFilter === "status" ? (
                    <Dropdown
                      className={styles.filter_dropdown}
                      onChange={(e, item) => {
                        statusOptionSelect(e, item);
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      styles={(props) => DropdownStyles(props, currentHover, errors.approvalStatusSelect, selectedStatusOption, "statusOptionSelect")}
                      options={statusOption}
                    />
                  ) : searchFilter === "approval_status" ? (
                    <Dropdown
                      className={styles.filter_dropdown}
                      onChange={(e, item) => {
                        approvalStatusSelect(e, item);
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      styles={(props) => DropdownStyles(props, currentHover, errors.dropdownSearch, selectedApprovalStatusOption, "approvalStatusSelect")}
                      options={approvalStatusOption}
                    />
                  ) : (
                    <SearchBox
                    placeholder=" "
                    styles={(props) => searchField(props, currentHover)}
                    iconProps={searchIcon}
                    className={styles.search}
                    showIcon
                    onClick={clearSearch}
                    onChange={handleSearchChange}
                    value={searchQuery}
                  />                  
                  )}

                  <Dropdown
                    className={styles.filter_dropdown}
                    onChange={(e, item) => {
                      submitDropdownHandler(e, item, "submit");
                    }}
                    placeholder="Submit"
                    selectedKey={selectedKey}
                    notifyOnReselect
                    styles={(props) => submitStyle(props, currentHover, null, "Submit")}
                    options={submitOption}
                  />
                  {isModalOpen && (
                    <AddSubmissionModal
                      isModalOpen={isModalOpen}
                      subIsModalOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      setSubIsModalOpen={setIsModalOpen}
                      showSubStauts={showSubStauts}
                      setShowSubStatus={setShowSubStatus}
                      demandId={demandId}
                      demandViewId={demandViewId}
                    />
                  )}

                  {submitStatus && (
                    <>
                      <TextField
                        className={styles.candidateId_style}
                        disabled={submitStatus !== "quick submission"}
                        value={candidateId}
                        onChange={(e) => {
                          setCandidateID(e.target.value);
                          if (e.target.value) {
                            setHasError(false);
                          }
                        }}
                        placeholder="Enter Candidate ID"
                        styles={(props) => textFieldColored(props, currentHover, hasError)}
                      />

                      <PrimaryButton onClick={createSubmission} iconProps={{ iconName: "Accept" }} className={styles.acceptBtn} styles={(props) => submitStyle(props, currentHover, null, "Submit")} />
                    </>
                  )}

                  {loading ? (
                    <Spinner size={SpinnerSize.medium} className={iconClass} />
                  ) : completed ? (
                    <FontIcon iconName="CheckMark" className={iconClass} />
                  ) : (
                    <FontIcon iconName="Download" onClick={() => handleDownload("sourced_profile")} className={iconClass} />
                  )}
                </div>
              </div>
            </div>

            <div id="scrollableDiv" className={styles.table_container}>
              <InfiniteScroll
                style={{ overflow: "visible", height: "100%" }}
                dataLength={filteredData.length}
                loader={isDataLoaded && filteredData.length >= 15 && <h4>Loading...</h4>}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
              >
                <table>
                  <thead className={styles.table_header}>
                    <tr className={styles.table_row}>
                      {columns.map((column) => (
                        <th onClick={() => clickSortHandler(column.columnKey)} className={styles.table_headerContents} key={column.columnKey}>
                          <div className={styles.table_heading}>
                            <div>{column.label}</div>
                            {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {load &&
                      Shimmeritems.map((employee) => (
                        <tr className={styles.table_row}>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <Shimmer />
                          </td>
                          <td className={styles.table_dataContents}>
                            <div className={styles.moreOptions}>
                              <FontIcon iconName="MoreVertical" className={iconClass1} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    {!load && historyTableData.length === 0 ? (
                      <tr>
                        <td className={styles.table_dataContents1} colSpan="13">
                          <img src={NoDataFound} alt="NoDataimage" />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {!load && filteredData.length === 0 ? (
                          <tr>
                            <td className={styles.table_dataContents1} colSpan="13">
                              <img src={Nomatchimg} alt="NoDataimage" className={styles.nomatch_img} />
                            </td>
                          </tr>
                        ) : (
                          filteredData.map((history) => {
                            return (
                              <tr className={styles.table_row} key={history._id}>
                                <td className={styles.table_dataContents}>
                                  <div >{""}</div>
                                </td>
                                <td onClick={() => navigateTo(`/candidatelibrary/viewcandidate?candidate_id=${history._id}`)} className={styles.table_dataContents}>
                                  <span className={styles.custom_link}>{highlightText(history.CandidateId, searchQuery)}</span>
                                </td>
                                <td
                                  onMouseEnter={() => setHoverCallout(`name_${history._id}`)}
                                  onMouseLeave={() => setHoverCallout("")}
                                  id={`ME${history.first_name}${history._id}`.replaceAll(" ", "_")}
                                  className={`${styles.table_dataContents}`}
                                >
                                  {highlightText(addEllipsisToName(`${history.first_name} ${history.last_name}`), searchQuery)}
                                  {(history.first_name + history.last_name).length >= 15 && hoverCallout === `name_${history._id}` && (
                                    <Callout
                                      alignTargetEdge={true}
                                      isBeakVisible={false}
                                      styles={CalloutNameStyles}
                                      directionalHint={DirectionalHint.bottomLeftEdge}
                                      target={`#ME${history.first_name}${history._id}`.replaceAll(" ", "_")}
                                    >
                                      {`${history.first_name} ${history.last_name}`}
                                    </Callout>
                                  )}
                                </td>
                                <td className={styles.table_dataContents}>{ISOdateToCustomDate(history.date_of_source)}</td>
                                <td className={styles.table_dataContents}>{highlightText(history.mobile_number, searchQuery)}</td>
                                <td
                                  className={styles.table_dataContents}
                                  onMouseEnter={() => setHoverCallout(`email_${history._id}`)}
                                  onMouseLeave={() => setHoverCallout("")}
                                  id={`email_${history._id}`}
                                >
                                  {highlightText(addEllipsisToName(history.email), searchQuery)}
                                  {history.email.length >= 15 && hoverCallout === `email_${history._id}` && (
                                    <Callout alignTargetEdge={true} isBeakVisible={false} styles={CalloutNameStyles} directionalHint={DirectionalHint.bottomLeftEdge} target={`#email_${history._id}`}>
                                      {history.email}
                                    </Callout>
                                  )}
                                </td>
                                <td
                                  className={styles.table_dataContents}
                                  onMouseEnter={() => setHoverCallout(`created_by_${history._id}`)}
                                  onMouseLeave={() => setHoverCallout("")}
                                  id={`${history.created_by?.first_name}${history._id}`.replaceAll(" ", "")}
                                >
                                  {highlightText(addEllipsisToName(`${history.created_by?.first_name} ${history.created_by?.last_name}`), searchQuery)}
                                  {(history.created_by?.first_name + history.created_by?.last_name).length >= 15 && hoverCallout === `created_by_${history._id}` && (
                                    <Callout
                                      alignTargetEdge={true}
                                      isBeakVisible={false}
                                      styles={CalloutNameStyles}
                                      directionalHint={DirectionalHint.bottomLeftEdge}
                                      target={`#${history.created_by?.first_name}${history._id}`.replaceAll(" ", "")}
                                    >
                                      {`${history.created_by.first_name} ${history.created_by.last_name}`}
                                    </Callout>
                                  )}
                                </td>
                                <td
                                  className={styles.table_dataContents}
                                  onMouseEnter={() => setHoverCallout(`primary_skill_${history._id}`)}
                                  onMouseLeave={() => setHoverCallout("")}
                                  id={`primary_skill_${history._id}`}
                                >
                                  {highlightText(addEllipsisToName(`${history.skillset[0]?.skill || "-"}`), searchQuery)}
                                  {history.skillset[0]?.skill?.length >= 15 && hoverCallout === `primary_skill_${history._id}` && (
                                    <Callout
                                      alignTargetEdge={true}
                                      isBeakVisible={false}
                                      styles={CalloutNameStyles}
                                      directionalHint={DirectionalHint.bottomLeftEdge}
                                      target={`#primary_skill_${history._id}`}
                                    >
                                      {history.skillset[0]?.skill}
                                    </Callout>
                                  )}
                                </td>
                                <td
                                  className={styles.table_dataContents}
                                  onMouseEnter={() => setHoverCallout(`secondary_skill_${history._id}`)}
                                  onMouseLeave={() => setHoverCallout("")}
                                  id={`secondary_skill_${history._id}`}
                                >
                                  {highlightText(addEllipsisToName(history.skillset[1]?.skill ? history.skillset[1]?.skill : "Nil"), searchQuery)}
                                  {history.skillset[1]?.skill?.length >= 15 && hoverCallout === `secondary_skill_${history._id}` && (
                                    <Callout
                                      alignTargetEdge={true}
                                      isBeakVisible={false}
                                      styles={CalloutNameStyles}
                                      directionalHint={DirectionalHint.bottomLeftEdge}
                                      target={`#secondary_skill_${history._id}`}
                                    >
                                      {history.skillset[1]?.skill}
                                    </Callout>
                                  )}
                                </td>

                                <td className={styles.table_dataContents}>
                                  {highlightText(
                                    history.total_experience
                                      ? history.total_experience
                                      : `${calcTotalExp(history.employment_details).years} Years ${calcTotalExp(history.employment_details).months} Months`,
                                    searchQuery
                                  )}
                                </td>
                                <td className={styles.table_dataContents}>
                                  {history.resume_cv ? (
                                    <div onClick={() => openResume(history.resume_cv)}>link</div>
                                  ) : (
                                    <a href={history.resume_url} target="_blank" rel="noreferrer">
                                      Link
                                    </a>
                                  )}
                                </td>
                                <td className={styles.table_dataContents}>{history.status}</td>
                                <td className={styles.table_dataContents} style={{ fontWeight: "550", color: getColorForStatus(history.command.profile_status) }}>
                                  {history.command.profile_status}
                                </td>
                                {isAdmin || isAccountManager || isTeamLead ? (
                                  <>
                                    <td className={styles.table_dataContents} >
                                      <div className={styles.card}>
                                        {history.command.profile_status === "Selected" && (
                                          <FontIcon
                                            onMouseEnter={(e) => handleAcceptIconHover(e, history.command._id)}
                                            onMouseLeave={handleAcceptIconLeave}
                                            iconName="Accept"
                                            style={{ color: "#13A10E", fontWeight: "bold", fontSize: 16 , paddingRight:"40px"}}
                                          />
                                        )}
                                        {acceptHovered === history.command._id && hoverTarget && (
                                          <Callout
                                            onDismiss={handleAcceptIconLeave}
                                            alignTargetEdge={true}
                                            isBeakVisible={false}
                                            styles={CalloutNameStyles}
                                            directionalHint={DirectionalHint.bottomLeftEdge}
                                            target={{ x: hoverTarget.x, y: hoverTarget.y }}
                                          >
                                            {`Accepted by ${history.approved_by?.first_name} ${history.approved_by?.last_name} on ${ISOdateToCustomDate(history.command.updatedAt, true)}`}
                                          </Callout>
                                        )}
                                        {history.command.profile_status === "Rejected" && (
                                          <FontIcon
                                            iconName="Cancel"
                                            onMouseEnter={(e) => handleRejectIconHover(e, history.command?._id)}
                                            onMouseLeave={handleRejectIconLeave}
                                            style={{ color: "#E80B0B", fontWeight: "bold", fontSize: 16 , paddingRight:"40px" }}
                                          />
                                        )}
                                        {rejectHovered === history.command._id && hoverTarget && (
                                          <Callout
                                            onDismiss={handleRejectIconLeave}
                                            alignTargetEdge={true}
                                            isBeakVisible={false}
                                            styles={CalloutNameStyles}
                                            directionalHint={DirectionalHint.bottomLeftEdge}
                                            target={{ x: hoverTarget.x, y: hoverTarget.y }}
                                          >
                                            {`Rejected by ${history?.approved_by?.first_name} ${history?.approved_by?.last_name} on  ${ISOdateToCustomDate(history.command.updatedAt, true)}`}
                                          </Callout>
                                        )}

                                        {(history.command.profile_status === "Waiting" || history.command.profile_status === "NotProcessed") && (
                                          <>
                                            <div className={styles.card}>
                                              <div className={styles.cardContent1}>
                                                <FontIcon
                                                  iconName="Accept"
                                                  style={{ color: "#13A10E", fontWeight: "bold", fontSize: 16 }}
                                                  onClick={() => {
                                                    handleAcceptSubmission(history.submission_id, history._id, history.command?.demand?._id);
                                                    handleIconSelect("Accept");
                                                  }}
                                                />
                                              </div>
                                              <div className={styles.cardContent1}>
                                                <FontIcon
                                                  iconName="Cancel"
                                                  style={{ color: "#E80B0B", fontWeight: "bold", fontSize: 16 }}
                                                  onClick={() => {
                                                    handleRejectSubmission(history.submission_id);
                                                    handleIconSelect("Cancel");
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                ) : null}

                                {commentPopups[history.submission_id] && (
                                  <Stack tokens={{ childrenGap: 10 }} styles={{ root: { position: "absolute", zIndex: 2 } }} className={styles.commentbox}>
                                    <span className={styles.close_botn} onClick={() => handleCloseCommentBox(history.submission_id)} style={{ cursor: "pointer" }}>
                                      <Icon iconName="ErrorBadge" className={styles.close_btn} />
                                    </span>
                                    <div className={styles.commenttype}>
                                      <h2>Comment</h2>
                                      <TextField multiline rows={4} placeholder="Enter your comment" value={rejCommand} onChange={(e) => setRejeCommand(e.target.value)} />
                                      <br />
                                      <PrimaryButton className={styles.Share} onClick={() => handleSaveCommentBox(history.submission_id, history._id, history.command?.demand?._id)}>
                                        Save
                                      </PrimaryButton>
                                    </div>
                                  </Stack>
                                )}
                              </tr>
                            );
                          })
                        )}
                        {!isDataLoaded &&
                          Shimmeritems.map((employee, index) => (
                            <tr className={styles.table_row} key={index}>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>

                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>

                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>

                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <Shimmer />
                              </td>
                              <td className={styles.table_dataContents}>
                                <div className={styles.moreOptions}>
                                  <FontIcon iconName="MoreVertical" className={iconClass1} />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </>
        )}
        <div>
          {isSubmissionDataLoaded && <DemandSubmissionListing submission={submissionData} />}
          {isInterviewLoaded && <DemandInterviewListing interview={interviewData} />}
        </div>

        <div>
          {isOfferedDataLoaded && (
            <>
              <div className={styles.table_header_container}>
                <div className={styles.table_header_name}>Offered Listing</div>
                <div className={styles.table_options_joined}>
                  <TextField placeholder=" " styles={(props) => searchField(props, currentHover)} iconProps={searchIcon} className={styles.search} showIcon onChange={handleSearchChange} />
                  {loading ? (
                    <Spinner size={SpinnerSize.medium} className={iconClass} />
                  ) : completed ? (
                    <FontIcon iconName="CheckMark" className={iconClass} />
                  ) : (
                    <FontIcon iconName="Download" onClick={() => handleDownload("offered")} className={iconClass} />
                  )}
                </div>
              </div>

              <div id="scrollableDiv" className={styles.table_container}>
                <InfiniteScroll
                  style={{ overflow: "visible", height: "100%" }}
                  dataLength={offeredData.length}
                  loader={isOfferedDataLoaded && offeredData.length >= 15 && <h4>Loading...</h4>}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                >
                  <table>
                    <thead className={styles.table_header}>
                      <tr className={styles.table_row}>
                        {offeredjoinedcolumns.map((column) => (
                          <th onClick={() => clickSortHandler(column.columnKey)} className={styles.table_headerContents_joined} key={column.columnKey}>
                            <div className={styles.table_heading}>
                              <div>{column.label}</div>
                              {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {isOfferedDataLoaded && offeredData.length === 0 ? (
                        <tr>
                          <td className={styles.table_dataContents1} colSpan="13">
                            <img src={NoDataFound} alt="image"  />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {isOfferedDataLoaded && offeredData.filter((history) => JSON.stringify(history).toLowerCase().includes(searchQuery.toLowerCase())).length === 0 ? (
                            <tr>
                              <td className={styles.table_dataContents1} colSpan="13" >
                                <img src={Nomatchimg} alt="image" className={styles.nomatch_img}/>
                              </td>
                            </tr>
                          ) : (
                            offeredData
                              .filter((history) => JSON.stringify(history).toLowerCase().includes(searchQuery.toLowerCase()))
                              .map((history) => {
                                const { billingRate, offeredCtc, join_date, fee } = history;
                                return (
                                  <tr className={styles.table_row} key={history._id}>
                                    <td className={styles.table_dataContents}></td>
                                    <td onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${history.demand_id._id}`)} className={styles.table_dataContents}>
                                      <span className={styles.custom_link}>{highlightText(history.demand_id.DemandId, searchQuery)}</span>
                                    </td>
                                    <td onClick={() => navigateTo(`/candidatelibrary/viewcandidate?candidate_id=${history.candidate_id._id}`)} className={styles.table_dataContents}>
                                      <span className={styles.custom_link}>{highlightText(history.candidate_id.CandidateId, searchQuery)}</span>
                                    </td>
                                    <td
                                      onMouseEnter={() => setHoverCallout(history._id)}
                                      onMouseLeave={() => setHoverCallout("")}
                                      id={`ME${history.first_name}${history._id}`.replaceAll(" ", "_")}
                                      className={`${styles.table_dataContents} `}
                                    >
                                      {highlightText(`${history.candidate_id.first_name} ${history.candidate_id.last_name}`, searchQuery)}
                                      {(history.candidate_id.first_name + history.candidate_id.last_name).length >= 14 && hoverCallout === history._id && (
                                        <Callout
                                          alignTargetEdge={true}
                                          isBeakVisible={false}
                                          styles={CalloutNameStyles}
                                          directionalHint={DirectionalHint.bottomLeftEdge}
                                          target={`#ME${history.first_name}${history._id}`.replaceAll(" ", "_")}
                                        >
                                          {`${history.candidate_id.first_name} ${history.candidate_id.last_name}`} *
                                        </Callout>
                                      )}
                                    </td>
                                    <td className={styles.table_dataContents}>{highlightText(history.candidate_id.mobile_number, searchQuery)}</td>
                                    <td className={styles.table_dataContents}>{highlightText(history.candidate_id.email, searchQuery)}</td>
                                    <td
                                      className={styles.table_dataContents}
                                      onMouseEnter={() => setHoverCallout(history.created_by)}
                                      onMouseLeave={() => setHoverCallout("")}
                                      id={`${history.created_by?.first_name}${history._id}`.replaceAll(" ", "")}
                                    >
                                      {highlightText(history.demand_id.client, searchQuery)}
                                    </td>
                                    <td className={styles.table_dataContents}>{highlightText(ISOdateToCustomDate(join_date), searchQuery)}</td>
                                    <td
                                      className={styles.table_dataContents}
                                      onMouseEnter={() => setHoverCallout(history._id)}
                                      onMouseLeave={() => setHoverCallout("")}
                                      id={`primary_skill_${history._id}`}
                                    >
                                      {highlightText(history.candidate_id.expected_ctc, searchQuery)}
                                    </td>
                                    <td className={styles.table_dataContents}>{highlightText(formatIndianNumber(offeredCtc), searchQuery)}</td>
                                    <td className={styles.table_dataContents}>{highlightText(formatIndianNumber(billingRate), searchQuery)}</td>
                                    <td className={styles.table_dataContents}>{highlightText(fee ? `${fee}%` : "-", searchQuery)}</td>
                                    <td className={styles.table_dataContents} style={{ color: history.status === "offer_denied" ? "#E80B0B" : "#13A10E" }}>
                                      {highlightText(
                                        history.status === "offer_denied"
                                          ? "Offer Denied"
                                          : history.status === "offer_accept" ||
                                            history.status === "onboard_select" ||
                                            history.status === "bg_verification_select" ||
                                            history.status === "bg_verification_reject" ||
                                            history.status === "onboard_reject"
                                          ? "Offer Accept"
                                          : history.status,
                                        searchQuery
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>

        <div>
          {isJoinedLoaded && (
            <>
              <div className={styles.table_header_container}>
                <div className={styles.table_header_name}>Joined Listing</div>
                <div className={styles.table_options_joined}>
                  <TextField placeholder=" " styles={(props) => searchField(props, currentHover)} iconProps={searchIcon} className={styles.search} showIcon onChange={handleSearchChange} />
                  {loading ? (
                    <Spinner size={SpinnerSize.medium} className={iconClass} />
                  ) : completed ? (
                    <FontIcon iconName="CheckMark" className={iconClass} />
                  ) : (
                    <FontIcon iconName="Download" onClick={() => handleDownload("joined")} className={iconClass} />
                  )}
                </div>
              </div>

              <div id="scrollableDiv" className={styles.table_container}>
                <InfiniteScroll
                  style={{ overflow: "visible", height: "100%" }}
                  dataLength={joinedData.length}
                  loader={isJoinedLoaded && joinedData.length >= 15 && <h4>Loading...</h4>}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                >
                  <table>
                    <thead className={styles.table_header}>
                      <tr className={styles.table_row}>
                        {offeredjoinedcolumns.map((column) => (
                          <th onClick={() => clickSortHandler(column.columnKey)} className={styles.table_headerContents_joined} key={column.columnKey}>
                            <div className={styles.table_heading}>
                              <div>{column.label}</div>
                              {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {isJoinedLoaded && joinedData.length === 0 ? (
                        <tr>
                          <td className={styles.table_dataContents1} colSpan="13">
                            <img src={NoDataFound} alt="image"/>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {isJoinedLoaded && joinedData.filter((history) => JSON.stringify(history).toLowerCase().includes(searchQuery.toLowerCase())).length === 0 ? (
                            <tr>
                              <td className={styles.table_dataContents1} colSpan="13" >
                                <img src={Nomatchimg} alt="image" className={styles.nomatch_img} />
                              </td>
                            </tr>
                          ) : (
                            joinedData
                              .filter((history) => JSON.stringify(history).toLowerCase().includes(searchQuery.toLowerCase()))
                              .map((history) => {
                                const { billingRate, offeredCtc, join_date, fee } = history;
                                return (
                                  <tr className={styles.table_row} key={history._id}>
                                    <td className={styles.table_dataContents}></td>
                                    <td onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${history.demand_id._id}`)} className={styles.table_dataContents}>
                                      <span className={styles.custom_link}>{highlightText(history.demand_id.DemandId, searchQuery)}</span>
                                    </td>
                                    <td onClick={() => navigateTo(`/candidatelibrary/viewcandidate?candidate_id=${history.candidate_id._id}`)} className={styles.table_dataContents}>
                                      <span className={styles.custom_link}>{highlightText(history.candidate_id.CandidateId, searchQuery)}</span>
                                    </td>
                                    <td
                                      onMouseEnter={() => setHoverCallout(history._id)}
                                      onMouseLeave={() => setHoverCallout("")}
                                      id={`ME${history.first_name}${history._id}`.replaceAll(" ", "_")}
                                      className={`${styles.table_dataContents} `}
                                    >
                                      {highlightText(`${history.candidate_id.first_name} ${history.candidate_id.last_name}`, searchQuery)}
                                      {(history.candidate_id.first_name + history.candidate_id.last_name).length >= 14 && hoverCallout === history._id && (
                                        <Callout
                                          alignTargetEdge={true}
                                          isBeakVisible={false}
                                          styles={CalloutNameStyles}
                                          directionalHint={DirectionalHint.bottomLeftEdge}
                                          target={`#ME${history.first_name}${history._id}`.replaceAll(" ", "_")}
                                        >
                                          {`${history.candidate_id.first_name} ${history.candidate_id.last_name}`} *
                                        </Callout>
                                      )}
                                    </td>
                                    <td className={styles.table_dataContents}>{highlightText(history.candidate_id.mobile_number, searchQuery)}</td>
                                    <td className={styles.table_dataContents}>{highlightText(history.candidate_id.email, searchQuery)}</td>
                                    <td
                                      className={styles.table_dataContents}
                                      onMouseEnter={() => setHoverCallout(history.created_by)}
                                      onMouseLeave={() => setHoverCallout("")}
                                      id={`${history.created_by?.first_name}${history._id}`.replaceAll(" ", "")}
                                    >
                                      {highlightText(history.demand_id.client, searchQuery)}
                                    </td>
                                    <td className={styles.table_dataContents}>{highlightText(ISOdateToCustomDate(join_date), searchQuery)}</td>
                                    <td
                                      className={styles.table_dataContents}
                                      onMouseEnter={() => setHoverCallout(history._id)}
                                      onMouseLeave={() => setHoverCallout("")}
                                      id={`primary_skill_${history._id}`}
                                    >
                                      {highlightText(history.candidate_id.expected_ctc, searchQuery)}
                                    </td>
                                    <td className={styles.table_dataContents}>{highlightText(formatIndianNumber(offeredCtc), searchQuery)}</td>
                                    <td className={styles.table_dataContents}>{highlightText(formatIndianNumber(billingRate), searchQuery)}</td>
                                    <td className={styles.table_dataContents}>{highlightText(fee ? `${fee}%` : "-", searchQuery)}</td>
                                    <td
                                      className={styles.table_dataContents}
                                      style={{
                                        color:
                                          history.status === "onboard_select" || history.status === "bg_verification_select" || history.status === "bg_verification_reject" ? "#13A10E" : "#E80B0B",
                                      }}
                                    >
                                      {highlightText(
                                        history.status === "onboard_select"
                                          ? "Onboard Accept"
                                          : history.status === "bg_verification_select"
                                          ? "Onboard Accept"
                                          : history.status === "bg_verification_reject"
                                          ? "Onboard Accept"
                                          : history.status === "onboard_reject"
                                          ? "Onboard Denied"
                                          : history.status,
                                        searchQuery
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
}

export default DemandHistory;
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal } from "@fluentui/react";
import styles from "./AddClientModal.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { axiosPrivateCall, axiosJsonCall } from "../constants";
import { Popup } from "../components/Popup";
import { UploadPopup } from "../components/UploadModal";
import { Label } from "@fluentui/react/lib/Label";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { ChoiceGroup } from "@fluentui/react";
import uploadImage from "../assets/upload_cloud_image.png";
const customizedDropdown = (props, currentHover, error, value) => {
  const borderColor = error ? "D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  const focusBorderColor = "yellow";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "D24545" : "#D7D7D7";
  }
  return {
    title: {
      height: "30px",     
      color: titleColor,
    },
    dropdown: {
      borderRadius: "4px",
      selectors: {
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderColor: focusBorderColor,
        },
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px",
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
      },
    },
  };
};
const textFieldColored1 = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = error ? "#D24545" : "#D7D7D7";
  if (value) {
    titleColor = "#484848"; 
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "130%", 
      maxWidth: "190px",
      height: "30px",
      backgroundColor: "#FFFFFF",
      marginLeft: "39px",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#red",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};
const textFieldColored3 = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = error ? "#D24545" : "#D7D7D7";
  if (value) {
    titleColor = "#484848"; 
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "250%",
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#red",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};
const textFieldColored4 = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = error ? "#D24545" : "#D7D7D7"; 
  if (value) {
    titleColor = "#484848"; 
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "145%", 
      height: "30px",
      backgroundColor: "#FFFFFF",
      marginLeft: "-30px",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#red",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};
const textFieldColored2 = (props, currentHover, error, value, fieldName) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "130%", 
      maxWidth: "190px", 
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#red",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
  };
};
const buttonStyles = {
  root: {
    marginTop: "3px",
    height: "30px",
    borderRadius: "2px",
    marginRight: "8px",
  },
};
const buttonStyles1 = {
  root: {
    marginTop: "10px",
    width: "155px",
    height: "30px",
    borderRadius: "8px",
  },
};
const textFieldColored = (props, currentHover, error, value, fieldName) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      display: "flex",
      width: "305px",
      height: "30px",
      marginTop: "2.5px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      fontSize: 12,
      input: {
        color: "#red",
        "&::placeholder": {
          color: titleColor,
        },
      },
    },
    root: {
      marginRight: "30px",
    },
  };
};
const nameInputRegex = /^[a-zA-Z0-9- '.\u00c0-\u024f\u1e00-\u1eff]*$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const addressRegex = /^[\p{L}\p{N}\s\p{P}\p{S}]+$/u;
  const mobileRegex = /^[6-9]\d{9}$/;
const pincodeRegex = /^\d{6}$/;
const websiteRegex =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
const contractIconClass = mergeStyles({
  fontSize: 20,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});
const closeIconClass = mergeStyles({
  fontSize: 16,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});
const tableCloseIconClass = mergeStyles({
  fontSize: 8,
  height: "12px",
  width: "12px",
  cursor: "pointer",
  color: "red",
  marginLeft: "15x",
  paddingLeft: "10px",
});
const AddCandidateModal = (props) => {
  let isModalOpen = props.isModalOpen;
  const setIsModalOpen = props.setIsModalOpen;
  let isSubmitSuccess = props.isSubmitSuccess;
  const setSubmitSuccess = props.setSubmitSuccess;
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [fileTitle, setFileTitle] = useState("");
  const [buttonText, setButtonText] = useState("Attach Template");
  let defaultbasicInfo = {
    template: '',
    company_name: "",
    empanelment: "No",
    expansion: "No",
    point_of_contact_name: "",
    destination: "",
    reports_to: "",
    primary_email: "",
    primary_mobile: "",
    alternate_email: "",
    alternate_mobile: "",
    website: "",
    linkedin: "",
    company_address: "",
    city: "",
    pincode: "",
    passthrough: "",
    passthrough_company: "",
    passthrough_person: "",
    documents: [],
    template_url: [],
  };
  const [basicInfo, setBasicInfo] = useState({ ...defaultbasicInfo });
  console.log(basicInfo, "data set");
  const [empanelmentOption, setEmpanelmentOption] = useState("empanelmentNo");
  const [expansionOption, setExpansionOption] = useState("expansionNo");
  const empanelmentOptions = [{ key: "empanelmentYes", text: "Yes" }];
  const empanelmentOptions1 = [{ key: "empanelmentNo", text: "No" }];
  const expansionOptions = [{ key: "expansionYes", text: "Yes" }];
  const expansionOptions1 = [{ key: "expansionNo", text: "No" }];
  const handleEmpanelmentChange = (ev, option) => {
    setEmpanelmentOption(option.key);
    const value = option.key === "empanelmentYes" ? "Yes" : "No";
    setBasicInfo((prevInfo) => ({ ...prevInfo, empanelment: value }));
  };
  const handleExpansionChange = (ev, option) => {
    setExpansionOption(option.key);
    const value = option.key === "expansionYes" ? "Yes" : "No";
    setBasicInfo((prevInfo) => ({ ...prevInfo, expansion: value }));
  };
  const [selectedPassthrough, setSelectedPassthrough] = useState("");
  const passthroughOptions = [
    { key: "direct", text: "Direct" },
    { key: "passthrough_company", text: "Passthrough Company" },
    { key: "passthrough_person", text: "Passthrough Person" },
  ];
  const handlePassthroughChange = (event, item) => {
    setSelectedPassthrough(item.key);
    let passthroughValue = "";
    if (item.key === "direct") {
      passthroughValue = "Direct";
    } else {
      passthroughValue = item.key;
    }
    setBasicInfo((prevInfo) => ({
      ...prevInfo,
      passthrough: passthroughValue,
      passthrough_company: "", 
      passthrough_person: "", 
    }));
  };
  const [basicInfoerrors, setBasicInfoErrors] = useState({
    ...defaultbasicInfo,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  function uploadDocs(files) {
    setUploading(true);
    let data = Object.values(files);
    const formData = new FormData();
    data.map((file) => {
      formData.append("files", file);
    });
    axiosPrivateCall
      .post("/api/v1/client/addClientDocuments", formData)
      .then((res) => {
        let fileuploaded = res.data.documents;
        setBasicInfo((prev) => {
          let buffer = { ...prev };
          fileuploaded.forEach((file) => {
            if (!buffer.documents.find((doc) => doc.document_name === file.document_name)) {
              buffer.documents.push(file);
            }
          });
          return buffer;
        });
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  }
  
  function removeUploadedDoc(key) {
    console.log(key);
    let buffer = [...basicInfo.documents];
    let result = [];
    buffer.map((doc) => {
      if (!(doc.document_name === key)) {
        result.push(doc);
      }
    });
    console.log(result);
    setBasicInfo((prev) => {
      let buffer = { ...prev };
      buffer.documents = [...result];
      return buffer;
    });
  }
  const modalSizeHandler = () => {
    setIsModalShrunk(!isModalShrunk);
  };
  useEffect(() => {
    console.log("rendering..");
  }, [basicInfo, basicInfoerrors]);
  function validate(values) {
    const errors = {};
    if (!basicInfo.company_name) {
      errors.company_name = " ";
    } else if (!nameInputRegex.test(values.company_name)) {
      errors.company_name = " ";
    }
    if (!basicInfo.point_of_contact_name) {
      errors.point_of_contact_name = " ";
    } else if (!nameInputRegex.test(values.point_of_contact_name)) {
      errors.point_of_contact_name = " ";
    }
    if (!basicInfo.destination) {
      errors.destination = " ";
    } else if (!nameInputRegex.test(values.destination)) {
      errors.destination = " ";
    }
    if (!basicInfo.primary_email) {
      errors.primary_email = " ";
    } else if (!emailRegex.test(basicInfo.primary_email)) {
      errors.primary_email = " ";
    }
    if (!basicInfo.primary_mobile) {
      errors.primary_mobile = " ";
    } else if (!mobileRegex.test(basicInfo.primary_mobile)) {
      errors.primary_mobile = " ";
    }
    if (!basicInfo.company_address) {
      errors.company_address = " ";
    } else if (!addressRegex.test(values.company_address)) {
      errors.company_address = " ";
    }
    if (!basicInfo.city) {
      errors.city = " ";
    } else if (!nameInputRegex.test(values.city)) {
      errors.city = " ";
    }
    if (!basicInfo.pincode) {
      errors.pincode = " ";
    } else if (!pincodeRegex.test(values.pincode)) {
      errors.pincode = " ";
    }
    if (basicInfo.passthrough === "passthrough_person") {
      if (!basicInfo.passthrough_person) {
        errors.passthrough_person = " ";
      } else if (!nameInputRegex.test(values.passthrough_person)) {
        errors.passthrough_person = " ";
      }
    }
    if (basicInfo.passthrough === "passthrough_company") {
      if (!basicInfo.passthrough_company) {
        errors.passthrough_company = " ";
      } else if (!nameInputRegex.test(values.passthrough_company)) {
        errors.passthrough_company = " ";
      }
    }
    return errors;
  }
  function submitHandler(e) {
    e.preventDefault();
    console.log("submit clicked");
    let errorsBasicInfoSet = validate(basicInfo);
    function analyseError(errorDataSet) {
      let answer = true;
      for (let i = 0; i < errorDataSet.length; i++) {
        if (!(Object.keys(errorDataSet[i]) === 0)) {
          answer = false;
          break;
        }
      }
      return answer;
    }
    let stage1 = Object.keys(errorsBasicInfoSet).length === 0;
    console.log(errorsBasicInfoSet, "ruby");
    console.log(stage1, errorsBasicInfoSet, "selvi");
    if (stage1) {
      if (basicInfo.passthrough === "passthrough_person" && !basicInfo.passthrough_person) {
        setBasicInfoErrors({ passthrough_person: "Required" });
      } else if (basicInfo.passthrough === "passthrough_company" && !basicInfo.passthrough_company) {
        setBasicInfoErrors({ passthrough_company: "Required" });
      } else {
        axiosPrivateCall
          .post("/api/v1/client/createClient", basicInfo)
          .then((res) => {
            console.log(res);
            submitForm();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setBasicInfoErrors(errorsBasicInfoSet);
      console.log("error");
    }    
  }
  function submitForm() {
    setSubmitSuccess(true);
    setIsModalOpen(false);
  }
  const closeHandler = () => {
    setShowPopup(true);
  };
  const handlePopupClose = () => {
    const hasChanges = Object.keys(defaultbasicInfo).some((key) => {
      if (Array.isArray(defaultbasicInfo[key])) {
        return !arraysEqual(defaultbasicInfo[key], basicInfo[key]);
      } else {
        return defaultbasicInfo[key] !== basicInfo[key];
      }
    });
  
    if (hasChanges) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
      setIsModalOpen(!isModalOpen);
    }
  };
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };
  const close = useCallback(() => {
    let value_temp;
    setBasicInfo((prevState) => {
      value_temp = Object.values(validate(prevState));
      if (value_temp.length === 15) {
        closeHandler();
      } else {
        closeHandler();
      }
      return prevState;
    });
  }, [JSON.stringify(basicInfo)]);
  const escKeyHandler = (event) => {
    if (event.key === "Escape") {
      closeHandler();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", escKeyHandler, { capture: true });
    return () => {
      document.removeEventListener("keydown", escKeyHandler, { capture: true });
    };
  }, []);
  const uploadExcel = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel" ||
        file.type === "application/excel")
    ) {
      setFileTitle("uploading");
      const formData = new FormData();
      formData.append("files", file);
      axiosPrivateCall
        .post("/api/v1/client/addClientTemplate", formData)
        .then((res) => {
          console.log(res, "ress");
          setBasicInfo((prevState) => ({
            ...prevState,
            template: res.data.template,
            template_url: res.data.fileUrl,
          }));
          setBasicInfoErrors((prevState) => ({ ...prevState, template: "" }));
          setFileTitle("");
          setButtonText("Attached");
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      setBasicInfoErrors((prevState) => ({
        ...prevState,
        template: "Invalid",
      }));
      setFileTitle("");
    }
  };
  return (
    <div>
      {
        <UploadPopup
          showPopup={showUploadPopup}
          setShowPopup={setShowUploadPopup}
          basicInfo={basicInfo}
          setBasicInfo={setBasicInfo}
        />
      }
      {
        <Popup
          resetState={() => ""}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }
      <Modal
        scrollableContentClassName={
          styles.addcandidate_modal_scrollable_content
        }
        containerClassName={`${
          isModalShrunk
            ? styles.addcandidate_modal_container_shrunk
            : styles.addcandidate_modal_container
        }`}
        isOpen={isModalOpen}
      >
        <div className={styles.addcandidate_modal_header_container}>
          <div className={styles.header_tag_expand_close_icon_container}>
            <div className={styles.header_tag_container}>Client</div>
            <div className={styles.header_expand_close_icon_container}>
              <div
                onClick={modalSizeHandler}
                className={styles.header_expand_icon_container}
              >
                {isModalShrunk ? (
                  <Icon iconName="FullScreen" className={contractIconClass} />
                ) : (
                  <Icon iconName="BackToWindow" className={contractIconClass} />
                )}
              </div>
              <div onClick={handlePopupClose} className={styles.header_close_icon_container}>
                <Icon iconName="ChromeClose" className={closeIconClass} />
              </div>
            </div>
          </div>
          <div className={styles.header_content_container}>
            <div className={styles.header_content_title_container}>
              <div className={styles.header_content_title_container}>
                Add Client
              </div>
              <br />
              <div className={styles.dropdownRow}>
                <div className={styles.customer_name}>
                  <Label className={styles.name_label_style} required>
                    Company Name
                  </Label>
                  <div
                    className={
                      basicInfo.company_name || basicInfoerrors.company_name
                        ? styles.showfield
                        : styles.hidefield
                    }
                  >
                    <TextField
  placeholder="Enter the Company Name"
  name="company_name"
  onChange={(e) => {
    setBasicInfo({
      ...basicInfo,
      company_name: e.target.value,
    });
    setBasicInfoErrors({
      ...basicInfoerrors,
      company_name: "",
    });
  }}
  value={basicInfo.company_name}
  styles={(props) => textFieldColored(props, false, basicInfoerrors.company_name, basicInfo.company_name, "company_name")}
/>
                    <p className={styles.errorMessage}>
                      {basicInfoerrors.company_name}
                    </p>
                  </div>
                </div>
                <div className={styles.submitButton}>
                  <PrimaryButton
                    text="Save & Close"
                    onClick={submitHandler}
                    iconProps={{ iconName: "Save" }}
                    styles={buttonStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.addemployee_modal_main_container}>
          <div className={styles.main_filter_options_container}>
            <div className={styles.subcontainer}>
              <div className={styles.main_dropdown_container}>
                <div className={styles.main_repotingmanager_title}>
                  <Label required className={styles.name_label_style}>
                    Point of Contact Name
                  </Label>
                </div>
                <div
                  className={
                    basicInfo.point_of_contact_name ||
                    basicInfoerrors.point_of_contact_name
                      ? styles.showfield
                      : styles.hidefield
                  }
                >
<div className={styles.textfield}>
                    <TextField
                      type="text"
                      name="point_of_contact_name"
                      onChange={(e) => {
                        setBasicInfo({
                            ...basicInfo,
                            point_of_contact_name: e.target.value,
                        });
                        setBasicInfoErrors({
                            ...basicInfoerrors,
                            point_of_contact_name: "",
                        });
                    }}
                      value={basicInfo.point_of_contact_name}
                      placeholder={"Enter the Name"}
                      styles={(props) => textFieldColored(props, false, basicInfoerrors.point_of_contact_name, basicInfo.point_of_contact_name, "point_of_contact_name")}
                      />
                    <p className={styles.errorMessage}>
                      {basicInfoerrors.point_of_contact_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.subcontainer}>
              <div className={styles.main_dropdown_container}>
                <div className={styles.main_repotingmanager_title}>
                  <Label required className={styles.name_label_style}>
                    Designation
                  </Label>
                </div>
                <div
                  className={
                    basicInfo.destination || basicInfoerrors.destination
                      ? styles.showfield
                      : styles.hidefield
                  }
                >
                  <div className={styles.textfield}>
                    <TextField
                      type="text"
                      name="destination"
                      onChange={(e) => {
                        setBasicInfo({
                            ...basicInfo,
                            destination: e.target.value,
                        });
                        setBasicInfoErrors({
                            ...basicInfoerrors,
                            destination: "",
                        });
                    }}
                      value={basicInfo.destination}
                      placeholder={`Enter the Designation`}
                      styles={(props) => textFieldColored(props, false, basicInfoerrors.destination, basicInfo.destination, "destination")}                    />
                    <p className={styles.errorMessage}>
                      {basicInfoerrors.destination}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.subcontainer}>
              <div className={styles.main_dropdown_container}>
                <div className={styles.main_repotingmanager_title}>
                  <Label className={styles.name_label_style}>Reports to</Label>
                </div>
                <div
                  className={
                    basicInfo.reports_to || basicInfoerrors.reports_to
                      ? styles.showfield
                      : styles.hidefield
                  }
                >
                  <div className={styles.textfield}>
                    <TextField
                      type="text"
                      name="reports_to"
                      onChange={(e) =>
                        setBasicInfo({
                          ...basicInfo,
                          reports_to: e.target.value,
                        })
                      }
                      value={basicInfo.reports_to}
                      placeholder={`Enter the Name`}
                      styles={textFieldColored}
                    />
                    <p className={styles.errorMessage}>
                      {basicInfoerrors.reports_to}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.main_box_container}>
            <div className={styles.firstBox}>
              <div className={styles.box}>
                <p className={styles.P_customerDetains}>Customer Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      Primary Mail ID
                    </Label>
                    <div
                      className={
                        basicInfo.primary_email ||
                        basicInfoerrors.primary_email
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        name="primary_email"
                        onChange={(e) => {
                          setBasicInfo({
                              ...basicInfo,
                              primary_email: e.target.value,
                          });
                          setBasicInfoErrors({
                              ...basicInfoerrors,
                              primary_email: "",
                          });
                      }}
                        value={basicInfo.primary_email}
                        placeholder="Enter the Email ID"
                        styles={(props) => textFieldColored2(props, false, basicInfoerrors.primary_email, basicInfo.primary_email)}
                        className={styles.input}
                      />
                      <p className={styles.errorMessage}>
                        {basicInfoerrors.primary_email}
                      </p>
                    </div>
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style11}>
                      Secondary Email ID
                    </Label>
                    <div
                      className={
                        basicInfo.alternate_email ||
                        basicInfoerrors.alternate_email
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        placeholder="Enter the Email ID"
                        name="alternate_email"
                        onChange={(e) =>
                          setBasicInfo({
                            ...basicInfo,
                            alternate_email: e.target.value,
                          })
                        }
                        value={basicInfo.alternate_email}
                        styles={textFieldColored1}
                      />
                       {/* <p className={styles.errorMessage}>
                        {basicInfoerrors.alternate_email}
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      Primary Mobile Number
                    </Label>
                    <div
                      className={
                        basicInfo.primary_email ||
                        basicInfoerrors.primary_email
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        placeholder="Enter the Mobile"
                        name="primary_mobile"
                        onChange={(e) => {
                          const onlyNumbers = e.target.value.replace(/\D/g, '').slice(0, 10);
                          setBasicInfo({
                              ...basicInfo,
                              primary_mobile: onlyNumbers,
                          });
                          setBasicInfoErrors({
                              ...basicInfoerrors,
                              primary_mobile: "",
                          });
                      }}
                        value={basicInfo.primary_mobile}
                        styles={(props) => textFieldColored2(props, false, basicInfoerrors.primary_mobile, basicInfo.primary_mobile)}                        
                        style={{ "::placeholder": { color: "yellow" } }}
                      />
                      <p className={styles.errorMessage}>
                        {basicInfoerrors.primary_mobile}
                      </p>
                    </div>
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style11}>
                      Secondary Mobile Number
                    </Label>
                    <div
                      className={
                        basicInfo.alternate_mobile ||
                        basicInfoerrors.alternate_mobile
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        placeholder="Enter the Mobile"
                        name="alternate_mobile"
                        onChange={(e) =>{
                          const onlyNumbers = e.target.value.replace(/\D/g, '').slice(0, 10);
                          setBasicInfo({
                            ...basicInfo,
                            alternate_mobile: onlyNumbers,
                          })
                        }  }
                        value={basicInfo.alternate_mobile}
                        styles={textFieldColored1}
                      />
                      {/* <p className={styles.errorMessage}>
                        {basicInfoerrors.alternate_mobile}
                      </p> */}
                    </div>
                  </div>
                </div>
                </div>
                <div className={styles.boxClient}>
                  <p className={styles.P_AC}>Client Details</p>
                  <div className={styles.customerDetailsContainer}>
                    <div className={styles.C_D_container}>
                      <div className={styles.EM_container}>
                        <div className={styles.EMi}>
                          <Label className={styles.customer_label_styles}>
                            Empanelment
                          </Label>
                        </div>
                          <div className={styles.radioButtons}>
                              <ChoiceGroup
                                options={empanelmentOptions}
                                selectedKey={empanelmentOption}
                                onChange={handleEmpanelmentChange}
                                className={styles.radioGroup}/>
                            <ChoiceGroup
                              options={empanelmentOptions1}
                              selectedKey={empanelmentOption}
                              onChange={handleEmpanelmentChange}
                              className={styles.radioGroup}/>
                          </div>
                        </div>
                      <div className={styles.EM_container}>
                        <div className={styles.EMi}>
                          <Label className={styles.customer_label_styles}>
                            Expansion
                          </Label>
                        </div>
                        <div className={styles.radioButtonsone}>
                            <ChoiceGroup
                              options={expansionOptions}
                              className={styles.radioGroup}
                              selectedKey={expansionOption}
                              onChange={handleExpansionChange}
                            />
                            <ChoiceGroup
                              options={expansionOptions1}
                              className={styles.radioGroup}
                              selectedKey={expansionOption}
                              onChange={handleExpansionChange}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.boxPassthrougt}>
                  <p className={styles.P_AC1}>Passthrough Details</p>
                  <div className={styles.customerDetailsContainer12}>
                    <div className={styles.textField_customerDetails1}>
                      <Label className={styles.customer_label_style}>
                        Passthrough
                      </Label>
                      <Dropdown
                        options={passthroughOptions}
                        placeholder="Select"
                        notifyOnReselect
                        onChange={handlePassthroughChange}
                        styles={customizedDropdown}
                      />
                    </div>
                    <div className={styles.passthroughValue}>
                    {selectedPassthrough === "passthrough_company" && (
                      <div className={styles.textField_customerDetails_11}>
                        <Label className={styles.customer_label_style12} required>
                          Passthrough Company
                        </Label>
                        <div
                          className={
                            basicInfo.passthrough_company ||
                            basicInfoerrors.passthrough_company
                              ? styles.showfield
                              : styles.hidefield
                          }
                        >
                          <TextField
                            name="passthrough_company"
                            placeholder="Enter the Name"
                            onChange={(e) => {
                              setBasicInfo({
                                  ...basicInfo,
                                  passthrough_company: e.target.value,
                              });
                              setBasicInfoErrors({
                                  ...basicInfoerrors,
                                  passthrough_company: "",
                              });
                          }}
                            value={basicInfo.passthrough_company}
                            styles={(props) => textFieldColored4(props, false, basicInfoerrors.passthrough_company, basicInfo.passthrough_company)}                             
                            style={{ "::placeholder": { color: "yellow" } }}
                          />
                          <p className={styles.errorMessage}>
                            {basicInfoerrors.passthrough_company}
                          </p>
                        </div>
                      </div>
                    )}
                    {selectedPassthrough === "passthrough_person" && (
                      <div className={styles.textField_customerDetails_11}>
                        <Label className={styles.customer_label_style12} required>
                          Passthrough Person
                        </Label>
                        <div
                          className={
                            basicInfo.passthrough_person ||
                            basicInfoerrors.passthrough_person
                              ? styles.showfield
                              : styles.hidefield
                          }
                        >
                          <TextField
                            name="passthrough_person"
                            placeholder="Enter the Name"
                            onChange={(e) => {
                              setBasicInfo({
                                  ...basicInfo,
                                  passthrough_person: e.target.value,
                              });
                              setBasicInfoErrors({
                                  ...basicInfoerrors,
                                  passthrough_person: "",
                              });
                          }}
                            value={basicInfo.passthrough_person}
                            styles={(props) => textFieldColored4(props, false, basicInfoerrors.passthrough_person, basicInfo.passthrough_person)}                             
                            style={{ "::placeholder": { color: "yellow" } }}
                          />
                          <p className={styles.errorMessage}>
                            {basicInfoerrors.passthrough_person}
                          </p>
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                </div>
            </div>
            <div className={styles.firstBox}>
              <div className={styles.boxCompany}>
                <p className={styles.P_timeline}>Company Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      Company Address
                    </Label>
                    <div
                      className={
                        basicInfo.company_address ||
                        basicInfoerrors.company_address
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        name="company_address"
                        onChange={(e) => {
                          setBasicInfo({
                              ...basicInfo,
                              company_address: e.target.value,
                          });
                          setBasicInfoErrors({
                              ...basicInfoerrors,
                              company_address: "",
                          });
                      }}
                        value={basicInfo.company_address}
                        placeholder="Enter the Address"
                        styles={(props) => textFieldColored3(props, false, basicInfoerrors.company_address, basicInfo.company_address)}                       />
                      <p className={styles.errorMessage}>
                        {basicInfoerrors.company_address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style} required>
                      City
                    </Label>
                    <div
                      className={
                        basicInfo.city || basicInfoerrors.city
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        type="text"
                        name="city"
                        onChange={(e) => {
                          setBasicInfo({
                              ...basicInfo,
                              city: e.target.value,
                          });
                          setBasicInfoErrors({
                              ...basicInfoerrors,
                              city: "",
                          });
                      }}
                        value={basicInfo.city}
                        placeholder="Enter the City"
                        styles={(props) => textFieldColored2(props, false, basicInfoerrors.city, basicInfo.city)}                       />
                      <p className={styles.errorMessage}>
                        {basicInfoerrors.city}
                      </p>
                    </div>
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style11} required>
                      Pincode
                    </Label>
                    <div
                      className={
                        basicInfo.pincode || basicInfoerrors.pincode
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        placeholder="Enter the Pincode"
                        type="text"
                        name="pincode"
                        onChange={(e) => {
                          const onlyNumbers = e.target.value.replace(/\D/g, '').slice(0, 6);
                          setBasicInfo({
                              ...basicInfo,
                              pincode: onlyNumbers,
                          });
                          setBasicInfoErrors({
                              ...basicInfoerrors,
                              pincode: "",
                          });
                      }}
                        value={basicInfo.pincode}
                        styles={(props) => textFieldColored1(props, false, basicInfoerrors.pincode, basicInfo.pincode)}                       />
                      <p className={styles.errorMessage}>
                        {basicInfoerrors.pincode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>
                      Website
                    </Label>
                    <div
                      className={
                        basicInfo.website || basicInfoerrors.website
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        placeholder="Enter the Link"
                        name="website"
                        onChange={(e) =>
                          setBasicInfo({
                            ...basicInfo,
                            website: e.target.value,
                          })
                        }
                        value={basicInfo.website}
                        styles={textFieldColored2}
                      />
                       {/* <p className={styles.errorMessage}>
                        {basicInfoerrors.website}
                      </p> */}
                    </div>
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style11}>
                      Linkedin
                    </Label>
                    <div
                      className={
                        basicInfo.linkedin || basicInfoerrors.linkedin
                          ? styles.showfield
                          : styles.hidefield
                      }
                    >
                      <TextField
                        placeholder="Enter the Link"
                        name="linkedin"
                        onChange={(e) =>
                          setBasicInfo({
                            ...basicInfo,
                            linkedin: e.target.value,
                          })
                        }
                        value={basicInfo.linkedin}
                        styles={textFieldColored1}
                      />
                       {/* <p className={styles.errorMessage}>
                        {basicInfoerrors.linkedin}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.boxSubmission}>
                <p className={styles.P_AC3}>Submission Client Template</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <input
                      type="file"
                      id="template-upload"
                      accept=".xlsx, .xls, .xlsb, .xlsm"
                      style={{ display: "none" }}
                      onChange={(e) => uploadExcel(e)}
                    />
<div>
{fileTitle === "uploading" ? (
                    <>
                    <div className={styles.spinnerContainer}>
<Spinner className={styles.attachbutton} />
  <span className={styles.spinnerLabel}>Uploading...</span>                   
  </div>
  </>
                  ) : (
                    <>
                     <PrimaryButton
                      text={buttonText}
                      styles={buttonStyles1}
                      onClick={() =>
                        document.getElementById("template-upload").click()
                      }
                    />
                    </>
                  )}
                    {/* {fileTitle === "uploading" && (
                      <Spinner className={styles.Spinner1} size={SpinnerSize.medium} />
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.firstBox}>
              <div className={styles.boxLinked}>
                <p className={styles.P_timeline1}>Linked Opportunities</p>
                <br />
                <a href="#" className={styles.noLinked}>
                 <p className={styles.noLinkedText}>No Linked Opportunities</p> 
                </a>
              </div>
              <div className={styles.boxRightBottom}>
                <p className={styles.P_customerDetains1}>Attachments</p>
                <div className={styles.boxWithDottedLines}>
                  {uploading ? (
                    <>
                      <Spinner className={styles.Spinner2} label="Uploading..." />
                    </>
                  ) : (
                    <>
                  <img className={styles.uploadImage} src={uploadImage} />
                  <p className={styles.fileTypeText}>
                    PDF, JPG, PNG, DOC below 1 Mb
                  </p>
                  <p className={styles.infoText}>
                    You can also upload files by
                  </p>
                  <div>
                    <PrimaryButton
                      text="Browse Files"
                      className={styles.uploadButton}
                      styles={buttonStyles}
                      onClick={() => fileInputRef.current.click()}
                    />
                    <input
                      multiple
                      hidden
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => uploadDocs(e.target.files)}
                    />
                  </div>
                  </>
                  )}
                </div>
                <div className={styles.bottomContainer}>
                  {basicInfo.documents.map((doc) => (
                    <div className={styles.spacer} 
                    // key={doc.document_name}
                    >
                      <a href={doc.document}>{doc.document_name}</a>
                    <span> <Icon
                        iconName="ChromeClose"
                        className={tableCloseIconClass}
                        onClick={() => {
                          removeUploadedDoc(doc.document_name);
                        }}
                      /> </span> 
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddCandidateModal;
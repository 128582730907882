import React from "react";
import styles from "./CandidateHistory.module.css";
import {
  PrimaryButton,
  initializeIcons,
  Callout,
  DirectionalHint,
  FontIcon,
  mergeStyles,
  Modal,
  TextField,
} from "@fluentui/react";
import { useState, useEffect,useRef } from "react";
import { Shimmer } from "@fluentui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosPrivateCall } from "../constants";
import { Popup } from "../components/Popup";
import { Icon } from "@fluentui/react/lib/Icon";
import Arrow from "../assets/arrowMark.png";
import bigArrow from "../assets/bigArrow.png";
import Nomatchimg from "../assets/no.png"
import { ISOdateToCustomDate, calcTotalExp, addEllipsisToName } from "../utils/helpers";




const contractIconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  cursor: "pointer",
});

const closeIconClass = mergeStyles({
  fontSize: 16,
  height: 20,
  width: 20,
  cursor: "pointer",
});

const iconClass1 = mergeStyles({
  fontSize: 12,
  height: 12,
  width: 12,
  margin: "0 ",
  color: "#999DA0",
  cursor: "pointer",
});

let items = Array(1).fill(null);

function CandidateHistory(props) {
  let isModalOpen = props.isCandidateModalOpen1;
  const setIsModalOpen = props.setIsCandidateModalOpen1;
  let resetState = props?.resetState;
  const CandidateID = props.candidateId;
  const [showPopup, setShowPopup] = useState(false);
  const [candidate, setCandidateList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hoverCallout, setHoverCallout] = useState("");
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [comments, setCommants] = useState(Number);
  const [error, setError] = useState("");
  const [candidateHistory, setcandidateHistory] = useState([]);
  const [calloutTarget, setCalloutTarget] = useState(null);
  const [feedbackData,setFeedbackData] = useState(false);
  const [feedbackDataref,setFeedbackDataref] = useState("");
  const childRef = useRef(null);
  const [selectedDiv, setSelectedDiv] = useState(null);
   const [feedbackDataone,setFeedbackDataone] = useState(null);

  const location= useLocation();
  const navigateTo = useNavigate();
  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  let user_Id = decodedValue.user_id;
  initializeIcons();

  const columns = [
    {
      columnKey: "CandidateID",
      label: "Candidate ID",
    },
    {
      columnKey: "CandidateName",
      label: "Candidate Name",
    },
    {
      columnKey: "DateofSourcing",
      label: "Date of Sourcing",
    },
    {
      columnKey: "Mobile",
      label: "Mobile",
    },
    {
      columnKey: "email",
      label: "Email ID",
    },
    {
      columnKey: "Recruiter",
      label: "Recruiter",
    },
    {
      columnKey: "Primary Skill",
      label: "Primary Skill ",
    },
    {
      columnKey: "SecondarySkill",
      label: "Secondary Skill ",
    },
    {
      columnKey: "TotalExperience",
      label: "Total Experience",
    },
    {
      columnKey: "Resume",
      label: "Resume",
    },
    {
      columnKey: "Status",
      label: "Status",
    },
  ];

  const textFieldStyles = {
    fieldGroup: {
      border: "1px solid #D9D9D9",
      margin: "10px 15px 10px 15px",
      transition: "background-color 0.3s",
      ...(error && {
        borderColor: "red",
        "&:hover": { borderColor: "red" },
        "&:focus": { borderColor: "red" },
      }),
    },
  };

  useEffect(() => {
    getCandidateData();
  }, []);

  const getSubmissionByCandidateId = () => {
    setIsDataLoaded(false);
    axiosPrivateCall
      .get(`/api/v1/submission/getSubmissionByCandidateId?candidate=${CandidateID}`)
      .then((res) => {
        setcandidateHistory(res.data);
        console.log(res.data, "res");
        setIsDataLoaded(true)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getSubmissionByCandidateId();
  }, []);

  const getCandidateData = () => {
    setIsDataLoaded(false);
    axiosPrivateCall
      .get(`/api/v1/candidate/getCandidateDetails?candidate_id=${CandidateID}`)
      .then((res) => {
        setCandidateList(res.data);
        if (res.data && res.data.submissions && res.data.submissions.length > 0) {
          setCalloutTarget(res.data.submissions[0].submission_id);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const modalSizeHandler = () => {
    setIsModalShrunk(!isModalShrunk);
  };
  


  function getCurrentCompany(Arr) {
    let currCompany = { name: "", ctc: "" };

    Arr.map((data) => {
      if (data.is_current === "yes" || data.is_current === true) {
        currCompany.name = data.company_name;
        currCompany.ctc = data.ctc;
      }
    });

    return currCompany;
  }

  const closeHandler = () => {
    setShowPopup(true);
  };

  function openResume(resumeString) {
    // Split the resume string into an array of lines

    const lines = resumeString.split(/\r?\n/);
    const promptLines = [];

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i].trim();

      if (line.startsWith("?")) {
        promptLines.push(`\n${line.replace("?", "")}`);
      } else if (line.includes(":")) {
        const [key, value] = line.split(":");
        promptLines.push(`\n- ${key.trim()}: ${value.trim()}`);
      } else if (line.length > 0) {
        promptLines.push(line);
      }
    }

    // Open the HTML content in a new tab
    var newTab = window.open();
    newTab.document.open();
    newTab.document.write(promptLines.join("\n"));
    newTab.document.close();
  }
  function formatTimeAgo(Createdattime) {
    const timestamp = new Date(Createdattime);
    const now = new Date();
    const timeDiff = now - timestamp;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (seconds < 5) {
      return "just now";
    } else if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else {
      const day = timestamp.getDate();
      const month = timestamp.toLocaleString("en", { month: "short" });
      const year = timestamp.getFullYear();

      return `${day} ${month} ${year}`;
    }
  }

  const [clickedIndex, setClickedIndex] = useState(null);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const showCallout = (index) => {
    setClickedIndex(index);
    setIsCalloutVisible(true);
  };

  const hideCallout = () => {
    setIsCalloutVisible(false);
  };

  const handleViewNavigation = (candidateId)=>{
    let url=`/candidatelibrary/viewcandidate?candidate_id=${candidateId}`;
    const currentPath = location.pathname;
    const targetPath = url.split('?')[0]; 

    if(currentPath==targetPath){
      setIsModalOpen(!setIsModalOpen)
    }
    else{
      navigateTo(url)
    }
    
  }
let combinedIndex = ''
  const handleFeedbackvisibility = (feedbacktext,index,uniqueKey,event) => {

   setSelectedDiv(index);
    setFeedbackDataone(uniqueKey)
    event.stopPropagation()
    


if(feedbacktext === undefined || feedbacktext == null || feedbacktext < 0){
 setFeedbackDataref("No Data")
}else{
   // Split the paragraph into individual sentences
   setFeedbackDataref(feedbacktext)
}
    if(selectedDiv === index && feedbackDataone === uniqueKey){
      setFeedbackData(!feedbackData)
    }else{
      setFeedbackData(true)
    }
    

  
  }

    useEffect(() => {
    const handleClickOutside = (event) => {
      if (childRef.current && !childRef.current.contains(event.target)) {
        setFeedbackData(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  

  return (
    <div>
      {
        <Popup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }
      {/* <Modal
        scrollableContentClassName={styles.addcandidate_modal_scrollable_content}
        containerClassName={`${
          isModalShrunk ? styles.addcandidate_modal_container_shrunk : styles.addcandidate_modal_container
        }`}
        isOpen={isModalOpen}
      > */}
        {/* <div className={styles.addcandidate_modal_header_container}>
          <div className={styles.header_tag_expand_close_icon_container}>
            <div className={styles.header_tag_container}>Candidate History</div>

            <div className={styles.header_expand_close_icon_container}>
              <div onClick={modalSizeHandler} className={styles.header_expand_icon_container}>
                {isModalShrunk ? (
                  <Icon iconName="FullScreen" className={contractIconClass} />
                ) : (
                  <Icon iconName="BackToWindow" className={contractIconClass} />
                )}
              </div>
              <div onClick={() => { if (props.resetState)
                                    {
                                    setShowPopup(!showPopup);
                                    resetState();                                            setIsModalOpen(!isModalOpen);
                                    } else {
                                    setShowPopup(!showPopup);
                                    setIsModalOpen(!isModalOpen);
                                    }}}
                      className={styles.header_close_icon_container}>
                <Icon iconName="ChromeClose" className={closeIconClass} />
              </div>
            </div>
          </div>

          <div className={styles.header_content_container}>
            <div className={styles.header_content_title_container}>
              <div className={styles.header_content_title_container}>Candidate ID : {candidate.CandidateId}</div>
            </div>
          </div>
        </div> */}

        <div className={styles.page}>
          <div className={styles.container}>
            <div className={styles.table_container}>
              {/* <table>
                <thead className={styles.table_header}>
                  <tr className={styles.table_row}>
                    {columns.map((column) => (
                      <th className={styles.table_headerContents} key={column.columnKey}>
                        <div className={styles.table_heading}>
                          <div>{column.label}</div>
                          {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isDataLoaded ? (
                    <tr className={styles.table_row}>
                      <td
                        onClick={() =>handleViewNavigation(candidate._id) }
                        className={styles.table_dataContents}
                      >
                        <span className={styles.custom_link}>{candidate.CandidateId}</span>
                      </td>
                      <td
                        className={styles.table_dataContents}
                        onMouseEnter={() => setHoverCallout(candidate.first_name)}
                        onMouseLeave={() => setHoverCallout("")}
                        id={`${candidate.first_name}${candidate._id}`.replaceAll(" ", "")}
                      >
                        {addEllipsisToName(`${candidate.first_name} ${candidate.last_name}`)}
                      </td>
                      <td className={styles.table_dataContents}>{ISOdateToCustomDate(candidate.createdAt)}</td>
                      <td className={styles.table_dataContents}>{candidate.mobile_number}</td>
                      <td className={styles.table_dataContents}>{candidate.email}</td>
                      <td
                        className={styles.table_dataContents}
                        onMouseEnter={() => setHoverCallout(candidate.created_by)}
                        onMouseLeave={() => setHoverCallout("")}
                        id={`${candidate.created_by?.first_name}${candidate._id}`.replaceAll(" ", "")}
                      >
                        {addEllipsisToName(`${candidate.created_by?.first_name} ${candidate.created_by?.last_name}`)}
                      </td>
                      <td
                        className={styles.table_dataContents}
                        onMouseEnter={() => setHoverCallout(candidate._id)}
                        onMouseLeave={() => setHoverCallout("")}
                        id={`primary_skill_${candidate._id}`}
                      >
                        {addEllipsisToName(`${candidate.skillset[0]?.skill ? candidate.skillset[0]?.skill : "-"}`)}
                      </td>
                      <td className={styles.table_dataContents}>
                        {candidate.skillset[1]?.skill ? candidate.skillset[1]?.skill : "Nil"}
                      </td>
                      <td className={styles.table_dataContents}>
                        {candidate.total_experience
                          ? candidate.total_experience
                          : `${calcTotalExp(candidate.employment_details).years} Years ${
                              calcTotalExp(candidate.employment_details).months
                            } Months`}
                      </td>
                      <td className={styles.table_dataContents}>
                        {candidate.resume_cv ? (
                          <div onClick={() => openResume(candidate.resume_cv)}>link</div>
                        ) : (
                          <a href={candidate.resume_url} target="_blank">
                            Link
                          </a>
                        )}
                      </td>
                      <td className={styles.table_dataContents}>{candidate.status}</td>
                    </tr>
                  ) : (
                    isDataLoaded &&
                    items.map((candidate) => (
                      <tr className={styles.table_row}>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                        <td className={styles.table_dataContents}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table> */}

              {/* ---------------------------Arrow------------------------ */}

              <div className={styles.historyContainer}>
                <p className={styles.historyStyle}>Candidate History</p>

                 { isDataLoaded && candidateHistory?.length === 0 ? (
                  <tr>
                    <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                    <img src={Nomatchimg} alt="image" width={"190px"} height={"200px"} />
                    </td>
                  </tr>
                 ) :
                  <>
                  {candidateHistory?.map((historyItem, candidateIndex) => (
                    
                  <div key={candidateIndex} className={styles.rectangle_box}>
                    <div className={styles.scrollContainer}>
                      <p className={styles.companyName}>{historyItem.demand_id?.client}</p>
                      <div className={styles.underline}></div>
                      <div className={styles.historyScroll} key = {candidateIndex}>
                        <div className={styles.mainFlow} ref={childRef}>
                          <div className={styles.buttonBox}>
                            <div className={styles.left}>
                              <Icon className={styles.dotIcon} iconName="LocationDot" />
                            </div>

                            <div className={styles.right}>
                              <p className={styles.demandIDText}>Demand ID</p>
                              <p className={styles.demandID}>{historyItem.demand_id?.DemandId}</p>
                            </div>
                          </div>

                          <div className={styles.arrowMark}>
                            <p className={styles.updatedName}>Submitted</p>
                            <img src={Arrow} className={styles.arrowImage} />
                            <p>{historyItem.submitted_by?.first_name + " " + historyItem.submitted_by?.last_name}</p>
                            <p>{ISOdateToCustomDate(historyItem.createdAt)}</p>
                          </div>

                          <div className={styles.buttonBox}>
                            <div className={styles.left}>
                              <Icon className={styles.dotIcon} iconName="LocationDot" />
                            </div>

                            <div className={styles.right}>
                              <p className={styles.demandIDText}>Submission ID</p>
                              <p className={styles.demandID}>{historyItem.SubmissionId}</p>
                            </div>
                          </div>
{/* 
                          <div className={styles.buttonBox}>
                            <div className={styles.left}>
                              <Icon className={styles.dotIcon} iconName="LocationDot" />
                            </div>

                            <div className={styles.right}>
                              <p className={styles.demandIDText}>Submission ID</p>
                              <p className={styles.demandID}>{historyItem.SubmissionId}</p>
                            </div>
                          </div>

                          <div className={styles.arrowMark}>
                            <p className={styles.updatedName}>Approval</p>
                            <img src={Arrow} className={styles.arrowImage} />
                            <p>{historyItem.approved_by?.first_name + " " + historyItem.approved_by?.last_name}</p>
                            <p>{ISOdateToCustomDate(historyItem.submission_id?.approved_date)}</p>
                          </div>

                          <div className={
                             historyItem.submission_id?.approval === true ? styles.buttonBox : styles.buttonBox1
}>
                            <div className={styles.left}>
                              <Icon
                                className={
                                  historyItem.submission_id?.approval === true ? styles.dotIcon1 : styles.dotIcon2
                                }
                                iconName="LocationDot"
                              />
                            </div>
                            <div className={styles.right}>
                              {historyItem.submission_id?.approval === true ? (
                                <p className={styles.approvedText}>Approved </p>
                              ) : (
                                <p className={styles.approvedText}> Rejected</p>
                              )}
                            </div>
                          </div> */}

{(historyItem?.submission_id?.message === "accept" && historyItem?.submission_id?.is_deleted === false) 
                            ? (
                            <>
                              <div className={styles.arrowMark}>
                                <p className={styles.updatedName}>Approval</p>
                                <img src={Arrow} className={styles.arrowImage} alt="Arrow" />
                                <p>
                                  {historyItem?.approved_by?.first_name + " " + historyItem?.approved_by?.last_name}
                                </p>
                                <p>{ISOdateToCustomDate(historyItem.submission_id?.approved_date)}</p>
                              </div>
                              <div
                                className={
                                  historyItem.submission_id?.approval === true ? styles.buttonBox : styles.buttonBox1
                                }
                              >
                                <div className={styles.left}>
                                  <Icon
                                    className={styles.dotIcon1}
                                    iconName="LocationDot"
                                  />
                                </div>
                                <div className={styles.right}>
                                  <p className={styles.approvedText}>Approved</p>
                                </div>
                              </div>
                            </>
                          ) : historyItem?.submission_id?.message === "old" && historyItem?.submission_id?.is_deleted === true ? (
                            <>
                              <div className={styles.arrowMark}>
                                <p className={styles.updatedName}>Approval</p>
                                <img src={Arrow} className={styles.arrowImage} alt="Arrow" />
                                <p>
                                  {historyItem?.approved_by?.first_name + " " + historyItem?.approved_by?.last_name}
                                </p>
                                <p>{ISOdateToCustomDate(historyItem.submission_id?.approved_date)}</p>
                              </div>
                              <div className={styles.buttonBox}>
                                <div className={styles.left}>
                                  <Icon className={styles.dotIcon2} iconName="LocationDot" />
                                </div>
                                <div className={styles.right}>
                                  <p className={styles.approvedText}>Rejected</p>
                                </div>
                              </div>
                            </>
                          ) : historyItem?.submission_id?.message === "old" && historyItem?.submission_id?.is_deleted === false ? (
                            <>
                              <div className={styles.arrowMark}>
                                <p className={styles.updatedName}>In Progress</p>
                                <img src={Arrow} className={styles.arrowImage1} alt="Arrow" />
                              </div>
                              <div className={styles.buttonBox}>
                                <div className={styles.left}>
                                  <Icon className={styles.dotIcon} iconName="LocationDot" />
                                </div>
                                <div className={styles.right}>
                                  <p className={styles.approvedText}>Waiting</p>
                                </div>
                              </div>
                            </>
                            ):null}


                          {/* --------------------------------Initial screening------------------------------------------ */}

                          {historyItem?.submission_id?.message==="accept" ? (
                            <>
                              <div className={styles.arrowMark}>
                                <div className={styles.infoStyle}>
                                  <p className={styles.updatedName}>Initial Screening</p>
                                </div>

                                <img src={bigArrow} />
                                <p className={styles.updatedName}>
                                  {historyItem.remarks[1]?.updated_by_info?.first_name +
                                    " " +
                                    historyItem.remarks[1]?.updated_by_info?.last_name}
                                </p>
                                <p className={styles.updatedName}>
                                  {historyItem.remarks[1]?.updated_date
                                    ? ISOdateToCustomDate(historyItem.remarks[1].updated_date)
                                    : "No Data"}
                                </p>
                              </div>
                                    
                              <div className={styles.buttonBox1} onClick={(event) =>  handleFeedbackvisibility("Feedback not available for initial screening",candidateIndex,"um",event)}>
                                <div className={styles.left}>
                                  <Icon className={styles.dotIcon1} iconName="LocationDot" />
                                </div>
                                <div className={styles.right}>
                                  <p className={styles.approvedText}>Selected</p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                            </>
                          )}

                          {historyItem.remarks.slice(1).map((remark, historyItemIndex) => {
                            combinedIndex = `${candidateIndex}-${historyItemIndex}`;
                            const uniqueKey = `mapContainer-${combinedIndex}`;

                            const level1Failed =
                              historyItem.remarks.filter((r) => r.status === "level_1" && r.failed === true).length > 0;
                            const level2Failed =
                              historyItem.remarks.filter((r) => r.status === "level_2" && r.failed === true).length > 0;
                            const level3Failed =
                              historyItem.remarks.filter((r) => r.status === "final_select" && r.failed === true)
                                .length > 0;
                            const level4Failed =
                              historyItem.remarks.filter((r) => r.status === "offered" && r.failed === true).length > 0;
                            const level5Failed =
                              historyItem.remarks.filter((r) => r.status === "onboard" && r.failed === true).length > 0;
                            const level6Failed =
                              historyItem.remarks.filter((r) => r.status === "bg_verification" && r.failed === true)
                                .length > 0;

                            let previousLevelsRejected = false;

                            if (remark.status === "level_1") {
                              const approvalStatus = level1Failed ? "Dropped" : "Selected";
                              previousLevelsRejected = historyItem?.submission_id?.approval === true;

                              if (!previousLevelsRejected && !level1Failed) return null; // Skip rendering if previous levels are not rejected and level 1 is not failed

                              return (
                                <div className={styles.mapContainer} key={uniqueKey}>
                                  <div className={styles.arrowMark}>
                                    <div className={styles.infoStyle}>
                                      <p className={styles.updatedName}>Level 1</p>
                                      {/* <Icon
                                        className={styles.iconInfo}
                                        iconName="Info"
                                        onClick={() => showCallout(combinedIndex)}
                                        id={`${candidate.CandidateId}-${combinedIndex}`}
                                      />
                                      {isCalloutVisible && clickedIndex === combinedIndex && (
                                        <Callout
                                          target={`#${candidate.CandidateId}-${combinedIndex}`}
                                          isBeakVisible={false}
                                          directionalHint={DirectionalHint.topLeftEdge}
                                          onDismiss={hideCallout}
                                          setInitialFocus
                                          styles={{
                                            root: {
                                              height: "auto",
                                              width: "200px",
                                              zIndex: 3,
                                              padding: "5px",
                                              background: "white",
                                            },
                                          }}
                                        >
                                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          {historyItem.StatusModify &&
                                          historyItem.StatusModify[0] &&
                                          historyItem.StatusModify[0].length > 0
                                            ? historyItem.StatusModify[0]
                                            : "no data"}
                                            </div>
                                        </Callout>
                                      )} */}
                                    </div>
                                    <img src={Arrow} className={styles.arrowImage} />
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[1]?.updated_by_info?.first_name +
                                        " " +
                                        historyItem.remarks[1]?.updated_by_info?.last_name}
                                    </p>
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[1]?.updated_date
                                        ? ISOdateToCustomDate(historyItem.remarks[1].updated_date)
                                        : "No Data"}
                                    </p>
                                  </div>

                                  <div className={styles.buttonBox1} onClick={(event) => handleFeedbackvisibility(historyItem.StatusModify[0],candidateIndex,uniqueKey,event)}>
                                    <div className={styles.left}>
                                      <Icon
                                        className={level1Failed ? styles.dotIcon2 : styles.dotIcon1}
                                        iconName="LocationDot"
                                      />
                                    </div>

                                    <div className={styles.right}>
                                      <p className={styles.approvedText}>{approvalStatus}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            const failedStatusLevel2 = historyItem.remarks[2]?.failed;

                            if (remark.status === "level_2") {
                              const approvalStatusLevel2 = failedStatusLevel2 ? "Dropped" : "Selected";

                              previousLevelsRejected = level1Failed;

                              if (failedStatusLevel2 === "") return null; // Skip rendering if current level has unknown status

                              if (previousLevelsRejected || failedStatusLevel2) return null; // Skip rendering if previous levels are rejected or current level failed

                              return (
                                <div className={styles.mapContainer} key={uniqueKey}>
                                  <div className={styles.arrowMark}>
                                    <div className={styles.infoStyle}>
                                      <p className={styles.updatedName}>Level 2</p>
                                      {/* <Icon
                                        className={styles.iconInfo}
                                        iconName="Info"
                                        onClick={() => showCallout(combinedIndex)}
                                        id={`${candidate.CandidateId}-${combinedIndex}`}
                                      />
                                      {isCalloutVisible && clickedIndex === combinedIndex && (
                                        <Callout
                                          target={`#${candidate.CandidateId}-${combinedIndex}`}
                                          isBeakVisible={false}
                                          directionalHint={DirectionalHint.topLeftEdge}
                                          onDismiss={hideCallout}
                                          setInitialFocus
                                          styles={{
                                            root: {
                                              height: "auto",
                                              width: "200px",
                                              zIndex: 3,
                                              padding: "5px",
                                              background: "white",
                                            },
                                          }}
                                        >
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          {historyItem.StatusModify &&
                                          historyItem.StatusModify[1] &&
                                          historyItem.StatusModify[1].length > 0
                                            ? historyItem.StatusModify[1]
                                            : "no data"}
                                            </div>
                                        </Callout>
                                      )} */}
                                    </div>
                                    <img src={Arrow} className={styles.arrowImage} />
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[1]?.updated_by_info?.first_name}
                                    </p>
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[1]?.updated_date
                                        ? ISOdateToCustomDate(historyItem.remarks[1].updated_date) // Corrected index from 2 to 1
                                        : "No Data"}
                                    </p>
                                  </div>
                                  <div className={styles.buttonBox1}  onClick={(event) => handleFeedbackvisibility(historyItem.StatusModify[1],candidateIndex,uniqueKey,event)}>
                                    <div className={styles.left}>
                                      <Icon
                                        className={failedStatusLevel2 ? styles.dotIcon2 : styles.dotIcon1}
                                        iconName="LocationDot"
                                      />
                                    </div>
                                    <div className={styles.right}>
                                      <p className={styles.approvedText}>{approvalStatusLevel2}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            const failedStatusFinalSelect = historyItem.remarks[3]?.failed;

                            if (remark.status === "final_select") {
                              const approvalStatusFinalSelect = level3Failed ? "Dropped" : "Selected";

                              previousLevelsRejected = level1Failed || level2Failed;

                              if (failedStatusFinalSelect === "") return null; // Skip rendering if current level is failed or has unknown status
                              if (previousLevelsRejected) return null; // Skip rendering if any of the previous levels are rejected

                              return (
                                <div className={styles.mapContainer} key={uniqueKey}>
                                  <div className={styles.arrowMark}>
                                    <div className={styles.infoStyle}>
                                      <p className={styles.updatedName}>Final Select</p>
                                      {/* <Icon
                                        className={styles.iconInfo}
                                        iconName="Info"
                                        onClick={() => showCallout(combinedIndex)}
                                        id={`${candidate.CandidateId}-${combinedIndex}`}
                                      />
                                      {isCalloutVisible && clickedIndex === combinedIndex && (
                                        <Callout
                                          target={`#${candidate.CandidateId}-${combinedIndex}`}
                                          isBeakVisible={false}
                                          directionalHint={DirectionalHint.topLeftEdge}
                                          onDismiss={hideCallout}
                                          setInitialFocus
                                          styles={{
                                            root: {
                                              height: "auto",
                                              width: "200px",
                                              zIndex: 3,
                                              padding: "5px",
                                              background: "white",
                                            },
                                          }}
                                        >
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          {historyItem.StatusModify &&
                                          historyItem.StatusModify[2] &&
                                          historyItem.StatusModify[2].length > 0
                                            ? historyItem.StatusModify[2]
                                            : "no data"}
                                            </div>
                                        </Callout>
                                      )} */}
                                    </div>
                                    <img src={Arrow} className={styles.arrowImage} />
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[3]?.updated_by_info?.first_name +
                                        " " +
                                        historyItem.remarks[3]?.updated_by_info?.last_name}
                                    </p>
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[3]?.updated_date
                                        ? ISOdateToCustomDate(historyItem.remarks[3].updated_date)
                                        : "No Data"}
                                    </p>
                                  </div>
                                  <div className={styles.buttonBox1}  onClick={(event) => handleFeedbackvisibility(historyItem.StatusModify[2],candidateIndex,uniqueKey,event)}>
                                    <div className={styles.left}>
                                      <Icon
                                        className={failedStatusFinalSelect ? styles.dotIcon2 : styles.dotIcon1}
                                        iconName="LocationDot"
                                      />
                                    </div>
                                    <div className={styles.right}>
                                      <p className={styles.approvedText}>{approvalStatusFinalSelect}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            // Repeat similar logic for other levels
                            const failedStatusOffered = historyItem.remarks[4]?.failed;

                            if (remark.status === "offered") {
                              const approvalStatusOffered = level4Failed ? "Dropped" : "Selected";

                              previousLevelsRejected = level1Failed || level2Failed || level3Failed;

                              if (failedStatusOffered === "") return null; // Skip rendering if current level is failed or has unknown status
                              if (previousLevelsRejected) return null; // Skip rendering if any of the previous levels are rejected

                              return (
                                <div className={styles.mapContainer} key={uniqueKey}>
                                  <div className={styles.arrowMark}>
                                    <div className={styles.infoStyle}>
                                      <p className={styles.updatedName}>Offered</p>
                                      {/* <Icon
                                        className={styles.iconInfo}
                                        iconName="Info"
                                        onClick={() => showCallout(combinedIndex)}
                                        id={`${candidate.CandidateId}-${combinedIndex}`}
                                      />
                                      {isCalloutVisible && clickedIndex === combinedIndex && (
                                        <Callout
                                          target={`#${candidate.CandidateId}-${combinedIndex}`}
                                          isBeakVisible={false}
                                          directionalHint={DirectionalHint.topLeftEdge}
                                          onDismiss={hideCallout}
                                          setInitialFocus
                                          styles={{
                                            root: {
                                              height: "auto",
                                              width: "200px",
                                              zIndex: 3,
                                              padding: "5px",
                                              background: "white",
                                            },
                                          }}
                                        >
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          {historyItem.StatusModify &&
                                          historyItem.StatusModify[3] &&
                                          historyItem.StatusModify[3].length > 0
                                            ? historyItem.StatusModify[3]
                                            : "no data"}
                                            </div>
                                        </Callout>
                                      )} */}
                                    </div>
                                    <img src={Arrow} className={styles.arrowImage} />
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[4]?.updated_by_info?.first_name +
                                        " " +
                                        historyItem.remarks[4]?.updated_by_info?.last_name}
                                    </p>
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[4]?.updated_date
                                        ? ISOdateToCustomDate(historyItem.remarks[4].updated_date)
                                        : "No Data"}
                                    </p>
                                  </div>
                                  <div className={styles.buttonBox1}  onClick={(event) => handleFeedbackvisibility(historyItem.StatusModify[3],candidateIndex,uniqueKey,event)}>
                                    <div className={styles.left}>
                                      <Icon
                                        className={failedStatusOffered ? styles.dotIcon2 : styles.dotIcon1}
                                        iconName="LocationDot"
                                      />
                                    </div>
                                    <div className={styles.right}>
                                      <p className={styles.approvedText}>{approvalStatusOffered}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            const failedStatusOnboard = historyItem.remarks[5]?.failed;

                            if (remark.status === "onboard") {
                              const approvalStatusOnboard = level5Failed ? "Dropped" : "Selected";

                              previousLevelsRejected = level1Failed || level2Failed || level3Failed || level4Failed;

                              if (failedStatusOnboard === "") return null; // Skip rendering if current level is failed or has unknown status
                              if (previousLevelsRejected) return null; // Skip rendering if any of the previous levels are rejected

                              return (
                                <div className={styles.mapContainer} key={uniqueKey}>
                                  <div className={styles.arrowMark}>
                                    <div className={styles.infoStyle}>
                                      <p className={styles.updatedName}>Onboard</p>
                                      {/* <Icon
                                        className={styles.iconInfo}
                                        iconName="Info"
                                        onClick={() => showCallout(combinedIndex)}
                                        id={`${candidate.CandidateId}-${combinedIndex}`}
                                      />
                                      {isCalloutVisible && clickedIndex === combinedIndex && (
                                        <Callout
                                          target={`#${candidate.CandidateId}-${combinedIndex}`}
                                          isBeakVisible={false}
                                          directionalHint={DirectionalHint.topLeftEdge}
                                          onDismiss={hideCallout}
                                          setInitialFocus
                                          styles={{
                                            root: {
                                              height: "auto",
                                              width: "200px",
                                              zIndex: 3,
                                              padding: "5px",
                                              background: "white",
                                            },
                                          }}
                                        >
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          {historyItem.StatusModify &&
                                          historyItem.StatusModify[4] &&
                                          historyItem.StatusModify[4].length > 0
                                            ? historyItem.StatusModify[4]
                                            : "no data"}
                                            </div>
                                        </Callout>
                                      )} */}
                                    </div>
                                    <img src={Arrow} className={styles.arrowImage} />
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[5]?.updated_by_info?.first_name +
                                        " " +
                                        historyItem.remarks[5]?.updated_by_info?.last_name}
                                    </p>
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[5]?.updated_date
                                        ? ISOdateToCustomDate(historyItem.remarks[5].updated_date)
                                        : "No Data"}
                                    </p>
                                  </div>
                                  <div className={styles.buttonBox1}  onClick={(event) => handleFeedbackvisibility(historyItem.StatusModify[4],candidateIndex,uniqueKey,event)}>
                                    <div className={styles.left}>
                                      <Icon
                                        className={failedStatusOnboard ? styles.dotIcon2 : styles.dotIcon1}
                                        iconName="LocationDot"
                                      />
                                    </div>
                                    <div className={styles.right}>
                                      <p className={styles.approvedText}>{approvalStatusOnboard}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            const failedStatus = historyItem.remarks[6]?.failed;

                            if (remark.status === "bg_verification") {
                              const approvalStatus = level6Failed ? "Dropped" : "Selected";

                              previousLevelsRejected =
                                level1Failed || level2Failed || level3Failed || level4Failed || level5Failed;

                              if (failedStatus === "") return null; // Skip rendering if current level is failed or has unknown status
                              if (previousLevelsRejected) return null; // Skip rendering if any of the previous levels are rejected

                              return (
                                <div className={styles.mapContainer} key={uniqueKey}>
                                  <div className={styles.arrowMark}>
                                    <div className={styles.infoStyle}>
                                      <p className={styles.updatedName}>BG Verification</p>
                                      {/* <Icon
                                        className={styles.iconInfo}
                                        iconName="Info"
                                        onClick={() => showCallout(combinedIndex)}
                                        id={`${candidate.CandidateId}-${combinedIndex}`}
                                      />
                                      {isCalloutVisible && clickedIndex === combinedIndex && (
                                        <Callout
                                          target={`#${candidate.CandidateId}-${combinedIndex}`}
                                          isBeakVisible={false}
                                          directionalHint={DirectionalHint.topLeftEdge}
                                          onDismiss={hideCallout}
                                          setInitialFocus
                                          styles={{
                                            root: {
                                              height: "auto",
                                              width: "200px",
                                              zIndex: 3,
                                              padding: "5px",
                                              background: "white",
                                            },
                                          }}
                                        >
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                          {historyItem.StatusModify &&
                                          historyItem.StatusModify[5] &&
                                          historyItem.StatusModify[5].length > 0
                                            ? historyItem.StatusModify[5]
                                            : "no data"}
                                            </div>
                                        </Callout>
                                      )} */}
                                    </div>
                                    <img src={Arrow} className={styles.arrowImage} />
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[6]?.updated_by_info?.first_name +
                                        " " +
                                        historyItem.remarks[6]?.updated_by_info?.last_name}
                                    </p>
                                    <p className={styles.updatedName}>
                                      {historyItem.remarks[6]?.updated_date
                                        ? ISOdateToCustomDate(historyItem.remarks[6].updated_date)
                                        : "No Data"}
                                    </p>
                                  </div>
                                  <div className={styles.buttonBox}>
                                    <div className={styles.left}>
                                      <Icon
                                        className={failedStatus ? styles.dotIcon2 : styles.dotIcon1}
                                        iconName="LocationDot"
                                      />
                                    </div>
                                    <div className={styles.right}>
                                      <p className={styles.approvedText}>{approvalStatus}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }

                            // Add similar blocks for other levels
                          })}
                        </div>
                      </div>
                        <div className={styles.feedback_container} key={candidateIndex} >
                      {selectedDiv === candidateIndex && feedbackData &&
                     <>
                        <p className={styles.companyName}>Feedback</p>
                        <p className={styles.feedback_text}>
                        {feedbackDataref}
                        </p>
        
                     </>
                       }
                        </div>
                    </div>
                  </div>
                ))}
                  </>
                  }
               
              </div>
            </div>
          </div>
        </div>
      {/* </Modal> */}
    </div>
  );
}

export default CandidateHistory;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@fluentui/react';
import { darkTheme, lightTheme } from '../src/themes';
import { BrowserRouter as Router } from "react-router-dom";


import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/authConfig';
import { Provider } from 'react-redux';
import store from '../src/redux_library/Store';


initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <Router>
        <MsalProvider instance={msalInstance}>
          <Provider store={store} >
            <App />
          </Provider>
        </MsalProvider>
      </Router>

    </ThemeProvider>
  // </React.StrictMode>







);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
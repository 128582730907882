import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import styles from './LoadingSpinner.module.css';
const LoadingSpinner = () => (
    <div className={styles.spinnerOverlay}>
        <Spinner
            label="Loading..."
            size={SpinnerSize.large}
            className={styles.spinnerContainer}
            styles={{ label: { fontSize: '18px' } }}
        />
    </div>
);
export default LoadingSpinner;
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, DatePicker } from "@fluentui/react";
import styles from "./HierarchyView.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { axiosPrivateCall } from "../constants";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { debounce } from "lodash";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";


const closeIconClass = mergeStyles({
  fontSize: 14,
  height: "18px",
  width: "15px",
  paddingTop: "4px",
  marginLeft:"5px",
  cursor: "pointer",
});

const calendarClass = mergeStyleSets({
	root: {
	
		"*": {
			minWidth: "70px",
			maxWidth: "120px",
			fontSize: 12,
			height: "22px !important",
      lineHeight: "20px !important",
      borderColor: "#E1E5E8 !important",
		},
	},
	icon: {
		height: "8px !important",
		width: "8px !important",
    left: "65%",
    color:"skyblue",
		padding: "2px 0px 3px 0px",
		scale: "75%",
	},
	statusMessage: { marginBottom: "-25px" },
});



const DropdownStyles = (props, currentHover, error, value) => {
  const hoverBorderColor = "#F9FBFF";
  let titleColor = "#D7D7D7";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  return {
    title: {
      height: "22px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "75px",
      color: "#7A7A7A",
      fontSize: "12px",
      background: "#F9FBFF",
      border: "1px solid #E1E5E8 !important",
    },
    dropdown: {
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "21px",
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
      },
    },
  };
};

const options = [
  { key: "day", text: "Day" },
  { key: "week", text: "Week" },
  { key: "month", text: "Month" },
  { key: "year", text: "Year" },
  { key: "tilltime", text: "Till time" },
];

const SubmissionHierarchy = (props) => {
  
  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  const user_id = decodedValue.user_id;
  const { isModalOpen, setIsModalOpen } = props;


  const [history, setHistory] = useState([]);
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [overallSubmission, setOverallSubmission] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [empCount, setEmpCount] = useState([]);
  const [dropdownFilter, setDropdownFilter] = useState("");
  const [indSearch, setIndSearch] = useState(false);
  const [id, setId] = useState(user_id);
  const [idHistory, setIdHistory] = useState([user_id]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  const handlePopupClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  const capitalizeRole = (role) => {
    if (!role) return "";
  
    if (role.includes("_")) {
      return role
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return role.charAt(0).toUpperCase() + role.slice(1);
  };

  const updateHistory = (data) => {
    setHistory((prevHistory) => [...prevHistory, data]);
  };

   const updateIdHistory = (id) => {
    console.log(id,"idss")
    setIdHistory((prevIdHistory) => [...prevIdHistory, id]);
  };

  const handleBackButton = () => {
    if (history.length > 1) {
      const previousId = idHistory[idHistory.length - 2];  
      const previousState = history[history.length - 2];
      setHistory(history.slice(0, -1));
      setOverallSubmission(previousState);
      const newEmpCount = previousState.length;
      setEmpCount(newEmpCount);
      setId(previousId); // Set the previous id
      setIdHistory((prevIdHistory) => prevIdHistory.slice(0, -1));
    } else {
      setHistory([]);
      setOverallSubmission(initialData);
      setId("");
      const newEmpCount = initialData.length;
      setEmpCount(newEmpCount);
    }
  };
  
  const getIndividualHierarchy = debounce((select) => {
    
    setLoading(true);
    let apiUrl = `/api/v1/aggregate/getIndividualHierarchy?user_id=${id}&timeRange=${select}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate}&end_date=${endDate}`;
    }

    axiosPrivateCall.get(apiUrl).then((res) => {
        const employeeSubmissions = res.data.data.employeeSubmissions;
        setOverallSubmission(employeeSubmissions);
        setEmpCount(res.data.data.employeCount);
        const role = employeeSubmissions[0]?.employee?.role;
        setIndSearch(true);

       if (employeeSubmissions.length === 0) {
          updateHistory([]);
        } else {
          updateHistory(employeeSubmissions);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },300);

  const handleDropdownChange = (e, item) => {
    setDropdownFilter(item.key);
    setStartDate(""); 
    setEndDate("");
    if (!indSearch) {
      getIndividualHierarchy(item.key);
    } else {
      getIndividualHierarchy(item.key);
    }
  };

  useEffect(() => {
    if(id.length != 0)
    getIndividualHierarchy(); 
  }, [id]);

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const setDateHandler = () => {
    if (endDate > startDate) {
      getIndividualHierarchy();
    }
  };
 
	const onFormatDate = (date) => {
		if (!date) {
			return '';
		}
		return date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
	};
  
  return (
    <div>
      <Modal
        scrollableContentClassName={styles.addcandidate_modal_scrollable_content}
        containerClassName={`${
          isModalShrunk ? styles.addcandidate_modal_container_shrunk : styles.addcandidate_modal_container
        }`}
        isOpen={isModalOpen}
      >
        <div className={styles.header_expand_close_icon_container}>
          <DatePicker
            placeholder={"Start Date"}
            styles={calendarClass}
            onSelectDate={handleStartDateChange}
            formatDate={onFormatDate} 
            value={startDate ?(startDate) : null} // Use formatDate function here
            maxDate={new Date()} // Convert maxDate to Date object
          />
          <DatePicker
            placeholder={"End Date"}
            styles={calendarClass}
            onSelectDate={handleEndDateChange}
            formatDate={onFormatDate} 
            value={endDate ?(endDate) : null} // Use formatDate function here
          />

          <PrimaryButton className={styles.setDateStyle} text={"Date Filter"} onClick={setDateHandler} />

          <Dropdown
            placeholder="Filter By"
            selectedKey={dropdownFilter}
            styles={(props) => DropdownStyles(props, "role")}
            onChange={handleDropdownChange}
            options={options}
          />

          <div onClick={handlePopupClose} className={styles.header_close_icon_container}>
            <Icon iconName="ChromeClose" className={closeIconClass} />
          </div>
        </div>
        <div className={styles.main_container}>
          <div className={styles.back_button}>
            <Icon iconName="Chromeback" onClick={handleBackButton} />
          </div>
          <span className={styles.sub_title}>
            {overallSubmission.length > 0 && capitalizeRole(overallSubmission[0].employee.role)} Count: {empCount}
          </span>

          <table>
            <thead className={styles.table_header}>
              <tr className={styles.table_row}>
                <th className={styles.table_headerContents}>Employee Name</th>
                <th className={styles.table_headerContents1}>Designation</th>
                <th className={styles.table_headerContents2}>Submission Count</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <Spinner className={styles.spinnerStyle} size={SpinnerSize.large} label="Loading ..." />
              ) : empCount === 0 ? (
                <div className={styles.notfound}>Data Not found !</div>
              ) : (
                overallSubmission.map((submission, index) => (
                  <tr key={index} className={styles.table_row}>
                    <td
                      className={`${styles.table_dataContents} ${
                        submission.employee.role === "recruiter" ? styles.recruiterRole : ""
                      }`}
                      onClick={
                        submission.employee.role !== "recruiter" 
                          ? () => {
                              setId(submission.employee._id);
                              updateIdHistory(submission.employee._id); // Update idHistory here
                            }
                          : undefined
                      }
                    >
                      {submission.employee.first_name} {submission.employee.last_name}
                    </td>

                    <td className={styles.table_dataContents1}>{capitalizeRole(submission.employee.role)}</td>
                    <td className={styles.table_dataContents2}>{submission.overallcount}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default SubmissionHierarchy;

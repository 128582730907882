import { configureStore } from '@reduxjs/toolkit';
import { dataReducer } from '../redux_library/slice/dashboarddata.slice';
import { bdedataReducer } from './slice/bdeDashboardData.slice';
import { bdeHierarchyDetailsDataReducer } from './slice/bdeHierarchyDetails.slice'
import isEditReducer from './slice/isDemandEdit.slice';

const store = configureStore({
  reducer: {
    dashboardData: dataReducer,
    bdeDashboardData: bdedataReducer,
    bdeHierarchyDetails: bdeHierarchyDetailsDataReducer,
    isEdit:isEditReducer,
  }   
});

export default store;
const emailContent = (meetingId, password, joinWebUrl, startDateTime, endDateTime)=>{
    return {

    creatOnlineMeeting:`<html>
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    </head>
    <body>
      <h1>Spectrum Call</h1>
      <a href="https://www.microsoft.com/en-in/microsoft-teams/join-a-meeting">
        <button style="background-color:blue; color:white; padding:10px 20px; font-size:16px">Join Now</button>
      </a>
      <br><br>
      <div style="width: 100%; margin-top: 20px;">
      <span style="white-space: nowrap; color: #5F5F5F; opacity: .36;">________________________________________________________________________________</span>
    </div>
    <div style="margin-top: 20px;">
      <div style="margin-bottom: 20px;">
        <span style="font-size: 20px; font-weight: bold;">Interview Start Time: </span>
        <span style="font-size: 20px; font-weight: normal;">${startDateTime}</span><br>
        <span style="font-size: 20px; font-weight: bold;">End Time: </span>
        <span style="font-size: 20px; font-weight: normal;">${endDateTime}</span>
      </div>
    </div>
    <div style="width: 100%; margin-top: 20px;">
      <span style="white-space: nowrap; color: #5F5F5F; opacity: .36;">________________________________________________________________________________</span>
    </div>
      <div class="me-email-text" lang="en-US" style="color:#252424; font-family:'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif">
        <div style="margin-top:24px; margin-bottom:20px">
          <span style="font-size:24px; color:#252424">Microsoft Teams meeting</span>
        </div>
        <div style="margin-bottom:20px">
          <a href=${joinWebUrl}>Click here to join the meeting</a>
        </div>
        <div style="margin-bottom:20px; margin-top:20px">
          <div style="margin-bottom:4px">
            <span data-tid="meeting-code" style="font-size:14px; color:#252424">Meeting ID:
              <span style="font-size:16px; color:#252424">${meetingId}</span>
            </span>
            <br>
            <span style="font-size:14px; color:#252424">Passcode:</span>
            <span style="font-size:16px; color:#252424">${password}</span>
            <div style="font-size:14px; margin-bottom:4px; font-family:'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif"></div>
            <div style="font-size:12px"></div>
            <div></div>
            <div style="width:100%">
              <span style="white-space:nowrap; color:#5F5F5F; opacity:.36">
                ________________________________________________________________________________
              </span>
            </div>
          </div>
        </div>
      </div>
    </body>
  </html>`, 
  
  cancelOnlineMeeting: `<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  </head>
  <body style="font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif; color: #252424; margin: 0; padding: 0;">
    <div style="text-align: center; margin-bottom: 20px;">
      <h1 style="margin: 0; padding: 20px;">Spectrum Call</h1>
      <p style="font-size: 18px; color: red; font-weight: bold;">The meeting has been cancelled.</p>
    </div>

    <div style="width: 100%; margin-top: 20px;">
      <span style="white-space: nowrap; color: #5F5F5F; opacity: .36;">________________________________________________________________________________</span>
    </div>

    <div style="margin-top: 20px;">
      <div style="margin-bottom: 20px;">
      <span style="font-size: 20px; font-weight: bold;">Interview Start Time: </span>
      <span style="font-size: 20px; font-weight: normal;">${startDateTime}</span><br>
      <span style="font-size: 20px; font-weight: bold;">End Time: </span>
      <span style="font-size: 20px; font-weight: normal;">${endDateTime}</span>
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px;">
      <span style="white-space: nowrap; color: #5F5F5F; opacity: .36;">________________________________________________________________________________</span>
    </div>

    <div style="margin-top: 24px; margin-bottom: 20px;">
      <span style="font-size: 24px;">Microsoft Teams meeting</span>
    </div>

    <div style="margin-bottom: 20px;">
      <a href="${joinWebUrl}" style="font-size: 16px; color: blue; text-decoration: none;">Click here to join the meeting</a>
    </div>

    <div style="margin-bottom: 20px;">
      <div style="margin-bottom: 4px;">
        <span style="font-size: 14px; color: #252424;">Meeting ID: </span>
        <span style="font-size: 16px; color: #252424; font-weight: normal;">${meetingId}</span>
      </div>
      <div>
        <span style="font-size: 14px; color: #252424;">Passcode: </span>
        <span style="font-size: 16px; color: #252424; font-weight: normal;">${password}</span>
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px;">
      <span style="white-space: nowrap; color: #5F5F5F; opacity: .36;">________________________________________________________________________________</span>
    </div>
  </body>
</html>
`

}
} 

export default emailContent;
import React from "react";
import styles from "./SubmissionReport.module.css";
import { SearchBox, FontIcon, mergeStyles, mergeStyleSets, Dropdown } from "@fluentui/react";
import { useState, useEffect } from "react";
import { Callout, DirectionalHint } from "@fluentui/react";
import { Shimmer } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { axiosPrivateCall } from "../constants";
import { DeletePopup } from "../components/DeletePopup";
import { Spinner, SpinnerSize, DatePicker, PrimaryButton } from "@fluentui/react";

import debounce from 'lodash.debounce';




const calendarClass = (value ) => {
  return mergeStyleSets({
    root: {
      "*": {
        fontSize: 13,
        width: "130px !important",
        height: "29px !important",
        // lineHeight: "20px !important",
        // borderColor: error ?  "red !important":"LightGray !important" ,
        borderColor: "#E1E5E8 !important",
        borderRadius: "4px",
        lineHeight: "26px !important",
          color:  value?"#484848 !important": "LightGray !important" 
        
                // color: error?"red":"yellow",
      },
    },
    field: {
      color: value? "#484848 !important": "LightGray !important" ,
      fontSize: 12,
      input: {
        color: value?"#484848 !important": "LightGray !important" ,
        "&::placeholder": {
          color: value?"#484848 !important": "LightGray !important" ,
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "2px !important",
      left: "80%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};

const searchIcon = { iconName: "Search" };
const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: "0 10px",
  color: "#999DA0",
  cursor: "pointer",
  userSelect: "none",
});

const CalloutNameStyles = {
  calloutMain: {
    background: "#EDF2F6",
    padding: "2",
    fontSize: "12px", // Reduced font size
  },
};
// const dropdownStyles = {
//   // Increase specificity by targeting the element more specifically
//   root: {
//     width: 200,
//     border: '1px solid #B3B3B3 !important', // Use !important to override other styles
//   },
// };

const DropdownStyles = (props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8"; // Same color for hover state
  let titleColor = "#D7D7D7"; // Default color when value is not empty
  if (!value) {
    titleColor = error ? "#D24545" : "#484848"; // Change the color to red if value is empty
  }
  return {
    title: {
      height: "32px",
      display: "flex",
      width:"150px",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      borderRadius: "4px",
      selectors: {
        
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: "4px",
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: "4px",
        },
      },
    },
  };
};

const options = [
  { key: "all", text: "All" },
  { key: "account_manager", text: "Account Manager" },
  { key: "lead", text: "Lead" },
  { key: "recruiter", text: "Recruiter" },
];

const options2 = [
  { key: "today", text: "Today" },
  { key: "last_week", text: "Last Week" },
  { key: "last_month", text: "Last Month" },
  { key: "last_year", text: "Last Year" },
  { key: "till_time", text: "Till Time" },
];

const searchFieldStyles = mergeStyleSets({
  root: { width: "185px" },
});

let items = Array(4).fill(null);

function SubmissionReport(props) {
  const [showPopup, setShowPopup] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteID] = useState("");
  const [isSubmitDel, setSubmitDel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [updateCallout, setUpdateCallout] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [employeeList, setEmployeeList] = useState("");

  const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('')

  const [getReports, setGetReports] = useState([]);
  const [getReportsLead, setGetReportsLead] = useState([]);
  const [getReportsRecruiter, setGetReportsRecruiter] = useState([]);
  const [interviewList, setInterviewList] = useState('');
  const [leadList, setLeadList] = useState('');
  const [recruiterList, setRecruiterList] = useState('');

  console.log(getReportsLead.length, getReports.length, getReportsRecruiter.length, "getReportsRecruiter");

  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [hoverCallout, setHoverCallout] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [isInformationContainerVisible, setIsInformationContainerVisible] = useState(true);
  const [isAMContainerVisible, setIsAMContainerVisible] = useState(true);
  const [isLeadContainerVisible, setIsLeadContainerVisible] = useState(true);
  const [isRecruiterContainerVisible, setIsRecruiterContainerVisible] = useState(true);
  const [selectedTimeRange, setSelectedTimeRange] = useState("");
  const [searchState, setsearchState] = useState("");
  const [allSearchResult, getAllSearchResult] = useState([]);

  const [storeSearch, setStoreSearch] = useState("");

  console.log(storeSearch, "storeSearch");

  const [targetElement, setTargetElement] = useState(null);

  const showCallout = (target) => {
    setTargetElement(target);
  };

  console.log(selectedTimeRange, "selectedTimeRange");
  const [selectedRole, setSelectedRole] = useState("");
  const [isRecDataLoaded, setIsRecDataLoaded] = useState(false);
  const [isLeadDataLoaded, setIsLeadDataLoaded] = useState(false);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [fetchOptions, setFetchOptions] = useState({
    skip: 0,
    limit: 15,
    sort_field: "updatedAt",
    sort_type: -1,
    search_field: "",
  });

  const [hasMore, setHasMore] = useState(true);
  const [datas, setDatas] = useState([]);

  const navigateTo = useNavigate();

  const handleUpdate = (showpop) => {};

  useEffect(() => {
    setHasMore(true);
    setFetchOptions({ ...fetchOptions, skip: 0, limit: 15 });
  }, [isModalOpen, fetchOptions.sort_field, fetchOptions.sort_type]);


 
  // const getReportsData = async () => {
  //   setIsDataLoaded(true);
  
  //   try {
  //     const res = await axiosPrivateCall.get(`/api/v1/reports/get_AM_Reports?timeRange=${selectedTimeRange}`);
  //     setIsDataLoaded(false);
  //     setGetReports(res.data.data);
  //   } catch (error) {
  //     console.error("Error fetching reports:", error);
  //   }
  // };


  // const getReportsDataLead = async () => {
  //   setIsLeadDataLoaded(true);
  
  //   try {
  //     const res = await axiosPrivateCall.get(`/api/v1/reports/get_TL_Reports?timeRange=${selectedTimeRange}`);
  //     setIsLeadDataLoaded(false);
  //     setGetReportsLead(res.data.data);
  //   } catch (error) {
  //     console.error("Error fetching reports:", error);
  //   }
  // };
  

  // const getReportsDataRecruiter = async () => {
  //   setIsRecDataLoaded(true);
  
  //   try {
  //     const res = await axiosPrivateCall.get(`/api/v1/reports/getRecruitersReports?timeRange=${selectedTimeRange}`);
  //     setIsRecDataLoaded(false);
  //     setGetReportsRecruiter(res.data.data);
  //   } catch (error) {
  //     console.error("Error fetching reports:", error);
  //   }
  // };

  
// // Define your useEffect hooks here
// useEffect(() => {
//   if (!isSearching) {
//     switch (selectedRole) {
//       case "all":
//         getReportsData();
//         getReportsDataLead();
//         getReportsDataRecruiter();
//         break;
//       case "account_manager":
//         getReportsData();
//         break;
//       case "lead":
//         getReportsDataLead();
//         break;
//       case "recruiter":
//         getReportsDataRecruiter();
//         break;
//       default:
//         break;
//     }
//   }
// }, [isSearching, selectedRole, selectedTimeRange]);
 
// const getReportsData = debounce(async () => {
//   setIsDataLoaded(true);
//   setGetReports([]);
//   try {
    
//     const res = await axiosPrivateCall.get(`/api/v1/reports/get_AM_Reports?timeRange=${selectedTimeRange}`);
//     setIsDataLoaded(false);
//     setGetReports(res.data.data);
//   } catch (error) {
//     console.error("Error fetching reports:", error);
//   }
// }, 500); // 500 milliseconds debounce delay
// Debounce delay constant
const DEBOUNCE_DELAY = 500;

const getReportsData = debounce(async () => {
  setIsDataLoaded(true);
  setGetReports([]);

  console.log("startDate:", startDate);
  console.log("endDate:", endDate);

  if (startDate && endDate) {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/reports/get_AM_Reports?searchValue=${searchTerm}&start_date=${formateDate(startDate)}&end_date=${formateDate(endDate)}`);
      setIsDataLoaded(false);
      setGetReports(res.data.data);

      console.log("API response:", res.data);
    } catch (error) {
      console.error("Error fetching reports:", error);
      // Handle error, show message to the user, etc.
    }
  } else {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/reports/get_AM_Reports?timeRange=${selectedTimeRange}`);
      setIsDataLoaded(false);
      setGetReports(res.data.data);

      console.log("API response:", res.data);
    } catch (error) {
      console.error("Error fetching reports:", error);
      // Handle error, show message to the user, etc.
    }
  }
}, DEBOUNCE_DELAY);

const setDateHandler = () => {
  console.log(formateDate(startDate), formateDate(endDate));

  if (endDate > startDate) {
    getReportsData();
    getReportsDataLead();
    getReportsDataRecruiter();
  }
};

const getReportsDataLead = debounce(async () => {
  setIsLeadDataLoaded(true);
  setGetReportsLead([]);

  if (startDate && endDate) {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/reports/get_TL_Reports?searchValue=${searchTerm}&start_date=${formateDate(startDate)}&end_date=${formateDate(endDate)}`);
      setIsLeadDataLoaded(false);
      setGetReportsLead(res.data.data);

      console.log("API response (Lead):", res.data);
    } catch (error) {
      console.error("Error fetching Lead reports:", error);
      // Handle error, show message to the user, etc.
    }
  } else {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/reports/get_TL_Reports?timeRange=${selectedTimeRange}`);
      setIsLeadDataLoaded(false);
      setGetReportsLead(res.data.data);

      console.log("API response (Lead):", res.data);
    } catch (error) {
      console.error("Error fetching Lead reports:", error);
      // Handle error, show message to the user, etc.
    }
  }
}, DEBOUNCE_DELAY);

const getReportsDataRecruiter = debounce(async () => {
  setIsRecDataLoaded(true);
  setGetReportsRecruiter([]);

  if (startDate && endDate) {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/reports/getRecruitersReports?searchValue=${searchTerm}&start_date=${formateDate(startDate)}&end_date=${formateDate(endDate)}`);
      setIsRecDataLoaded(false);
      setGetReportsRecruiter(res.data.data);

      console.log("API response (Recruiter):", res.data);
    } catch (error) {
      console.error("Error fetching Recruiter reports:", error);
      // Handle error, show message to the user, etc.
    }
  } else {
    try {
      const res = await axiosPrivateCall.get(`/api/v1/reports/getRecruitersReports?timeRange=${selectedTimeRange}`);
      setIsRecDataLoaded(false);
      setGetReportsRecruiter(res.data.data);

      console.log("API response (Recruiter):", res.data);
    } catch (error) {
      console.error("Error fetching Recruiter reports:", error);
      // Handle error, show message to the user, etc.
    }
  }
}, DEBOUNCE_DELAY);



useEffect(() => {
  // Fetch initial data if not searching
  console.log(selectedRole,"issearch")
  if (!isSearching) {
    if (selectedRole === "all") {
      getReportsData();
      getReportsDataLead();
      getReportsDataRecruiter();
    } else if (selectedRole === "account_manager") {
      getReportsData();
    } else if (selectedRole === "lead") {
      getReportsDataLead();
    } else if (selectedRole === "recruiter") {
      getReportsDataRecruiter();
    } else {
      getReportsData();
      getReportsDataLead();
      getReportsDataRecruiter();
    }

  }
}, [isSearching, selectedRole, selectedTimeRange]); // Dependency array includes isSearching, selectedRole, and selectedTimeRange

  const addEllipsisToName = (name) => {
    const upper_convert = name.charAt(0).toUpperCase() + name.slice(1);

    if (name.length > 14) {
      let new_name = name.substring(0, 13).padEnd(16, ".");
      let convert_Upper = new_name.charAt(0).toUpperCase() + new_name.slice(1);
      return convert_Upper;
    } else return upper_convert;
  };
  const handlePropupOpen = () => {
    setIsInformationContainerVisible(true);
    setIsAMContainerVisible(false);
    setIsLeadContainerVisible(false);
    setIsRecruiterContainerVisible(false);
  };

  const handlePropupOpen1 = () => {
    setIsInformationContainerVisible(false);
    setIsAMContainerVisible(true);
    setIsLeadContainerVisible(false);
    setIsRecruiterContainerVisible(false);
  };

  const handlePropupOpen2 = () => {
    setIsInformationContainerVisible(false);
    setIsAMContainerVisible(false);
    setIsLeadContainerVisible(true);
    setIsRecruiterContainerVisible(false);
  };

  const handlePropupOpen3 = () => {
    setIsInformationContainerVisible(false);
    setIsAMContainerVisible(false);
    setIsLeadContainerVisible(false);
    setIsRecruiterContainerVisible(true);
  };

  const [DropdownSearch, setDropdownSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [SearchData, setSearchData] = useState("");
  const [SplitedData, setSplitedData] = useState("");

  const handleDropdownChange = (e, item) => {
    setDropdownSearch(item.key);

    if (item.key === "all") {
      handlePropupOpen();
      setSelectedRole(item.key);
      getReportsData();
      getReportsDataLead();
      getReportsDataRecruiter();
    } else if (item.key === "account_manager") {
      handlePropupOpen1();
      setSelectedRole(item.key);
      getReportsData();
    } else if (item.key === "lead") {
      handlePropupOpen2();
      setSelectedRole(item.key);
      getReportsDataLead();
    } else if (item.key === "recruiter") {
      handlePropupOpen3();
      setSelectedRole(item.key);
      getReportsDataRecruiter();
    }
    setSearchTerm("");
  };
  
  const handleDropdownChange2 = (e, item) => {
    setDropdownSearch(item.key);
    setSelectedTimeRange(item.key);
    setSearchTerm("");
  };
  // const handleDropdownChange2 = (e, item) => {
  //   // Update the selected time range when the dropdown value changes
  //   setSelectedTimeRange(item.key);
  
  //   // Check if a search is in progress
  //   if (isSearching) {
  //     // If searching, call the searchEmployeeList function with the updated time range
  //     searchEmployeeList(searchTerm, selectedRole, item.key);
  //   } else {
  //     // If not searching, call the appropriate API based on the selected role and updated time range
  //     switch (selectedRole) {
  //       case "all":
  //         getReportsData(item.key);
  //         getReportsDataLead(item.key);
  //         getReportsDataRecruiter(item.key);
  //         break;
  //       case "account_manager":
  //         getReportsData(item.key);
  //         break;
  //       case "lead":
  //         getReportsDataLead(item.key);
  //         break;
  //       case "recruiter":
  //         getReportsDataRecruiter(item.key);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };
  

  const handleSearchInputChange = (event) => {
    if (!event || !event.target) {
      setSearchTerm("");
      return;
    }

    const { value } = event.target;
    setSearchTerm(value);
  };

  const searchEmployeeList = (searchValue, role, timeRange) => {
    setIsSearching(true);
    setSearchTerm(searchValue.trim());
    setIsDataLoaded(true);
    setIsLeadDataLoaded(true);
    setIsRecDataLoaded(true);
  
    let endpoint = `/api/v1/reports/searchReports?searchValue=${searchValue}`;
  
    if (role && role.trim() !== "") {
      endpoint += `&role=${role}`;
    }
  
    if (timeRange && timeRange.trim() !== "") {
      endpoint += `&timeRange=${timeRange}`;
    }
  
    axiosPrivateCall
      .get(endpoint)
      .then((res) => {
        const data = res.data.data;
  
        const accountManagers = data.filter((item) => item.employee.role === "account_manager");
        const teamLeads = data.filter((item) => item.employee.role === "team_lead");
        const recruiters = data.filter((item) => item.employee.role === "recruiter");
  
        setGetReports(accountManagers);
        setGetReportsLead(teamLeads);
        setGetReportsRecruiter(recruiters);
        setSearchData(res.data);
        setSplitedData(15);
        setHasMore(true);
        setEmployeeList(data.slice(0, 15));
        setIsDataLoaded(false);
        setIsLeadDataLoaded(false);
        setIsRecDataLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDataLoaded(true);
        setIsLeadDataLoaded(true);
        setIsRecDataLoaded(true);
      })
    
  };
  

  const clearSearchBox = () => {
    setIsUserSearching(false);
    setFetchOptions((prevData) => {
      return {
        ...prevData,
        search_field: "",
      };
    });
    setSearchTerm("");
    setHasMore(true);
    setIsSearching(false);

  };

  const downloadReport = async () => {
    setLoading(true);
    if (selectedRole === "" || selectedRole ==="all") {
      await axiosPrivateCall
        .get(`/api/v1/reports/downloadReports`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 4000);
          setLoading(false);
          console.log(selectedRole, "rol");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      await axiosPrivateCall
        .get(`/api/v1/reports/downloadReports?role=${selectedRole}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 4000);
          setLoading(false);
          console.log(selectedRole, "rol");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };
  const handleSearch = (e) => {

  if(isAMContainerVisible){
        const searchTerm = e.target.value.toLowerCase(); // Convert search term to lowercase
  setSearchTerm(searchTerm);
  // Filter the initialData array based on the search term
  const filteredData = interviewList.filter(item => {
    // If search term is empty, include all items
    if (!searchTerm) {
      return true;
    }
    // Perform case-insensitive search on employee's first_name, last_name, and email
    return (
     item.employee.employee_id.toLowerCase().includes(searchTerm) ||
      item.employee.first_name.toLowerCase().includes(searchTerm) ||
      item.employee.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.first_name + " " + item.employee.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.email.toLowerCase().includes(searchTerm) ||
      item.employee.reports_to?.first_name.toLowerCase().includes(searchTerm) || 
      item.employee.reports_to?.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.reports_to?.first_name + " " + item.employee.reports_to?.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.mobile_number.toLowerCase().includes(searchTerm) || 
((searchTerm === '0' || searchTerm === 0) && (
  (item.submissionsCount !== undefined && item.submissionsCount === 0) ||
  (item.sub_teamCount !== undefined && item.sub_teamCount === 0)
)) ||
      (item.submissionsCount && item.submissionsCount.toString().includes(searchTerm)) ||
      (item.sub_teamCount && item.sub_teamCount.toString().includes(searchTerm))
    );
  });

  setGetReports(filteredData);
  }else if(isLeadContainerVisible){
      const searchTerm = e.target.value.toLowerCase(); // Convert search term to lowercase
  setSearchTerm(searchTerm);
  // Filter the initialData array based on the search term
  const filteredData = leadList.filter(item => {
    // If search term is empty, include all items
    if (!searchTerm) {
      return true;
    }
    // Perform case-insensitive search on employee's first_name, last_name, and email
    return (
      item.employee.employee_id.toLowerCase().includes(searchTerm) ||
      item.employee.first_name.toLowerCase().includes(searchTerm) ||
      item.employee.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.first_name + " " + item.employee.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.email.toLowerCase().includes(searchTerm) ||
      item.employee.reports_to?.first_name.toLowerCase().includes(searchTerm) || 
      item.employee.reports_to?.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.reports_to?.first_name + " " + item.employee.reports_to?.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.mobile_number.toLowerCase().includes(searchTerm) || 
((searchTerm === '0' || searchTerm === 0) && (
  (item.submissionsCount !== undefined && item.submissionsCount === 0) ||
  (item.sub_teamCount !== undefined && item.sub_teamCount === 0)
)) ||
      (item.submissionsCount && item.submissionsCount.toString().includes(searchTerm)) ||
      (item.sub_teamCount && item.sub_teamCount.toString().includes(searchTerm))
    );
  });

  setGetReportsLead(filteredData);
  }else if(isRecruiterContainerVisible){
          const searchTerm = e.target.value.toLowerCase(); // Convert search term to lowercase
  setSearchTerm(searchTerm);
  // Filter the initialData array based on the search term
  const filteredData = recruiterList.filter(item => {
    // If search term is empty, include all items
    if (!searchTerm) {
      return true;
    }
    // Perform case-insensitive search on employee's first_name, last_name, and email
    return (
 item.employee.employee_id.toLowerCase().includes(searchTerm) ||
      item.employee.first_name.toLowerCase().includes(searchTerm) ||
      item.employee.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.first_name + " " + item.employee.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.email.toLowerCase().includes(searchTerm) ||
      item.employee.reports_to?.first_name.toLowerCase().includes(searchTerm) || 
      item.employee.reports_to?.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.reports_to?.first_name + " " + item.employee.reports_to?.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.mobile_number.toLowerCase().includes(searchTerm) || 
((searchTerm === '0' || searchTerm === 0) && (
  (item.submissionsCount !== undefined && item.submissionsCount === 0) ||
  (item.approvalFalseCount !== undefined && item.approvalFalseCount === 0) ||
   (item.approvalTrueCount !== undefined && item.approvalTrueCount === 0)
)) ||
      (item.submissionsCount && item.submissionsCount.toString().includes(searchTerm)) ||
      (item.approvalFalseCount && item.approvalFalseCount.toString().includes(searchTerm)) ||
      (item.approvalTrueCount && item.approvalTrueCount.toString().includes(searchTerm))
    );
  });

  setGetReportsRecruiter(filteredData);
  }else if(setIsInformationContainerVisible){
     
    // THIS IS FOR ALL - ACCOUNT MANAGER
  const searchTerm = e.target.value.toLowerCase();
  setSearchTerm(searchTerm);
  const filteredData = interviewList.filter(item => {
    if (!searchTerm) {
      return true;
    }
    return (
      item.employee.first_name.toLowerCase().includes(searchTerm) ||
      item.employee.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.first_name + " " + item.employee.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.reports_to?.first_name.toLowerCase().includes(searchTerm) || 
      item.employee.reports_to?.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.reports_to?.first_name + " " + item.employee.reports_to?.last_name).toLowerCase().includes(searchTerm)) ||
      ((searchTerm === '0' || searchTerm === 0) && (
  (item.submissionsCount !== undefined && item.submissionsCount === 0) 
)) ||
      (item.submissionsCount && item.submissionsCount.toString().includes(searchTerm))
    );
  });
  setGetReports(filteredData);

  // this is for lead 
    const filteredDataLead = leadList.filter(item => {
    if (!searchTerm) {
      return true;
    }
    return (
      item.employee.first_name.toLowerCase().includes(searchTerm) ||
      item.employee.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.first_name + " " + item.employee.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.reports_to?.first_name.toLowerCase().includes(searchTerm) || 
      item.employee.reports_to?.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.reports_to?.first_name + " " + item.employee.reports_to?.last_name).toLowerCase().includes(searchTerm)) ||
((searchTerm === '0' || searchTerm === 0) && (
  (item.submissionsCount !== undefined && item.submissionsCount === 0) 
)) ||
      (item.submissionsCount && item.submissionsCount.toString().includes(searchTerm))    );
  });
  setGetReportsLead(filteredDataLead);

  // This is for Rec
  const filteredDataRec = recruiterList.filter(item => {
    if (!searchTerm) {
      return true;
    }
    return (
      item.employee.first_name.toLowerCase().includes(searchTerm) ||
      item.employee.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.first_name + " " + item.employee.last_name).toLowerCase().includes(searchTerm)) ||
      item.employee.reports_to?.first_name.toLowerCase().includes(searchTerm) || 
      item.employee.reports_to?.last_name.toLowerCase().includes(searchTerm) ||
      ((item.employee.reports_to?.first_name + " " + item.employee.reports_to?.last_name).toLowerCase().includes(searchTerm)) ||
((searchTerm === '0' || searchTerm === 0) && (
  (item.submissionsCount !== undefined && item.submissionsCount === 0) )) ||
      (item.submissionsCount && item.submissionsCount.toString().includes(searchTerm))     );
  });

  setGetReportsRecruiter(filteredDataRec);
  }

};


  const onStartDateChange = (date) => {
		setStartDate(date);
		setEndDate(null);
	}

  
	const onFormatDate = (date) => {
		if (!date) {
			return '';
		}
		return date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
	};

	const formateDate = (date) => {

		if (date) {

			const year = date.getFullYear(); // Get the four-digit year
			const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (adding 1 since it's zero-based) and pad with leading zero if necessary
			const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary

			const formattedDate = `${year}-${month}-${day}`;

			return formattedDate
		}

	}
	const minEndDate = startDate ? new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) : undefined;


  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <DeletePopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          handleUpdate={handleUpdate}
          deleteId={deleteId}
          updateCallout={updateCallout}
          setUpdateCallout={setUpdateCallout}
        />

        <div className={styles.nav_container}>
          <div className={styles.title}>Submission Report</div>

          <div className={styles.dashboard_date_picker_container}>
						<DatePicker styles={calendarClass(startDate)} placeholder="Start Date" formatDate={onFormatDate} value={startDate} onSelectDate={onStartDateChange} maxDate={new Date()} />
						<DatePicker styles={calendarClass(endDate)} placeholder="End Date" formatDate={onFormatDate} value={endDate} minDate={minEndDate} onSelectDate={(date) => setEndDate(date)} maxDate={new Date()} disabled={!startDate}/>
						<PrimaryButton  text={'Get'} onClick={setDateHandler}  />
					</div>
          <div className={styles.nav_items}>
            <Dropdown
              placeholder="Select Role"
              onChange={handleDropdownChange}
              options={options}
              styles={(props) => DropdownStyles(props)}
              />
            <Dropdown
              placeholder="Select Filter"
              onChange={handleDropdownChange2}
              options={options2}
              styles={(props) => DropdownStyles(props)}
            />

            <SearchBox
              // onChange={handleSearchInputChange}
              onChange={handleSearch}
              value={searchTerm}
              // onSearch={() => searchEmployeeList(searchTerm, selectedRole, selectedTimeRange)} // Adjust timeRange as needed
              onClear={clearSearchBox}
              placeholder=" "
              iconProps={searchIcon}
              className={styles.search}
              styles={searchFieldStyles}
              showIcon
            />
            {/* <FontIcon iconName="Breadcrumb" className={iconClass} /> //filter icon */}

            {loading ? (
              <Spinner size={SpinnerSize.medium} className={iconClass} />
            ) : completed ? (
              <FontIcon iconName="CheckMark" className={iconClass} />
            ) : (
              <FontIcon iconName="Download" onClick={downloadReport} className={iconClass} />
            )}
          </div>
        </div>

        {isInformationContainerVisible ? (
          <div className={styles.mainBox}>
            {/* Table 1 */}
            <div className={styles.box}>
              <table className={styles.tablecont}>
                <thead>
                  <tr>
                    <th className={styles.head}>Recruiter Name</th>
                    <th className={styles.head}>Reports To</th>
                    <th className={styles.head}>Submission Count</th>
                  </tr>
                </thead>
                <tbody>
                  {!isRecDataLoaded &&
                    getReportsRecruiter?.map((item) => (
                      <tr className={styles.test1} key={item.id}>
                        <td
                          className={styles.tableData}
                          onMouseEnter={(e) => {
                            showCallout(e.target);
                            setHoverCallout(item.employee.first_name);
                          }}
                          onMouseLeave={() => setHoverCallout("")}
                        >
                          {addEllipsisToName(item.employee.first_name + " " + item.employee.last_name)}

                          {(item.employee.first_name + " " + item.employee?.last_name).length >= 14 &&
                            hoverCallout === item.employee.first_name && (
                              <Callout
                                alignTargetEdge={true}
                                isBeakVisible={false}
                                styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                target={targetElement}
                              >
                                {item.employee.first_name + " " + item.employee.last_name}
                              </Callout>
                            )}
                        </td>
                        <td
  className={styles.tableData}
  onMouseEnter={(e) => {
    showCallout(e.target);
    setHoverCallout(item.employee?.reports_to?._id + item.employee._id); // Concatenate both IDs
  }}
  onMouseLeave={() => setHoverCallout("")}
>
  {/* Check if reports_to information is available */}
  {item.employee?.reports_to?.first_name ? (
    <>
      {addEllipsisToName(
        item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name
      )}
      {(item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name).length >= 14 &&
        hoverCallout === item.employee?.reports_to?._id + item.employee._id && ( // Check if both IDs are equal
          <Callout
            alignTargetEdge={true}
            isBeakVisible={false}
            styles={CalloutNameStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={targetElement}
          >
            {item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name}
          </Callout>
        )
      }
    </>
  ) : (
    "-"
  )}
</td>



                        <td className={styles.tableData}>{item.submissionsCount}</td>
                      </tr>
                    ))}
                  {isRecDataLoaded &&
                    items.map((item) => (
                      <tr>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* Table 2 */}
            <div className={styles.box}>
              <table className={styles.tablecont}>
                <thead>
                  <tr>
                    <th className={styles.head}>Lead Name</th>
                    <th className={styles.head}>Reports To</th>
                    <th className={styles.head}>Submission Count</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLeadDataLoaded &&
                    getReportsLead.map((item) => (
                      <tr className={styles.test1} key={item.id}>
                        <td
                          className={styles.tableData}
                          onMouseEnter={(e) => {
                            showCallout(e.target);
                            setHoverCallout(item.employee.first_name);
                          }}
                          onMouseLeave={() => setHoverCallout("")}
                        >
                          {addEllipsisToName(item.employee.first_name + " " + item.employee.last_name)}

                          {(item.employee.first_name + " " + item.employee?.last_name).length >= 14 &&
                            hoverCallout === item.employee.first_name && (
                              <Callout
                                alignTargetEdge={true}
                                isBeakVisible={false}
                                styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                target={targetElement}
                              >
                                {item.employee.first_name + " " + item.employee.last_name}
                              </Callout>
                            )}
                        </td>

                        <td
  className={styles.tableData}
  onMouseEnter={(e) => {
    showCallout(e.target);
    setHoverCallout(item.employee?.reports_to?._id + item.employee._id); // Concatenate both IDs
  }}
  onMouseLeave={() => setHoverCallout("")}
>
  {/* Check if reports_to information is available */}
  {item.employee?.reports_to?.first_name ? (
    <>
      {addEllipsisToName(
        item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name
      )}
      {(item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name).length >= 14 &&
        hoverCallout === item.employee?.reports_to?._id + item.employee._id && ( // Check if both IDs are equal
          <Callout
            alignTargetEdge={true}
            isBeakVisible={false}
            styles={CalloutNameStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={targetElement}
          >
            {item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name}
          </Callout>
        )
      }
    </>
  ) : (
    "-"
  )}
</td>

                        

                        <td className={styles.tableData}>{item.submissionsCount}</td>
                      </tr>
                    ))}
                  {isLeadDataLoaded &&
                    items.map((item) => (
                      <tr className={styles.test1}>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* Table 3 */}
            <div className={styles.box}>
              <table className={styles.tablecont}>
                <thead>
                  <tr>
                    <th className={styles.head}>Account Manager</th>
                    <th className={styles.head}>Reports To</th>
                    <th className={styles.head}>Submission Count</th>
                  </tr>
                </thead>
                <tbody>
                  {!isDataLoaded &&
                    getReports.map((item) => (
                      <tr className={styles.test1} key={item.id}>
                        <td
                          className={styles.tableData}
                          onMouseEnter={(e) => {
                            showCallout(e.target);
                            setHoverCallout(item.employee.first_name);
                          }}
                          onMouseLeave={() => setHoverCallout("")}
                        >
                          {addEllipsisToName(item.employee.first_name + " " + item.employee.last_name)}

                          {(item.employee.first_name + " " + item.employee?.last_name).length >= 14 &&
                            hoverCallout === item.employee.first_name && (
                              <Callout
                                alignTargetEdge={true}
                                isBeakVisible={false}
                                styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                target={targetElement}
                              >
                                {item.employee.first_name + " " + item.employee.last_name}
                              </Callout>
                            )}
                        </td>
                        <td
  className={styles.tableData}
  onMouseEnter={(e) => {
    showCallout(e.target);
    setHoverCallout(item.employee?.reports_to?._id + item.employee._id); // Concatenate both IDs
  }}
  onMouseLeave={() => setHoverCallout("")}
>
  {/* Check if reports_to information is available */}
  {item.employee?.reports_to?.first_name ? (
    <>
      {addEllipsisToName(
        item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name
      )}
      {(item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name).length >= 14 &&
        hoverCallout === item.employee?.reports_to?._id + item.employee._id && ( // Check if both IDs are equal
          <Callout
            alignTargetEdge={true}
            isBeakVisible={false}
            styles={CalloutNameStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={targetElement}
          >
            {item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name}
          </Callout>
        )
      }
    </>
  ) : (
    "-"
  )}
</td>


                        <td className={styles.tableData}>{item.submissionsCount}</td>
                      </tr>
                    ))}
                  {isDataLoaded &&
                    items.map((item) => (
                      <tr className={styles.test1}>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : isAMContainerVisible ? (
          <div className={styles.mainBox}>
            <div className={styles.box1}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.head}></th>
                    <th className={styles.head}>Employee ID</th>
                    <th className={styles.head}>Account Manager Name</th>
                    <th className={styles.head}>Reports to</th>
                    <th className={styles.head}>Mobile</th>
                    <th className={styles.head}>Email ID</th>
                    <th className={styles.head}>Individual Count</th>
                    <th className={styles.head}>Team Count</th>
                  </tr>
                </thead>
                <tbody>
                  {!isDataLoaded &&
                    getReports.map((item) => (
                      <tr className={styles.test1} key={item.id}>
                        <td className={styles.tableData}>
                          <div
                            className={item.employee.status === "Active" ? styles.status : styles.status_inactive}
                          ></div>
                        </td>
                        <td className={styles.tableData}>{item.employee.employee_id}</td>

                        <td
                          className={styles.tableData}
                          onMouseEnter={(e) => {
                            showCallout(e.target);
                            setHoverCallout(item.employee.first_name);
                          }}
                          onMouseLeave={() => setHoverCallout("")}
                        >
                          {addEllipsisToName(item.employee.first_name + " " + item.employee.last_name)}

                          {(item.employee.first_name + " " + item.employee?.last_name).length >= 14 &&
                            hoverCallout === item.employee.first_name && (
                              <Callout
                                alignTargetEdge={true}
                                isBeakVisible={false}
                                styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                target={targetElement}
                              >
                                {item.employee.first_name + " " + item.employee.last_name}
                              </Callout>
                            )}
                        </td>
                        <td
  className={styles.tableData}
  onMouseEnter={(e) => {
    showCallout(e.target);
    setHoverCallout(item.employee?.reports_to?._id + item.employee._id); // Concatenate both IDs
  }}
  onMouseLeave={() => setHoverCallout("")}
>
  {/* Check if reports_to information is available */}
  {item.employee?.reports_to?.first_name ? (
    <>
      {addEllipsisToName(
        item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name
      )}
      {(item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name).length >= 14 &&
        hoverCallout === item.employee?.reports_to?._id + item.employee._id && ( // Check if both IDs are equal
          <Callout
            alignTargetEdge={true}
            isBeakVisible={false}
            styles={CalloutNameStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={targetElement}
          >
            {item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name}
          </Callout>
        )
      }
    </>
  ) : (
    "-"
  )}
</td>


                        <td className={styles.tableData}>{item.employee.mobile_number}</td>
                        <td className={styles.tableData}>{item.employee.email}</td>
                        <td className={styles.tableData}>{item.submissionsCount}</td>
                        <td className={styles.tableData}>{item.sub_teamCount}</td>
                      </tr>
                    ))}
                  {isDataLoaded &&
                    items.map((item) => (
                      <tr className={styles.test1}>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : isLeadContainerVisible ? (
          <div className={styles.mainBox}>
            <div className={styles.box1}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.head}></th>
                    <th className={styles.head}>Employee ID</th>
                    <th className={styles.head}>Lead Name</th>
                    <th className={styles.head}>Reports to</th>
                    <th className={styles.head}>Mobile</th>
                    <th className={styles.head}>Email ID</th>
                    <th className={styles.head}>Individual Count</th>
                    <th className={styles.head}>Team Count</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLeadDataLoaded &&
                    getReportsLead.map((item) => (
                      <tr className={styles.test1} key={item.id}>
                        <td className={styles.tableData}>
                          <div
                            className={item.employee.status === "Active" ? styles.status : styles.status_inactive}
                          ></div>
                        </td>
                        <td className={styles.tableData}>{item.employee.employee_id}</td>

                        <td
                          className={styles.tableData}
                          onMouseEnter={(e) => {
                            showCallout(e.target);
                            setHoverCallout(item.employee.first_name);
                          }}
                          onMouseLeave={() => setHoverCallout("")}
                        >
                          {addEllipsisToName(item.employee.first_name + " " + item.employee.last_name)}

                          {(item.employee.first_name + " " + item.employee?.last_name).length >= 14 &&
                            hoverCallout === item.employee.first_name && (
                              <Callout
                                alignTargetEdge={true}
                                isBeakVisible={false}
                                styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                target={targetElement}
                              >
                                {item.employee.first_name + " " + item.employee.last_name}
                              </Callout>
                            )}
                        </td>
                        <td
  className={styles.tableData}
  onMouseEnter={(e) => {
    showCallout(e.target);
    setHoverCallout(item.employee?.reports_to?._id + item.employee._id); // Concatenate both IDs
  }}
  onMouseLeave={() => setHoverCallout("")}
>
  {/* Check if reports_to information is available */}
  {item.employee?.reports_to?.first_name ? (
    <>
      {addEllipsisToName(
        item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name
      )}
      {(item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name).length >= 14 &&
        hoverCallout === item.employee?.reports_to?._id + item.employee._id && ( // Check if both IDs are equal
          <Callout
            alignTargetEdge={true}
            isBeakVisible={false}
            styles={CalloutNameStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={targetElement}
          >
            {item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name}
          </Callout>
        )
      }
    </>
  ) : (
    "-"
  )}
</td>


                        <td className={styles.tableData}>{item.employee.mobile_number}</td>
                        <td className={styles.tableData}>{item.employee.email}</td>
                        <td className={styles.tableData}>{item.submissionsCount}</td>
                        <td className={styles.tableData}>{item.sub_teamCount}</td>
                      </tr>
                    ))}
                  {isLeadDataLoaded &&
                    items.map((item) => (
                      <tr className={styles.test1}>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : isRecruiterContainerVisible ? (
          <div className={styles.mainBox}>
            {/* Table 1 */}
            <div className={styles.box1}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.head}></th>
                    <th className={styles.head}>Employee ID</th>
                    <th className={styles.head}>Recruiter Name</th>
                    <th className={styles.head}>Reports to</th>
                    <th className={styles.head}>Mobile</th>
                    <th className={styles.head}>Email ID</th>
                    <th className={styles.head}>Sourced Count</th>
                    <th className={styles.head}>Rejected Count</th>
                    <th className={styles.head}>Approval Count</th>
                  </tr>
                </thead>
                <tbody>
                  {!isRecDataLoaded &&
                    getReportsRecruiter?.map((item) => (
                      <tr className={styles.test1} key={item.id}>
                        <td className={styles.tableData}>
                          <div
                            className={item.employee.status === "Active" ? styles.status : styles.status_inactive}
                          ></div>
                        </td>
                        <td className={styles.tableData}>{item.employee.employee_id}</td>

                        <td
                          className={styles.tableData}
                          onMouseEnter={(e) => {
                            showCallout(e.target);
                            setHoverCallout(item.employee.first_name);
                          }}
                          onMouseLeave={() => setHoverCallout("")}
                        >
                          {addEllipsisToName(item.employee.first_name + " " + item.employee.last_name)}

                          {(item.employee.first_name + " " + item.employee?.last_name).length >= 14 &&
                            hoverCallout === item.employee.first_name && (
                              <Callout
                                alignTargetEdge={true}
                                isBeakVisible={false}
                                styles={CalloutNameStyles}
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                target={targetElement}
                              >
                                {item.employee.first_name + " " + item.employee.last_name}
                              </Callout>
                            )}
                        </td>
                        <td
  className={styles.tableData}
  onMouseEnter={(e) => {
    showCallout(e.target);
    setHoverCallout(item.employee?.reports_to?._id + item.employee._id); // Concatenate both IDs
  }}
  onMouseLeave={() => setHoverCallout("")}
>
  {/* Check if reports_to information is available */}
  {item.employee?.reports_to?.first_name ? (
    <>
      {addEllipsisToName(
        item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name
      )}
      {(item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name).length >= 14 &&
        hoverCallout === item.employee?.reports_to?._id + item.employee._id && ( // Check if both IDs are equal
          <Callout
            alignTargetEdge={true}
            isBeakVisible={false}
            styles={CalloutNameStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            target={targetElement}
          >
            {item.employee?.reports_to?.first_name + " " + item.employee?.reports_to?.last_name}
          </Callout>
        )
      }
    </>
  ) : (
    "-"
  )}
</td>


                        <td className={styles.tableData}>{item.employee.mobile_number}</td>
                        <td className={styles.tableData}>{item.employee.email}</td>
                        <td className={styles.tableData}>{item.submissionsCount}</td>
                        <td className={styles.tableData}>{item.approvalFalseCount}</td>
                        <td className={styles.tableData}>{item.approvalTrueCount}</td>
                      </tr>
                    ))}
                  {isRecDataLoaded &&
                    items.map((item) => (
                      <tr className={styles.test1}>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                        <td className={styles.tableData}>
                          <Shimmer />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SubmissionReport;

import { createSlice } from '@reduxjs/toolkit';

const bdeDashboardDataSlice = createSlice({
  name: 'bde_dashboard_data',
  initialState: {
    response: null,
  },
  reducers: {
    saveBDEDashboardData: (state, action) => {
      state.response = action.payload;
    },
    resetBDEDashboardData: (state) => {
      state.response = null;
    }
  }
});

export const { saveBDEDashboardData, resetBDEDashboardData } = bdeDashboardDataSlice.actions;

export const bdedataReducer = bdeDashboardDataSlice.reducer; // Exporting dataReducer as named export
export default bdeDashboardDataSlice.reducer;

const digits = '0123456789';
const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const specialCharacters = '!@#$%^&*()';

const pwordLength = 10;

const generatePassword = () => {
    let password = '';
let chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    
    password += getRandomCharacter(digits);
    password += getRandomCharacter(lowercaseLetters);
    password += getRandomCharacter(uppercaseLetters);
    password += getRandomCharacter(specialCharacters);

    for (let i = password.length; i < pwordLength; i++) {
        password += chars[Math.floor(Math.random() * chars.length)];
    }

    password = shuffleString(password);

    console.log(password);
    return password;
};

// Function to get a random character from a given string
const getRandomCharacter = (charSet) => {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    return charSet[randomIndex];
};


const shuffleString = (str) => {
    const array = str.split('');
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join('');
};

export { generatePassword }




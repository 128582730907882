import { createSlice } from '@reduxjs/toolkit';

const dashboardDataSlice = createSlice({
  name: 'dashboard_data',
  initialState: {
    response: null,
  },
  reducers: {
    saveDashboardData: (state, action) => {
      state.response = action.payload;
    },
    resetDashboardData: (state) => {
      state.response = null;
    }
  }
});

export const { saveDashboardData, resetDashboardData } = dashboardDataSlice.actions;

export const dataReducer = dashboardDataSlice.reducer; // Exporting dataReducer as named export
export default dashboardDataSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Modal, TextField, Label, MessageBar, MessageBarType } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import styles from './Popup.module.css';
export function EventCancelPopup(props) {
    let showPopup = props.showPopup;
    let setShowPopup = props.setShowPopup;
    const titleValue = props.titleValue;
    const [notes, setNotes] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (showToast) {
            const timerId = setTimeout(() => {
                setShowToast(false);
            }, 3500);
            return () => clearTimeout(timerId);
        }
    }, [showToast]);
    const messageBarStyles = {
        root: {
            width: 300, 
            position: 'absolute',
            top: 65, 
            right:720,
            zIndex: 1000, 
        },
        content: {
            maxWidth: '100%',
        },
    };    
    const lighterBorderStyle = {
        fieldGroup: {
            width: 440,
            borderColor: isInvalid ? "#8B0000" : "#E1E5E8",
            display: "flex",
        },
    };
    const validateNotes = () => {
        const regex = /^[a-zA-Z0-9.,'&() \-]*$/;
        const isValid = regex.test(notes.trim());
        const isLengthValid = notes.trim().length >= 2 && notes.trim().length <= 1500;
        return isValid && isLengthValid;
    };
    const handleNotesChange = (value) => {
        setNotes(value);
        setIsInvalid(false);
        setErrorMessage(""); 
    };
    const handleUpdate = () => {
        const trimmedNotes = notes.trim();
        if (trimmedNotes === "") {
            setIsInvalid(true);
            setErrorMessage("Required");
            return;
        }
        if (!validateNotes()) {
            setIsInvalid(true);
            setErrorMessage("Invalid Character");
            return;
        }
        setShowToast(true);
        setShowPopup(false);
        if (props.onSaveButtonClick) {
            props.onSaveButtonClick(trimmedNotes);
        }
    };
    return (
        <>
            <Modal isOpen={showPopup} containerClassName={styles.main_cancel_popup}>
                <div className={styles.cancelClosePopup}>
                    <div className={styles.cancelTopContainer}>
                        <div className={styles.cancelTitle}>{`Are you sure you want to cancel this interview (${titleValue})?`}</div>
                        <div className={styles.cancelCloseButton} onClick={() => setShowPopup(!showPopup)}><Icon iconName='ChromeClose' /></div>
                    </div>
                    <div className={styles.message}>
                        This action cannot be undone. Please select 'Yes' to confirm cancellation, or select 'No' to return without cancelling.
                    </div>
                    <div className={styles.message}>
                        <Label required>Add Notes</Label>
                    </div>
                    <div className={styles.commentbox1}>
                        <TextField
                            placeholder="Enter the Notes"
                            multiline
                            rows={6}
                            value={notes}
                            onChange={(e, newValue) => handleNotesChange(newValue)}
                            styles={lighterBorderStyle}
                            errorMessage={isInvalid ? errorMessage : undefined}
                        />
                    </div>
                    <div className={styles.cancelBottomContainer}>
                        <div className={styles.spacer}></div>
                        <div className={styles.cancelButtonContainer}>
                            <DefaultButton text="No" onClick={() => setShowPopup(!showPopup)} className={styles.eventCancelNo} />
                            <PrimaryButton text="Yes" onClick={handleUpdate} className={styles.eventCancelYes} />
                        </div>
                    </div>
                </div>
            </Modal>
            {showToast && (
                <div>
                    <MessageBar
                        styles={messageBarStyles}
                        onDismiss={() => setShowToast(false)}
                        dismissButtonAriaLabel="Close"
                        messageBarType={MessageBarType.success}
                    >
                        Interview Cancelled Successfully!
                    </MessageBar>
                </div>
            )}
        </>
    );
}
import React from "react";
import hierarcharrowdown from '../assets/hierarchyarrowdown.svg'


const HierarchyArrow = () => {


	return (
		<div>

			<img src={hierarcharrowdown} alt="" />

		</div>
	)



}



export default HierarchyArrow;
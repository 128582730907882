import React, { useState } from "react";
import styles from "./TrackOpportunity.module.css";
import { Icon, Callout, DirectionalHint } from "@fluentui/react";
import { ISOdateToCustomDate } from "../utils/helpers";


export default function TrackOpportunity(props) {
  const data = props.value;

  const [selectedTrackerIndex, setSelectedTrackerIndex] = useState(null);
  const [hoverCallout, setHoverCallout] = useState(null);
  const [targetElement, setTargetElement] = useState(null);
  const addEllipsisToName = (name) => {
    if (name.length > 12) {
      let new_name = name.substring(0, 12).padEnd(15, "...");
      return new_name;
    } else return name;
  };

  function getStatusClass(status) {
    switch (status) {
      case "Won":
      case "In Progress":
        return styles.green_dot;
      case "Hold":
      case "N/A":
        return styles.grey_dot;
      case "Lost":
      case "Drop":
        return styles.red_dot;
      default:
        return "";
    }
  }

  const handleMouseEnter = (name, e) => {
    if (name.length > 12) {
      setHoverCallout(name);
      setTargetElement(e.target);
    }
  };

  const handleMouseLeave = () => {
    setHoverCallout(null);
  };

  const handleCardClick = (index) => {
    setSelectedTrackerIndex((prevIndex) => 
      prevIndex === index ? null : index
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.History_header_container}>
        <div className={styles.Header}>Opportunity Tracker</div>
        <div className={styles.info_container}>
          <div className={styles.rectangle_header}>
            {data.request_client?.company_name + " - " + data.name}
          </div>
          <div className={styles.history_rectangle}>
            <div className={styles.rectangle}>
              <div className={styles.arrow_container}>
                <div className={styles.arrowUpper}>Created By</div>
                <div className={styles.blue_arrow}></div>
                <div
                  className={styles.arrowLower}
                  onMouseEnter={(e) =>
                    handleMouseEnter(
                      `${data.request_client?.created_by?.first_name} ${data.request_client?.created_by?.last_name}`,
                      e
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  {addEllipsisToName(
                    `${data.request_client?.created_by?.first_name} ${data.request_client?.created_by?.last_name}`
                  )}
                </div>
                <div className={styles.arrowUpper}>
                  {ISOdateToCustomDate(data.createdAt)}
                </div>
              </div>
              <div className={styles.custom}>
                <Icon
                  className={styles.status_dot}
                  iconName="LocationDot"
                ></Icon>
                <h5 className={styles.custom_client}>
                  <span>Client ID</span>
                  <span>{data.request_client?.ClientId}</span>
                </h5>
              </div>
              <div className={styles.arrow_container}>
                <div className={styles.arrowUpper}>Created By</div>
                <div className={styles.blue_arrow}></div>
                <div
                  className={styles.arrowLower}
                  onMouseEnter={(e) =>
                    handleMouseEnter(
                      `${data.created_by?.first_name} ${data.created_by?.last_name}`,
                      e
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  {addEllipsisToName(
                    `${data.created_by?.first_name} ${data.created_by?.last_name}`
                  )}
                </div>
                <div className={styles.arrowUpper}>
                  {ISOdateToCustomDate(data.createdAt)}
                </div>
              </div>
              <div className={styles.custom}>
                <Icon
                  className={styles.status_dot}
                  iconName="LocationDot"
                ></Icon>
                <h5 className={styles.custom_oppId}>
                  <span>Opportunity ID</span>
                  <span className={styles.OppId}>{data.Opportunity_id}</span>
                </h5>
              </div>
                            
              {data.oppurtunity_tracker.map((tracker, index) => (
                <div className={styles.map} key={index}>
                  <div className={styles.arrow_container}>
                    <p className={styles.arrowUpper}>{tracker.level}</p>
                    <p className={styles.blue_arrow}></p>
                    <p
                      className={styles.arrowLower}
                      onMouseEnter={(e) =>
                        handleMouseEnter(
                          `${tracker.updated_by?.first_name} ${tracker.updated_by?.last_name}`,
                          e
                        )
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      {addEllipsisToName(
                        `${tracker.updated_by?.first_name} ${tracker.updated_by?.last_name}`
                      )}
                    </p>
                    <p className={styles.arrowUpper}>
                      {ISOdateToCustomDate(tracker.updatedAt)}
                    </p>
                  </div>

                  <div
                    className={styles.custom_card}
                    onClick={() => handleCardClick(index)}
                  >
                    <Icon
                      className={getStatusClass(tracker.status)}
                      iconName="LocationDot"
                    ></Icon>
                    <h5 className={styles.custom_value}>
                      <span>{tracker.status}</span>
                    </h5>
                  </div>
                </div>
              ))}

            </div>
            {selectedTrackerIndex !== null && (
              <div>
                <div className={styles.Remarks_header}>Remark</div>
                <div className={styles.Remarks_value}>
                  {data.oppurtunity_tracker[selectedTrackerIndex].notes}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {hoverCallout && (
        <Callout
          alignTargetEdge={true}
          isBeakVisible={false}
          styles={{ root: { padding: "2px" } }}
          directionalHint={DirectionalHint.bottomLeftEdge}
          target={targetElement}
        >
          {hoverCallout}
        </Callout>
      )}
    </div>
  );
}
